import React, { useEffect, useState } from 'react';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePaymentMade, fetchPaymentMadeDetails } from '../../services/payment_made';
import { getDate } from '../../utils/dateUtils';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import { toast } from 'react-toastify';
import { numberToWords } from '../../utils/helper';
import pdfMake, { fonts } from 'pdfmake/build/pdfmake';

const PaymentMadeDetails = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const [paymentMadeDetails, setPaymentMadeDetails] = useState({});
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	const navigateRouter = (path) => {
		navigate(path);
	};

	async function handleConfirmDelete() {
		try {
			await deletePaymentMade([Number(id)]);
			toast.success('payment made deleted successfully');
			navigateRouter(`/user/${suburl}/payment-made`);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleFetchPaymentMadeDetails() {
		const pmDetails = await fetchPaymentMadeDetails(id);
		console.log({ pmDetails });
		setPaymentMadeDetails(pmDetails);
	}

	async function generatePdfToPrintAndDownload() {
		// const filterValue = bulkAction.join(',');
		// const { purchaseBills } = await fetchPurchaseBill({
		// 	filterBy: 'purchase_bill_id',
		// 	filterValue,
		// });
		const docDefinitions = [];
		const {
			vendor,
			organisation,
			payment_number,
			date,
			reference_number,
			payment_mode,
			payment_amount,
			purchase_bill_payment_made,
		} = paymentMadeDetails;
		docDefinitions.push({
			content: [
				{
					text: vendor?.vendor_name,
					style: 'vendorName',
					margin: [0, 20, 0, 0],
				},
				{
					columns: [
						{
							stack: [
								{
									text: [
										'\nRajasthan\nIndia\n',
										{ text: `${vendor?.phone_mobile} \n ${vendor?.email}`, bold: true },
									],
									style: 'vendorAddress',
								},
							],
						},
						{
							stack: [
								{ text: 'BILL', style: 'header', bold: true },
								{ text: 'N/A', style: 'billNumber' },
							],
							margin: [0, 20, 0, 10],
						},
					],
					columnGap: 100,
				},
				{
					columns: [
						{
							stack: [
								{ text: `Payment# : ${payment_number || 'N/A'}`, style: 'detail' },
								{
									text: `Payment Date : ${date != null ? getDate(date) : 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Reference Number : ${reference_number || 'N/A'}`,
									style: 'detail',
								},
							],
						},
						{
							stack: [
								{ text: 'Amount Paid', style: 'label' },
								{ text: `Rs ${payment_amount ?? 0.0}`, bold: true },
							],
							fillColor: '#e3e3e3', // Light gray background color
							margin: [10, 5, 10, 5], // Optional: Adds padding around the container
							border: [true, true, true, true], // Optional: Adds borders around the container
							borderColor: '#e3e3e3',
						},
					],
					columnGap: 100,
				},
				{
					columns: [
						{
							stack: [
								{
									text: `Payment Mode : ${payment_mode || 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Paid Through : ${vendor.vendor_display_name || 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Amount Paid In Words : ${numberToWords(Number(payment_amount)) || 'N/A'}`,
									style: 'detail',
								},
							],
						},
					],
				},
				{
					stack: [
						{ text: 'Paid To', style: 'label' },
						{ text: `${organisation?.organisation_name}`, style: 'linkStyle' },
					],
					margin: [0, 30, 10, 10],
				},
				{
					table: {
						widths: ['30%', '20%', '20%', '30%'],
						body: [
							[
								{ text: 'Bill Number', bold: true },
								{ text: 'Bill Date', bold: true },
								{ text: 'Bill Amount', bold: true },
								{ text: 'Payment Amount', bold: true },
							],
							...purchase_bill_payment_made.map((pm, index) => [
								{ text: pm.purchase_bill?.bill_number, color: 'blue' },
								{ text: getDate(pm.purchase_bill?.bill_date) },
								{ text: pm.purchase_bill?.bill_amount },
								{ text: pm.payment_amount },
							]),
						],
					},
					margin: [0, 20, 0, 10],
				},
				{
					text: 'Authorized Signature --------------------',
					margin: [0, 30, 0, 0],
					alignment: 'left',
				},
			],
			styles: {
				status: {
					fontSize: 10,
					margin: [0, 0, 0, 5],
				},
				linkStyle: {
					fontSize: 12,
					color: '#3B58FF',
				},
				header: {
					fontSize: 15,
					margin: [0, 10, 10, 0],
				},
				vendorName: {
					fontSize: 16,
					bold: true,
				},
				vendorAddress: {
					fontSize: 12,
				},
				subheader: {
					fontSize: 14,
					bold: true,
				},
				orderNumber: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				orderDate: {
					fontSize: 12,
				},
				label: {
					fontSize: 15,
					bold: true,
					margin: [0, 5, 0, 5],
				},
				detail: {
					fontSize: 10,
					margin: [0, 2, 0, 2],
				},
			},
		});
		// });

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};
		pdfMake.createPdf(mergedDocDefinition).print();

		// return mergedDocDefinition;
	}

	useEffect(() => {
		if (id) {
			handleFetchPaymentMadeDetails();
		}
	}, [id]);

	return (
		<div style={{ backgroundColor: '#EDEDF7', overflow: 'auto', height: '100%' }}>
			<div className="">
				<div
					className=""
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 100,
					}}
				>
					<div className="product-pg-header add-product">
						<p>Payment Made Detail</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Edit"
								onClick={() => navigate(`/user/${suburl}/payment-made-create/${id}`)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Pdf/Print"
								onClick={() => generatePdfToPrintAndDownload()}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Send Email"
								// onClick={() => handleSavePurchaseReceive(1)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Delete"
								onClick={() => setIsDeleteModalOpen(true)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/payment-made`)}
							/>
						</div>
					</div>
				</div>

				{/* Payment Made Details */}
				<div>
					<div
						style={{
							maxWidth: '750px',
							margin: '19px auto',
							minHeight: '600px',
							backgroundColor: '#fff',
							padding: '20px',
							boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
						}}
					>
						{/* Store Details */}
						<div style={{ border: '1px solid #555B67', padding: '12px' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ marginBottom: '20px' }}>
									<h4 style={{ marginBottom: '13px', fontWeight: 'bold', fontSize: '22px' }}>
										Medical Store
									</h4>
									<p style={{ color: '#555B67', fontSize: '14px' }}>N/A</p>
									<p style={{ color: '#555B67', fontSize: '14px' }}>N/A</p>
									<p style={{ color: '#555B67', fontSize: '14px' }}>
										{paymentMadeDetails?.vendor?.email}
									</p>
								</div>

								<div style={{ marginTop: '16px' }}>
									<h2
										style={{
											fontSize: '30px',
											color: '#000000',
											fontWeight: '600',
											textAlign: 'end',
											marginTop: '8px',
											marginBottom: '12px',
										}}
									>
										BILL
									</h2>
									<p>N/A</p>
								</div>
							</div>

							{/* Payment Info */}

							<h5
								style={{
									margin: '8px 262px 28px 280px',
									fontWeight: '600',
									borderBottom: '1px solid black',
									fontSize: '20px',
									textAlign: 'center',
									maxWidth: '148px',
									paddingBottom: '5px',
								}}
							>
								Payment Made
							</h5>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									marginBottom: '20px',
								}}
							>
								<div>
									<div className="d-flex">
										<div style={{ marginTop: '5px', color: '#555B67' }}>
											<p style={{ marginBottom: '16px' }}>Payment#</p>
											<p style={{ marginBottom: '16px' }}>Payment Date</p>
											<p style={{ marginBottom: '16px' }}>Reference Number</p>
											<p style={{ marginBottom: '16px' }}>Payment Mode</p>
											<p style={{ marginBottom: '16px' }}>Paid Through</p>
											<p style={{ marginBottom: '16px' }}>Amount Paid In Words</p>
										</div>
										<div style={{ width: '270px', marginLeft: '35px', fontWeight: 'bold' }}>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '20px' }}>
												{paymentMadeDetails?.payment_number}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>
												{getDate(paymentMadeDetails?.date)}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>
												{paymentMadeDetails?.reference_number || 'N/A'}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>
												{paymentMadeDetails?.payment_mode}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>N/A</p>
											<p style={{ borderBottom: '1px solid #E5E6E8' }}>
												{numberToWords(Number(paymentMadeDetails?.payment_amount))}
											</p>
										</div>
									</div>
								</div>

								<div
									style={{
										backgroundColor: '#84AD5F',
										width: '170px',
										height: '110px',
										marginTop: '18px',
										color: 'white',
										textAlign: 'center',
									}}
								>
									<div style={{ marginTop: '32px' }}>
										<p style={{ marginRight: '24px' }}> Amount Paid</p>
										<p style={{ fontSize: '20px', fontWeight: '600' }}>
											{'Rs. '}
											{paymentMadeDetails?.payment_amount}
										</p>
									</div>
								</div>
							</div>

							{/* Paid To */}
							<div style={{ marginBottom: '20px' }}>
								<p style={{ fontWeight: 'bold', margin: 0 }}>Paid To</p>
								<a
									href="#"
									style={{ color: '#3B58FF', textDecoration: 'none', fontWeight: 'bold' }}
								>
									ABC Company Private Limited
								</a>
							</div>

							{/* Table */}
							<table
								style={{
									width: '100%',
									border: '1px solid #dee2e6',
									borderCollapse: 'collapse',
									textAlign: 'left',
								}}
							>
								<thead>
									<tr style={{ backgroundColor: '#f1f3f5', fontWeight: 'bold', color: '#555B67' }}>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'left' }}>
											Bill Number
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'left' }}>
											Bill Date
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'right' }}>
											Bill Amount
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'right' }}>
											Payment Amount
										</th>
									</tr>
								</thead>
								<tbody>
									{paymentMadeDetails.purchase_bill_payment_made?.map((pb) => (
										<tr>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'left',
													color: '#3B58FF',
												}}
											>
												{pb?.purchase_bill?.bill_number}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'left',
													color: '#555B67',
												}}
											>
												{getDate(pb?.purchase_bill?.bill_date)}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'right',
													color: '#555B67',
												}}
											>
												{pb?.purchase_bill?.bill_amount}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'right',
													color: '#555B67',
												}}
											>
												{pb?.payment_amount}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal
				isOpen={isDeleteModalOpen}
				onCancel={() => setIsDeleteModalOpen(false)}
				onOk={handleConfirmDelete}
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete{' '}
						<span style={{ fontWeight: '600' }}>{paymentMadeDetails?.payment_number}</span>?
					</p>
				}
			/>
		</div>
	);
};

export default PaymentMadeDetails;
