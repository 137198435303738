import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDetails from '../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../purchaseOrder/Navigation/Transactions';
import { toast } from 'react-toastify';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Modal from 'react-responsive-modal';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import { paymentModeColumn } from './purchaseBillsColoum';
import PageLoader from '../../component/PageLoader';
import pdfMake from 'pdfmake/build/pdfmake';
import Header from '../components/Header';
import ArrowUp from '../../assets/image/arrow_up.svg';
import ArrowDown from '../../assets/image/arrowD.svg';
import PurchaseBillInvoice from '../components/PurchaseBillInvoice';
import { deletePurchaseBill, fetchPurchaseBillDetails } from '../../services/purchase_bill';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import { getDate } from '../../utils/dateUtils';
import ProductDetailsDrawer from '../components/ProductDetailsDrawer';

const PurchaseBillDetails = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [purchaseBillDetails, setPurchaseBillDetails] = useState(null);
	const [paymentMade, setPaymentMade] = useState([]);
	const [activeTab, setActiveTab] = useState('bill');
	const [isProductDetailsSidebarOpen, setIsProductDetailsSidebarOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isShowTable, setIsShowTable] = useState(false);
	const navTabs = ['Product Details', 'Transactions'];
	const renderTabContent = () => {
		switch (activeTab) {
			case 'Product Details':
				return <ProductDetails />;
			case 'Transactions':
				return <Transactions />;
			default:
				return null;
		}
	};

	async function handleDeletePurchaseBill() {
		try {
			await deletePurchaseBill([id]);
			toast.success('Purchase bill deleted successfully.');
			navigate(`/user/${suburl}/purchases-bills`);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function generatePdfToPrintAndDownload() {
		const docDefinitions = [];
		const {
			vendor,
			bill_number,
			bill_date,
			due_date,
			terms,
			due_amount,
			payment_method,
			purchase_bill_products,
		} = purchaseBillDetails;
		docDefinitions.push({
			content: [
				{
					text: vendor?.vendor_name,
					style: 'vendorName',
					margin: [0, 20, 0, 0],
				},
				{
					columns: [
						{
							stack: [
								{
									text: [
										'\nRajasthan\nIndia\n',
										{ text: `${vendor?.phone_mobile} \n ${vendor?.email}`, bold: true },
									],
									style: 'vendorAddress',
								},
							],
						},
						{
							stack: [
								{ text: 'BILL', style: 'header', bold: true },
								{ text: `#${bill_number}`, style: 'billNumber' },
							],
							margin: [0, 20, 0, 10],
						},
					],
					columnGap: 100,
				},
				{
					columns: [
						{
							stack: [
								{ text: `Bill Date. : ${getDate(bill_date) || 'N/A'}`, style: 'detail' },
								{
									text: `Due Date : ${due_date != null ? getDate(due_date) : 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Terms : ${terms || 'N/A'}`,
									style: 'detail',
								},
							],
						},
						{
							stack: [
								{ text: 'Balance Due', style: 'label' },
								{ text: `Rs ${due_amount ?? 0.0}`, bold: true },
							],
						},
					],
					columnGap: 100,
				},
				{
					stack: [
						{ text: 'Bill To', style: 'label' },
						{ text: `${vendor?.vendor_display_name}`, style: 'linkStyle' },
					],
					margin: [0, 30, 10, 10],
				},
				{
					table: {
						widths: ['5%', '40%', '15%', '15%', '25%'],
						body: [
							[
								{ text: '#', bold: true },
								{ text: 'ITEMS AND DESCRIPTION', bold: true },
								{ text: 'QNTY', bold: true },
								{ text: 'RATE', bold: true },
								{ text: 'AMOUNT', bold: true },
							],
							...purchase_bill_products.map((prod, index) => [
								{ text: index + 1 },
								{ text: prod.product?.product_name, color: 'blue' },
								{ text: prod.quantity },
								{ text: prod.purchase_rate },
								{ text: prod.amount },
							]),
						],
					},
					margin: [0, 20, 0, 10],
				},
				{
					columns: [
						{
							stack: [
								{
									text: `Sub Total :  Rs ${
										purchase_bill_products?.reduce(
											(total, bill) => Number(total) + Number(bill.amount),
											0,
										) || 'N/A'
									}`,
									style: 'detail',
								},
								{
									text: ` Total: Rs ${
										purchase_bill_products?.reduce(
											(total, bill) => Number(total) + Number(bill.amount),
											0,
										) || 'N/A'
									}`,
									style: 'detail',
								},
								{
									text: `Payment Made : ${payment_method || 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Balance Due :  Rs ${due_amount ?? 0.0}`,
									style: 'detail',
								},
							],
							alignment: 'right',
						},
					],
				},
				{
					text: 'Authorized Signature --------------------',
					margin: [0, 30, 0, 0],
					alignment: 'left',
				},
			],
			styles: {
				status: {
					fontSize: 10,
					margin: [0, 0, 0, 5],
				},
				linkStyle: {
					fontSize: 12,
					color: '#3B58FF',
				},
				header: {
					fontSize: 15,
					margin: [0, 10, 10, 0],
				},
				vendorName: {
					fontSize: 16,
					bold: true,
				},
				vendorAddress: {
					fontSize: 12,
				},
				subheader: {
					fontSize: 14,
					bold: true,
				},
				orderNumber: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				orderDate: {
					fontSize: 12,
				},
				label: {
					fontSize: 15,
					bold: true,
					margin: [0, 5, 0, 5],
				},
				detail: {
					fontSize: 10,
					margin: [0, 2, 0, 2],
				},
			},
		});
		// });

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};
		pdfMake.createPdf(mergedDocDefinition).print();

		// return mergedDocDefinition;
	}

	// async function handlePrintAndDownloadPdf(isPrint) {
	// 	setLoading((prev) => {
	// 		const obj = {};
	// 		if (isPrint) {
	// 			obj.pdf = {
	// 				button: 'pdf',
	// 				isLoading: true,
	// 			};
	// 		} else {
	// 			obj.download = {
	// 				button: 'download',
	// 				isLoading: true,
	// 			};
	// 		}

	// 		return {
	// 			...prev,
	// 			...obj,
	// 		};
	// 	});

	// 	const mergedDocDefinition = await generatePdfToPrintAndDownload();
	// 	if (isPrint) {
	// 		pdfMake.createPdf(mergedDocDefinition).print();
	// 	} else {
	// 		pdfMake.createPdf(mergedDocDefinition).download();
	// 	}

	// 	setLoading((prev) => ({
	// 		...prev,
	// 		pdf: {
	// 			...prev.pdf,
	// 			isLoading: false,
	// 		},
	// 		download: {
	// 			...prev.download,
	// 			isLoading: false,
	// 		},
	// 	}));
	// }

	async function handleFetchPurchaseBillDetails() {
		try {
			const purchaseBillDetails = await fetchPurchaseBillDetails(id);
			setPurchaseBillDetails(purchaseBillDetails);
			var purchaseBillPaymentMade =
				purchaseBillDetails.purchase_bill_payment_made === null ||
				purchaseBillDetails.purchase_bill_payment_made.length === 0
					? []
					: purchaseBillDetails.purchase_bill_payment_made.map((pm) => {
							return {
								id: pm.payment_made_id,
								DATE: getDate(pm?.payment_made?.date),
								'PAYMENT#': pm?.payment_made?.payment_number,
								'REFERENCE#': pm.payment_made?.reference_number,
								'PAYMENT MODE': pm?.payment_made?.payment_mode,
								AMOUNT: pm?.payment_amount,
							};
						});
			setPaymentMade(purchaseBillPaymentMade);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		handleFetchPurchaseBillDetails();
	}, [id]);

	const returnScreen = () => {
		navigate(`/user/${suburl}/purchases-bills`);
	};

	const editHandler = () => {
		navigate(`/user/${suburl}/purchases-bills-create/${id}`);
	};
	const createCreditNoteHandler = () => {};
	const printPdfHandler = () => {};
	const cloneHandler = () => {};
	const undoReceivedHandler = () => {};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<div style={{ backgroundColor: '#EDEDF7', height: '100%', overflowY: 'auto' }}>
			<Header
				headerTitle="Purchase Bills Details"
				closeButton={{
					showCloseButton: true,
					handler: () => returnScreen(),
				}}
				buttons={[
					{
						id: 1,
						className: 'btn-gray mt-0 px-4 ',
						button: 'Edit',
						handler: () => navigate(`/user/${suburl}/purchases-bills-create/${id}`),
					},
					{
						id: 2,
						className: 'btn-gray mt-0 px-4',
						button: 'PDF/Print',
						handler: () => generatePdfToPrintAndDownload(),

						// handler: () => navigate(`/user/${suburl}/purchases-order-send-emails/${id}`),
					},
					{
						id: 5,
						className: 'btn-gray',
						button: 'Delete',
						handler: () => setIsDeleteModalOpen(true),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'Create Credit Note',
						disabled: true,
						// handler: async () => {
						// 	await setIsShowPdfView(true)
						// 	printPurchaseOrderPdf()
						// },
					},
					{
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Clone',
						disabled: true,
						// handler: () => setIsConvertToBillOpen(true),
					},

					{
						id: 6,
						className: 'mt-0 btn-gray add-pro',
						button: 'Undo Receive',
						disabled: true,
						// handler: handleVoidPurchaseOrder,
					},
				]}
			/>

			{!purchaseBillDetails ? (
				<PageLoader />
			) : (
				<div>
					<div>
						<div
							className="tabs"
							style={{ justifyContent: 'space-between', alignItems: 'center' }}
						>
							<div
								className={`tab`}
								onClick={() => setIsShowTable(!isShowTable)}
								style={{ fontSize: '16px', fontWeight: 'bold' }}
							>
								Payment Made
								<span
									style={{
										// backgroundColor: '#DDE1FA',
										borderRadius: '5px',
										color: '#3B58FF',
										marginLeft: '7px',
									}}
								>
									{paymentMade?.length}
								</span>
							</div>
							<div
								style={{
									padding: '0px 10px',
								}}
							>
								{isShowTable ? (
									<img
										style={{
											cursor: 'pointer',
										}}
										src={ArrowUp}
										onClick={() => setIsShowTable(false)}
									/>
								) : (
									<img
										style={{
											cursor: 'pointer',
										}}
										src={ArrowDown}
										onClick={() => setIsShowTable(true)}
									/>
								)}
							</div>
						</div>

						{isShowTable && paymentMade.length > 0 && (
							<div style={styles.table}>
								<Table
									coloum={paymentModeColumn}
									row={paymentMade}
									onRowClick={(rowId) => {
										navigate(`/user/${suburl}/payment-made-create/${rowId}`);
									}}
									style={{ height: '210px', padding: '0%' }}
								/>
							</div>
						)}

						<style>
							{`
          .tabs {
            display: flex;
            font-size: 16px;
            font-weight: 400;
            background-color: #F6F6F9;
            height: 54px;
          }

          .tab {
            padding: 10px 20px;
            cursor: pointer;
            position: relative;
            transition: all 0.3s ease;
          }

          .tab.active {
            color: #000000;
            font-weight: bold;
          }

          .tab.inactive {
            color: #555B67; 
            font-weight: normal;
          }

          .tab.active::after {
            content: '';
            position: absolute;
            width: 75%;
            height: 3px;
            background-color: #3B58FF;
            bottom: 13px;
            left: 15px;
          }
        `}
						</style>
					</div>
					<PurchaseBillInvoice
						handleProduct={(product) => {
							setProductDetails(product);
							setIsProductDetailsSidebarOpen(true);
						}}
						purchaseBillDetails={purchaseBillDetails}
					/>

					{/* <PurchaseBillInvoice
							isShowPdfView={isShowPdfView}
							setIsShowPdfView={setIsShowPdfView}
							printPdfRef={printPdfRef}
							PurchaseBillDetails={PurchaseBillDetails}
							handleProductDetails={(product) => {
								setProductDetails(product)
								setIsSidebarOpen(true)
							}
							}
							handleDiscardQuantity={(prod) => {
								setProductDetails(prod)
								setIsDiscardQuantityModalOpen(true)
							}}
						/> */}
				</div>
			)}

			{/* Sidebar ka layout */}
			{isProductDetailsSidebarOpen && (
				<ProductDetailsDrawer
					id={productDetails?.product_id}
					vendor={{
						vendor_id: purchaseBillDetails?.vendor?.vendor_id,
						vendor_name: purchaseBillDetails?.vendor?.vendor_name,
					}}
					setIsSidebarOpen={setIsProductDetailsSidebarOpen}
				/>
			)}

			{/* Sidebar ka layout */}
			{isSidebarOpen && (
				<div className="sidebar">
					<div
						className="d-flex align-self-center justify-content-sm-between "
						style={{ gap: '60px' }}
					>
						<div className="VenderDetails">Vendor Details</div>

						<div
							className="d-flex align-self-center "
							style={{ gap: '26px', alignItems: 'center' }}
						>
							<div>
								<a href="#">View more details</a>
							</div>

							<div
								className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
								onClick={toggleSidebar}
							>
								<img
									src={BlackClose}
									alt=""
									style={{ width: '14px', cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>

					<br />

					<div
						className="d-flex"
						style={{ gap: '20px' }}
					>
						<div className="profileImage d-flex align-self-center justify-content-sm-center">
							<img
								src={ProfilePlaceHolder}
								alt=""
								style={{ width: '55px', borderRadius: '10px' }}
							/>
						</div>

						<div
							className="d-flex flex-column"
							style={{ gap: '15px' }}
						>
							<div
								style={{
									fontSize: '18px',
									fontWeight: '600',
									color: '#333',
								}}
							>
								Shree Ram Medicals
							</div>

							<div
								style={{
									fontSize: '14px',
									color: 'var(--sub, #555B67)',
								}}
							>
								<b>Strip</b> contains <b>10 Tablets</b>
							</div>
						</div>
					</div>
					<br />
					<hr />

					{/* tabs  */}
					<div>
						<ul className="nav mb-4 mt-2">
							{navTabs.map((item, index) => (
								<li
									key={index}
									className="nav-item"
								>
									<a
										className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
										href="#"
										onClick={(e) => {
											e.preventDefault(); // Prevent the default anchor click behavior
											setActiveTab(item);
										}}
									>
										{item}
									</a>
								</li>
							))}
						</ul>
						<hr
							style={{
								backgroundColor: '#E3E4E5',
								boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
								marginTop: '-13px',
							}}
						/>
						<div>{renderTabContent()}</div>
					</div>
				</div>
			)}

			{/* {isDiscardQuantityModalOpen && (
				<Modal
					className="discardQuantityModal"
					isOpen={isDiscardQuantityModalOpen}
					onClose={setIsDiscardQuantityModalOpen}
					cancelButton={setIsDiscardQuantityModalOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#0F1C35',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Discard Quantity
						</div>
					}
					headerClassName="discard-header"
					bodyClassName="discard-Body"
				>
					<div>
						<div
							style={{
								padding: '20px 0px',
							}}
						>
							<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '-37px' }}>
								<thead>
									<tr
										style={{
											backgroundColor: '#E6F5FF',
											textAlign: 'left',
											color: '#555B67',
											fontSize: '14px',
										}}
									>
										<th style={{ padding: '10px' }}>ITEMS AND DESCRIPTION</th>
										<th>ORDERED</th>
										<th>RECEIVED STATUS</th>
										<th>REMAINING</th>
										<th>QUANTITY TO DISCARD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: '10px' }}>
											<a
												href="#"
												style={{ color: 'blue', textDecoration: 'underline' }}
											>
												Product A
											</a>
										</td>
										<td>
											100
											<br />
											<span>Strips</span>
										</td>
										<td>
											<div>90 Received</div>
											<div>90 Billed</div>
										</td>
										<td>10</td>
										<td>
											<input
												type="number"
												placeholder="10"
												style={{
													width: '50px',
													padding: '5px',
													borderRadius: '4px',
													border: '1px solid #ccc',
												}}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '22px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button=" Discard Quantity"
							/>
						</div>
					</div>
				</Modal>
			)} */}
			<ConfirmationModal
				isOpen={isDeleteModalOpen} // Pass the state of the modal
				// onClose={handleCancelDelete} // Pass close handler
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete{' '}
						<span
							style={{
								fontWeight: '600',
							}}
						>
							{purchaseBillDetails?.bill_number}
						</span>{' '}
						PB ?
					</p>
				}
				onCancel={() => setIsDeleteModalOpen(false)} // Cancel button logic
				onOk={handleDeletePurchaseBill} // Ok button logic (confirm deletion)
			/>
		</div>
	);
};
const styles = {
	table: {
		// width: '530px',
		margin: 'auto',
		// maxHeight: '120px',
		overflowY: 'hidden',
		overflowX: 'hidden',
		borderBottom: '3px solid #C3CBDC',
	},
	tableContainer: {
		marginTop: '20px',
		// maxHeight: '120px',
		overflowY: 'hidden',
		width: '626px',
	},
};
export default PurchaseBillDetails;
