import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import './table.scss';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const Table = ({
	row,
	coloum,
	className,
	onRowDataChange,
	onRowClick,
	action,
	bulkAction,
	onDeselectAll,
	extraClass,
	style = {},
}) => {
	const gridRef = useRef(null);
	const containerRef = useRef(null); // For the outer container DOM element
	const isGridFocused = useRef(true); // To track grid focus state

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (!isGridFocused.current) return;

			const focusedCell = gridRef.current?.getFocusedCell?.();
			if (event.key === 'Enter' && focusedCell) {
				const params = {
					...focusedCell,
					data: gridRef.current?.getDisplayedRowAtIndex(focusedCell.rowIndex)?.data,
					colDef: focusedCell.column?.getColDef(),
				};
				onCellClicked(params);
			} else if (event.key === 'ArrowUp') {
				navigateToPreviousRow();
			} else if (event.key === 'ArrowDown') {
				navigateToNextRow();
			}
		};

		const navigateToPreviousRow = () => {
			const focusedCell = gridRef.current?.getFocusedCell?.();
			if (focusedCell && focusedCell.rowIndex > 0) {
				const previousRowIndex = focusedCell.rowIndex - 1;
				gridRef.current?.setFocusedCell(previousRowIndex, focusedCell.column.colId);
			}
		};

		const navigateToNextRow = () => {
			const focusedCell = gridRef.current?.getFocusedCell?.();
			if (focusedCell && focusedCell.rowIndex < row.length - 1) {
				const nextRowIndex = focusedCell.rowIndex + 1;
				gridRef.current?.setFocusedCell(nextRowIndex, focusedCell.column.colId);
			}
		};

		const handleClickOutside = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				isGridFocused.current = false;
				document.activeElement.blur();
			} else {
				isGridFocused.current = true;
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [row]);

	const onCellClicked = (params) => {
		if (bulkAction && bulkAction.length > 0) return;
		if (onRowClick) onRowClick(params?.data?.id);
	};

	const onSelectionChanged = () => {
		if (!gridRef.current?.api) return; // Safeguard against undefined `api`

		const selectedNodes = gridRef.current.api.getSelectedNodes() || [];
		const selectedData = selectedNodes.map((node) => node.data);
		const ids = selectedData.map((data) => data.id);
		console.log(ids);
		if (action) action(ids); // Pass the selected IDs to the action callback
	};

	const onCellValueChanged = (params) => {
		if (onRowDataChange) {
			onRowDataChange(params.data);
		}
	};

	const CheckboxRenderer = (props) => {
		const handleCheckboxChange = (event) => {
			console.log(event.target.checked);
			const checked = event.target.checked;
			const updatedData = { ...props.data, select: checked };
			props.node.setData(updatedData);
			console.log(props.node);
		};

		return (
			<input
				type="checkbox"
				checked={props.value}
				onChange={handleCheckboxChange}
			/>
		);
	};

	const onFirstDataRendered = () => {
		if (gridRef.current?.api && coloum.length > 0) {
			gridRef.current.api.setFocusedCell(0, coloum[0].field); // Use `gridRef.current.api`
		}
	};

	const deselectAllRows = useCallback(() => {
		if (gridRef.current?.api) {
			gridRef.current.api.deselectAll();
		} else {
			console.warn('Grid API is not available to deselect rows.');
		}
	}, []);

	useEffect(() => {
		if (onDeselectAll) onDeselectAll(deselectAllRows);
	}, [onDeselectAll, deselectAllRows]);

	const sortingOrder = useMemo(() => ['asc', 'desc'], []);

	const suppressKeyboardEvent = (params) => {
		return !isGridFocused.current;
	};

	return (
		<div className="planDetail-body">
			<div
				className="ag-theme-quartz"
				ref={containerRef}
			>
				<div
					className={`ag-grid-table ${extraClass || ''}`}
					style={style}
				>
					<AgGridReact
						ref={gridRef}
						rowData={row}
						className={className}
						columnDefs={coloum}
						rowSelection="multiple"
						onFirstDataRendered={onFirstDataRendered}
						onSelectionChanged={onSelectionChanged}
						onCellClicked={onCellClicked}
						onCellValueChanged={onCellValueChanged}
						sortingOrder={sortingOrder}
						suppressKeyboardEvent={suppressKeyboardEvent}
						gridOptions={{
							enableHeaderFocus: false,
						}}
						frameworkComponents={{
							checkboxRenderer: CheckboxRenderer,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default Table;
