module.exports = {
	vendor: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'vendor_display_name', value: 'Vendor Display Name' },
		{ key: 'email', value: 'Email' },
		{ key: 'phone_mobile', value: 'Phone Number' },
		{ key: 'vendor_id', value: 'Vendor Id' },
		{ key: 'status', value: 'Status' },
	],
	purchase_order: [
		{ key: 'status', value: 'Status' },
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'purchase_order_number', value: 'Puchase Order Number' },
		{ key: 'purchase_order_id', value: 'Purchase Order Id' },
	],

	purchase_receive: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'purchase_order_number', value: 'PO#' },
		{ key: 'purchase_receive_id', value: 'PR#' },
	],
	purchase_bill: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'purchase_order_number', value: 'PO#' },
		{ key: 'purchase_receive_id', value: 'PR#' },
	],
	payment_made: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'payment_number', value: 'Payment Number' },
		{ key: 'amount', value: 'Amount' },
	],
	credit_note: [
		{
			key: 'vendor_name',
			value: 'Vendor Name',
		},
		{ key: 'credit_note_number', value: 'CN#' },
		{ key: 'amount', value: 'Amount' },
	],
};
