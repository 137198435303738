import React, { useEffect } from 'react';
import './index.scss';
import { getDate } from '../../../utils/dateUtils';

function PurchaseBillInvoice({ purchaseBillDetails, handleProduct }) {
	const {
		vendor,
		bill_number,
		bill_date,
		due_date,
		bill_amount,
		due_amount,
		purchase_bill_payment_made,
		purchase_bill_products,
	} = purchaseBillDetails || {};
	const { email, vendor_name } = vendor || {};
	return (
		purchaseBillDetails && (
			<div
				className="invoice-container"
				style={{ marginTop: '45px' }}
			>
				{/* Header Section */}
				<p>
					Status:{' '}
					{purchaseBillDetails?.status === 0
						? 'Draft'
						: purchaseBillDetails?.status === 1
							? 'Open'
							: purchaseBillDetails?.status === 2
								? 'Partially Paid'
								: 'Paid'}
				</p>
				<div className="invoice-header">
					<div>
						<h2 style={{ fontSize: '22px', fontWeight: 'bold', color: '#0F1C35' }}>{''}</h2>
						<div style={{ marginTop: '15px', color: '#555B67' }}>
							<p>{'Rajasthan'}</p>
							<p>{'India'}</p>
							<p>{email}</p>
						</div>
					</div>
					<div className="bill-status">
						<h2 style={{ fontSize: '30px', fontWeight: 'bold' }}>BILL</h2>
						<p style={{ marginTop: '10px', color: '#555B67' }}>Bill# {bill_number}</p>
					</div>
				</div>

				{/* Invoice Details */}
				<div className="invoice-details">
					<div style={{ marginTop: '18px', color: '#555B67', fontSize: '14px' }}>
						<p>Bill Date: </p>
						<p>Due Date: </p>
						<p>Terms:</p>
					</div>
					<div
						style={{
							marginRight: '148px',
							marginTop: '19px',
							fontWeight: 'bold',
							fontSize: '14px',
							color: '#000000',
						}}
					>
						<p>{getDate(bill_date)}</p>
						<p>{due_date != null ? getDate(due_date) : 'N/A'}</p>
						<p>{purchaseBillDetails.terms || 'Net 0'}</p>
					</div>
					<div style={{ marginTop: '18px' }}>
						<p style={{ fontSize: '14px', color: '#555B67' }}>Balance Due:</p>
						<h2
							style={{ fontSize: '18px', fontWeight: 'bold', color: '#000000', textAlign: 'right' }}
						>
							Rs {due_amount || '0.00'}
						</h2>
					</div>
				</div>

				{/* Bill To Section */}
				<div
					className="bill-to"
					style={{ border: '1px solid #ddd', padding: '13px' }}
				>
					<p style={{ color: '#0F1C35', fontSize: '16px', fontWeight: '600' }}>Bill To:</p>
					<h3 style={{ fontWeight: 'bold', color: '#3B58FF', fontSize: '14px' }}>{vendor_name}</h3>
				</div>

				{/* Items Table */}
				<table className="invoice-table">
					<thead>
						<tr>
							<th>#</th>
							<th>ITEM & DESCRIPTION</th>
							<th>QNTY</th>
							<th>RATE</th>
							<th>AMOUNT</th>
						</tr>
					</thead>
					<tbody>
						{purchase_bill_products?.map((item, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td style={{ color: "blue", cursor: "pointer" }}>
									<strong onClick={() => handleProduct(item)} >{item.product.product_name}</strong>
								</td>
								<td>{item.quantity}</td>
								<td>{item.purchase_rate}</td>
								<td>{item.amount}</td>
							</tr>
						))}
					</tbody>
				</table>

				{/* Total Section */}
				<div className="total-section">
					<div></div>
					<div
						className="totals"
						style={{
							fontSize: '14px',
							fontWeight: 'bold',
							color: '#555B67',
							marginLeft: '380px',
							textAlign: 'right',
						}}
					>
						<p>Sub Total: </p>
						<p>Total: </p>
						<p>Payment Made:</p>
						<p>Balance Due:</p>
					</div>
					<div style={{ color: '#555B67', textAlign: 'right' }}>
						<p>
							<b>Rs.</b>
							{purchase_bill_products?.reduce(
								(total, bill) => Number(total) + Number(bill.amount),
								0,
							)}
						</p>
						<p>
							<b>Rs.</b>{' '}
							{purchase_bill_products?.reduce(
								(total, bill) => Number(total) + Number(bill.amount),
								0,
							)}
						</p>
						<p>
							<span className="negative">
								{purchase_bill_payment_made?.reduce(
									(total, made) => Number(total) + Number(made.payment_amount),
									0,
								)}
							</span>
						</p>
						<p>
							<b>Rs.</b> {due_amount || '0.00'}
						</p>
					</div>
				</div>

				{/* Footer */}
				<div className="footer">
					<p>Authorized Signature: __________________________</p>
				</div>
			</div>
		)
	);
}

export default PurchaseBillInvoice;
