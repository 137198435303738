import React, { useEffect, useRef, useState } from 'react';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	deletePurchaseOrder,
	discard_purchase_order_product_quantity,
	fetchPurchaseOrders,
	update_purchase_order,
	update_purchase_order_status,
} from '../../services/purchaseOrder';
import PageLoader from '../../component/PageLoader';
import Modal from '../../component/modal/Modal';
import './purchaseOrder.scss';
import Header from '../components/Header';
import BillReceiveTable from '../components/BillReceiveTable';
import PurchaseOrderInvoice from '../components/PurchaseOrderInvoice';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import { useReactToPrint } from 'react-to-print';
import { getDate } from '../../utils/dateUtils';
import UpdatePOStatusModel from './components/UpdatePOStatusModel';
import ConvertToBillModal from './components/ConvertToBillModal';
import ProductDetailsDrawer from '../components/ProductDetailsDrawer';
import VendorDetailsDrawer from './components/VendorDetailsDrawer';
import { vendor } from '../../component/Filter/advanceFilterOptions';

function PurchaseOrderDetails() {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	// const [activeTab, setActiveTab] = useState("Product Details");
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState();
	const [isUpdatePOStatusModelOpen, setIsUpdatePOStatusModelOpen] = useState(false);
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [isDiscardQuantityModalOpen, setIsDiscardQuantityModalOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isConfirmDiscardModalOpen, setIsConfirmDiscardModalOpen] = useState(false);
	const printPdfRef = useRef(null);
	const printPurchaseOrderPdf = useReactToPrint({ contentRef: printPdfRef });
	const [isShowPdfView, setIsShowPdfView] = useState(false);
	const [discardProductQuanity, setDiscardProductQuantity] = useState(0);
	const [updatePOStatus, SetUpdatePOStatus] = useState('');
	const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);
	const [isChangePOStatusTOConvertPR, setIsChangePOStatusTOConvertPR] = useState(false);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);

	const Receivecolumn = [
		{
			field: 'PR#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 50,
			flex: 1,
			cellStyle: { color: '#3B58FF' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'VENDOR NAME',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellRenderer: (params) => {
				const status = params.data.STATUS ? 'Received' : 'Partially Received';
				return <>{status}</>;
			},
		},
	];

	const Billcolumn = [
		{
			field: 'BILL#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 50,
			flex: 1,
			cellStyle: { color: '#3B58FF' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellStyle: { color: '#555B67' },
			headerStyle: { color: '#555B67' },
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'DUE DATE',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'AMOUNT',
			suppressSizeToFit: true,
			minWidth: 40,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
		{
			field: 'BALANCE DUE',
			suppressSizeToFit: true,
			minWidth: 70,
			flex: 1,
			cellClass: 'right-align',
			headerClass: 'HeaderBg right-align',
		},
	];

	async function handleSavePO() {
		try {
			if (purchaseOrderDetails.purchase_order_products.length > 0) {
				let body = {
					vendor_id: Number(purchaseOrderDetails.vendor_id),
					purchase_order_number: purchaseOrderDetails.purchase_order_number,
					date: getDate(purchaseOrderDetails.date, true),
					delivery_address: purchaseOrderDetails.delivery_address,
					expected_delivery_date: purchaseOrderDetails.expected_delivery_date ? getDate(purchaseOrderDetails.expected_delivery_date, true) : null,
					delivery_method: purchaseOrderDetails.delivery_method,
					payment_terms: purchaseOrderDetails?.vendor?.vendor_other_details?.payment_terms || null,
					is_draft: 0,
					purchase_order_products: purchaseOrderDetails.purchase_order_products?.map((p) => {
						return {
							product_id: Number(p.product_id),
							product_packaging: p.product_packaging,
							order_quantity: Number(p.order_quantity),
						};
					}),
					email_communication: purchaseOrderDetails.email_communication,
					remarks: purchaseOrderDetails.remarks,
				};
				console.log({ body });
				await update_purchase_order(id, body);
				toast.success('Purchase order updated successfully.');
				navigate(`/user/${suburl}/purchases-order`);
			} else {
				toast.error('No products available in this PO.');
			}
		} catch (error) {
			toast.error('Something went wrong! Try again later.');
		}
	}

	async function handleConvertToBill(type, ids) {
		if (ids.length > 0) {
			let poId;
			if (ids.length > 1) {
				poId = ids.join('_');
			} else {
				poId = ids[0];
			}
			console.log(poId);
			if (type === 'yetToReceive') {
				navigate(`/user/${suburl}/purchases-bills-create/ytr-${poId}`);
			} else if (type === 'received') {
				navigate(`/user/${suburl}/purchases-bills-create/por-${poId}`);
			}
		}
		setIsConvertToBillOpen(false);
	}

	// Open the confirmation modal when discard button is clicked
	const handleDiscardQuantityClick = () => {
		setIsConfirmDiscardModalOpen(true); // Open confirmation modal
	};

	// Confirm discard action
	const handleConfirmDiscard = async () => {
		if (
			productDetails?.order_quantity - productDetails?.received_quantity >=
			discardProductQuanity
		) {
			// Perform the discard action here (e.g., update quantity, etc.)
			const body = {
				purchase_order_id: purchaseOrderDetails.purchase_order_id,
				purchase_order_product_id: productDetails?.purchase_order_product_id,
				discard_quantity: discardProductQuanity,
			};
			await discard_purchase_order_product_quantity(body);

			setIsConfirmDiscardModalOpen(false); // Close confirmation modal
			setIsDiscardQuantityModalOpen(false);
			// setIsChangeStatusOpen(true);
			// Close discard quantity modal
			toast.success('Quantity discarded successfully');
			setDiscardProductQuantity(0);
			if (
				purchaseOrderDetails?.purchase_order_products.length === 1 &&
				Number(productDetails?.received_quantity) === 0 &&
				Number(productDetails?.order_quantity) -
				Number(productDetails?.received_quantity) -
				discardProductQuanity ===
				0
			) {
				handleUpdatePOStatus('is_draft', 3);
			}
			if (
				purchaseOrderDetails?.purchase_order_products.length === 1 &&
				Number(productDetails?.received_quantity) === 0 &&
				Number(productDetails?.order_quantity) -
				Number(productDetails?.received_quantity) -
				discardProductQuanity ===
				0
			) {
				setIsChangeStatusOpen(true);
			}
			handleFetchPurchaseOrderDetails();
		} else {
			toast.error('You can only discard up to the unreceived quantity of this product.');
		}
	};

	// Cancel discard action
	const handleCancelDiscard = () => {
		setIsConfirmDiscardModalOpen(false); // Close confirmation modal
	};

	async function handleFetchPurchaseOrderDetails() {
		try {
			const params = {
				filterBy: 'purchase_order_id',
				filterValue: id,
			};
			const response = await fetchPurchaseOrders(params);
			const purchaseOrderData = response.purchaseOrders[0]; // Assuming the response contains the purchase order in an array
			setPurchaseOrderDetails(purchaseOrderData);
		} catch (error) {
			console.error('Error fetching purchase order details:', error);
			toast.error('Something went wrong while fetching purchase order details.');
		}
	}

	const handleConfirmDelete = async () => {
		try {
			if (id) {
				// Call the delete API
				await deletePurchaseOrder(id);
				toast.success('Purchase order deleted successfully.');
				setIsDeleteModalOpen(false); // Close modal after deleting
				navigate(`/user/${suburl}/purchases-order`);
			}
		} catch (error) {
			toast.error('Failed to delete purchase order');
		}
	};
	const handleCancelDelete = () => {
		setIsDeleteModalOpen(false); // Close modal if cancel is clicked
	};

	const handleCancelUpdatePOStatus = () => {
		setIsUpdatePOStatusModelOpen(false);
		setIsChangeStatusOpen(false);
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	async function handleUpdatePOStatus(status, receiveOrder = 0) {
		try {
			const body = {
				status,
			};
			await update_purchase_order_status(id, body);

			if (receiveOrder === 1) {
				handleCancelUpdatePOStatus();
				navigate(`/user/${suburl}/purchases-recieve-create/${id}`);
				toast.success('Purchase order status updated.');
			} else if (receiveOrder === 0) {
				handleCancelUpdatePOStatus();
				navigate(`/user/${suburl}/purchases-order`);
				toast.success('Purchase order status updated.');
			}
		} catch (error) {
			toast.error('Something went wrong while changing the status.');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseOrderDetails();
		}
	}, [id]);

	return (
		<div style={{ backgroundColor: '#EDEDF7', height: '100%', overflowY: 'auto' }}>
			<Header
				headerTitle="Purchase Order Details"
				closeButton={{
					showCloseButton: true,
					handler: () => navigate(`/user/${suburl}/purchases-order`),
				}}
				buttons={[
					purchaseOrderDetails &&
					purchaseOrderDetails.status !== 'Issued' &&
					purchaseOrderDetails?.status !== 'Partially Received' &&
					purchaseOrderDetails?.status !== 'Received' &&
					purchaseOrderDetails.status !== 'Partially Paid' &&
					purchaseOrderDetails.status !== 'Paid' &&
					purchaseOrderDetails.status !== 'Closed' && {
						id: 1,
						className: 'btn-gray mt-0 px-4 ',
						button: 'Edit',
						handler: () => navigate(`/user/${suburl}/purchases-order-edit/${id}`),
					},
					purchaseOrderDetails &&
					purchaseOrderDetails.status === 'Draft' && {
						id: 8,
						className: 'btn-gray mt-0 px-4 ',
						button: 'Save',
						handler: () => handleSavePO(),
					},

					purchaseOrderDetails &&
					purchaseOrderDetails.status !== 'Draft' &&
					purchaseOrderDetails.is_draft !== 1 && {
						id: 7,
						className: 'mt-0 POStausButton',
						button: (
							<div
								className="dropdown"
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
									height: '12px',
									width: '133px',
									paddingLeft: '0%',
								}}
							>
								<button
									className="btn btn-light dropdown-toggle"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									style={{
										height: '18px',
										width: '100px',
										fontSize: '14px',
										border: 'none',
										backgroundColor: 'transparent',
										boxShadow: 'none',
										cursor: 'pointer',
									}}
								>
									Update PO Status
								</button>
								<ul
									className="dropdown-menu font-14 DropdownMenuSize  DropdownPadding"
									style={{ minWidth: '150px' }}
								>
									{purchaseOrderDetails?.is_partially_received !== 1 &&
										purchaseOrderDetails?.status !== 'Partially Received' &&
										purchaseOrderDetails?.is_received !== 1 &&
										purchaseOrderDetails?.status !== 'Received' &&
										purchaseOrderDetails?.is_issued !== 1 &&
										purchaseOrderDetails?.status !== 'Issued' && (
											<li>
												<a
													className="dropdown-item"
													onClick={() => {
														SetUpdatePOStatus('is_approved');
														setIsUpdatePOStatusModelOpen(true);
													}}
													style={{ cursor: 'pointer', fontSize: '14px' }}
												>
													Approved
												</a>
											</li>
										)}
									{purchaseOrderDetails?.is_partially_received !== 1 &&
										purchaseOrderDetails?.status !== 'Partially Received' &&
										purchaseOrderDetails?.is_received !== 1 &&
										purchaseOrderDetails?.status !== 'Received' &&
										purchaseOrderDetails?.is_issued !== 1 &&
										purchaseOrderDetails?.status !== 'Issued' && (
											<li>
												<a
													className="dropdown-item"
													onClick={() => {
														SetUpdatePOStatus('is_issued');
														setIsUpdatePOStatusModelOpen(true);
													}}
													style={{ cursor: 'pointer', fontSize: '14px' }}
												>
													Issued
												</a>
											</li>
										)}
									{purchaseOrderDetails?.is_partially_received !== 1 &&
										purchaseOrderDetails?.status !== 'Partially Received' &&
										purchaseOrderDetails?.is_received !== 1 &&
										purchaseOrderDetails?.status !== 'Received' &&
										purchaseOrderDetails?.is_issued !== 1 &&
										purchaseOrderDetails?.status !== 'Issued' && (
											<li>
												<a
													className="dropdown-item"
													onClick={() => {
														SetUpdatePOStatus('is_in_transit');
														setIsUpdatePOStatusModelOpen(true);
													}}
													style={{ cursor: 'pointer', fontSize: '14px' }}
												>
													In-Transit
												</a>
											</li>
										)}
									{purchaseOrderDetails?.is_issued ||
										purchaseOrderDetails?.is_partially_received ||
										purchaseOrderDetails?.is_received ? (
										<li>
											<a
												className="dropdown-item"
												onClick={() => {
													SetUpdatePOStatus('is_cancelled');
													setIsUpdatePOStatusModelOpen(true);
												}} // Navigate to Send Email Page
												style={{ cursor: 'pointer', fontSize: '14px' }}
											>
												Cancel
											</a>
										</li>
									) : (
										<></>
									)}
								</ul>
							</div>
						),
					},
					purchaseOrderDetails &&
					purchaseOrderDetails.status !== 'Draft' &&
					purchaseOrderDetails.is_draft !== 1 && {
						id: 2,
						className: 'btn-gray',
						button: 'Send Email',
						handler: () => navigate(`/user/${suburl}/purchases-order-send-emails/${id}`),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'PDF/Print',
						handler: async () => {
							await setIsShowPdfView(true);
							printPurchaseOrderPdf();
						},
					},

					purchaseOrderDetails &&
					purchaseOrderDetails.status !== 'Draft' &&
					purchaseOrderDetails.is_draft !== 1 && {
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Convert To Bill',
						handler: () => setIsConvertToBillOpen(true),
					},

					purchaseOrderDetails &&
					purchaseOrderDetails.status !== 'Draft' &&
					purchaseOrderDetails.is_draft !== 1 && {
						id: 5,
						className: 'btn-gray',
						button: 'Receive Order',
						handler: () => navigate(`/user/${suburl}/purchases-receive-create/${id}`),
					},
					purchaseOrderDetails &&
					!purchaseOrderDetails?.is_issued && {
						id: 6,
						className: 'btn-gray add-pro',
						button: 'Delete',
						handler: () => setIsDeleteModalOpen(true),
					},
					// Add "Update PO Status" dropdown after the "Edit" button
				].filter(Boolean)}
				dropDownButtons={[]}
			/>

			{!purchaseOrderDetails ? (
				<PageLoader />
			) : (
				<div>
					<BillReceiveTable
						height={'210px'}
						tableConfig={{
							billTable: {
								title: 'Bills',
								column: Billcolumn,
								row:
									purchaseOrderDetails?.purchase_bill.length > 0
										? purchaseOrderDetails?.purchase_bill.map((pb) => {
											const formattedBillDate = new Date(pb.bill_date).toLocaleDateString(
												'en-GB',
											);
											const formattedDueDate = new Date(pb.due_date).toLocaleDateString('en-GB');
											const status =
												pb?.status === 1
													? 'Open'
													: pb?.status === 2
														? 'Partially Paid'
														: 'Closed';
											return {
												id: pb?.bill_number,
												'BILL#': pb.bill_number,
												DATE: formattedBillDate,
												STATUS: status,
												'DUE DATE': formattedDueDate,
												AMOUNT: pb.bill_amount,
												'BALANCE DUE': pb.due_amount,
											};
										})
										: [],
								count: purchaseOrderDetails?.purchase_bill.length,
								onRowClick: () => { },
							},
							receiveTable: {
								title: 'Receive',
								column: Receivecolumn,
								row:
									purchaseOrderDetails?.purchase_receive.length > 0
										? purchaseOrderDetails?.purchase_receive.map((pb) => {
											return {
												id: pb.purchase_receive_id,
												'PR#': pb.purchase_receive_id,
												DATE: getDate(pb.receive_date),
												'VENDOR NAME': pb?.vendor?.vendor_name,
												STATUS: pb.status,
											};
										})
										: [],
								count: purchaseOrderDetails?.purchase_receive.length,
								onRowClick: (id) => {
									if (id) {
										navigate(`/user/${suburl}/purchases-receive-details/${id}`);
									}
								},
							},
						}}
					/>

					<PurchaseOrderInvoice
						isShowPdfView={isShowPdfView}
						setIsShowPdfView={setIsShowPdfView}
						printPdfRef={printPdfRef}
						setVendorDetailsDrawerActiveOpenTab={setVendorDetailsDrawerActiveOpenTab}
						setIsVendorDetailsDrawerOpen={setIsVendorDetailsDrawerOpen}
						purchaseOrderDetails={purchaseOrderDetails}
						handleProductDetails={(product) => {
							setProductDetails(product);
							setIsSidebarOpen(true);
						}}
						handleDiscardQuantity={(prod) => {
							setProductDetails(prod);
							setIsDiscardQuantityModalOpen(true);
						}}
					/>
				</div>
			)}

			{/* Sidebar ka layout */}
			{isSidebarOpen && (
				<ProductDetailsDrawer
					id={productDetails?.product_id}
					vendor={{
						vendor_id: purchaseOrderDetails?.vendor?.vendor_id,
						vendor_name: purchaseOrderDetails?.vendor?.vendor_name,
					}}
					setIsSidebarOpen={setIsSidebarOpen}
				/>
			)}

			{isConvetToBillOpen && (
				<ConvertToBillModal
					isConvertToBillOpen={isConvetToBillOpen}
					setIsConvertToBillOpen={setIsConvertToBillOpen}
					purchaseOrderDetails={purchaseOrderDetails}
					handleConvertToBill={handleConvertToBill}
				/>
			)}

			{isDiscardQuantityModalOpen && (
				<Modal
					className="discardQuantityModal"
					isOpen={isDiscardQuantityModalOpen}
					onClose={setIsDiscardQuantityModalOpen}
					cancelButton={setIsDiscardQuantityModalOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#0F1C35',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Discard Quantity
						</div>
					}
					headerClassName="discard-header"
					bodyClassName="discard-Body"
				>
					<div>
						<div
							style={{
								padding: '20px 0px',
							}}
						>
							<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '-37px' }}>
								<thead>
									<tr
										style={{
											backgroundColor: '#E6F5FF',
											textAlign: 'left',
											color: '#555B67',
											fontSize: '14px',
										}}
									>
										<th style={{ padding: '10px' }}>ITEMS AND DESCRIPTION</th>
										<th>ORDERED</th>
										<th>RECEIVED STATUS</th>
										<th>REMAINING</th>
										<th>QUANTITY TO DISCARD</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ padding: '10px' }}>
											<a
												href="#"
												style={{ color: 'blue', textDecoration: 'underline' }}
											>
												{productDetails?.product?.product_name}
											</a>
										</td>
										<td>
											{productDetails?.order_quantity}
											<br />
											<span>{productDetails?.product_packaging}</span>
										</td>
										<td>
											<div>
												{productDetails?.received_quantity || 0} <br />
												Received
											</div>
										</td>
										<td>
											{productDetails?.order_quantity - productDetails?.received_quantity || 0}
										</td>
										<td>
											<input
												type="number"
												value={discardProductQuanity}
												onChange={(e) => setDiscardProductQuantity(e.target.value)}
												placeholder="10"
												style={{
													width: '50px',
													padding: '5px',
													borderRadius: '4px',
													border: '1px solid #ccc',
												}}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '22px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button=" Discard Quantity"
								onClick={handleDiscardQuantityClick}
							/>
						</div>
					</div>
				</Modal>
			)}

			{isChangeStatusOpen && (
				<Modal
					className="deleteVendorsModal"
					title="Change Status PO"
					isOpen={isChangeStatusOpen}
					onClose={() => {
						setIsChangeStatusOpen(false);
					}}
					cancelButton={() => {
						setIsChangeStatusOpen(false);
					}}
				>
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '600',
							color: 'black',
						}}
					>
						No products available in this PO.
					</p>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							justifyContent: 'end',
						}}
					>
						<Button
							type="button"
							className="mt-0 btn-gray add-pro"
							button="Delete PO"
							onClick={() => {
								handleConfirmDelete();
							}}
						/>

						<Button
							type="button"
							button="Draft PO"
							className="mt-0 px-4 btn-danger relative"
							onClick={() => handleUpdatePOStatus('is_draft')}
						/>
					</div>
				</Modal>
			)}
			{isChangePOStatusTOConvertPR && (
				<Modal
					className="deleteVendorsModal"
					title="Confirm Change PO Status"
					isOpen={isChangePOStatusTOConvertPR}
					onClose={() => {
						setIsChangePOStatusTOConvertPR(false);
					}}
					cancelButton={() => {
						setIsChangePOStatusTOConvertPR(false);
					}}
				>
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '600',
							color: 'black',
						}}
					>
						Are you want to convert purchase order into receive order?
					</p>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							justifyContent: 'end',
						}}
					>
						<Button
							type="button"
							className="mt-0 btn-gray add-pro"
							button="Cancel"
							onClick={() => {
								setIsChangePOStatusTOConvertPR(false);
							}}
						/>

						<Button
							type="button"
							button="Confirm"
							className="mt-0 px-4 btn-danger relative"
							onClick={() => handleUpdatePOStatus('is_issued', 1)}
						/>
					</div>
				</Modal>
			)}

			{/* Confirmation Modal */}
			{isDeleteModalOpen && (
				<ConfirmationModal
					isOpen={isDeleteModalOpen} // Pass the state of the modal
					// onClose={handleCancelDelete} // Pass close handler
					title="Confirm Deletion"
					content={
						<p
							style={{
								textAlign: 'center',
								backgroundColor: '#E6F5FF',
								padding: '10px',
								borderRadius: '5px',
								fontWeight: '500',
								color: 'black',
							}}
						>
							Are you sure you want to delete{' '}
							<span
								style={{
									fontWeight: '600',
								}}
							>
								{purchaseOrderDetails?.purchase_order_number}
							</span>{' '}
							PO ?
						</p>
					}
					onCancel={handleCancelDelete} // Cancel button logic
					onOk={handleConfirmDelete} // Ok button logic (confirm deletion)
				/>
			)}

			{/* Confirmation Modal */}
			{isConfirmDiscardModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmDiscardModalOpen}
					title="Confirm Discard"
					content={
						<p
							style={{
								textAlign: 'center',
								backgroundColor: '#E6F5FF',
								padding: '10px',
								borderRadius: '5px',
								fontWeight: '500',
								color: 'black',
							}}
						>
							Are you sure you want to discard the quantity?
						</p>
					}
					onClose={handleCancelDiscard}
					onCancel={handleCancelDiscard}
					onOk={handleConfirmDiscard}
				/>
			)}

			{isUpdatePOStatusModelOpen && (
				<UpdatePOStatusModel
					isUpdatePOStatusModelOpen={isUpdatePOStatusModelOpen}
					setIsUpdatePOStatusModelOpen={setIsUpdatePOStatusModelOpen}
					handleUpdatePOStatus={() => {
						handleUpdatePOStatus(updatePOStatus);
					}}
					status={updatePOStatus}
				/>
			)}

			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={purchaseOrderDetails?.vendor?.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>

			{/* <ConfirmationModal
				isOpen={isUpdatePOStatusModelOpen} // Pass the state of the modal
				onClose={handleCancelUpdatePOStatus} // Pass close handler
				title="Confirm Update PO Status"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to change update PO status
						<span
							style={{
								fontWeight: '600',
								marginLeft: '10px',
							}}
						>
							{purchaseOrderDetails?.purchase_order_number}
						</span>
					</p>
				}
				onCancel={handleCancelUpdatePOStatus} // Cancel button logic
				onOk={() => {
					handleUpdatePOStatus(updatePOStatus);
				}} // Ok button logic (confirm deletion)
			/> */}
		</div>
	);
}

export default PurchaseOrderDetails;
