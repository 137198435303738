import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { toast } from 'react-toastify';
import Header from '../../components/Header';
import PurchaseOrderForm from '../components/PurchaseOrderForm';
import {
	create_purchase_order,
	fetchPurchaseOrders,
	update_purchase_order,
} from '../../../services/purchaseOrder';
import { getCurrentDate, getDate } from '../../../utils/dateUtils';

const CreatePurchaseOrder = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [isErrortable, setIsTableError] = useState(true);
	const [disabled, setDisabled] = useState(false);
	const [errorCells, setErrorCells] = useState([]);
	const [error, setError] = useState({});
	const [formData, setFormData] = useState({
		vendor_id: '',
		purchase_order_number: '',
		date: getCurrentDate(),
		delivery_address: '',
		expected_delivery_date: '',
		delivery_method: '',
		is_draft: 1,
		purchase_order_products: [],
		email_communication: [],
		remarks: '',
	});

	function handleFormData(key, value) {
		setFormData((prev) => ({
			...prev,
			[key]: value,
		}));
	}

	async function handleCreatePurchaseOrder(body) {
		try {
			const data = await create_purchase_order(body);
			return data;
		} catch (error) {
			throw error;
		}
	}

	async function handleUpdatePurchaseOrder(id, body) {
		try {
			const data = await update_purchase_order(id, body);
			return data;
		} catch (error) {
			throw error;
		}
	}

	function checkValidation(is_draft) {
		const newError = { ...error };
		if (!formData.vendor_id || formData.vendor_id === '' || formData.vendor_id === undefined) {
			newError.vendor_id = 'Please select a valid vendor from the list.';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!formData.purchase_order_number ||
			formData.purchase_order_number === '' ||
			formData.purchase_order_number === undefined
		) {
			newError.purchase_order_number = 'Please enter purchase order number in the field';
		} else {
			delete newError.purchase_order_number; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!formData.date ||
			formData.date === '' ||
			formData.date === undefined ||
			(formData.date !== '' && newError.date)
		) {
			newError.date = newError.date ?? 'Please enter date in the field';
		} else {
			delete newError.date; // Agar fill kiya hai to error ko remove karein
		}
		if (is_draft === 0) {
			if (
				formData.purchase_order_products.length === 1 &&
				errorCells.length < 1 &&
				(formData.purchase_order_products[0].product_id === '' ||
					formData.purchase_order_products[0].product_packaging === '' ||
					formData.purchase_order_products[0].order_quantity === '' ||
					formData.purchase_order_products[0].order_quantity === '')
			) {
				let newErrorCell = [...errorCells];
				if (formData.purchase_order_products[0].product_id === '') {
					newErrorCell = [...errorCells, { index: 0, name: 'product_id' }];
				}
				if (formData.purchase_order_products[0].product_packaging === '') {
					newErrorCell = [...errorCells, { index: 0, name: 'product_packaging' }];
				}
				if (
					formData.purchase_order_products[0].order_quantity === null ||
					formData.purchase_order_products[0].order_quantity === ''
				) {
					newErrorCell = [...errorCells, { index: 0, name: 'order_quantity' }];
				}
				setErrorCells(newErrorCell);
				newError.purchase_order_products = '';
			} else {
				delete newError.purchase_order_products;
			}
		} else {
			delete newError.purchase_order_products;
		}

		if (Object.keys(newError).length > 0 || (is_draft === 0 && isErrortable)) {
			setError(newError);
			return true;
		} else {
			return false;
		}
	}

	const handleSavePurchaseOrder = async (
		is_draft = 1,
		isSendEmail = false,
		redirectProductUrl = false,
	) => {
		let isError = checkValidation(is_draft);
		if (isError) {
			if (is_draft === 0 && isErrortable) {
				return toast.error('Error in product. Click on Show All Errors to see the description!');
			} else {
				return toast.error(
					'Something went wrong while creating purchase order! Please fill required fields.',
				);
			}
		} else {
			try {
				let purchase_order_id = null;
				setDisabled(true);
				const body = {
					...formData,
					is_draft,
					purchase_order_products: formData?.purchase_order_products.filter(
						(product) =>
							product.product_id !== '' &&
							product.product_id !== null &&
							product.order_quantity !== 0 &&
							product.order_quantity !== null &&
							product.order_quantity !== '',
					),
				};

				if (id) {
					//TODO: update purchaseOrder
					await handleUpdatePurchaseOrder(id, body);
					//TODO: set purchase_order_id for send Email
					purchase_order_id = id;
					toast.success('Purchase order updated successfully.');
				} else {
					//TODO: create purchase order
					const data = await handleCreatePurchaseOrder(body);
					//TODO: set purchase_order_id for send Email
					purchase_order_id = data?.data;
					toast.success('New Purchase Order Created Successfully.');
				}
				setDisabled(false);

				if (isSendEmail) {
					navigate(`/user/${suburl}/purchases-order-send-emails/${purchase_order_id}`);
				} else {
					if (redirectProductUrl) {
						navigate(`/user/${suburl}/product-create`);
					} else {
						navigate(`/user/${suburl}/purchases-order`);
					}
				}
			} catch (error) {
				setDisabled(false);
				// toast.error('Something went wrong!');
			}
		}
	};

	async function handleFetchPurchaseOrderDetails() {
		try {
			const params = {
				filterBy: 'purchase_order_id',
				filterValue: id,
			};
			const purchaseOrderDetails = await fetchPurchaseOrders(params);
			const purchaseDetails = purchaseOrderDetails.purchaseOrders[0];
			const {
				vendor_id,
				purchase_order_number,
				date,
				delivery_address,
				expected_delivery_date,
				delivery_method,
				is_draft,
				payment_terms,
				purchase_order_products,
				remarks,
				vendor,
			} = purchaseDetails;

			setFormData({
				vendor_id,
				purchase_order_number,
				date: getDate(date, true),
				delivery_address,
				expected_delivery_date:
					expected_delivery_date !== null && expected_delivery_date !== ''
						? getDate(expected_delivery_date, true)
						: '',
				delivery_method,
				is_draft,
				payment_terms,
				purchase_order_products: purchase_order_products || [],
				email_communication:
					vendor?.contact_person?.length > 0
						? vendor?.contact_person?.map((contact) => contact?.email)
						: [],
				remarks,
			});
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseOrderDetails();
		}
	}, [id]);

	return (
		<div>
			<div className="">
				<Header
					headerTitle="Create New Purchase Order"
					buttons={[
						{
							id: 1,
							className: 'btn-primary ',
							button: 'Save',
							handler: () => handleSavePurchaseOrder(0, false, false),
						},
						{
							id: 2,
							className: 'btn-primary',
							button: 'Save & Send',
							handler: () => handleSavePurchaseOrder(0, true, false),
						},
						{
							id: 3,
							className: 'btn-primary mt-0 px-4',
							button: 'Save as Draft',
							handler: () => handleSavePurchaseOrder(1, false, false),
						},
					]}
					closeButton={{
						showCloseButton: true,
						handler: () => navigate(`/user/${suburl}/purchases-order`),
					}}
				/>
				<PurchaseOrderForm
					id={id}
					formData={formData}
					handleSavePurchaseOrder={handleSavePurchaseOrder}
					error={error}
					handleFormData={handleFormData}
					disabled={disabled}
					setErrorCells={setErrorCells}
					errorCells={errorCells}
					paramId={id}
					tableError={isErrortable}
					setError={setError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
		</div>
	);
};

export default CreatePurchaseOrder;
