import React, { useState } from 'react';
import InputField from '../../component/form/FormInput';
import { toast } from 'react-toastify';
import { addNewVendor } from '../../services/vendor';

const AddVendorModal = ({ onClose, handleFormData, vendorList, setVendorList }) => {
	const [addVendorForm, setAddVendorForm] = useState({
		vendor_name: '',
		vendor_display_name: '',
		drug_license_number: '',
		email: '',
		phone_landline: '',
		phone_mobile: '',
	});

	const handleSave = async () => {
		try {
			const data = await addNewVendor(addVendorForm);
			const updatedVendorList = [{ key: data.vendor_id, value: data.vendor_name }, ...vendorList];
			setVendorList(updatedVendorList);
			handleFormData('vendor_id', data?.vendor_id);
			onClose();
		} catch (error) {
			toast.error('Something went wrong!');
		}
	};

	function handleInputChange(key, value) {
		setAddVendorForm((prev) => ({
			...prev,
			[key]: value,
		}));
	}

	return (
		<div style={styles.overlay}>
			<div style={styles.modal}>
				<div style={styles.header}>
					<h2 style={{ color: '#000000', fontSize: '22px', fontWeight: '600' }}>Add New Vendor</h2>
					<button
						onClick={onClose}
						style={styles.closeButton}
					>
						X
					</button>
				</div>

				<div style={styles.formRow}>
					<div
						className="row"
						style={{
							flexWrap: 'nowrap',
							width: '307px',
							color: '#00000',
							fontSize: '14px',
							fontWeight: '600',
						}}
					>
						<div className="col">
							<InputField
								style={{ width: 'max-content', width: '290px' }}
								label="Vendor Name"
								placeholder="Enter Vendor Name"
								type="text"
								required={true}
								value={addVendorForm.vendor_name}
								onChange={(value) => {
									handleInputChange('vendor_name', value);
								}}
								// disabled={disabled}
								// error={error?.vendor_name}
								autoFocus={true}
							/>
						</div>
						<div style={styles.formGroup}>
							<div className="col">
								<InputField
									label="Vendor Display Name"
									placeholder="Vendor Display Name"
									type="text"
									value={addVendorForm.vendor_display_name}
									onChange={(value) => handleInputChange('vendor_display_name', value)}
									// disabled={disabled}
									// required={true}
									// error={error?.vendor_display_name}
									onFocus={(e) => {
										// isVendorDisplayNameTouched.current = true;
									}}
								/>
							</div>
						</div>

						<div style={styles.formGroup}>
							<div className="col">
								<InputField
									label="Drug License Number"
									placeholder="Drug License Number"
									type="text"
									required={true}
									value={addVendorForm.drug_license_number}
									onChange={(value) => handleInputChange('drug_license_number', value)}
									// disabled={disabled}
									// error={error?.drug_license_number}
									// showValidateIcon={true}
									// onValidateIconClick={() => handleValidateIconClick('drug_license_number')}
								/>
							</div>
						</div>
					</div>

					<div style={styles.formRow}>
						<div
							className="row"
							style={{
								flexWrap: 'nowrap',
								width: '307px',
								color: '#00000',
								fontSize: '14px',
								fontWeight: '600',
								marginTop: '15px',
							}}
						>
							<div className="col">
								<InputField
									style={{ width: 'max-content', width: '290px' }}
									label="Email"
									placeholder="Email Address"
									type="text"
									value={addVendorForm.email}
									onChange={(value) => handleInputChange('email', value)}
									// disabled={disabled}
									// error={error?.email}
								/>
							</div>

							<div className="col">
								<InputField
									style={{ width: '292px' }}
									label="Phone (Landline)"
									placeholder="Landline No."
									type="text"
									value={addVendorForm.phone_landline}
									onChange={(value) => handleInputChange('phone_landline', value)}
									// disabled={disabled}
									// error={error?.phone_landline}
								/>
							</div>

							<div>
								<InputField
									label="Phone (Mobile)"
									placeholder="Vendor Mobile (Work)"
									type="text"
									value={addVendorForm.phone_mobile}
									onChange={(value) => handleInputChange('phone_mobile', value)}
									// disabled={disabled}
									// error={error?.phone_mobile}
								/>
							</div>
						</div>
					</div>
				</div>

				<div style={styles.buttonContainer}>
					<button
						onClick={handleSave}
						style={styles.saveButton}
					>
						Save Vendor
					</button>
					<button
						onClick={onClose}
						style={styles.cancelButton}
					>
						Cancel
					</button>
				</div>
			</div>
		</div>
	);
};

// CSS Styles
const styles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 1000,
	},
	modal: {
		backgroundColor: '#fff',
		padding: '20px',
		width: '950px',
		borderRadius: '8px',
		boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
		position: 'relative',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '20px',
	},
	closeButton: {
		backgroundColor: '#FF0000',
		color: '#fff',
		border: 'none',
		borderRadius: '5px',
		padding: '6px 11px',
		cursor: 'pointer',
	},
	// formRow: {
	//   display: 'flex',

	// },
	// formGroup: {
	//   display: 'flex',
	//   flexDirection: 'column',
	//   width: '32%', // Adjusted to fit three inputs in a row
	// },
	input: {
		width: '310px',
		padding: '8px',
		fontSize: '14px',
		borderRadius: '4px',
		border: '1px solid #ccc',
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		gap: '10px',
		marginTop: '45px',
	},
	saveButton: {
		backgroundColor: '#3B58FF',
		color: '#fff',
		padding: '8px 16px',
		border: 'none',
		borderRadius: '7px',
		cursor: 'pointer',
	},
	cancelButton: {
		backgroundColor: '#424553',
		color: 'white',
		padding: '8px 16px',
		border: 'none',
		borderRadius: '7px',
		cursor: 'pointer',
	},
};

export default AddVendorModal;
