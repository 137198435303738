import vector from '../../assets/image/Vector.png';
export const paymentModeColumn = [
	// {
	// 	field: '',
	// 	headerClass: 'HeaderBg',
	// },
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'PAYMENT#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'right-align',
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'REFERENCE#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		cellClass: 'right-align',
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'PAYMENT MODE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'left-align',
		headerClass: 'HeaderBg',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg left-align',
	},
	{
		field: '',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg left-align',
		cellRenderer: (params) => {
			const action = params.action;
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100%',
					}}
				>
					{' '}
					<img src={vector} />
				</div>
			);
		},
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];
