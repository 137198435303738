import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';
import axiosInstance from '../../../services/axios';

export const ORGANISTATION_SETUP_DONE = 'ORGANISTATION_SETUP_DONE';
export const ORGANISTATION_SETUP_FAIL = 'ORGANISTATION_SETUP_DONE';

export const ORGANISTATION_API_URL = `${APP_API_URL}/auth/organisation-setup`;

export const organistation_setup = (formBody) => {
	let API_URL = ORGANISTATION_API_URL;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: ORGANISTATION_SETUP_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: ORGANISTATION_SETUP_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
