const initState = {
	page: 1,
	totalPages: 0,
	pageSize: 20,
	filters: {},
	selectedInputs: [],
};

export const advanceFilterReducer = (state = initState, action) => {
	switch (action.type) {
		case 'SET_ADVANCE_FILTER':
			return {
				...state,
				filters: action?.payload?.filters || {},
				selectedInputs: action?.payload?.selectedInputs || [],
			};
		case 'CLEAR_ADVANCE_FILTER':
			return {
				page: 1,
				totalPages: 0,
				pageSize: 20,
				filters: {},
				selectedInputs: [],
			};
		default:
			return state;
	}
};
