import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../component/InputTable/InputTable.scss';
import { useLocation, useParams } from 'react-router-dom';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import { product_list } from '../../../../store/action/product';
import ErrorModal from '../../../../component/ErrorModal/ErrorModal';
import InputField from '../../../../component/form/FormInput';
import AppendInput from '../../../../component/form/AppendInput';
import { getProductList } from '../../../../services/products';

const NoteTable = ({
	initialRows,
	columns,
	className,
	onChange,
	value,
	disabled,
	newRowgenerate = '',
	returnInputvalue,
	tableError,
	setIsTableErrorShow,
}) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [batchOptions, setBatchOptions] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState({});
	const [rows, setRows] = useState([]);
	const [errorCells, setErrorCells] = useState([]);
	const [showError, setShowError] = useState(false);
	const dispatch = useDispatch();
	const inputRefs = useRef([]);
	const param = useParams();
	const paramId = param.id;
	const [loading, setLoading] = useState(false);
	const paramProductID = queryParams.get('prodid');

	// Fetch product list and details
	useEffect(() => {
		let param = `page=1&pageSize=2000`;
		fetchProduct(param);
	}, []);

	useEffect(() => {
		if (errorCells.length > 0) {
			setIsTableErrorShow(true);
		} else {
			setIsTableErrorShow(false);
		}
	}, [errorCells]);

	useEffect(() => {
		if (tableError && rows?.length > 0 && errorCells.length > 0) {
			const LastRowIndex = rows.length - 1;
			const LastRow = rows[LastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = LastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '' || value === 'MM/YY' || value === '0.00') &&
						[
							'product_id',
							'batch_no',
							'mfg_date',
							'exp_date',
							'mrp',
							'quantity',
							'tax',
							'tax',
							'amount',
							'rate',
						].includes(col.name)
					);
				})
				.map((col) => ({
					index: LastRowIndex,
					name: col.name,
				}));

			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === LastRowIndex)),
				...errors,
			]);
		}
	}, [tableError]);

	useEffect(() => {
		setRows([]);
		setLoading(true);
		if (value?.length > 0) {
			setRows(value);
			setLoading(false);
		} else {
			// if (!paramProductID) {
			setLoading(true);
			setRows(initialRows);
			setLoading(false);
			// }
		}
	}, [value, initialRows]);

	const getProductBatchByProductId = (productID, index) => {
		let param = `filterBy=product_id&filterValue=${productID ? productID : ''}`;
		dispatch(product_list(param)).then((res) => {
			if (res?.success) {
				let filteredProduct = res?.data?.productWithStockCount?.[0];
				let batch = res?.data?.productWithStockCount?.[0]?.batchdetails?.map((ele) => ({
					key: ele.batch_no,
					value: ele.batch_no,
				}));
				setBatchOptions(batch || []);
				setSelectedRowData((prevData) => ({
					...prevData,
					[index]: filteredProduct,
				}));
			} else {
			}
		});
	};

	const fetchProduct = (param) => {
		dispatch(product_list(param)).then((res) => {
			if (res?.success) {
				setProductDetails(res?.data?.productWithStockCount);
				const productOptions = res?.data?.productWithStockCount?.map((ele) => ({
					key: ele?.product_id,
					value: ele?.product_name,
				}));
				setProductOptions(productOptions);
			} else {
			}
		});
	};

	// Debounce function definition
	const getproductbyInoutValue = (func, delay) => {
		let timeoutId;
		return function (...args) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	};

	// Create a debounced version of fetchProduct
	const debouncedFetchProduct = getproductbyInoutValue((query) => {
		fetchProduct(query);
	}, 300); // Adjust the delay as needed

	// Helper function to get product details by product_id
	const getProductById = (productId) => {
		return productDetails?.find((product) => product.product_id === productId);
	};

	// Add a new row
	const addRow = () => {
		const emptyRow = columns.reduce((acc, col) => {
			acc[col.name] = '';
			return acc;
		}, {});
		const newRows = [...rows, emptyRow];
		setRows(newRows);
		// Check if the second last row is empty
		if (newRows.length > 1) {
			const secondLastRowIndex = newRows.length - 2;
			const secondLastRow = newRows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '' || value === 'MM/YY' || value === '0.00') &&
						[
							'product_id',
							'batch_no',
							'mfg_date',
							'exp_date',
							'mrp',
							'quantity',
							'amount',
							'rate',
							'tax',
						].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === secondLastRowIndex)),
				...errors,
			]);
		}

		if (onChange) {
			onChange(newRows);
		}
		setTimeout(() => {
			inputRefs.current[newRows.length - 1]?.[0]?.focus();
			let lastRowRef = inputRefs.current[newRows.length - 1]?.[0];
			lastRowRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	};

	// Delete a row
	const deleteRow = (index) => {
		if (rows.length > 1) {
			const newRows = [...rows];
			newRows.splice(index, 1);
			const newErrorCells = errorCells
				.filter((error) => error.index !== index)
				.map((error) => ({
					...error,
					index: error.index > index ? error.index - 1 : error.index,
				}));
			setErrorCells(newErrorCells);
			setRows(newRows);
			if (onChange) {
				onChange(newRows);
			}
			setTimeout(() => {
				inputRefs.current[newRows.length - 1]?.[0]?.focus();
			}, 0);
		}
	};

	// Handle select input change (product_id)
	const handleProductInputChange = async (index, value) => {
		if (rows.length === 1 && value && isNaN(rows?.product_id)) {
			getProductBatchByProductId(paramProductID, index);
		}
		const newRows = [...rows];
		const data = await getProductList({
			filterBy: isNaN(value) || value === '' || value === null ? 'product_name' : 'product_id',
			filterValue: value === '' || value === null ? null : value,
		});

		if (!data || data.length === 0) return;

		setSelectedRowData((prevData) => ({
			...prevData,
			[index]: data.productWithStockCount[0],
		}));
		const batch = data.productWithStockCount[0]?.batchdetails?.map((ele) => ({
			key: ele.batch_no,
			value: ele.batch_no,
		}));

		setBatchOptions(batch || []);
		if (value === '') {
			setErrorCells([...errorCells, { index, name: 'product_id' }]);
		} else {
			setErrorCells(
				errorCells.filter((cell) => !(cell.index === index && cell.name === 'product_id')),
			);
		}

		newRows[index].product_id = value;
		// newRows[index].product_name = filteredProduct?.product_name || "";
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	const handleDateChange = (index, event, field) => {
		let { value } = event.target;
		value = value.replace(/\D/g, '');

		if (value.length > 4) {
			value = value.slice(0, 4);
		}

		if (value.length >= 2) {
			let month = value.slice(0, 2);
			const year = value.slice(2);

			if (month > '12') {
				month = '12';
			}
			if (value.length === 2) {
				value = `${month}`;
			} else {
				value = `${month}/${year}`;
			}
		}
		if (field === 'exp_date') {
			if (value === '' || value === '' || value === 'MM/YY') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}

		const newRows = [...rows];
		newRows[index][field] = value;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle batch change
	const handleBatchChange = async (index, value, indexName) => {
		let name = indexName;

		let ProductCode = isNaN(rows[index]?.product_name)
			? rows[index]?.product_id
			: rows[index]?.product_name;

		if (paramId || value === null || value === '') {
			setBatchOptions([]);
			getProductBatchByProductId(ProductCode, index);
		}

		if (value) {
			let updatedErrorCell = errorCells.filter(
				(cell) => !(cell.index === index && cell.name === 'batch_no'),
			);
			setErrorCells(updatedErrorCell);

			const newRows = [...rows];
			newRows[index][name] = value;
			setRows(newRows);
			if (onChange) {
				onChange(newRows);
			}
		} else {
			setErrorCells([...errorCells, { index, name: 'batch_no' }]);
		}
	};

	const handleChangeDefault = (index, event, field) => {
		let { name, value } = event.target;
		if (field === 'mfg_date') {
			if (value === '' || value === null) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'mrp') {
			if (value === 0 || value === '' || value === '0') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'quantity') {
			if (value === 0 || value === '' || value === '0') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'tax') {
			if (value === '') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'tax') {
			if (value === '') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'amount') {
			if (value === 0 || value === '' || value === '0') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'rate') {
			if (value === 0 || value === '' || value === '0') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'batch_no') {
			if (value === '') {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		const newRows = [...rows];
		newRows[index][name] = value;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle key down events (e.g., adding a new row)
	const handleKeyDown = (index, event) => {
		let { name, value } = event.target;
		if (event.key === 'Enter') {
			const column = columns.find((col) => col.name === event.target.name);
			if (
				(column && column.label === newRowgenerate) ||
				(column && column.label === 'Final Amount')
			) {
				addRow();
			}
		}
		if (name === 'exp_date') {
			handleDateChange(index, event, name);
		} else if (name === 'product_id') {
			handleProductInputChange(index, value);
		} else {
			handleChangeDefault(index, event, name);
		}
	};

	// Handle reference for input elements
	const handleRef = (rowIndex, cellIndex) => (el) => {
		if (!inputRefs.current[rowIndex]) {
			inputRefs.current[rowIndex] = [];
		}
		inputRefs.current[rowIndex][cellIndex] = el;
	};

	const getToolTipData = (name) => {
		switch (name) {
			case 'product_id':
				return 'Name can not be empty.';
			case 'batch_no':
				return 'Batch No can not be empty';
			case 'mfg_date':
				return `mfg_date number can not be empty`;
			case 'exp_date':
				return `exp_date date can not be empty`;
			case 'mrp':
				return `MRP can not be empty`;
			case 'quantity':
				return `Quantity can not be empty`;
			case 'tax':
				return `tax can not be empty`;
			case 'amount':
				return `Net Amount can not be empty`;
			case 'rate':
				return `Final Amount can not be empty`;
			default:
				return '';
		}
	};

	const calculateTotalPayment = () => {
		return rows?.reduce((total, notes) => total + parseFloat(notes.rate || 0), 0);
	};

	// New useEffect to handle changes in the 'disabled' prop
	useEffect(() => {
		if (rows.length > 0 && onChange) {
			onChange(rows);
		}
	}, [disabled]);

	const ErrorModalOpen = () => {
		setShowError(true);
	};
	const CloseErrorModal = () => {
		setShowError(false);
		setTimeout(() => {
			if (rows.length > 0) {
				inputRefs.current[rows.length - 1]?.[0]?.focus();
			}
		}, 0);
	};
	const numberOfProducts = rows.length;

	return (
		<>
			{errorCells && errorCells?.length > 0 && (
				<div className="d-flex justify-content-end  text-danger  mb-0">
					{' '}
					<span
						style={{ textDecoration: 'underline', cursor: 'pointer' }}
						onClick={ErrorModalOpen}
					>
						Show all error
					</span>
				</div>
			)}
			<div className="text-center mb-2 custum_table">
				<div>
					<table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th
										className={`${className}`}
										key={col.name}
									>
										<div
											style={{
												display: 'flex',
												justifyItems: 'start',
												alignItems: 'center',
											}}
										>
											<span>{col.label}</span>
											<span style={{ color: 'red' }}>
												{[
													'product_id',
													'batch_no',
													'mfg_date',
													'exp_date',
													'mrp',
													'quantity',
													'amount',
													'rate',
													'tax',
												].includes(col.name)
													? '*'
													: ''}
											</span>
											{[
												'product_id',
												'batch_no',
												'mfg_date',
												'exp_date',
												'mrp',
												'quantity',
												'amount',
												'rate',
												'tax',
											].includes(col.name) ? (
												<i className="toolTip_custum fa fa-info-circle">
													<span className="toolTip_custumtext">{getToolTipData(col.name)}</span>
												</i>
											) : (
												''
											)}
										</div>
									</th>
								))}
								<th>Actions</th>
							</tr>
						</thead>
						{loading ? (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{
									marginTop: '5%',
									marginLeft: '40%',
									position: 'fixed ',
								}}
							>
								<span
									className="Page_loader"
									role="status"
									aria-hidden="true"
								></span>
							</div>
						) : (
							<tbody>
								{rows.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{columns.map((col, cellIndex) => (
											<td
												key={col.name}
												className={
													errorCells.some(
														(cell) => cell.index === rowIndex && cell.name === col.name,
													)
														? 'error'
														: ''
												}
											>
												{col.name === 'batch_no' ? (
													<InputSelect
														placeholder="Select Batch No"
														value={
															batchOptions?.find((option) => option.key === row[col.name])?.value ||
															row[col.name]
														}
														onChange={(value) => handleBatchChange(rowIndex, value, col?.name)}
														onSelect={(option) =>
															handleBatchChange(rowIndex, option.value, col?.name)
														}
														options={batchOptions}
														returnInputValue={returnInputvalue}
														disabled={disabled}
														propInputRef={handleRef(rowIndex, cellIndex)}
														isLocalValidationError={
															errorCells.some(
																(cell) => cell.index === rowIndex && cell.name === col.name,
															)
																? true
																: false
														}
														onfucusCapture={(e) =>
															handleBatchChange(rowIndex, e.target.value, col?.name)
														}
													/>
												) : col.type === 'product_name' ? (
													<InputSelect
														placeholder="Select Product"
														value={row[col.name]}
														onChange={(value) => handleProductInputChange(rowIndex, value)}
														onSelect={(option) => handleProductInputChange(rowIndex, option.key)}
														isLocalValidationError={
															errorCells.some(
																(cell) => cell.index === rowIndex && cell.name === col.name,
															)
																? true
																: false
														}
														options={productOptions}
														disabled={disabled}
														returnInputValue={false}
														propInputRef={handleRef(rowIndex, cellIndex)}
														onfucusCapture={(e) => {
															handleProductInputChange(rowIndex, rows[rowIndex].product_id);
														}}
													/>
												) : col.type === 'date' ? (
													<input
														type="text"
														name={col.name}
														value={row[col.name]}
														style={{
															border: '1px solid rgba(85, 91, 103, 0.15)',
															borderRadius: '8px',
															height: '38px',
														}}
														onChange={(e) => handleDateChange(rowIndex, e, col.name)}
														onKeyDown={(e) => handleKeyDown(rowIndex, e)}
														placeholder="MM/YY"
														maxLength="5"
														disabled={disabled}
														className="table_input"
														// ref={handleRef(index, colIndex)}
														// ref={(el) =>
														//   (inputRefs.current[index] = [
														//     ...(inputRefs.current[index] || []),
														//     el,
														//   ])
														// }
														ref={handleRef(rowIndex, cellIndex)}
													/>
												) : (
													<input
														className="table_input"
														type={col.type}
														name={col.name}
														value={row[col.name]}
														style={{
															border: '1px solid rgba(85, 91, 103, 0.15)',
															borderRadius: '8px',
															height: '38px',
														}}
														onChange={(e) => handleChangeDefault(rowIndex, e, col.name)}
														onKeyDown={(e) => handleKeyDown(rowIndex, e)}
														disabled={disabled}
														ref={handleRef(rowIndex, cellIndex)}
													/>
												)}
											</td>
										))}
										<td>
											{!(row?.adjustment_details_id || rowIndex === 0 || disabled) && (
												<button
													className="delete mt-0 bg-transparent"
													onClick={() => deleteRow(rowIndex)}
													tabIndex="-1"
													disabled={disabled}
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<path
															d="M3 6H5H21"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M10 11V17"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M14 11V17"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</button>
											)}
										</td>
									</tr>
								))}
							</tbody>
						)}
					</table>
				</div>
				<span
					className="d-flex "
					style={{
						border: '1px solid #C3CBDC',
						height: '36px',
						backgroundColor: '#F6F6F9',
						display: 'flex',
						alignItems: 'center',
						paddingLeft: '10px',
						paddingRight: '60px',
						justifyContent: 'space-between',
						width: '100%',
						margin: 'auto',
					}}
				>
					<div>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '400',
								fontSize: '14px',
							}}
						>
							Total Items :
						</span>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '700',
								fontSize: '14px',
								marginLeft: '5px',
							}}
						>
							{numberOfProducts}
						</span>
					</div>
					{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<p style={{ margin: '5px 35px' }}>₹220</p>
						<p style={{ margin: '5px 30px' }}>30%</p>
						<p style={{ margin: '5px 45px' }}>₹322</p>
						<p style={{ margin: '5px 25px' }}>₹240</p>
					</div> */}
				</span>

				{!disabled && (
					<span style={{ display: 'flex', justifyContent: 'right' }}>
						<button
							className="bg-transparent text-dark add-new-row-button mt-2"
							onClick={addRow}
						>
							Add New Row
							<i
								className="fa fa-plus"
								style={{
									marginLeft: '.5rem',
									strokeWidth: '.1rem',
									color: 'var(--brand-color)',
									WebkitTextStrokeColor: '#fff',
									WebkitTextStrokeWidth: '1px',
								}}
							/>
						</button>
					</span>
				)}
			</div>
			<div className="text-start">
				{showError && (
					<ErrorModal
						show={showError}
						isOpen={showError}
						onClose={CloseErrorModal}
						title="Error in Add Inventory Table"
						error={errorCells}
					/>
				)}
			</div>
		</>
	);
};

export default NoteTable;
