import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL, // Set your base API URL
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
	(response) => {
		// If the response is successful, simply return it
		return response;
	},
	(error) => {
		// Check if the error response status is 401 (Unauthorized)
		if (error.response && error.response.status === 401) {
			// Redirect the user to the login page
			localStorage.clear();
			window.location.href = '/'; // Adjust the route as necessary
		}

		// Return a rejected promise to allow error handling in your code
		return Promise.reject(error);
	},
);

export default axiosInstance;
