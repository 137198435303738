import React, { useState, useEffect, useRef } from 'react';
import './InputTable.scss';
import InputSelect from '../inputSelect/InputSelect';
import { useDispatch } from 'react-redux';
import { product_list } from '../../store/action/product';
import { useLocation, useParams } from 'react-router-dom';
import ErrorModal from '../ErrorModal/ErrorModal';
import { getProductList } from '../../services/products';

const PurchaseBillsTable = ({
	initialRows,
	columns,
	className,
	onChange,
	value,
	disabled,
	newRowgenerate = '',
	returnInputvalue,
	tableError,
	setIsTableErrorShow,
}) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [batchOptions, setBatchOptions] = useState([]);
	const [productOptions, setProductOptions] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState({});
	const [rows, setRows] = useState([]);
	const [errorCells, setErrorCells] = useState([]);
	const [showError, setShowError] = useState(false);
	const dispatch = useDispatch();
	const inputRefs = useRef([]);
	const param = useParams();
	const paramId = param.id;
	const [loading, setLoading] = useState(false);
	const date = new Date();
	const ThisYear = date.getFullYear().toString().slice(-2);
	const Thismonth = (date.getMonth() + 1).toString().padStart(2, '0');
	const [batchList, setBatchList] = useState({});
	const isFirstRender = useRef(false);

	// Access query parameters
	const paramProductID = queryParams.get('prodid');
	const paramProductName = queryParams.get('prod');

	// Fetch product list and details
	useEffect(() => {
		let param = `page=1&pageSize=2000`;
		fetchProduct(param);
	}, []);

	useEffect(() => {
		if (errorCells.length > 0) {
			setIsTableErrorShow(true);
		} else {
			setIsTableErrorShow(false);
		}
	}, [errorCells]);

	useEffect(() => {
		if (tableError && rows?.length > 0 && errorCells.length > 0) {
			const secondLastRowIndex = rows.length - 1;
			const secondLastRow = rows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null ||
							value === '' ||
							value === 'MM/YY' ||
							value === '0.00' ||
							value === '0' ||
							Number(value) === 0) &&
						[
							'product_id',
							'batch_no',
							'quantity',
							'free_quantity',
							'purchase_rate',
							'discount',
							'mfg_date',
							'exp_date',
							'mrp',
							'amount',
						].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === secondLastRowIndex)),
				...errors,
			]);
		}
	}, [tableError]);

	useEffect(() => {
		setRows(value.length > 0 ? value : initialRows.length > 0 ? initialRows : []);
	}, [value, initialRows]);

	// useEffect(() => {
	// 	if (paramProductID) {
	// 		fetchProduct();
	// 		getProductBatchByProductId(paramProductID, 0);
	// 		// setLoading(false);
	// 		let initValue = [
	// 			{
	// 				product_id: paramProductName,
	// 				batch_no: '',
	// 				quantity: '',

	// 				// adjustment_details_id: "",
	// 				product_name: Number(paramProductID),
	// 				qty: '',
	// 				qtyHand: '',
	// 			},
	// 		];
	// 		setRows(initValue);

	// 		// setLoading(false);
	// 	}
	// }, [paramProductID]);

	const getProductBatchByProductId = (productID, index) => {
		let param = `filterBy=product_id&filterValue=${productID ? productID : ''}`;
		dispatch(product_list(param)).then((res) => {
			if (res?.success) {
				let filteredProduct = res?.data?.productWithStockCount?.[0];
				let batch = res?.data?.productWithStockCount?.[0]?.batchdetails?.map((ele) => ({
					key: ele.batch_no,
					value: ele.batch_no,
				}));
				setBatchOptions(batch || []);
				setSelectedRowData((prevData) => ({
					...prevData,
					[index]: filteredProduct,
				}));
			} else {
			}
		});
	};

	const fetchProduct = (param) => {
		dispatch(product_list(param)).then((res) => {
			if (res?.success) {
				setProductDetails(res?.data?.productWithStockCount);
				const productOptions = res?.data?.productWithStockCount?.map((ele) => ({
					key: ele?.product_id,
					value: ele?.product_name,
				}));
				setProductOptions(productOptions);
			} else {
			}
		});
	};

	// Debounce function definition
	const getproductbyInoutValue = (func, delay) => {
		let timeoutId;
		return function (...args) {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func.apply(this, args);
			}, delay);
		};
	};

	// Create a debounced version of fetchProduct
	const debouncedFetchProduct = getproductbyInoutValue((query) => {
		fetchProduct(query);
	}, 300); // Adjust the delay as needed

	// Helper function to get product details by product_id
	const getProductById = (productId) => {
		return productDetails?.find((product) => product.product_id === productId);
	};

	// Add a new row
	const addRow = () => {
		const emptyRow = columns.reduce((acc, col) => {
			acc[col.name] = '';
			return acc;
		}, {});
		const newRows = [...rows, emptyRow];
		setRows(newRows);
		// Check if the second last row is empty
		if (newRows.length > 1) {
			const secondLastRowIndex = newRows.length - 2;
			const secondLastRow = newRows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null ||
							value === '' ||
							value === 'MM/YY' ||
							value === '0.00' ||
							value === '0' ||
							Number(value) === 0) &&
						[
							'product_id',
							'batch_no',
							'quantity',
							'free_quantity',
							'purchase_rate',
							'discount',
							'mfg_date',
							'exp_date',
							'mrp',
							'amount',
						].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === secondLastRowIndex)),
				...errors,
			]);
		}

		if (onChange) {
			onChange(newRows);
		}
		setTimeout(() => {
			inputRefs.current[newRows.length - 1]?.[0]?.focus();
			let lastRowRef = inputRefs.current[newRows.length - 1]?.[0];
			lastRowRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	};

	// Delete a row
	const deleteRow = (index) => {
		if (rows.length > 1) {
			const newRows = [...rows];
			newRows.splice(index, 1);
			const newErrorCells = errorCells
				.filter((error) => error.index !== index)
				.map((error) => ({
					...error,
					index: error.index > index ? error.index - 1 : error.index,
				}));
			setErrorCells(newErrorCells);
			setRows(newRows);
			if (onChange) {
				onChange(newRows);
			}
			setTimeout(() => {
				inputRefs.current[newRows.length - 1]?.[0]?.focus();
			}, 0);
		} else {
			// setRows([]);
			// setErrorCells([]);
			// setRows(initialRows);
		}
	};

	// Handle input change in the table
	const handleChange = (index, event) => {
		let { name, value } = event.target;
		value = value?.replace(/[^0-9.]/g, '');
		const decimalCount = (value.match(/\./g) || []).length;
		let stockInhand = 0;
		if (decimalCount > 1) {
			value = +value?.slice(0, value.lastIndexOf('.'));
		}

		stockInhand = rows[index]?.qty - value;

		if (rows[index]?.qty && +value <= rows[index]?.qty) {
			setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === name)));
		} else {
			setErrorCells([...errorCells, { index, name: name }]);
		}
		const newRows = [...rows];
		newRows[index].qtyHand = stockInhand === 0 ? '' : stockInhand;
		newRows[index][name] = value;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};
	// Handle select input change (product_id)
	const handleProductInputChange = async (index, value) => {
		console.log({ index, value });
		setBatchOptions([]);
		if (rows.length === 1 && value && isNaN(rows?.product_id)) {
			getProductBatchByProductId(paramProductID, index);
		}
		const newRows = [...rows];
		// let param = `filterBy=product_name&filterValue=${value}`;
		// debouncedFetchProduct(param);
		// const filteredProduct = getProductById(value);
		const data = await getProductList({
			filterBy: isNaN(value) || value === '' ? 'product_name' : 'product_id',
			filterValue: value === '' ? null : value,
		});

		if (!data || data.length === 0) return;

		setSelectedRowData((prevData) => ({
			...prevData,
			[index]: data.productWithStockCount[0],
		}));
		console.log(data);
		newRows[index].product_tax1 = data.productWithStockCount[0]?.tax1 ?? 0;
		newRows[index].product_tax2 = data.productWithStockCount[0]?.tax2 ?? 0;
		const batch = data.productWithStockCount[0]?.batchdetails?.map((ele) => ({
			key: ele.batch_no,
			value: ele.batch_no,
		}));

		setBatchOptions(batch || []);
		if (value === '') {
			setErrorCells([...errorCells, { index, name: 'product_id' }]);
		} else {
			setErrorCells(
				errorCells.filter((cell) => !(cell.index === index && cell.name === 'product_id')),
			);
		}

		newRows[index].product_id = value;
		// newRows[index].product_name = filteredProduct?.product_name || "";
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle batch change
	const handleBatchChange = async (index, value, indexName) => {
		let name = indexName;

		let ProductCode = isNaN(rows[index]?.product_name)
			? rows[index]?.product_id
			: rows[index]?.product_name;
		let batchCode = isNaN(rows[index]?.batch_no) ? rows[index]?.batch_id : rows[index]?.batch_no;

		console.log({ ProductCode, index, value, indexName });

		if (paramId || value === null || value === '') {
			setBatchOptions([]);
			getProductBatchByProductId(ProductCode, index);
		}

		// const data = await getProductById({
		// 	filterBy: 'product_name',
		// 	filterValue: rows[index]?.product_name,
		// });

		// const batchList = data?.productWithStockCount[0]?.batchdetails?.map((pb) => {
		// 	return {
		// 		key: pb?.product_batch_id,
		// 		value: pb?.batch_no,
		// 	};
		// });

		// setBatchOptions([]);

		if (value) {
			let updatedErrorCell = errorCells.filter(
				(cell) => !(cell.index === index && cell.name === 'batch_no'),
			);
			setErrorCells(updatedErrorCell);
			// const filteredBatch = selectedRowData[index]?.batchdetails?.find(
			// 	(ele) => (ele.product_batch_id || ele.batch_no) === value,
			// );

			// let filterProductselectedIndex = productDetails.filter((ele) => {
			// 	return ele?.product_id === ProductCode;
			// });

			// let batch_qty = filterProductselectedIndex?.[0]?.batchdetails?.filter((item) => {
			// 	return item?.product_batch_id === batchCode;
			// });

			// const availableQuantity =
			// 	filteredBatch?.free_quantity + filteredBatch?.quantity || batch_qty?.[0]?.quantity || 0;

			const newRows = [...rows];
			newRows[index][name] = value;
			// newRows[index].quantity = availableQuantity;
			setRows(newRows);
			if (onChange) {
				onChange(newRows);
			}
		} else {
			setErrorCells([...errorCells, { index, name: 'batch_no' }]);
		}
	};

	const handleDateChange = (index, event, field) => {
		let { value } = event.target;
		value = value.replace(/\D/g, '');

		if (value.length > 4) {
			value = value.slice(0, 4);
		}

		if (value.length >= 2) {
			let month = value.slice(0, 2);
			const year = value.slice(2);

			if (month > '12') {
				month = '12';
			}
			if (value.length === 2) {
				value = `${month}`;
			} else {
				value = `${month}/${year}`;
			}
		}
		if (field === 'exp_date') {
			let currentRow = rows[index];
			let currentRowmfg_date = currentRow?.mfg_date;
			if (
				value === '' ||
				currentRowmfg_date?.split('/')?.[1] > value?.split('/')?.[1] ||
				value === ''
			) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'mfg_date') {
			if (
				value === '' ||
				value?.split('/')?.[1] > ThisYear ||
				(value?.split('/')?.[1] >= ThisYear && value?.split('/')?.[0] > Thismonth)
			) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}

		const newRows = [...rows];
		newRows[index][field] = value;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	const handleChangeDefault = (index, value, field) => {
		let currentRowValue = rows[index];
		if (field === 'free_quantity') {
			if (Number(currentRowValue?.quantity) < Number(value) || value === '' || value === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'mrp') {
			if (Number(currentRowValue?.purchase_rate) > Number(value) || value === '' || value === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'quantity') {
			if (value === '' || value === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'purchase_rate') {
			if (value === '' || value === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'discount') {
			if (value === '' || Number(value) === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		if (field === 'amount') {
			if (value === '' || value === 0) {
				setErrorCells([...errorCells, { index, name: field }]);
			} else {
				setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === field)));
			}
		}
		// if (field === 'batch_no') {
		// 	if (value === '') {
		// 		setErrorCells([...errorCells, { index, field }]);
		// 	} else {
		// 		setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.field === field)));
		// 	}
		// }
		const newRows = [...rows];
		newRows[index][field] = value;
		let totalAmountWithDiscount =
			Number(newRows[index].purchase_rate ?? 0) * Number(newRows[index].quantity ?? 0) -
			(Number(newRows[index].purchase_rate ?? 0) *
				Number(newRows[index].quantity ?? 0) *
				Number(newRows[index].discount ?? 0)) /
				100;

		newRows[index].amount = totalAmountWithDiscount;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	const handleKeyDown = (index, event) => {
		let { name, value } = event.target;
		if (event.key === 'Enter') {
			const column = columns.find((col) => col.name === name);
			if ((column && column.label === newRowgenerate) || (column && column.label === 'Amount')) {
				addRow();
			}
		}
		if (name === 'mfg_date' || name === 'exp_date') {
			handleDateChange(index, event, name);
		} else if (name === 'batch_no') {
			handleBatchChange(index, value, name);
		} else if (name === 'product_id') {
			handleProductInputChange(index, value, name);
		} else {
			console.log('value ' + value);
			handleChangeDefault(index, value, name);
		}
	};

	// Handle key down events (e.g., adding a new row)
	// const handleKeyDown = (index, event) => {
	// 	let { name, value } = event.target;
	// 	if (event.key === 'Enter') {
	// 		const column = columns.find((col) => col.name === event.target.name);
	// 		if ((column && column.label === newRowgenerate) || (column && column.label === 'sale_rate')) {
	// 			addRow();
	// 		}
	// 	} else {
	// 		if (name === 'batch_no') {
	// 			handleBatchChange(index, value, name);
	// 		} else {
	// 			handleChange(index, event);
	// 		}
	// 	}
	// };

	// Handle reference for input elements
	const handleRef = (rowIndex, cellIndex) => (el) => {
		if (!inputRefs.current[rowIndex]) {
			inputRefs.current[rowIndex] = [];
		}
		inputRefs.current[rowIndex][cellIndex] = el;
	};

	const getToolTipData = (name) => {
		switch (name) {
			case 'product_id':
				return 'Product Name can not be empty.';
			case 'batch_no':
				return 'Batch No can not be empty';
			case 'quantity':
				return 'Quantity can not be empty or 0';
			case 'free_quantity':
				return 'free quantity can not be empty';
			case 'mfg_date':
				return 'Manufacture date can not be empty';
			case 'exp_date':
				return 'Expire date can not be empty';
			case 'mrp':
				return 'MRP can not be empty';
			case 'purchase_rate':
				return 'Purchase rate can not be empty';
			case 'discount':
				return `Discount can not empty`;
			case 'amount':
				return `Amount can not empty`;
			default:
				return '';
		}
	};

	// New useEffect to handle changes in the 'disabled' prop
	useEffect(() => {
		if (rows.length > 0 && onChange) {
			onChange(rows);
		}
	}, [disabled]);

	const ErrorModalOpen = () => {
		setShowError(true);
	};
	const CloseErrorModal = () => {
		setShowError(false);
		setTimeout(() => {
			if (rows.length > 0) {
				inputRefs.current[rows.length - 1]?.[0]?.focus();
			}
		}, 0);
	};

	const numberOfProducts = rows.length;

	return (
		<>
			{errorCells && errorCells?.length > 0 && (
				<div className="d-flex justify-content-end  text-danger  mb-0">
					{' '}
					<span
						style={{ textDecoration: 'underline', cursor: 'pointer' }}
						onClick={ErrorModalOpen}
					>
						Show all error
					</span>
				</div>
			)}
			<div className="text-center mb-2 custum_table">
				<div>
					<table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th
										className={`${className}`}
										key={col.name}
									>
										<div
											style={{
												display: 'flex',
												justifyItems: 'start',
												alignItems: 'center',
											}}
										>
											<span>{col.label}</span>
											<span style={{ color: 'red' }}>
												{[
													'product_id',
													'batch_no',
													'quantity',
													'free_quantity',
													'purchase_rate',
													'discount',
													'mfg_date',
													'exp_date',
													'mrp',
													'amount',
												].includes(col.name)
													? '*'
													: ''}
											</span>
											{[
												'product_id',
												'batch_no',
												'quantity',
												'free_quantity',
												'purchase_rate',
												'discount',
												'mfg_date',
												'exp_date',
												'mrp',
												'amount',
											].includes(col.name) ? (
												<i className="toolTip_custum fa fa-info-circle">
													<span className="toolTip_custumtext">{getToolTipData(col.name)}</span>
												</i>
											) : (
												''
											)}
										</div>
									</th>
								))}
								<th>Actions</th>
							</tr>
						</thead>
						{loading ? (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{
									marginTop: '5%',
									marginLeft: '40%',
									position: 'fixed ',
								}}
							>
								<span
									className="Page_loader"
									role="status"
									aria-hidden="true"
								></span>
							</div>
						) : (
							<tbody>
								{rows.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{columns.map((col, cellIndex) => (
											<td
												key={col.name}
												className={
													errorCells.some(
														(cell) => cell.index === rowIndex && cell.name === col.name,
													)
														? 'error'
														: ''
												}
											>
												{col.type === 'batch_no' ? (
													<InputSelect
														placeholder="Select Batch No"
														value={
															batchOptions?.find((option) => option.key === row[col.name])?.value ||
															row[col.name]
														}
														onChange={(value) => handleBatchChange(rowIndex, value, col?.name)}
														onSelect={(option) =>
															handleBatchChange(rowIndex, option.value, col?.name)
														}
														options={batchOptions}
														returnInputValue={returnInputvalue}
														disabled={disabled}
														propInputRef={handleRef(rowIndex, cellIndex)}
														isLocalValidationError={
															errorCells.some(
																(cell) => cell.index === rowIndex && cell.name === col.name,
															)
																? true
																: false
														}
														onfucusCapture={(e) =>
															handleBatchChange(rowIndex, e.target.value, col?.name)
														}
													/>
												) : col.type === 'product_name' ? (
													<InputSelect
														placeholder="Select Product"
														value={row[col.name]}
														onChange={(value) => handleProductInputChange(rowIndex, value)}
														onSelect={(option) => handleProductInputChange(rowIndex, option.key)}
														isLocalValidationError={
															errorCells.some(
																(cell) => cell.index === rowIndex && cell.name === col.name,
															)
																? true
																: false
														}
														options={productOptions}
														disabled={disabled}
														returnInputValue={false}
														propInputRef={handleRef(rowIndex, cellIndex)}
														onfucusCapture={(e) => {
															handleProductInputChange(rowIndex, rows[rowIndex].product_id);
														}}
													/>
												) : col.type === 'date' ? (
													<input
														type="text"
														name={col.name}
														value={row[col.name]}
														style={{
															border: '1px solid rgba(85, 91, 103, 0.15)',
															borderRadius: '8px',
															height: '38px',
														}}
														onChange={(e) => handleDateChange(rowIndex, e, col.name)}
														onKeyDown={(e) => handleKeyDown(rowIndex, e)}
														placeholder="MM/YY"
														maxLength="5"
														disabled={disabled}
														className="table_input"
														// ref={handleRef(index, colIndex)}
														// ref={(el) =>
														//   (inputRefs.current[index] = [
														//     ...(inputRefs.current[index] || []),
														//     el,
														//   ])
														// }
														ref={handleRef(rowIndex, cellIndex)}
													/>
												) : (
													<input
														className="table_input"
														type={col.type}
														name={col.name}
														value={row[col.name]}
														// error={
														// 	errorCells.some(
														// 		(cell) => cell.index === rowIndex && cell.name === col.name,
														// 	)
														// 		? true
														// 		: false
														// }
														style={{
															border: errorCells.some(
																(cell) => cell.index === rowIndex && cell.name === col.name,
															)
																? '1px solid red'
																: '1px solid rgba(85, 91, 103, 0.15)',
															borderRadius: '8px',
															height: '38px',
														}}
														onChange={(e) => {
															handleChangeDefault(rowIndex, e.target.value, col.name);
														}}
														onKeyDown={(e) => handleKeyDown(rowIndex, e)}
														disabled={disabled}
														// disabled={
														// 	disabled
														// 		? disabled
														// 		: col.name === 'qtyHand' || col.name === 'qty'
														// 			? true
														// 			: false
														// }
														// disabled={disabled}
														ref={handleRef(rowIndex, cellIndex)}
													/>
												)}
											</td>
										))}
										<td>
											{!(row?.adjustment_details_id || rowIndex === 0 || disabled) && (
												<button
													className="delete mt-0 bg-transparent"
													onClick={() => deleteRow(rowIndex)}
													tabIndex="-1"
													disabled={disabled}
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
													>
														<path
															d="M3 6H5H21"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M10 11V17"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<path
															d="M14 11V17"
															stroke="#FF0000"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</svg>
												</button>
											)}
										</td>
									</tr>
								))}
							</tbody>
						)}
					</table>
				</div>
				<span
					className="d-flex "
					style={{
						border: '1px solid #C3CBDC',
						height: '36px',
						backgroundColor: '#F6F6F9',
						display: 'flex',
						alignItems: 'center',
						paddingLeft: '10px',
						paddingRight: '100px',
						justifyContent: 'space-between',
						width: '100%',
						margin: 'auto',
					}}
				>
					<div>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '400',
								fontSize: '14px',
							}}
						>
							Total Items :
						</span>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '700',
								fontSize: '14px',
								marginLeft: '5px',
							}}
						>
							{numberOfProducts}
						</span>
					</div>
				</span>

				{!disabled && (
					<span style={{ display: 'flex', justifyContent: 'right' }}>
						<button
							className="bg-transparent text-dark add-new-row-button mt-2"
							onClick={addRow}
						>
							Add New Row
							<i
								className="fa fa-plus"
								style={{
									marginLeft: '.5rem',
									strokeWidth: '.1rem',
									color: 'var(--brand-color)',
									WebkitTextStrokeColor: '#fff',
									WebkitTextStrokeWidth: '1px',
								}}
							/>
						</button>
					</span>
				)}
			</div>
			<div className="text-start">
				{showError && (
					<ErrorModal
						show={showError}
						isOpen={showError}
						onClose={CloseErrorModal}
						title="Error in Add Inventory Table"
						error={errorCells}
					/>
				)}
			</div>
		</>
	);
};

export default PurchaseBillsTable;
