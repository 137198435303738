import React, { useState, useEffect, useRef } from 'react';
import './InputTable.scss';
import InputSelect from '../inputSelect/InputSelect';
import { Navigate, useLocation, useParams, useNavigate } from 'react-router-dom';
import ErrorModal from '../ErrorModal/ErrorModal';
import { getPackagingList, getProductList } from '../../services/products';
import InputField from '../form/FormInput';
import Modal from '../modal/Modal';
import Button from '../ui/Button';
import { toast } from 'react-toastify';

const PurchaseOrderTable = ({
	id,
	initialRows,
	columns,
	className,
	onChange,
	handleSavePurchaseOrder,
	value,
	disabled,
	newRowgenerate = '',
	returnInputvalue,
	setErrorCells,
	errorCells,
	tableError,
	setIsTableErrorShow,
}) => {
	const inputRefs = useRef([]);
	const [productList, setProductList] = useState([]);
	const [packagingList, setPackagingList] = useState([]);
	const [rows, setRows] = useState(value.length > 0 ? [...value] : [...initialRows]);
	const [showError, setShowError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isAddProductAndSavePOInDraft, setIsAddProductAndSavePOInDraft] = useState(false);
	const navigate = useNavigate();

	const suburl = localStorage.getItem('suburl');

	const [isAddNewProductModalOpen, setIsAddNewProductModalOpen] = useState(false);

	const [productParams, setProductParams] = useState({
		page: 1,
		pageSize: 10,
		orderBy: 'product_code',
		sortBy: 'asc',
		filterBy: '',
		filterValue: '',
	});
	const [packagingParams, setPackagingParams] = useState({
		page: 1,
		pageSize: 10,
		orderBy: 'name',
		sortBy: 'asc',
		filterBy: '',
		filterValue: '',
	});

	// Add a new row
	const addRow = () => {
		const emptyRow = columns.reduce((acc, col) => {
			acc[col.name] = '';
			return acc;
		}, {});
		const newRows = [...rows, emptyRow];
		setRows(newRows);
		// Check if the second last row is empty
		if (newRows.length > 1) {
			const secondLastRowIndex = newRows.length - 2;
			const secondLastRow = newRows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '' || Number(value) === 0) &&
						['product_id', 'product_packaging', 'order_quantity'].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter(
					(error) => !(error.index === secondLastRowIndex && error.customErrorMessage === null),
				),
				...errors,
			]);
		}

		if (onChange) {
			console.log(newRows);
			onChange(newRows);
		}
		setTimeout(() => {
			inputRefs.current[newRows.length - 1]?.[0]?.focus();
			let lastRowRef = inputRefs.current[newRows.length - 1]?.[0];
			lastRowRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	};

	// Delete a row
	const deleteRow = (index) => {
		if (rows.length > 1) {
			const newErrorCells = errorCells
				.filter((error) => error.index !== index)
				.map((error) => ({
					...error,
					index: error.index > index ? error.index - 1 : error.index,
				}));
			setErrorCells(newErrorCells);
			const updatedRows = rows.filter((row, _index) => _index !== index);
			setRows(updatedRows);
			setTimeout(() => {
				inputRefs.current[updatedRows.length - 1]?.[0]?.focus();
			}, 0);
		} else {
			// setRows([]);
			// setErrorCells([]);
			// setRows(initialRows);
		}
	};

	// Handle input change in the table
	const handleChange = (index, key, value) => {
		if (value === '' || Number(value) === 0) {
			setErrorCells([...errorCells, { index, name: 'order_quantity' }]);
		} else {
			setErrorCells(
				errorCells.filter((cell) => !(cell.index === index && cell.name === 'order_quantity')),
			);
		}
		const newRows = [...rows];
		newRows[index][key] = value === '' ? null : Number(value);
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle select input change (product_id)
	const handleProductInputChange = (index, value) => {
		const newRows = [...rows];
		let error = [...errorCells];
		if (value === '') {
			console.log('va;ue1 ' + value);
			if (!error.find((error) => error.name === 'product_id' && error.index === index)) {
				console.log('va;ue2 ' + value);
				error.push({ index: index, name: 'product_id' });
			}
			// setErrorCells([...errorCells, { index, name: 'product_id' }]);
		} else if (rows.find((row) => row.product_id !== '' && row.product_id === value)) {
			// setErrorCells([
			// 	...errorCells,
			if (!error.find((error) => error.name === 'product_id' && error.index === index)) {
				console.log('value112 ' + value);
				error.push({
					index: index,
					name: 'product_id',
					customErrorMessage: { product_id: 'This product already exists in the Purchase order.' },
				});
			}
			// ]);
		} else {
			error = error.filter((cell) => !(cell.index === index && cell.name === 'product_id'));
		}

		// if (!rows.find((row) => row.product_id === value)) {
		const prod = productList?.find((prod) => prod?.key === value);
		// if (rows.find((row) => row.product_id === value)) {
		// 	newRows[index].product_id = '';
		// } else {

		// if (!prod) {
		// 	setErrorCells(error);
		// 	return;
		// }

		newRows[index].product_id = prod?.key || '';
		// }
		const packaging = packagingList?.find(
			(packaging) => packaging?.packaging_id === prod?.packaging_id,
		);

		newRows[index].product_packaging = packaging?.value || '';

		if (newRows[index].product_packaging === '') {
			if (!error.find((error) => error.name === 'product_packaging' && error.index === index)) {
				error.push({ index: index, name: 'product_packaging' });
			}
		} else {
			error = error.filter((cell) => !(cell.index === index && cell.name === 'product_packaging'));
		}
		console.log(error);
		setErrorCells(error);
		setRows(newRows);
		if (onChange) {
			console.log(newRows);
			onChange(newRows);
		}
	};

	// Handle select input change (product_id)
	const handlePackagingInputChange = (index, value) => {
		const newRows = [...rows];
		if (value === '') {
			setErrorCells([...errorCells, { index, name: 'product_packaging' }]);
		} else {
			setErrorCells(
				errorCells.filter((cell) => cell.index === index && cell.name !== 'product_packaging'),
			);
		}
		const packaging = packagingList.find((packaging) => packaging.name === value);
		newRows[index].packaging = packaging?.packaging_id || '';
		newRows[index].name = packaging?.name || '';
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle key down events (e.g., adding a new row)
	const handleKeyDown = (index, event) => {
		let { name, value } = event.target;
		if (event.key === 'Enter') {
			const column = columns.find((col) => col.name === event.target.name);
			if ((column && column.label === newRowgenerate) || (column && name === 'order_quantity')) {
				addRow();
			}
		} else {
			if (name === 'order_quantity') {
				handleChange(index, name, value);
			}

			if (name === 'product_id') {
				handleProductInputChange(index, value);
			}
		}
	};

	// Handle reference for input elements
	const handleRef = (rowIndex, cellIndex) => (el) => {
		if (!inputRefs.current[rowIndex]) {
			inputRefs.current[rowIndex] = [];
		}
		inputRefs.current[rowIndex][cellIndex] = el;
	};

	const getToolTipData = (name) => {
		switch (name) {
			case 'product_id':
				return 'Name can not be empty.';
			case 'packaging':
				return 'Packaging can not be empty';
			case 'order_quantity':
				return `Quantity can not be 0`;
			default:
				return '';
		}
	};

	const ErrorModalOpen = () => {
		setShowError(true);
	};

	const CloseErrorModal = () => {
		setShowError(false);
		setTimeout(() => {
			if (rows.length > 0) {
				inputRefs.current[rows.length - 1]?.[0]?.focus();
			}
		}, 0);
	};

	// New useEffect to handle changes in the 'disabled' prop
	useEffect(() => {
		if (rows.length > 0 && onChange) {
			onChange(rows);
		}
	}, [disabled]);

	function generateSelectInputOption(data, key1, key2, key3 = undefined) {
		return data?.map((item) => {
			return {
				key: item[key1],
				value: item[key2],
				...(key3 ? { [key3]: item[key3] } : {}), // Conditionally include key3 if provided
			};
		});
	}

	async function handleFetchProductPackaging() {
		const data = await getPackagingList(packagingParams);
		const packagingOptionsList = generateSelectInputOption(
			data?.packagingList,
			'name',
			'name',
			'packaging_id',
		);
		setPackagingList(packagingOptionsList);
	}

	async function fetchProduct() {
		const data = await getProductList(productParams);
		const productOptionList = generateSelectInputOption(
			data?.productWithStockCount,
			'product_id',
			'product_name',
			'packaging_id',
		);
		setProductList(productOptionList);
	}

	useEffect(() => {
		handleFetchProductPackaging();
	}, [packagingParams]);

	useEffect(() => {
		fetchProduct();
	}, [productParams]);

	useEffect(() => {
		if (errorCells.length > 0) {
			setIsTableErrorShow(true);
		} else {
			setIsTableErrorShow(false);
		}
	}, [errorCells]);

	useEffect(() => {
		if (
			// (tableError && id === undefined) ||
			tableError &&
			rows?.length > 0 &&
			errorCells.length > 0
		) {
			const secondLastRowIndex = rows.length - 1;
			const secondLastRow = rows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '' || Number(value) === 0) &&
						['product_id', 'product_packaging', 'order_quantity'].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter(
					(error) => !(error?.index === secondLastRowIndex && error?.customErrorMessage === null),
				),
				...errors,
			]);
		}
	}, [tableError]);

	useEffect(() => {
		if (value?.length > 0) {
			setRows(value);
		}
		// setRows(
		// value.length > 0 ? value : initialRows.length > 0 ? initialRows : []
		// );
		// if (paramId && !paramProductID) {
		// 	setLoading(true);
		// 	if (value?.length > 0) {
		// 		setRows(value);
		// 		setLoading(false);
		// 	}
		// } else {
		// 	if (!paramProductID) {
		// 		setLoading(true);
		// 		setRows(initialRows);
		// 		setLoading(false);
		// 	}
		// }
	}, [value]);

	const confirmSavePOInDraft = () => {
		handleSavePurchaseOrder(1, false, true);
	};

	// useEffect(() => {
	// 	if (paramProductID) {
	// 		fetchProduct();
	// 		// getProductBatchByProductId(paramProductID, 0);
	// 		// setLoading(false);
	// 		let initValue = [
	// 			{
	// 				product_id: paramProductName,
	// 				packaging: '',
	// 				product_name: Number(paramProductID),
	// 				order_quantity: '',
	// 			},
	// 		];
	// 		setRows(initValue);

	// 		// setLoading(false);
	// 	}
	// }, [paramProductID]);

	const numberOfProducts = rows.length;

	const totalQuantity = rows.reduce((total, product) => {
		return total + (product.order_quantity || 0); // Ensure that quantity is a number
	}, 0);

	return (
		<>
			{errorCells && errorCells?.length > 0 && (
				<div className="d-flex justify-content-end  text-danger  mb-0">
					<span
						style={{ textDecoration: 'underline', cursor: 'pointer' }}
						onClick={ErrorModalOpen}
					>
						Show all error
					</span>
				</div>
			)}
			<div className="text-center mb-2 custum_table">
				<table>
					<thead>
						<tr>
							{columns?.map((col) => (
								<th
									className={`${className}`}
									key={col.name}
								>
									<div
										style={{
											display: 'flex',
											justifyItems: 'start',
											alignItems: 'center',
										}}
									>
										<span>{col.label}</span>
										<span style={{ color: 'red' }}>
											{['product_id', 'packaging', 'order_quantity'].includes(col.name) ? '*' : ''}
										</span>
										{['product_id', 'packaging', 'order_quantity'].includes(col.name) ? (
											<i className="toolTip_custum fa fa-info-circle">
												<span className="toolTip_custumtext">{getToolTipData(col.name)}</span>
											</i>
										) : (
											''
										)}
									</div>
								</th>
							))}
							<th>Actions</th>
						</tr>
					</thead>
					{loading ? (
						<div
							className="d-flex justify-content-center align-items-center"
							style={{
								marginTop: '5%',
								marginLeft: '40%',
								position: 'fixed ',
							}}
						>
							<span
								className="Page_loader"
								role="status"
								aria-hidden="true"
							></span>
						</div>
					) : (
						<tbody>
							{rows.map((row, rowIndex) => (
								<tr key={rowIndex}>
									{columns.map((col, cellIndex) => (
										<td
											key={col.name}
											className={
												errorCells.some((cell) => cell.index === rowIndex && cell.name === col.name)
													? 'error'
													: ''
											}
										>
											{col.type === 'product_id' ? (
												<InputSelect
													placeholder="Select Product"
													value={row[col.name]}
													onChange={(value) => {
														console.log('value12 ' + value);
														if (value === '') {
															handleProductInputChange(rowIndex, '');
														}
														setPackagingParams((prev) => ({
															...prev,
															filterBy: 'name',
															filterValue: value,
														}));
													}}
													onSelect={(value) => {
														handleProductInputChange(rowIndex, value.key);
													}}
													isLocalValidationError={
														errorCells.some(
															(cell) => cell.index === rowIndex && cell.name === col.name,
														)
															? true
															: false
													}
													options={productList}
													propInputRef={handleRef(rowIndex, cellIndex)}
													onAddNew={() => setIsAddProductAndSavePOInDraft(true)}
												/>
											) : col.type === 'product_packaging' ? (
												<InputField
													placeholder="Select Packaging"
													value={row[col.name]}
													returnInputValue={false}
													// onChange={(value) => {
													// 	handlePackagingInputChange(rowIndex, value.value);
													// }}
													// onSelect={(value) => {
													// 	handlePackagingInputChange(rowIndex, value.value);
													// }}
													error={errorCells.some(
														(cell) => cell.index === rowIndex && cell.name === col.name,
													)}
													// options={packagingList}
													disabled={true}
													propInputRef={handleRef(rowIndex, cellIndex)}
												/>
											) : (
												<InputField
													type={col.type}
													name={col.name}
													value={row[col.name]}
													// onKeyDownCb={(e) => handleKeyDown(rowIndex, e)}
													onKeyPress={(e) => handleKeyDown(rowIndex, e)}
													error={errorCells.some(
														(cell) => cell.index === rowIndex && cell.name === col.name,
													)}
													style={{
														border: '1px solid rgba(85, 91, 103, 0.15)',
														borderRadius: '8px',
														textAlign: 'right',
													}}
													onChange={(value) => handleChange(rowIndex, col.name, value)}
													ref={handleRef(rowIndex, cellIndex)}
												/>
											)}
										</td>
									))}

									<td>
										{!(row?.adjustment_details_id || rowIndex === 0 || disabled) && (
											<button
												className="delete mt-0 bg-transparent"
												onClick={() => deleteRow(rowIndex)}
												tabIndex="-1"
												// disabled={disabled}
											>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
												>
													<path
														d="M3 6H5H21"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M10 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M14 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					)}
				</table>

				<span
					className="d-flex "
					style={{
						border: '1px solid #C3CBDC',
						height: '36px',
						backgroundColor: '#F6F6F9',
						display: 'flex',
						alignItems: 'center',
						paddingLeft: '10px',
						paddingRight: '100px',
						justifyContent: 'space-between',
						width: '100%',
						margin: 'auto',
					}}
				>
					<div>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '400',
								fontSize: '14px',
							}}
						>
							Total Items :
						</span>
						<span
							style={{
								color: 'var(--sub, #555B67)',
								fontWeight: '700',
								fontSize: '14px',
							}}
						>
							{numberOfProducts}
						</span>
					</div>

					<div
						style={{
							color: 'var(--sub, #555B67)',
							fontSize: 'px',
							fontWeight: '700',
						}}
					>
						<span>Total Quantity:</span> <span>{totalQuantity}</span>
					</div>
				</span>
				{!disabled && (
					<span style={{ display: 'flex', justifyContent: 'right' }}>
						<button
							className="bg-transparent text-dark add-new-row-button mt-3"
							onClick={addRow}
						>
							Add New Row
							<i
								className="fa fa-plus"
								style={{
									marginLeft: '.5rem',
									strokeWidth: '.1rem',
									color: 'var(--brand-color)',
									WebkitTextStrokeColor: '#fff',
									WebkitTextStrokeWidth: '1px',
								}}
							/>
						</button>
					</span>
				)}
				{isAddProductAndSavePOInDraft && (
					<Modal
						className="delivery-modal"
						title="Confirm save purchase order in draft"
						isOpen={isAddProductAndSavePOInDraft}
						onClose={() => setIsAddProductAndSavePOInDraft(false)}
						cancelButton={true}
					>
						<p>Are you sure want to save purchase order in draft to add product?</p>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								marginTop: '20px',
							}}
						>
							<Button
								button="Proceed"
								type="button"
								className="btn btn-primary"
								onClick={confirmSavePOInDraft}
							/>
							<span style={{ width: '20px' }}></span>
							<Button
								button="Cancel"
								type="button"
								className="btn btn-danger"
								onClick={() => setIsAddProductAndSavePOInDraft(false)}
							/>
						</div>
					</Modal>
				)}
			</div>
			<div className="text-start">
				{showError && (
					<ErrorModal
						show={showError}
						isOpen={showError}
						onClose={CloseErrorModal}
						title="Error in Purchase Order Table"
						error={errorCells}
					/>
				)}
			</div>
		</>
	);
};

export default PurchaseOrderTable;
