// export function getDate(dateString) {
// 	const date = new Date(dateString);

// 	if (isNaN(date.getTime())) {
// 		throw new Error('Invalid date string');
// 	}

// 	const year = date.getFullYear();
// 	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
// 	const day = String(date.getDate()).padStart(2, '0');

// 	return `${year}-${month}-${day}`;
// }

export function getDate(dateString, isReverse = false, onlyMonth = false) {
	// Check if the input dateString is empty or undefined
	if (!dateString) {
		console.error('Date string is empty or undefined');
		return 'Invalid Date';
	}

	// Try to parse the date string using JavaScript's Date constructor
	const date = new Date(dateString);

	// Check if the date is invalid (NaN)
	if (isNaN(date.getTime())) {
		console.error(`Invalid date string: ${dateString}`);
		return 'Invalid Date'; // Optionally return a default or fallback value
	}

	// Extract year, month, and day, and format them with padding for single digits
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, hence +1
	const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if single digit

	// Return the date in the format YYYY-MM-DD
	// return `${year}-${month}-${day}`;
	// Return the date in the format DD-MM-YYYY
	if (isReverse && !onlyMonth) {
		return `${year}-${month}-${day}`;
	}

	if ((onlyMonth && isReverse) || onlyMonth) {
		return `${month}/${year}`;
	}

	return `${day}/${month}/${year}`;
}

export function convertToISO(dateString) {
	// Create a Date object from the input string
	const date = new Date(dateString);

	// Convert the Date object to ISO-8601 format
	return date.toISOString();
}

export function getCurrentDate() {
	const today = new Date();
	const formattedDate = today.toISOString().split('T')[0];
	return formattedDate;
}
