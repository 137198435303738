import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { ExportTable } from '../../store/action/export/Export';
import { fetchPurchaseReceive, updatePurchaseReceiveStatus } from '../../services/purchase_receive';
import { getDate } from '../../utils/dateUtils';
import FullCircle from '../../assets/image/full_circle.svg';
import HalfCircle from '../../assets/image/half_circle.svg';
import EmptyCircle from '../../assets/image/empty_circle.svg';
import addFilter from '../../assets/image/AddFilter.svg';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import PageLoader from '../../component/PageLoader';
import { toast } from 'react-toastify';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import DeletePurchaseReceiveModel from './components/DeletePurchaseReceiveModel';
import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import axiosInstance from '../../services/axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import { clearAdvanceFilter, updateAdvanceFilter } from '../../store/action/advanceFilter';

const PurchaseReceived = () => {
	const { advanceFilterReducer } = useSelector((state) => state);
	const suburl = localStorage.getItem('suburl');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Purchase Receive');
	const [sortDirection, setSortDirection] = useState({ purchase_receive_id: 'asc' });
	const [stateCondition, setStateCondition] = useState('');
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [loading, setLoading] = useState({
		pdf: {
			button: 'pdf',
			isLoading: false,
		},
		download: {
			button: 'download',
			isLoading: false,
		},
	});
	const [purchaseReceiveParams, setPurchaseReceiveParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_receive_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [purchaseReceive, setPurchaseReceive] = useState({
		currentPage: 1,
		totalPages: 0,
		data: [],
	});
	const [deselectRows, setDeselectRows] = useState(null);

	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};
	const [isAppliedAdvanceFilter, setIsAppliedAdvanceFilter] = useState(false);
	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		totalPages: 0,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);

	async function handleAdvanceFilterDropdown(option) {
		if (!option) return;

		const { key, value } = option;
		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === key);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: key,
				label: value,
				data: [],
			},
		]);
	}

	// removing the selected inputs from both modal and tables
	function handleRemoveSelectedFilterInput(key) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
	}

	async function handleOnFocusSelectedFilterInput(key) {
		let vendorList = [];
		const data = await fetchPurchaseReceive({ filterBy: key, filterValue: '' });
		const selectInputData = data?.purchaseReceive?.reduce((acc, pr) => {
			console.log(pr.vendor?.vendor_name);
			if (key === 'vendor_name') {
				if (acc.find((item) => item.key === pr.vendor?.vendor_name)) {
					return acc;
				}

				acc.push({
					key: pr.vendor?.vendor_name,
					value: pr.vendor?.vendor_name,
				});
				return acc;
			} else if (key === 'purchase_order_number') {
				if (acc.find((item) => item.key === pr.purchase_order?.purchase_order_number)) {
					return acc;
				}
				acc.push({
					key: pr.purchase_order?.purchase_order_number,
					value: pr.purchase_order?.purchase_order_number,
				});
				return acc;
			} else {
				acc.push({
					key: pr[key],
					value: 'PR-' + pr[key],
				});
				return acc;
			}
		}, []);

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === key) {
				return {
					...item,
					data: [...vendorList, ...selectInputData],
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
	}

	// searching in the option list for selected input
	async function handleOnKeyDownCb(key, value) {
		try {
			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchPurchaseReceive(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});
			setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] =
					item.key === 'purchase_receive_id' ? item?.value.split('-')[1] : item?.value;
				return acc;
			}
			return acc;
		}, {});

		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axiosInstance.post(
				`${APP_API_URL}/purchase-receive/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);
			const purchaseReceivedFilterData = data?.data?.purchaseReceives.map((pr) => {
				return {
					id: pr.purchase_receive_id,
					DATE: getDate(pr.receive_date),
					PR: JSON.stringify({ pr: pr?.purchase_receive_id, status: pr?.status }),
					'PO#': pr?.purchase_order?.purchase_order_number || pr?.purchase_order_number,
					'VENDOR NAME': pr.vendor.vendor_name,
					STATUS: pr?.status,
					RECEIVED: pr?.status,
					BILLED: JSON.stringify({
						is_billed: pr?.is_billed,
						is_partially_billed: pr?.is_partially_billed,
					}),
				};
			});
			setPurchaseReceive((prev) => ({
				...prev,
				data: purchaseReceivedFilterData,
				totalPages: data?.totalPages,
			}));
			// setTableData(purchaseOrderFilterData);
			// setTotalPages(data?.data?.totalPages);
			setAdvanceFilterParams((prev) => ({
				...prev,
				totalPages: data?.data?.totalPages,
				pageSize: data?.data?.pageSize,
				filters: {
					...prev.filters,
					...params,
				},
			}));

			dispatch(
				updateAdvanceFilter({
					filters: { ...advanceFilterParams.filters, ...params },
					selectedInputs: advanceFilterSelectInputList,
				}),
			);
			setIsAdvanceFilterOpen(false);
			setIsAppliedAdvanceFilter(true);
		} catch (error) {
			toast.error('Something went wrong! Try again.' + error);
		}
	}

	const PurchaseReceivedColoum = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			maxWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			headerComponent: () => {
				return (
					<div
						style={{
							width: '100%',
							paddingTop: '10px',
							cursor: 'pointer',
						}}
						onClick={() => sortDataList({ orderBy: 'receive_date', sortBy: 'asc' })}
					>
						<div
							style={{
								width: '55px',
							}}
						>
							<p
								style={{
									display: 'flex',
									alignItems: 'center',
									color: 'black',
								}}
							>
								DATE
								<span
									style={{
										fontSize: '18px',
										fontWeight: '800',
									}}
								>
									{sortIconMap[sortDirection?.receive_date]}
								</span>
							</p>
						</div>
					</div>
				);
			},
		},
		{
			field: 'PR#',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 180,
			maxWidth: 180,
			flex: 1,
			cellClass: 'left-align',
			headerComponent: () => {
				return (
					<div
						style={{
							width: '100%',
							paddingTop: '10px',
							cursor: 'pointer',
						}}
						onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
					>
						<div
							style={{
								width: '55px',
							}}
						>
							<p
								style={{
									display: 'flex',
									alignItems: 'center',
									color: 'black',
								}}
							>
								PR#
								<span
									style={{
										fontSize: '18px',
										fontWeight: '800',
									}}
								>
									{sortIconMap[sortDirection?.purchase_receive_id]}
								</span>
							</p>
						</div>
					</div>
				);
			},
			cellRenderer: (params) => {
				const PR = JSON.parse(params.data.PR);
				return (
					<p>
						PR-{PR?.pr}
						{PR?.status === 0 && (
							<span
								style={{
									backgroundColor: '#718096',
									color: 'white',
									borderRadius: '20px',
									padding: '2px 10px',
									paddingBottom: '3px',
									marginLeft: '10px',
									fontSize: '11px',
								}}
							>
								Draft
							</span>
						)}
					</p>
				);
			},
		},
		{
			field: 'PO#',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			maxWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			headerComponent: () => {
				return (
					<div
						style={{
							width: '100%',
							paddingTop: '10px',
							cursor: 'pointer',
						}}
						onClick={() => sortDataList({ orderBy: 'purchase_order_number', sortBy: 'asc' })}
					>
						<div
							style={{
								width: '55px',
							}}
						>
							<p
								style={{
									display: 'flex',
									alignItems: 'center',
									color: 'black',
								}}
							>
								PO#
								<span
									style={{
										fontSize: '18px',
										fontWeight: '800',
									}}
								>
									{sortIconMap[sortDirection?.purchase_order_number]}
								</span>
							</p>
						</div>
					</div>
				);
			},
		},
		{
			field: 'VENDOR NAME',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			headerComponent: () => {
				return (
					<div
						style={{
							width: '100%',
							paddingTop: '10px',
							cursor: 'pointer',
						}}
						onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
					>
						<div
							style={{
								width: '55px',
							}}
						>
							<p
								style={{
									display: 'flex',
									alignItems: 'center',
									color: 'black',
								}}
							>
								VENDOR NAME
								<span
									style={{
										fontSize: '18px',
										fontWeight: '800',
									}}
								>
									{sortIconMap[sortDirection?.vendor_name]}
								</span>
							</p>
						</div>
					</div>
				);
			},
		},
		{
			field: 'RECEIVED',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 120,
			maxWidth: 120,
			flex: 1,
			cellClass: 'center-align',
			cellRenderer: (params) => {
				const status = params.data?.RECEIVED || 0;
				if (status === 1) {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							{' '}
							<img src={FullCircle} />
						</div>
					);
				}
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img src={EmptyCircle} />
					</div>
				);
			},
		},
		{
			field: 'BILLED',
			headerClass: 'HeaderBg left-align',
			suppressSizeToFit: true,
			minWidth: 100,
			maxWidth: 100,
			flex: 1,
			cellClass: 'center-align',
			cellRenderer: (params) => {
				const status = JSON.parse(params.data?.BILLED); // Fallback to an empty string if undefined
				if (status.is_billed === 1) {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								src={FullCircle}
								alt=""
								style={{ alignItems: 'center' }}
							/>
						</div>
					);
				}
				if (status.is_partially_billed === 1) {
					return (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
							}}
						>
							<img
								src={HalfCircle}
								alt=""
								style={{ alignItems: 'center' }}
							/>
						</div>
					);
				}
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							src={EmptyCircle}
							alt=""
							style={{ alignItems: 'center' }}
						/>
					</div>
				);
			},
		},
	];

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPurchaseReceiveParams((prev) => ({
			...prev,
			...param,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPurchaseReceiveParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const handlePageChange = (page) => {
		setPurchaseReceiveParams((prev) => ({
			...prev,
			page,
		}));
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-receive-details/${id}`);
		}
	};

	async function handleBulkDelete() {
		try {
			const body = {
				purchase_receive_id: bulkAction,
				status: 'is_deleted',
				value: true,
			};

			const billedPR = bulkAction.filter((id) => {
				const row = purchaseReceive?.data?.find((pr) => pr?.id === id)
				return row && JSON.parse(row.BILLED).is_billed
			})

			if (billedPR.length > 0) {
				const ids = billedPR.map((pr) => {
					return `PR-${pr}`
				})
				throw new Error(`The following PR are billed and cannot be deleted: ${ids?.join(',')}! Delete the associated Purchase Bill before deleting the Purchase Receive.`)
			}
			await updatePurchaseReceiveStatus(body);
			toast.success('Purchase Order Deleted.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction(false);
			setBulkAction([]);
			deselectRows && deselectRows();
			handleFetchPurchaseReceive();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	async function generatePdfToPrintAndDownload() {
		const filterValue = bulkAction.join(',');
		const { purchaseReceive } = await fetchPurchaseReceive({
			filterBy: 'purchase_receive_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseReceive.forEach((pr) => {
			const {
				is_received,
				is_billed,
				vendor,
				purchase_order_number,
				date,
				organisation,
				delivery_address,
				purchase_order_products,
			} = pr?.purchase_order;
			docDefinitions.push({
				content: [
					{
						columns: [
							{
								text: `Received Status: ${is_received ? 'Received' : 'Not Received'}`,
								style: 'status',
								color: is_received ? '#6AA247' : 'red',
							},
							{ text: '|' },
							{
								text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
								style: 'status',
								color: is_billed ? '#6AA247' : 'red',
							},
						],
						margin: [0, 0, 0, 10],
					},
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 5],
					},
					{
						text: [
							vendor?.address,
							'\nRajasthan\nIndia\n',
							{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
						],
						style: 'vendorAddress',
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Purchase Order', style: 'subheader' },
									{ text: `#${purchase_order_number}`, style: 'orderNumber' },
									{ text: `Order Date: ${getDate(date)}`, style: 'orderDate' },
								],
								margin: [0, 20, 0, 10],
							},
						],
					},
					{
						columns: [
							{
								stack: [
									{ text: 'Vendor Address', style: 'label' },
									{ text: organisation?.organisation_name, style: 'detail' },
									{ text: `DL No.: ${vendor?.drug_license_number || 'N/A'}`, style: 'detail' },
									{ text: `GSTIN: ${vendor?.vendor_other_details?.gst || 'N/A'}`, style: 'detail' },
								],
							},
							{
								stack: [
									{ text: 'Deliver To', style: 'label' },
									{ text: delivery_address, style: 'detail' },
								],
							},
						],
						columnGap: 20,
					},
					{
						table: {
							widths: ['5%', '45%', '25%', '25%'],
							body: [
								[
									{ text: '#', bold: true },
									{ text: 'ITEMS AND DESCRIPTION', bold: true },
									{ text: 'PACKAGING', bold: true },
									{ text: 'ORDERED', bold: true },
								],
								...purchase_order_products.map((prod, index) => [
									{ text: index + 1 },
									{ text: prod.product.product_name, color: 'blue' },
									{ text: prod.product_packaging },
									{ text: prod.order_quantity },
								]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 10,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoading((prev) => {
			const obj = {};
			if (isPrint) {
				obj.pdf = {
					button: 'pdf',
					isLoading: true,
				};
			} else {
				obj.download = {
					button: 'download',
					isLoading: true,
				};
			}

			return {
				...prev,
				...obj,
			};
		});
		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}

		setLoading((prev) => ({
			...prev,
			pdf: {
				...prev.pdf,
				isLoading: false,
			},
			download: {
				...prev.download,
				isLoading: false,
			},
		}));
	}

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleFetchPurchaseReceive() {
		try {
			setLoader(true);
			const { purchaseReceive, totalPages } = await fetchPurchaseReceive(purchaseReceiveParams);
			const tableData = purchaseReceive.map((pr) => {
				return {
					id: pr.purchase_receive_id,
					DATE: getDate(pr.receive_date),
					PR: JSON.stringify({ pr: pr?.purchase_receive_id, status: pr?.status }),
					'PO#': pr.purchase_order.purchase_order_number || pr.purchase_order_number,
					'VENDOR NAME': pr.vendor.vendor_name,
					RECEIVED: pr?.status,
					BILLED: JSON.stringify({
						is_billed: pr?.is_billed,
						is_partially_billed: pr?.is_partially_billed,
					}),
				};
			});
			setPurchaseReceive((prev) => ({
				...prev,
				data: tableData,
				totalPages,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false);
		}
	}

	function handleOpenBulkDeletePurchaseReceiveModal() {
		const selectedVendorsList = purchaseReceive?.data?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.NAME);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	const updateURLWithFilters = (filters) => {
		const urlParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				urlParams.append(key, value);
			}
		});
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		window.history.replaceState(null, '', newUrl);
	};
	const handleClearAdvanceFilter = useCallback(() => {
		setIsAppliedAdvanceFilter(false);
		setadvanceFilterSelectInputList([]);
		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {},
		}));
		setPurchaseReceiveParams((prev) => ({
			...prev,
			filterBy: null,
			filterValue: null,
		}));
		dispatch(clearAdvanceFilter());
	}, []);

	useEffect(() => {
		if (advanceFilterSelectInputList.length === 0 && isAppliedAdvanceFilter) {
			handleClearAdvanceFilter();
		}

		if (advanceFilterSelectInputList.length > 0 && isAppliedAdvanceFilter) {
			handleApplyFilters();
		}
	}, [advanceFilterSelectInputList, isAppliedAdvanceFilter]);

	// Update the URL whenever advanced filter parameters change
	useEffect(() => {
		const activeFilters = advanceFilterSelectInputList.reduce((acc, filter) => {
			if (filter?.key && filter?.value) {
				acc[filter.key] = filter.value;
			}
			return acc;
		}, {});

		if (Object.keys(activeFilters).length > 0) {
			updateURLWithFilters(activeFilters);
		}
	}, [advanceFilterSelectInputList]);

	useEffect(() => {
		const filtersFromRedux = advanceFilterReducer?.filters || {};

		if (Object.keys(filtersFromRedux).length === 0) {
			handleFetchPurchaseReceive();
		}
		if (Object.keys(filtersFromRedux).length > 0) {
			setAdvanceFilterParams((prev) => ({
				...prev,
				filters: {
					...prev.filters,
					...filtersFromRedux,
				},
			}));
			setadvanceFilterSelectInputList(advanceFilterReducer?.selectedInputs);
			setIsAppliedAdvanceFilter(true);
		}
	}, [purchaseReceiveParams, advanceFilterReducer?.filters]);

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							loader={loading.pdf.isLoading}
							onClick={() => handlePrintAndDownloadPdf(true)}
						/>
						<Button
							type="button"
							button="Download"
							loader={loading.download.isLoading}
							className="mt-0 px-4 btn-gray"
							onClick={() => handlePrintAndDownloadPdf(false)}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleOpenBulkDeletePurchaseReceiveModal}
						></Button>
						<Button
							disabled={true}
							type="button"
							button="Mark as In Transit"
							className="mt-0 px-4 btn-gray relative"
						></Button>
						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						className="dropdown text-end d-flex"
						style={{ gap: '12px' }}
					>
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: null, filterValue: null },
											'',
											'All Purchase Receive',
										)
									}
								>
									All Purchase Receive
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 0 }, 'is_draft', 'Draft')
									}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'status', filterValue: 1 },
											'is_received',
											'Received',
										)
									}
								>
									Received
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'is_billed', filterValue: 1 }, 'is_billed', 'Billed')
									}
								>
									Billed
								</a>
							</li>
						</ul>
						<div>
							{isAppliedAdvanceFilter ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={handleClearAdvanceFilter}
									/>
									<a
										onClick={handleClearAdvanceFilter}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Receive"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-receive-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a
										className="dropdown-item sortBy"
										style={{ cursor: 'not-allowed' }}
									>
										Sort By
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'receive_date', sortBy: 'asc' })}
									>
										Receive Date
										<span>{sortIconMap[sortDirection?.receive_date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
									>
										Purchase Receive
										<span>{sortIconMap[sortDirection?.purchase_receive_id]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() =>
											sortDataList({ orderBy: 'purchase_order_number', sortBy: 'asc' })
										}
									>
										Purchase Order Number
										<span>{sortIconMap[sortDirection?.purchase_order_number]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'amount', sortBy: 'asc' })}
									>
										Amount
										<span>{sortIconMap[sortDirection?.amount]}</span>
									</a>
								</li> */}
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() =>
											sortDataList({ orderBy: 'expected_delivery_date', sortBy: 'asc' })
										}
									>
										Expected Delivery Date
										<span>{sortIconMap[sortDirection?.expected_delivery_date]}</span>
									</a>
								</li> */}

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Last Modified time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`purchase_receive`)}
									>
										Export Purchase Receive
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{isAppliedAdvanceFilter && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{advanceFilterSelectInputList.map((item) => (
							<AdvanceFilterSelectInput
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key);
								}}
								onKeyDownCb={(value) => {
									handleOnKeyDownCb(item?.key, value);
								}}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value: value.value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
								className="selectInput"
							/>
						))}
						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePurchaseReceiveModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={() => {
						setIsDeleteBulkConfirmationModalOpen(false);
						setBulkAction(false);
						setBulkAction([]);
						deselectRows && deselectRows();
					}}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseReceiveLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<PageLoader />
			) : (
				<div className="productTable-body">
					<Table
						row={purchaseReceive.data}
						coloum={PurchaseReceivedColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={purchaseReceiveParams.page}
				totalPages={purchaseReceive.totalPages}
				onChangePage={handlePageChange}
			/>
			<AdvanceFilter
				modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
				isOpen={isAdvanceFilterOpen}
				onKeyDownCb={handleOnKeyDownCb}
				advanceFilterOptions={advanceFilterOptions.purchase_receive}
				handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
				advanceFilterSelectInputList={advanceFilterSelectInputList}
				handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
				onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
				handleOnChangeSelectedFilterInput={(key, value) => {
					const updatedData = advanceFilterSelectInputList.map((item) => {
						if (item?.key === key) {
							return {
								...item,
								value,
							};
						}

						return item;
					});
					setadvanceFilterSelectInputList(updatedData);
				}}
				onClose={() => {
					setIsAdvanceFilterOpen(false);
				}}
				onApply={handleApplyFilters}
			/>
		</div>
	);
};

export default PurchaseReceived;
