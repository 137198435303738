import React, { useState } from 'react';
import './venUploadDocument.scss';
import ViewDocs from './ViewDocs';
import { FaFilePdf, FaFileAlt } from 'react-icons/fa';
import tick from '../../../assets/image/tick.svg';
import deletei from '../../../assets/image/delete.svg';
import eye from '../../../assets/image/eye.svg';
import { APP_API_URL } from '../../../utils/xhr-instance';
import { toast } from 'react-toastify';
import { deleteVendorDocument } from '../../../services/vendor';
import ConfirmationModal from '../../../component/modal/ConfirmationModal';
import axiosInstance from '../../../services/axios';

const UploadDoc = ({
	setVendorDocumentId,
	vendorDocumentId,
	setFassaiDoc,
	setGstDoc,
	setDrugLicanceDocs,
	setAdharCardDocs,
	setPanDocs,
	fssaiDoc,
	gstDoc,
	drugLicanceDocs,
	adharDocs,
	panDocs,
	vendorId, // Assuming you have vendorId passed as prop
}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [currentDocType, setCurrentDocType] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);
	const [dragging, setDragging] = useState(false);
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [isDeleteDocModalOpen, setIsDeleteDocModalOpen] = useState(false);
	const [deleteDocDetails, setDeleteDocDetails] = useState(null);

	const acceptedFileTypes = ['pdf', 'png', 'jpeg', 'jpg'];

	const openPopup = (docType) => {
		setCurrentDocType(docType);
		setSelectedFile(null);
		setIsPopupOpen(true);
	};

	const closePopup = () => setIsPopupOpen(false);

	const handleDragOver = (e) => {
		e.preventDefault();
		setDragging(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setDragging(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setDragging(false);
		const file = e.dataTransfer.files[0];
		if (file) {
			const extension = file.name.split('.').pop().toLowerCase();
			if (acceptedFileTypes.includes(extension)) {
				setSelectedFile(file);
			} else {
				alert('This file type is not accepted. Please upload a PDF, PNG, JPEG, or JPG file.');
			}
		}
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const extension = file.name.split('.').pop().toLowerCase();
			if (acceptedFileTypes.includes(extension)) {
				setSelectedFile(file);
			} else {
				toast.error('This file type is not accepted. Please upload a PDF, PNG, JPEG, or JPG file.');
			}
		}
	};

	const handleSetNullFile = (docName) => {
		if (docName === 'gst_certificate_doc') {
			setGstDoc(null);
		}

		if (docName === 'drug_license_doc') {
			setDrugLicanceDocs(null);
		}

		if (docName === 'pan_card_doc') {
			setPanDocs(null);
		}

		if (docName === 'aadhar_card_doc') {
			setAdharCardDocs(null);
		}

		if (docName === 'fssai_license_doc') {
			setFassaiDoc(null);
		}

		setSelectedFile(null);
		setCurrentDocType('');
	};

	const handleUpload = async (setDocs, docName) => {
		try {

			if (vendorId === undefined && selectedFile) {
				if (docName === 'gst_certificate_doc') {
					setGstDoc(selectedFile);
				}

				if (docName === 'drug_license_doc') {
					setDrugLicanceDocs(selectedFile);
				}

				if (docName === 'pan_card_doc') {
					setPanDocs(selectedFile);
				}

				if (docName === 'aadhar_card_doc') {
					setAdharCardDocs(selectedFile);
				}

				if (docName === 'fssai_license_doc') {
					setFassaiDoc(selectedFile);
				}
				closePopup();
				return;
			}

			if (selectedFile) {
				setIsUploadingFile(true);
				const token = localStorage.getItem('authToken');
				const formData = new FormData();
				const fullDocNameWithVendor = docName + "_" + vendorId
				formData.append(docName, selectedFile, fullDocNameWithVendor + "." + selectedFile?.type?.split("/")[1]);
				formData.append('vendor_id', vendorId);
				formData.append('vendor_document_id', vendorDocumentId);

				try {
					const { data } = await axiosInstance.post(`${APP_API_URL}/vendor/create-document`, formData, {
						headers: {
							'Content-Type': 'multipart/form-data',
							Authorization: `Bearer ${token}`,
						},
					});

					const docKey = Object.keys(data?.data)[0];
					if (docKey === 'gst_certificate') {
						setGstDoc(data?.data[docKey]);
					}

					if (docKey === 'drug_license') {
						setDrugLicanceDocs(data?.data[docKey]);
					}

					if (docKey === 'pan_card') {
						setPanDocs(data?.data[docKey]);
					}

					if (docKey === 'aadhar_card') {
						setAdharCardDocs(data?.data[docKey]);
					}

					if (docKey === 'fssai_license') {
						setFassaiDoc(data?.data[docKey]);
					}

					setVendorDocumentId(data?.data?.vendor_document_id);

					toast.success('Document upload successfully!');
					closePopup();
				} catch (error) {
					throw new Error('Fail to upload document!');
				}
			} else {
				throw new Error('Please select a document to upload!');
			}
		} catch (error) {
			toast.error(error.message);
		} finally {
			setIsUploadingFile(false);
		}
	};

	const handleDeleteFile = async () => {
		try {
			await deleteVendorDocument(vendorDocumentId, deleteDocDetails);
			handleSetNullFile(deleteDocDetails);
			toast.success('Document deleted successfully.');
		} catch (error) {
			toast.error('Document Delete failed! Try again.');
		} finally {
			setDeleteDocDetails(null);
			setIsDeleteDocModalOpen(false);
		}
	};

	const renderUploadSection = (docType, docData, setDocs) => {
		const docName = docType.split('_').join(' ').toUpperCase();
		return (
			<div className="accordion-item">
				<h2
					className="accordion-header"
					id={`heading${docType}`}
				>
					<button
						className="accordion-button collapsed"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target={`#collapse${docType}`}
						aria-expanded="false"
						aria-controls={`collapse${docType}`}
					>
						<span>{docName} </span>
						<span>
							{docData && (
								<img
									src={tick}
									alt="Uploaded"
								/>
							)}
						</span>
					</button>
				</h2>
				<div
					id={`collapse${docType}`}
					className="accordion-collapse collapse"
					aria-labelledby={`heading${docType}`}
				>
					{docData && (
						<table>
							<thead>
								<tr>
									<th>DOCUMENT TYPE</th>
									<th>DOCUMENT NAME</th>
									<th>STATUS</th>
									<th>ACTIONS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>{docName}</td>
									<td>
										<ViewDocs
											docName={docType}
											docData={docData}
										/>
									</td>
									<td>
										<span className="status-icon">
											<img
												src={tick}
												alt="Uploaded"
											/>
										</span>
									</td>
									<td>
										<div
											style={{
												display: 'flex',
												gap: 20,
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<div className="view-icon">
												<a
													href={docData instanceof File ? URL.createObjectURL(docData) : docData}
													target="_blank"
													rel="noreferrer"
												>
													<img
														src={eye}
														alt="View"
														style={{ cursor: 'pointer' }}
													/>
												</a>
											</div>
											<div
												className="delete-icon"
												style={{ cursor: 'pointer' }}
												onClick={() => {
													if (vendorId) {
														setDeleteDocDetails(docType);
														setIsDeleteDocModalOpen(true);
													} else {
														handleSetNullFile(docType);
													}
												}}
											>
												<img
													src={deletei}
													alt="Delete"
												/>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					)}
					<div className="accordion-body">
						<div className={docData ? 'document-areaForColor' : 'document-area'}>
							<button
								onClick={() => openPopup(docType)}
								className="DocUploadBtn"
							>
								{docData ? 'Re-upload Document' : 'Upload Document'}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	};

	const getFileIcon = (fileName) => {
		const extension = fileName.split('.').pop().toLowerCase();
		const iconStyle = {
			width: '70px',
			height: '70px',
			border: '1px dashed rgba(85, 91, 103, 0.30)',
			borderRadius: '10px',
			backgroundColor: '#FCFCFC',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		};

		return (
			<div style={iconStyle}>
				{extension === 'pdf' ? <FaFilePdf size={40} /> : <FaFileAlt size={40} />}
			</div>
		);
	};

	return (
		<div className="vendor-upload-document">
			<div
				className="accordion"
				id="accordionExample"
			>
				{renderUploadSection('gst_certificate_doc', gstDoc, setGstDoc)}
				{renderUploadSection('drug_license_doc', drugLicanceDocs, setDrugLicanceDocs)}
				{renderUploadSection('pan_card_doc', panDocs, setPanDocs)}
				{renderUploadSection('aadhar_card_doc', adharDocs, setAdharCardDocs)}
				{renderUploadSection('fssai_license_doc', fssaiDoc, setFassaiDoc)}
			</div>

			{isPopupOpen && (
				<div className={`popup ${dragging ? 'dragging' : ''}`}>
					<div
						className="popup-content"
						onDragOver={handleDragOver}
						onDragLeave={handleDragLeave}
						onDrop={handleDrop}
					>
						<h2 className="PopHeading">Upload {currentDocType.replace('_', ' ').toUpperCase()}</h2>
						<br />
						{!selectedFile ? (
							<div className={`DropfileDesign ${dragging ? 'drag-over' : ''}`}>
								<div className="Browse">
									<label
										htmlFor="file-upload"
										className="custom-file-upload"
									>
										Browse..
									</label>
									<input
										id="file-upload"
										type="file"
										onChange={handleFileChange}
										style={{ display: 'none' }}
									/>
								</div>
								<div>or drop file here</div>
							</div>
						) : (
							<div className="selected-file-area">
								<div className="selected-file-display d-flex justify-content-center align-items-center">
									<div
										style={{ width: '250px' }}
										className="d-flex align-items-center"
									>
										{getFileIcon(selectedFile.name)}
										<div
											className="d-flex align-items-center"
											style={{ paddingLeft: '10px' }}
										>
											<p style={{ marginRight: '30px' }}>
												{selectedFile.name} <br />
												<span style={{ fontSize: '12px', color: 'gray' }}>
													{(selectedFile.size / 1024).toFixed(2)} kb
												</span>
											</p>
										</div>
									</div>
									<div>
										<img
											src={deletei}
											alt="Delete"
											className="delete-file-icon"
											onClick={handleSetNullFile}
											style={{ cursor: 'pointer', marginLeft: '10px' }}
										/>
									</div>
								</div>
							</div>
						)}
						<br />
						<div className="button-group">
							<button
								className="CancelBtn"
								onClick={closePopup}
							>
								Cancel
							</button>
							<button
								className="UploadBtn"
								onClick={() =>
									handleUpload(
										currentDocType === 'gst_certificate_doc'
											? setGstDoc
											: currentDocType === 'drug_license_doc'
												? setDrugLicanceDocs
												: currentDocType === 'pan_card_doc'
													? setPanDocs
													: currentDocType === 'aadhar_card_doc'
														? setAdharCardDocs
														: setFassaiDoc,
										currentDocType,
									)
								}
								disabled={!selectedFile || isUploadingFile}
							>
								{isUploadingFile ? 'Uploading...' : 'Upload'}
							</button>
						</div>
					</div>
				</div>
			)}

			{isDeleteDocModalOpen && (
				<ConfirmationModal
					isOpen={isDeleteDocModalOpen}
					title="Delete Document"
					content={
						<p
							style={{
								textAlign: 'center',
								backgroundColor: '#E6F5FF',
								padding: '10px',
								borderRadius: '5px',
								fontWeight: '500',
								color: 'black',
							}}
						>
							Are you sure You want to delete {deleteDocDetails?.split('_').join(' ').toUpperCase()}{' '}
							file ?
						</p>
					}
					onCancel={() => {
						setIsDeleteDocModalOpen(false);
						setDeleteDocDetails(null);
					}}
					onOk={handleDeleteFile}
				/>
			)}
		</div>
	);
};

export default UploadDoc;
