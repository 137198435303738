import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import addFilter from '../../assets/image/AddFilter.svg';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import './CreatePurchaseBill.scss';
import DeletePurchaseBillModel from './components/DeletePurchaseBillModel';
import { deletePurchaseBill, fetchPurchaseBill } from '../../services/purchase_bill';
import { getDate } from '../../utils/dateUtils';
import pdfMake from 'pdfmake/build/pdfmake';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import { clearAdvanceFilter, updateAdvanceFilter } from '../../store/action/advanceFilter';
import axiosInstance from '../../services/axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';

const PurchaseBills = () => {
	const { advanceFilterReducer } = useSelector((state) => state);
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const dispatch = useDispatch();
	const [purchaseBill, setPurchaseBills] = useState({
		currentPage: 1,
		totalPages: 0,
		data: [],
	});
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Purchase Bills');
	const [sortDirection, setSortDirection] = useState({ purchase_bill_id: 'asc' });
	const [stateCondition, setStateCondition] = useState('');
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [purchaseBillParams, setPurchaseBillParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_bill_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [deselectRows, setDeselectRows] = useState(null);
	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};
	const [loading, setLoading] = useState({
		pdf: {
			button: 'pdf',
			isLoading: false,
		},
		download: {
			button: 'download',
			isLoading: false,
		},
	});

	const [isAppliedAdvanceFilter, setIsAppliedAdvanceFilter] = useState(false);
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		totalPages: 0,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);

	async function handleAdvanceFilterDropdown(option) {
		if (!option) return;

		const { key, value } = option;
		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === key);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: key,
				label: value,
				data: [],
			},
		]);
	}

	// removing the selected inputs from both modal and tables
	function handleRemoveSelectedFilterInput(key) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
	}

	async function handleOnFocusSelectedFilterInput(key) {
		let vendorList = [];
		const data = await fetchPurchaseBill({ filterBy: key, filterValue: '' });
		const selectInputData = data?.purchaseBills?.reduce((acc, purchaseOrder) => {
			if (purchaseOrder[key] || key === 'vendor_name') {
				if (key === 'vendor_name') {
					acc.push({
						key: purchaseOrder.vendor?.vendor_name,
						value: purchaseOrder.vendor?.vendor_name,
					});
					return acc;
				} else {
					acc.push({
						key: purchaseOrder[key],
						value: purchaseOrder[key],
					});
					return acc;
				}
			}
			return acc;
		}, []);

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === key) {
				return {
					...item,
					data: [...vendorList, ...selectInputData],
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
	}

	// searching in the option list for selected input
	async function handleOnKeyDownCb(key, value) {
		try {
			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchPurchaseBill(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});
			setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] = item?.value;
				return acc;
			}
			return acc;
		}, {});

		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axiosInstance.post(
				`${APP_API_URL}/purchase-bill/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);
			// purchaseBills
			// 	? purchaseBills.map((pr) => {
			// 			return {
			// 				id: pr.purchase_bill_id,
			// 				DATE: getDate(pr.bill_date),
			// 				PB: JSON.stringify({
			// 					pb_number: pr?.bill_number,
			// 					status: pr?.status,
			// 				}),
			// 				'VENDOR NAME': pr.vendor.vendor_name,
			// 				status: pr?.status,
			// 				AMOUNT: pr?.bill_amount,
			// 			};
			// 		})
			// 	: [];
			console.log(data);
			const purchaseBillFilterData = data?.data?.purchaseBills
				? data?.data?.purchaseBills.map((pr) => {
						return {
							id: pr.purchase_bill_id,
							DATE: getDate(pr.bill_date),
							PB: JSON.stringify({
								pb_number: pr?.bill_number,
								status: pr?.status,
							}),
							'VENDOR NAME': pr.vendor.vendor_name,
							status: pr?.status,
							AMOUNT: pr?.bill_amount,
						};
					})
				: [];
			setPurchaseBills((prev) => ({
				...prev,
				data: purchaseBillFilterData,
				totalPages: data?.totalPages,
			}));
			// setTableData(purchaseOrderFilterData);
			// setTotalPages(data?.data?.totalPages);
			setAdvanceFilterParams((prev) => ({
				...prev,
				totalPages: data?.data?.totalPages,
				pageSize: data?.data?.pageSize,
				filters: {
					...prev.filters,
					...params,
				},
			}));

			dispatch(
				updateAdvanceFilter({
					filters: { ...advanceFilterParams.filters, ...params },
					selectedInputs: advanceFilterSelectInputList,
				}),
			);
			setIsAdvanceFilterOpen(false);
			setIsAppliedAdvanceFilter(true);
		} catch (error) {
			toast.error('Something went wrong! Try again.' + error);
		}
	}

	const navigateRouter = (path) => {
		navigate(path);
	};
	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/purchases-bills-details/${id}`);
		}
	};

	const PurchaseBillColoum = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'PURCHASE BILL#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const PB = JSON.parse(params.data['PB']);
				return (
					<p style={{ alignItems: 'center' }}>
						{PB?.pb_number}
						{PB?.status === 0 && (
							<span
								style={{
									backgroundColor: '#718096',
									color: 'white',
									borderRadius: '20px',
									padding: '2px 10px',
									paddingBottom: '3px',
									marginLeft: '10px',
									fontSize: '11px',
								}}
							>
								Draft
							</span>
						)}
					</p>
				);
			},
		},

		{
			field: 'VENDOR NAME',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'left-align',
			cellRenderer: (params) => {
				const status = params.data?.status || 0; // Fallback to an empty string if undefined
				// console.log(status);
				if (status) {
					return (
						<p>
							<span
								style={{
									color:
										status === 0
											? '#000'
											: status === 1
												? '#FFC008'
												: status === 2
													? '#FFA500'
													: '#6AA247',
								}}
							>
								{status === 0
									? 'N/A'
									: status === 1
										? 'Open'
										: status === 2
											? 'Partially Paid'
											: 'Closed'}
							</span>
						</p>
					);
				} else {
					return (
						<p>
							<span
								style={{
									color: '#000',
								}}
							>
								N / A
							</span>
						</p>
					);
				}
			},
		},
		{
			field: 'AMOUNT',
			headerClass: 'HeaderBg right-align',
			suppressSizeToFit: true,
			minWidth: 150,
			flex: 1,
			cellClass: 'right-align',
		},
	];

	async function handleFetchPurchaseBill() {
		try {
			setLoader(true);
			const { purchaseBills, totalPages } = await fetchPurchaseBill(purchaseBillParams);
			const data = purchaseBills
				? purchaseBills.map((pr) => {
						return {
							id: pr.purchase_bill_id,
							DATE: getDate(pr.bill_date),
							PB: JSON.stringify({
								pb_number: pr?.bill_number,
								status: pr?.status,
							}),
							'VENDOR NAME': pr.vendor.vendor_name,
							status: pr?.status,
							AMOUNT: pr?.bill_amount,
						};
					})
				: [];
			setPurchaseBills((prev) => ({
				...prev,
				data: data,
				totalPages,
			}));
			setTableData(data);
		} catch (error) {
			toast.error('Something went wrong!');
		} finally {
			setLoader(false);
		}
	}

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPurchaseBillParams((prev) => ({
			...prev,
			...param,
			page: 1,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPurchaseBillParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const handlePageChange = (page) => {
		setPurchaseBillParams((prev) => ({
			...prev,
			page: page,
		}));
	};

	useEffect(() => {
		handleFetchPurchaseBill();
	}, [purchaseBillParams]);

	async function generatePdfToPrintAndDownload() {
		const filterValue = bulkAction.join(',');
		const { purchaseBills } = await fetchPurchaseBill({
			filterBy: 'purchase_bill_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseBills.forEach((pr) => {
			const {
				vendor,
				bill_number,
				bill_date,
				due_date,
				terms,
				due_amount,
				payment_method,
				purchase_bill_products,
			} = pr;
			docDefinitions.push({
				content: [
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 0],
					},
					{
						columns: [
							{
								stack: [
									{
										text: [
											'\nRajasthan\nIndia\n',
											{ text: `${vendor?.phone_mobile} | ${vendor?.email}`, bold: true },
										],
										style: 'vendorAddress',
									},
								],
							},
							{
								stack: [
									{ text: 'BILL', style: 'header', bold: true },
									{ text: `#${bill_number}`, style: 'billNumber' },
								],
								margin: [0, 20, 0, 10],
							},
						],
						columnGap: 100,
					},
					{
						columns: [
							{
								stack: [
									{ text: `Bill Date. : ${getDate(bill_date) || 'N/A'}`, style: 'detail' },
									{
										text: `Due Date : ${due_date != null ? getDate(due_date) : 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Terms : ${terms || 'N/A'}`,
										style: 'detail',
									},
								],
							},
							{
								stack: [
									{ text: 'Balance Due', style: 'label' },
									{ text: `Rs ${due_amount ?? 0.0}`, bold: true },
								],
							},
						],
						columnGap: 100,
					},
					{
						stack: [
							{ text: 'Bill To', style: 'label' },
							{ text: `${vendor?.vendor_display_name}`, style: 'linkStyle' },
						],
						margin: [0, 30, 10, 10],
					},
					{
						table: {
							widths: ['5%', '33%', '27%', '10%', '10%', '15%'],
							body: [
								[
									{ text: '#', bold: true },
									{ text: 'ITEMS AND DESCRIPTION', bold: true },
									{ text: 'CUSTOMER DETAILS', bold: true },
									{ text: 'QNTY', bold: true },
									{ text: 'RATE', bold: true },
									{ text: 'AMOUNT', bold: true },
								],
								...purchase_bill_products.map((prod, index) => [
									{ text: index + 1 },
									{ text: prod.product?.product_name, color: 'blue' },
									{ text: `${prod?.batch_no} \n MFG Date:${getDate(prod.mfg_date)}` },
									{ text: prod.quantity },
									{ text: prod.purchase_rate },
									{ text: prod.amount },
								]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						columns: [
							{
								stack: [
									{
										text: `Sub Total :  Rs ${
											purchase_bill_products?.reduce(
												(total, bill) => Number(total) + Number(bill.amount),
												0,
											) || 'N/A'
										}`,
										style: 'detail',
									},
									{
										text: ` Total: Rs ${
											purchase_bill_products?.reduce(
												(total, bill) => Number(total) + Number(bill.amount),
												0,
											) || 'N/A'
										}`,
										style: 'detail',
									},
									{
										text: `Payment Made : ${payment_method || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Balance Due :  Rs ${due_amount ?? 0.0}`,
										style: 'detail',
									},
								],
								alignment: 'right',
							},
						],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					linkStyle: {
						fontSize: 12,
						color: '#3B58FF',
					},
					header: {
						fontSize: 15,
						margin: [0, 10, 10, 0],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 15,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoading((prev) => {
			const obj = {};
			if (isPrint) {
				obj.pdf = {
					button: 'pdf',
					isLoading: true,
				};
			} else {
				obj.download = {
					button: 'download',
					isLoading: true,
				};
			}

			return {
				...prev,
				...obj,
			};
		});

		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}

		setLoading((prev) => ({
			...prev,
			pdf: {
				...prev.pdf,
				isLoading: false,
			},
			download: {
				...prev.download,
				isLoading: false,
			},
		}));
	}

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleBulkDelete() {
		try {
			await deletePurchaseBill(bulkAction);
			toast.success('Purchase Bill Deleted Successfully.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction([]);
			handleFetchPurchaseBill();
			deselectRows && deselectRows();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	function handleOpenBulkDeletePurchaseBillModal() {
		const selectedVendorsList = tableData?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				console.log(item);
				acc.push(JSON.parse(item.PB).pb_number);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	const updateURLWithFilters = (filters) => {
		const urlParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				urlParams.append(key, value);
			}
		});
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		window.history.replaceState(null, '', newUrl);
	};
	const handleClearAdvanceFilter = useCallback(() => {
		setIsAppliedAdvanceFilter(false);
		setadvanceFilterSelectInputList([]);
		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {},
		}));
		setPurchaseBillParams((prev) => ({
			...prev,
			filterBy: null,
			filterValue: null,
		}));
		dispatch(clearAdvanceFilter());
	}, []);

	useEffect(() => {
		if (advanceFilterSelectInputList.length === 0 && isAppliedAdvanceFilter) {
			handleClearAdvanceFilter();
		}

		if (advanceFilterSelectInputList.length > 0 && isAppliedAdvanceFilter) {
			handleApplyFilters();
		}
	}, [advanceFilterSelectInputList, isAppliedAdvanceFilter]);

	// Update the URL whenever advanced filter parameters change
	useEffect(() => {
		const activeFilters = advanceFilterSelectInputList.reduce((acc, filter) => {
			if (filter?.key && filter?.value) {
				acc[filter.key] = filter.value;
			}
			return acc;
		}, {});

		if (Object.keys(activeFilters).length > 0) {
			updateURLWithFilters(activeFilters);
		}
	}, [advanceFilterSelectInputList]);

	useEffect(() => {
		const filtersFromRedux = advanceFilterReducer?.filters || {};

		if (Object.keys(filtersFromRedux).length === 0) {
			handleFetchPurchaseBill();
		}
		if (Object.keys(filtersFromRedux).length > 0) {
			setAdvanceFilterParams((prev) => ({
				...prev,
				filters: {
					...prev.filters,
					...filtersFromRedux,
				},
			}));
			setadvanceFilterSelectInputList(advanceFilterReducer?.selectedInputs);
			setIsAppliedAdvanceFilter(true);
		}
	}, [purchaseBillParams, advanceFilterReducer?.filters]);

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							loader={loading.pdf.isLoading}
							onClick={() => handlePrintAndDownloadPdf(true)}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray"
							onClick={handleOpenBulkDeletePurchaseBillModal}
						/>

						<Button
							type="button"
							button="Record Bulk Payment"
							className="mt-0 px-4 ButtonColor"
							// onClick={() => window.print()}
						/>
						<Button
							type="button"
							button="Link to Purchase Order"
							className="mt-0 px-4 ButtonColor"
							// onClick={() => window.print()}
						/>
						<Button
							type="button"
							button=""
							className="mt-0 px-4 btn-danger"
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						className="dropdown text-end d-flex"
						style={{ gap: '12px' }}
					>
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: null, filterValue: null },
											'active',
											'All Purchase Bill',
										)
									}
								>
									All Purchase Bills
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 0 }, 'active', 'Draft')
									}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 1 }, 'active', 'Open')
									}
								>
									Open
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'status', filterValue: 2 },
											'active',
											'Partially Paid',
										)
									}
								>
									Partially Paid
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'status', filterValue: 3 },
											'active',
											'Closed / Paid',
										)
									}
								>
									Paid
								</a>
							</li>
						</ul>
						<div>
							{isAppliedAdvanceFilter ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={handleClearAdvanceFilter}
									/>
									<a
										onClick={handleClearAdvanceFilter}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Purchase Bill"
							add
							onClick={() => navigateRouter(`/user/${suburl}/purchases-bills-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Last Modified time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'bill_date', sortBy: 'asc' })}
									>
										Bill Date
										<span>{sortIconMap[sortDirection?.bill_date]}</span>
									</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_bill_id', sortBy: 'asc' })}
									>
										Bill #<span>{sortIconMap[sortDirection?.purchase_bill_id]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'due_date', sortBy: 'asc' })}
									>
										Due Date
										<span>{sortIconMap[sortDirection?.due_date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'bill_amount', sortBy: 'asc' })}
									>
										Bill Amount
										<span>{sortIconMap[sortDirection?.bill_amount]}</span>
									</a>
								</li>
								{/* <li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'balance_due', sortBy: 'asc' })}
									>
										Balance Due
										<span>{sortIconMap[sortDirection?.balance_due]}</span>
									</a>
								</li> */}
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`purchase_bill`)}
									>
										Export Purchase Bill
									</a>
								</li>
								{/* <li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'purchase_bill_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			)}
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{isAppliedAdvanceFilter && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{advanceFilterSelectInputList.map((item) => (
							<AdvanceFilterSelectInput
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key);
								}}
								onKeyDownCb={(value) => {
									handleOnKeyDownCb(item?.key, value);
								}}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value: value.value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
								className="selectInput"
							/>
						))}
						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePurchaseBillModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseBillLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={purchaseBill.data}
						coloum={PurchaseBillColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={purchaseBill.currentPage}
				totalPages={purchaseBill.totalPages}
				onChangePage={handlePageChange}
			/>

			<AdvanceFilter
				modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
				isOpen={isAdvanceFilterOpen}
				onKeyDownCb={handleOnKeyDownCb}
				advanceFilterOptions={advanceFilterOptions.purchase_order}
				handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
				advanceFilterSelectInputList={advanceFilterSelectInputList}
				handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
				onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
				handleOnChangeSelectedFilterInput={(key, value) => {
					const updatedData = advanceFilterSelectInputList.map((item) => {
						if (item?.key === key) {
							return {
								...item,
								value,
							};
						}

						return item;
					});
					setadvanceFilterSelectInputList(updatedData);
				}}
				onClose={() => {
					setIsAdvanceFilterOpen(false);
				}}
				onApply={handleApplyFilters}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
		</div>
	);
};

export default PurchaseBills;
