import axiosInstance from '../../../services/axios';
import { APP_API_URL } from '../../../utils/xhr-instance';

export const MASTER_DATA_DONE = 'MASTER_DATA_DONE';
export const MASTER_DATA_NOT_DONE = 'MASTER_DATA_NOT_DONE';
export const MASTER_DATA_API_URL = `${APP_API_URL}/app/master`;

export const master_data = () => {
	let API_URL = MASTER_DATA_API_URL;
	return (dispatch) => {
		axiosInstance
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: MASTER_DATA_DONE,
						payload: res.data,
					});
					return res.data;
				} else {
					dispatch({
						type: MASTER_DATA_NOT_DONE,
						payload: res.data,
					});
					return res.data;
				}
			})
			.catch((err) => {
				// console.error('API Error:', err);
				return err;
			});
	};
};
