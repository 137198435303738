import React from 'react';
import Button from '../../../component/ui/Button';

function Header({ headerTitle, buttons, dropDownButtons, closeButton }) {
	return (
		<div style={{
			position: "sticky",
			top: 0,
			zIndex: 100
		}}>
			<div className="product-pg-header add-product">
				<p>{headerTitle}</p>
				<div className="add-product-button">
					{buttons?.map((button, index) => (
						<Button
							key={button?.id || index} // Ensure unique key
							type="button"
							button={button?.button}
							className={`${button?.className} mt-0 px-4`}
							onClick={button?.handler}
							disabled={button.disabled || false}
						/>
					))}

					{dropDownButtons?.map((dropdown, index) => (
						<div
							key={`dropdown-${index}`}
							className="dropdown-section"
							style={{
								position: 'relative',
							}}
						>
							<Button
								type="button"
								// className="btn-gray mt-0 px-4"
								className="btn btn-gray mt-0 px-4 dropdown-toggle"
								button="Update PO Status"
								onClick={() => dropdown.setShow(!dropdown.show)}
							/>
							{dropdown.show && (
								<div
									className="dropdown-menu show font-14"
									style={{
										width: '160px',
										position: 'absolute',
										zIndex: 1000,
										maxHeight: '300px',
										overflowY: 'auto',
										marginTop: '10px',
										// marginLeft: '50px',
									}}
								>
									{dropdown.items?.map((item, itemIndex) => (
										<a
											key={`dropdown-item-${itemIndex}`}
											className="dropdown-item"
											onClick={() => item.handler()}
											style={{ cursor: 'pointer' }}
										>
											{item.title}
										</a>
									))}
								</div>
							)}
						</div>
					))}
					{closeButton?.showCloseButton && (
						<Button
							type="button"
							close
							button=""
							className="mt-0 btn-danger add-pro"
							onClick={closeButton?.handler}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Header;
