import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';
import axiosInstance from '../../../services/axios';

export const GROUP_CREATE_DONE = 'GROUP_CREATE_DONE';
export const GROUP_CREATE_FAIL = 'GROUP_CREATE_DONE';

export const GROUP_UPDATE_DONE = 'GROUP_UPDATE_DONE';
export const GROUP_UPDATE_FAIL = 'GROUP_UPDATE_UPDAT';

export const GROUP_LIST_GET_DONE = 'GROUP_LIST_GET_DONE';
export const GROUP_LIST_GET_FAIL = 'GROUP_LIST_GET_DONE';

export const CREATE_GROUP_API_URL = `${APP_API_URL}/product-group/create`;
export const UPDATE_GROUP_API_URL = `${APP_API_URL}/product-group/update`;
export const LIST_GROUP_API_URL = `${APP_API_URL}/product-group/list`;

export const group_create = (formBody) => {
	let API_URL = CREATE_GROUP_API_URL;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: GROUP_CREATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: GROUP_CREATE_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const product_group_list = (param) => {
	let API_URL = `${LIST_GROUP_API_URL}?${param}`;
	const token = getLocalStorageData();
	return (dispatch) => {
		return axiosInstance
			.get(API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: GROUP_LIST_GET_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: GROUP_LIST_GET_FAIL,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const group_update = (id, formBody) => {
	let API_URL = UPDATE_GROUP_API_URL;
	const token = getLocalStorageData();
	if (id) {
		API_URL = `${API_URL}/${id}`;
	}
	return (dispatch) => {
		return axiosInstance
			.put(API_URL, formBody, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: GROUP_UPDATE_DONE,
						payload: res,
					});
					return res?.data;
				} else {
					dispatch({
						type: GROUP_UPDATE_DONE,
						payload: res,
					});
					return res;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
