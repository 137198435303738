import React, { useEffect, useState } from 'react';
import './ContactPersons.scss';
import CombinedShape from '../../../assets/image/Combined-Shape.svg';
import { getDate } from '../../../utils/dateUtils';
import { Link } from 'react-router-dom';

const Transactions = ({ setParams, productDetails, vendor }) => {
	const suburl = localStorage.getItem('suburl');
	const [isVendorSelected, setIsVendorSelected] = useState(true);
	const [activeDropdown, setActiveDropdown] = useState({
		key: 'purchase_order',
		value: 'Purchase Orders',
	});
	const [activeFilter, setActiveFilter] = useState('All');
	const [isOpen, setIsOpen] = useState(false);
	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const dropdown = [
		{
			id: 7,
			key: "purchase_order",
			value: "Purchase Orders"
		},
		{
			id: 1,
			key: "purchase_bill",
			value: "Purchase Bills"
		},
		{
			id: 2,
			key: "credit_note",
			value: "Credit Note"
		},
		{
			id: 3,
			key: "sales_order",
			value: "Sales Orders"
		},
		{
			id: 8,
			key: "invoice",
			value: "Invoices"
		},
		{
			id: 9,
			key: "delivery_challan",
			value: "Delivery Challan"
		},
		{
			id: 10,
			key: "vendor_credit",
			value: "Vendor Credits"
		},
	]

	const statusFilter = {
		'purchase_order': [
			{ id: 1, key: 'all', value: "All" },
			{ id: 2, key: "is_draft", value: "Draft" },
			{ id: 3, key: "is_pending_approval", value: "Pending Approval" },
			{ id: 4, key: "is_approved", value: "Approved" },
			{ id: 5, key: 'is_in_transit', value: "Is In Transit" },
			{ id: 6, key: "is_cancelled", value: "Cancelled" },
			{ id: 7, key: "is_partially_received", value: "Partially Received" },
			{ id: 8, key: "is_received", value: "Received" },
			{ id: 9, key: "is_billed", value: "Billed" },
			{
				id: 10, key: "is_partially_billed", value: "Partially Billed"
			}],
		'purchase_bill': [
			{ id: 4, key: 'all', value: "All" },
			{ key: 'is_draft', value: "Draft", id: 0 },
			{ key: "is_open", value: "Open", id: 1 },
			{ key: "is_partially_received", value: "Partially Billed", id: 2 },
			{ key: "is_closed", value: "Paid/Close", id: 3 }
		]
	}

	function handleDropdownChange(key, value) {
		if (key === activeDropdown?.key) return;

		setActiveDropdown({
			key,
			value,
		});
		setActiveFilter('All');
		setIsVendorSelected(true);
		setParams((prev) => ({
			...prev,
			from: key,
			sortBy: null,
			orderBy: null,
			filterBy: "vendor_id",
			filterValue: Number(vendor?.vendor_id),
		}));
	}

	function handleStatusFilter(id, key, value) {
		setActiveFilter(value);
		if (value === 'All') {
			setParams((prev) => ({
				...prev,
				from: key,
				sortBy: null,
				orderBy: null,
				filterBy: null,
				filterValue: null,
			}));
			return;
		}
		setParams((prev) => ({
			...prev,
			filterBy: activeDropdown?.key === 'purchase_bill' ? 'status' : key,
			filterValue: activeDropdown?.key === 'purchase_bill' ? id : 1,
		}));
	}



	const getTransactionStatus = (type, status) => {

		if (type === "purchase_order") {

			return status
		}


		if (type === "purchase_bill") {
			switch (status) {
				case 0:
					return "Draft";
				case 1:
					return "Open";
				case 2:
					return "Partially Billed";
				default:
					return "Closed/Paid";
			}
		}
		if (type === "credit_note") {
			switch (status) {
				case 0:
					return "Draft";
				case 1:
					return "Active";
				case 2:
					return "Inactive";
				default:
					return "N/A";
			}
		}
	};

	const renderTransactions = (key, transactions, noDataMessage) => {
		if (!transactions || transactions.length === 0) return <p>{noDataMessage}</p>;

		return transactions.map((transaction) => (
			<TranscationCard
				key={transaction.id} // Ensure a unique key
				transactionType={key}
				transactionId={transaction.purchase_order_id || transaction.purchase_bill_id || transaction.credit_note_id}
				transactionStatus={getTransactionStatus(key, transaction.status)}
				transactionVendorName={transaction?.vendor?.vendor_name}
				transactionNumber={
					transaction.purchase_order_number ||
					transaction.bill_number ||
					transaction.credit_note_number
				}
				transactionDate={transaction.date || transaction.credit_note_date}
				transactionQuantity={
					transaction.purchase_order_products?.order_quantity ||
					transaction.bill_amount ||
					transaction.credit_note_amount
				}
			/>
		));
	};

	useEffect(() => {

		async function handleFetchVendor() {
			if (isVendorSelected) {
				setParams((prev) => ({
					...prev,
					filterBy: "vendor_id",
					filterValue: Number(vendor?.vendor_id)
				}))
			} else {
				setParams((prev) => ({
					...prev,
					filterValue: null,
					filterBy: null,
				}))
			}
		}

		handleFetchVendor()
	}, [isVendorSelected])

	return (
		<div>
			<div className="d-flex justify-content-between align-items-center">
				<div>
					<button
						class=" dropdown-toggle"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						style={{
							fontWeight: '600',
							fontSize: '18px',
							color: 'black',
							padding: '10px 0px',
							backgroundColor: '#ffffff',
						}}
					>
						{activeDropdown?.value}
					</button>
					<ul
						class="dropdown-menu "
						style={{
							fontSize: '14px',
							boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',
						}}
					>
						{dropdown?.map((item) => (
							<li
								className="dropdown-item"
								style={{ cursor: 'pointer' }}
								key={item?.id}
								onClick={() => handleDropdownChange(item?.key, item?.value)}
							>
								{item?.value}
							</li>
						))}
					</ul>
				</div>

				<div>
					<button
						class=" dropdown-toggle"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						style={{
							fontWeight: '600',
							fontSize: '18px',
							color: 'black',
							backgroundColor: '#ffffff',
							padding: '10px 0px',
						}}
					>
						<span style={{ color: '#797979' }}>Status:</span> {activeFilter}
					</button>
					<ul
						class="dropdown-menu "
						style={{
							fontSize: '14px',
							boxShadow: '-3px 0px 4px 0px rgba(0, 0, 0, 0.25)',
							fontWeight: '600',
						}}
					>
						{statusFilter[activeDropdown.key]?.map((item) => (
							<li
								key={item?.key + item?.id}
								className="dropdown-item"
								onClick={() => handleStatusFilter(item?.id, item?.key, item?.value)}
							>
								{item?.value}
							</li>
						))}
					</ul>
				</div>
			</div>


			<div style={{ backgroundColor: '#EBEBEB', padding: '5px', borderRadius: '6px' }}>
				<div class="form-check">
					<input class="form-check-input" type="checkbox" checked={isVendorSelected} id="flexCheckDefault" onChange={(e) => setIsVendorSelected(e.target.checked)} />
					<label class="form-check-label" for="flexCheckDefault">
						Show only {vendor?.vendor_name}’s transactions
					</label>
				</div>
			</div>
			<br />


			{activeDropdown.key === "purchase_order" &&
				renderTransactions(
					"purchase_order",
					productDetails?.purchase_orders,
					"No Purchase Orders Found!"
				)}
			{activeDropdown.key === "purchase_bill" &&
				renderTransactions(
					"purchase_bill",
					productDetails?.purchase_bills,
					"No Purchase Bills Found!"
				)}
			{activeDropdown.key === "credit_note" &&
				renderTransactions(
					"credit_note",
					productDetails?.credit_notes,
					"No Credit Notes Found!"
				)}
		</div >
	);
};

export default Transactions;

function TranscationCard({
	transactionType,
	transactionId,
	transactionStatus,
	transactionVendorName,
	transactionNumber,
	transactionDate,
	transactionQuantity,
}) {
	const suburl = localStorage.getItem('suburl');
	return (
		<div
			className="purchase-order-card"
			style={{ marginBottom: '10px' }}
		>
			{/* Status Bar */}
			<div
				className="status-bar"
				style={{
					backgroundColor: transactionStatus === 'Draft' ? 'black' : '',
					color: transactionStatus === 'Draft' ? 'white' : '',
				}}
			>
				{transactionStatus}
			</div>

			{/* Header */}
			<div className="order-header">
				<div className="order-info">
					<p>{transactionVendorName}</p>
					<Link
						to={`/user/${suburl}/${transactionType === 'purchase_order' ? 'purchases-order-details' : transactionType === 'purchase_bill' ? 'purchases-bills-details' : 'credit-note-details'}/${transactionId}`}
					>
						{' '}
						<span className="order-id">
							{transactionNumber}{' '}
							<img
								src={CombinedShape}
								alt=""
								style={{ padding: '3px' }}
							/>
						</span>
					</Link>
				</div>
				<div
					className="d-flex align-items-center "
					style={{ gap: '10px' }}
				>
					<div className="order-summary d-flex flex-column">
						<span className="order-date">{getDate(transactionDate)}</span>
						<span
							className="expected-date"
							style={{ color: '#030303' }}
						>
							{transactionType === 'purchase_order' ? 'Ordered Quantity' : 'Bill Amount'}:{' '}
							{transactionQuantity}
						</span>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	);
}
