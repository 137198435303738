import React from 'react';
import './ContactPersons.scss';

const ProductDetails = ({ productDetails }) => {


  if (!productDetails) return <p>Loading...</p>

  function calculateToBeReceivedQuantity(po) {
    if (po.length === 0) return 'N/A';

    const total = po.reduce((acc, p) => {
      const orderQuantity = Number(p?.purchase_order_products?.order_quantity || 0);
      const receivedQuantity = Number(p?.purchase_order_products?.received_quantity || 0);
      return acc + (orderQuantity - receivedQuantity);
    }, 0);

    return total;
  }

  function calculateStockOnHand(pb) {
    if (pb.length === 0) return 0
    return pb.reduce((acc, item) => {
      const quantity = Number(item?.quantity || 0)
      const freeQuantity = Number(item?.free_quantity || 0)
      return acc + (quantity + freeQuantity)
    }, 0)
  }

  function calculateCommittedStock(pb) {
    return 0
  }

  return (
    <div>
      <div>
        <div>
          <div className='d-flex' style={{ gap: '50px' }}>
            <div>
              <div style={{
                color: '#000',
                fontSize: '17px',

              }}>To Be Shipped</div>
              <div style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '30px',
                textAlign: 'center'

              }}>{productDetails?.order_quantity || 'N/A'}</div>
            </div>
            <div style={{ borderRight: '1px solid #C3CBDC' }}></div>
            <div>
              <div style={{
                color: '#000',
                fontSize: '17px',

              }}>To Be Received</div>
              <div style={{
                color: '#000',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '30px',
                textAlign: 'center'
              }}>{calculateToBeReceivedQuantity(productDetails?.purchase_orders)}</div>
            </div>
          </div>
          <br />
          <div style={{
            border: '1px solid #D0D0D0',
            borderRadius: '8px',
            padding: '16px',
            maxWidth: '100%',
            fontFamily: 'Arial, sans-serif'
          }}>
            <h3 style={{ borderBottom: '1px solid #D0D0D0', paddingBottom: '8px', fontWeight: '600' }}>Physical Stock Details</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Stock on Hand</div>
              <div>{calculateStockOnHand(productDetails?.product_details?.product_batch)}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', }}>
              <div className='ListQ'>Committed Stock</div>
              <div>{calculateCommittedStock(productDetails?.product_details?.product_batch)}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Available for Sale</div>
              <div>{calculateStockOnHand(productDetails?.product_details?.product_batch) - calculateCommittedStock(productDetails?.product_details?.product_batch)}</div>
            </div>
          </div>
          <br />
          <div style={{
            border: '1px solid #D0D0D0',
            borderRadius: '8px',
            padding: '16px',
            maxWidth: '100%',
            fontFamily: 'Arial, sans-serif'
          }}>
            <h3 style={{ borderBottom: '1px solid #D0D0D0', paddingBottom: '8px', fontWeight: '600' }}>Accounting Stock Details</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Stock on Hand</div>
              <div>{calculateStockOnHand(productDetails?.product_details?.product_batch)}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', }}>
              <div className='ListQ'>Committed Stock</div>
              <div>{calculateCommittedStock(productDetails?.product_details?.product_batch)}</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
              <div className='ListQ'>Available for Sale</div>
              <div>{calculateStockOnHand(productDetails?.product_details?.product_batch) - calculateCommittedStock(productDetails?.product_details?.product_batch)}</div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
