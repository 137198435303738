export const getLocalStorageData = () => {
	let data = {};
	const token = localStorage.getItem('authToken');
	if (token) {
		data = {
			token: token,
		};
	}
	return data;
};

export const setRequestHeader = (type) => {
	let loggedInInfo = getLocalStorageData();
	var headerObj = new Headers();

	if (loggedInInfo.token) {
		headerObj.append('Authorization', `Bearer ${loggedInInfo.token}`);
	}
	if (type === 'urlencoded') {
		headerObj.append(
			'Content-Type',
			'application/x-www-form-urlencoded;charset=UTF-8',
			'withCredentials: true',
		);
	}

	return headerObj;
};

export const formatDate = (dateString) => {
	const date = new Date(dateString);
	const month = date.getMonth() + 1; // Month is zero-based, so add 1
	const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
	return `${month}/${year}`;
};

export function numberToWords(amount) {
	const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
	const teens = [
		'Eleven',
		'Twelve',
		'Thirteen',
		'Fourteen',
		'Fifteen',
		'Sixteen',
		'Seventeen',
		'Eighteen',
		'Nineteen',
	];
	const tens = [
		'',
		'Ten',
		'Twenty',
		'Thirty',
		'Forty',
		'Fifty',
		'Sixty',
		'Seventy',
		'Eighty',
		'Ninety',
	];
	const thousands = ['', 'Thousand', 'Lakh', 'Crore'];

	// Helper function to convert up to three digits
	function convertToHundreds(num) {
		let str = '';
		if (num > 99) {
			str += units[Math.floor(num / 100)] + ' Hundred ';
			num %= 100;
		}
		if (num > 10 && num < 20) {
			str += teens[num - 11] + ' ';
		} else {
			if (num >= 10) {
				str += tens[Math.floor(num / 10)] + ' ';
				num %= 10;
			}
			if (num > 0) {
				str += units[num] + ' ';
			}
		}
		return str.trim();
	}

	// Main logic for converting amount
	function convertToWords(num) {
		if (num === 0) return 'Zero Rupees Only';

		let str = '';
		let i = 0;

		while (num > 0) {
			const part = num % 1000;
			if (part > 0) {
				str = convertToHundreds(part) + ' ' + thousands[i] + ' ' + str;
			}
			num = Math.floor(num / 1000);
			i++;
		}
		return str.trim() + ' Rupees Only';
	}

	// Handle decimal part (paise)
	const [integerPart, decimalPart] = amount.toString().split('.');
	let result = convertToWords(Number(integerPart));

	if (decimalPart && Number(decimalPart) > 0) {
		const paise = Number(decimalPart.slice(0, 2));
		result += ' and ' + convertToWords(paise) + ' Paise';
	}

	return result;
}
