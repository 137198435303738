// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalBillFormDiv {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: #000;
}

.TotalBillDivCredit {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.rs {
  color: #000;
  font-size: 14px;
  font-weight: 700;
}

.TBDQ {
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.TBDA {
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.ButtonBGColor {
  border-radius: 8px;
  border: 1px solid #c3cbdc;
  background: #daddec;
  color: var(--main, #0f1c35);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.ButtonBGColor:hover {
  background-color: #c3cbdc;
}`, "",{"version":3,"sources":["webpack://./src/pages/creditNote/CreditNote.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;AACD;;AAEA;EACC,aAAA;EACA,yBAAA;EACA,SAAA;AACD;;AACA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;AAED;;AAAA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;AAGD;;AADA;EACC,WAAA;EACA,eAAA;EACA,gBAAA;AAID;;AAFA;EACC,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAKD;;AAHA;EACC,yBAAA;AAMD","sourcesContent":[".TotalBillFormDiv {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tpadding-left: 10px;\n\talign-items: center;\n\tgap: 10px;\n\tfont-weight: 600;\n\tcolor: #000;\n}\n\n.TotalBillDivCredit {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tgap: 40px;\n}\n.rs {\n\tcolor: #000;\n\tfont-size: 14px;\n\tfont-weight: 700;\n}\n.TBDQ {\n\tcolor: #000;\n\tfont-size: 14px;\n\tfont-weight: 600;\n}\n.TBDA {\n\tcolor: #000;\n\tfont-size: 14px;\n\tfont-weight: 400;\n}\n.ButtonBGColor {\n\tborder-radius: 8px;\n\tborder: 1px solid #c3cbdc;\n\tbackground: #daddec;\n\tcolor: var(--main, #0f1c35);\n\tfont-size: 14px;\n\tfont-weight: 600;\n\tline-height: 24px;\n}\n.ButtonBGColor:hover {\n\tbackground-color: #c3cbdc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
