import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorModal from '../ErrorModal/ErrorModal';
import { toast } from 'react-toastify';

const PurchaseReceiveTableView = ({
	initialRows,
	columns,
	className,
	onChange,
	value,
	disabled,
	newRowgenerate = '',
	returnInputvalue,
	tableError,
	setIsTableErrorShow,
}) => {
	const [rows, setRows] = useState([]);
	const [totalProduct, setTotalProduct] = useState(0);
	const [totalQuantity, setTotalQuantity] = useState(0);
	const [errorCells, setErrorCells] = useState([]);
	const [showError, setShowError] = useState(false);
	const inputRefs = useRef([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (errorCells.length > 0) {
			setIsTableErrorShow(true);
		} else {
			setIsTableErrorShow(false);
		}
	}, [errorCells]);

	useEffect(() => {
		if (tableError || (tableError && rows?.length > 0 && errorCells.length > 0)) {
			const secondLastRowIndex = rows.length - 1;
			const secondLastRow = rows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '') &&
						['product_id', 'order', 'receive', 'in-transit', 'qty'].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === secondLastRowIndex)),
				...errors,
			]);
		}
	}, [tableError]);

	function handleSetInitialTableData() {
		try {
			setLoading(true);
			if (value?.length > 0) {
				setRows(value);
			} else {
				setRows(initialRows);
			}
		} catch (error) {
			toast.error('Something went wrong while loading product table!');
		} finally {
			setLoading(false);
		}
	}
	useEffect(() => {
		handleSetInitialTableData();
	}, [value]);

	// Add a new row
	const addRow = () => {
		const emptyRow = columns.reduce((acc, col) => {
			acc[col.name] = '';
			return acc;
		}, {});
		const newRows = [...rows, emptyRow];
		setRows(newRows);
		// Check if the second last row is empty
		if (newRows.length > 1) {
			const secondLastRowIndex = newRows.length - 2;
			const secondLastRow = newRows[secondLastRowIndex];

			// Determine if any cell in the second last row is empty or has a placeholder value
			const errors = columns
				.filter((col) => {
					const value = secondLastRow[col.name];
					// Check if the value is empty or a placeholder that should be considered an error
					return (
						(value === null || value === '') &&
						['product_id', 'order', 'receive', 'in_transit', 'qty'].includes(col.name)
					);
				})
				.map((col) => ({
					index: secondLastRowIndex,
					name: col.name,
				}));
			// Update errorCells state based on the errors found
			setErrorCells((prevErrorCells) => [
				...prevErrorCells.filter((error) => !(error.index === secondLastRowIndex)),
				...errors,
			]);
		}

		if (onChange) {
			onChange(newRows);
		}
		setTimeout(() => {
			inputRefs.current[newRows.length - 1]?.[0]?.focus();
			let lastRowRef = inputRefs.current[newRows.length - 1]?.[0];
			lastRowRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	};

	// Delete a row
	const deleteRow = (index) => {
		if (rows.length > 1) {
			const newRows = [...rows];
			newRows.splice(index, 1);
			const newErrorCells = errorCells
				.filter((error) => error.index !== index)
				.map((error) => ({
					...error,
					index: error.index > index ? error.index - 1 : error.index,
				}));
			setErrorCells(newErrorCells);
			setRows(newRows);
			if (onChange) {
				onChange(newRows);
			}
			setTimeout(() => {
				inputRefs.current[newRows.length - 1]?.[0]?.focus();
			}, 0);
		} else {
			setRows([]);
			setErrorCells([]);
			setRows(initialRows);
		}
	};

	// Handle input change in the table
	const handleChange = (index, event) => {
		let { name, value } = event.target;
		// if (value === '' || Number(value) === 0) {
		// 	setErrorCells([...errorCells, { index, name: 'qty' }]);
		// } else {
		// 	setErrorCells(errorCells.filter((cell) => cell.index === index && cell.name !== 'gty'));
		// }
		if (name === 'qty') {
			value = value?.replace(/[^0-9.]/g, '');
		}
		const decimalCount = (value.match(/\./g) || []).length;
		if (decimalCount > 1) {
			value = +value?.slice(0, value.lastIndexOf('.'));
		}
		if (value === '' || Number(value) === 0) {
			setErrorCells([...errorCells, { index, name: 'qty' }]);
		} else {
			setErrorCells(errorCells.filter((cell) => !(cell.index === index && cell.name === 'qty')));
		}
		const newRows = [...rows];
		newRows[index][name] = value;
		setRows(newRows);
		if (onChange) {
			onChange(newRows);
		}
	};

	// Handle key down events (e.g., adding a new row)
	const handleKeyDown = (index, event) => {
		let { name, value } = event.target;
		if (event.key === 'Enter') {
			const column = columns.find((col) => col.name === event.target.name);
			if ((column && column.label === newRowgenerate) || (column && column.name === 'qty')) {
				addRow();
			}
		} else {
			if (name === 'qty') {
				handleChange(index, event);
			}
		}
	};

	// Handle reference for input elements
	const handleRef = (rowIndex, cellIndex) => (el) => {
		if (!inputRefs.current[rowIndex]) {
			inputRefs.current[rowIndex] = [];
		}
		inputRefs.current[rowIndex][cellIndex] = el;
	};

	const getToolTipData = (name) => {
		switch (name) {
			case 'qty':
				return `Quantity can not be 0`;
			default:
				return '';
		}
	};

	// New useEffect to handle changes in the 'disabled' prop
	useEffect(() => {
		if (rows.length > 0 && onChange) {
			onChange(rows);
		}
	}, [disabled]);

	const ErrorModalOpen = () => {
		setShowError(true);
	};
	const CloseErrorModal = () => {
		setShowError(false);
		setTimeout(() => {
			if (rows.length > 0) {
				inputRefs.current[rows.length - 1]?.[0]?.focus();
			}
		}, 0);
	};

	const handleCount = useCallback(() => {
		const totalProductCount = rows.length;
		const totalQuantity = rows.reduce((acc, prod) => {
			acc = acc + Number(prod.qty);
			return acc;
		}, 0);

		setTotalProduct(totalProductCount);
		setTotalQuantity(totalQuantity);
	}, [rows]);

	useEffect(() => {
		handleCount();
	}, [rows]);

	return (
		<>
			{errorCells && errorCells?.length > 0 && (
				<div className="d-flex justify-content-end  text-danger  mb-0">
					{' '}
					<span
						style={{ textDecoration: 'underline', cursor: 'pointer' }}
						onClick={ErrorModalOpen}
					>
						Show all error
					</span>
				</div>
			)}
			<div className="text-center mb-2 custum_table">
				<div>
					<table>
						<thead>
							<tr>
								{columns.map((col) => (
									<th
										className={`${className}`}
										key={col.name}
									>
										<div
											style={{
												display: 'flex',
												justifyItems: 'start',
												alignItems: 'center',
											}}
										>
											<span>{col.label}</span>
											{['qty'].includes(col.name) ? (
												<i className="toolTip_custum fa fa-info-circle">
													<span className="toolTip_custumtext">{getToolTipData(col.name)}</span>
												</i>
											) : (
												''
											)}
										</div>
									</th>
								))}
								<th>Actions</th>
							</tr>
						</thead>
						{loading ? (
							<div
								className="d-flex justify-content-center align-items-center"
								style={{
									marginTop: '5%',
									marginLeft: '40%',
									position: 'fixed ',
								}}
							>
								<span
									className="Page_loader"
									role="status"
									aria-hidden="true"
								></span>
							</div>
						) : (
							<tbody>
								{rows.map((row, rowIndex) => (
									<tr key={rowIndex}>
										{columns.map((col, cellIndex) => (
											<td
												key={col.name}
												style={{ textAlign: col.name === 'product_id' ? 'left' : 'center' }}
												className={
													errorCells.some(
														(cell) => cell.index === rowIndex && cell.name === col.name,
													)
														? 'error'
														: ''
												}
											>
												{col.name === 'qty' ? (
													<input
														className="table_input"
														type={col.type}
														name={col.name}
														disabled={col.name === 'packaging' ? true : false}
														value={row[col.name]}
														style={{
															border: '1px solid rgba(85, 91, 103, 0.15)',
															borderRadius: '8px',
														}}
														onChange={(e) => handleChange(rowIndex, e)}
														onKeyDown={(e) => handleKeyDown(rowIndex, e)}
														ref={handleRef(rowIndex, cellIndex)}
													/>
												) : (
													row[col.name]
												)}
											</td>
										))}
										<td>
											<button
												className="delete mt-0 bg-transparent"
												onClick={() => deleteRow(rowIndex)}
												tabIndex="-1"
												disabled={disabled}
											>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
												>
													<path
														d="M3 6H5H21"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M10 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M14 11V17"
														stroke="#FF0000"
														strokeWidth="2"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</button>
										</td>
									</tr>
								))}
							</tbody>
						)}
						<tfoot>
							<tr>
								<td style={{ textAlign: 'left' }}>Total Item : {totalProduct}</td>
								<td></td>
								<td></td>
								<td></td>
								<td style={{ textAlign: 'right' }}>Total : {totalQuantity}</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
				</div>
				{/* {!disabled && (
					<button
						className="bg-transparent text-dark add-new-row-button mt-2"
						onClick={addRow}
					>
						Add New Row
						<i
							className="fa fa-plus"
							style={{
								marginLeft: '.5rem',
								strokeWidth: '.1rem',
								color: 'var(--brand-color)',
								WebkitTextStrokeColor: '#fff',
								WebkitTextStrokeWidth: '1px',
							}}
						/>
					</button>
				)} */}
			</div>
			<div className="text-start">
				{showError && (
					<ErrorModal
						show={showError}
						isOpen={showError}
						onClose={CloseErrorModal}
						title="Error in Purchase Receive Table"
						error={errorCells}
					/>
				)}
			</div>
		</>
	);
};

export default PurchaseReceiveTableView;
