import { APP_API_URL } from '../../../utils/xhr-instance';
import axiosInstance from '../../../services/axios';

export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_DONE = 'LOGIN_DONE';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const OTP_VERIFIATION_DONE = 'OTP_VERIFIATION_DONE';
export const OTP_VERIFIATION_FAIL = 'OTP_VERIFIATION_FAIL';

export const RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const FROGOT_PASSWORD_DONE = 'LOGIN_DONE';

export const LOGIN_API_URL = `${APP_API_URL}/auth/login`;
export const SIGNUP_API_URL = `${APP_API_URL}/auth/signup`;
export const SEND_OTP_API_URL = `${APP_API_URL}/auth/send-otp`;
export const OTP_VERIFICATION_API_URL = `${APP_API_URL}/auth/verify-otp`;
export const RESET_PASSWORD_API_URL = `${APP_API_URL}/auth/reset-password`;

export const user_login = (formData) => {
	let API_URL = LOGIN_API_URL;
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formData)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: LOGIN_DONE,
						payload: res?.data,
					});
					return res?.data;
				} else {
					dispatch({
						type: LOGIN_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const user_signup = (formData) => {
	let API_URL = SIGNUP_API_URL;
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formData)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: SIGNUP_DONE,
						payload: res?.data,
					});
					return res?.data;
				} else {
					dispatch({
						type: SIGNUP_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};

export const user_send_otp = (formData) => {
	let API_URL = SEND_OTP_API_URL;
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formData)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: OTP_VERIFIATION_DONE,
						payload: res?.data,
					});
					return res?.data;
				} else {
					dispatch({
						type: OTP_VERIFIATION_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const user_otp_verification = (formData, param) => {
	let API_URL;
	if (param) {
		API_URL = `${OTP_VERIFICATION_API_URL}${param}`;
	}
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formData)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: OTP_VERIFIATION_DONE,
						payload: res?.data,
					});
					return res?.data;
				} else {
					dispatch({
						type: OTP_VERIFIATION_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
export const user_reset_password = (formData) => {
	let API_URL = RESET_PASSWORD_API_URL;
	return (dispatch) => {
		return axiosInstance
			.post(API_URL, formData)
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: RESET_PASSWORD_DONE,
						payload: res?.data,
					});
					return res?.data;
				} else {
					dispatch({
						type: RESET_PASSWORD_FAIL,
						payload: res,
					});
					return res?.data;
				}
			})
			.catch((err) => {
				return err;
			});
	};
};
