import { APP_API_URL } from '../../utils/xhr-instance';
import axiosInstance from '../axios';

export async function getPackagingList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/product/packaging-list`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function getProductList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/product/getAllProducts`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function getProductBatchList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/product/product-batch`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function fetchProductDetails(id, params) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.get(`${APP_API_URL}/product/get-product-details/${id}`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}
