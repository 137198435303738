import React, { useState, useEffect, useRef, useCallback } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InputSelect from '../../component/inputSelect/InputSelect';
import BillTable from './BillTable';
import './CreatePurchaseBill.scss';
import add from '../../assets/image/add.svg';
import BlackClose from '../../assets/image/x-squareBlack.svg';
import ProfilePlaceHolder from '../../assets/image/emailPlaceHolder.svg';
import ProductDetails from '../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../purchaseOrder/Navigation/Transactions';
import { toast } from 'react-toastify';
import tooltipIcon from '../../assets/image/tooltip_vendor name.png';
import OpenPOAlert from '../../assets/image/Open PO Alert.svg';
import { fetchVendors } from '../../services/vendor';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import {
	create_purchase_bill,
	fetchPurchaseBillDetails,
	updatePurchaseBill,
} from '../../services/purchase_bill';
import Modal from '../../component/modal/Modal';
import ConvertToBillModal from '../purchaseOrder/components/ConvertToBillModal';
import { fetchPurchaseReceive } from '../../services/purchase_receive';
import closei from '../../assets/image/x-square.svg';
import { getCurrentDate, getDate } from '../../utils/dateUtils';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';
import AddVendorModal from '../purchaseOrder/AddNewVendor';
import { createPaymentMethod, fetchPaymentMethodList } from '../../services/payment_methods';
import AppendInput from '../../component/form/AppendInput';
import { createTransport, fetchTransportList } from '../../services/transport';

const CreatePurchaseBill = () => {
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [isErrortable, setIsTableError] = useState(false);
	const [disabled, setDisabled] = useState(false); //id ? true : false
	const [TableError, setTableError] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const [isAddPaymentMethod, setIsAddPaymentMethod] = useState(false);
	const [isTransportType, setIsTransportType] = useState(false);
	const navTabs = ['Product Details', 'Transactions'];
	const [activeTab, setActiveTab] = useState('Transactions');
	const [error, setError] = useState({});
	const [errorTransportType, setErrorTransportType] = useState('');
	const [errorPaymentMethod, setErrorPaymentMethod] = useState('');
	const [errorEwayBill, setErrorEwayBill] = useState({});
	const [paymentMethodParams, setPaymentMethodParams] = useState({
		page: 1,
		limit: 20,
		search: ""
	})
	const [searchTransport, setSearchTransport] = useState("")
	const [transportParams, setTransportParams] = useState({
		page: 1,
		limit: 20,
		search: ""
	})
	const [searchPaymentMethod, setSearchPaymentMethod] = useState("")
	const [paymentMethodList, setPaymentMethodList] = useState([]);
	const [transportTypeList, setTransportTypeList] = useState([]);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [transportType, setTransportType] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [isBillDateFocus, setIsBillDateFocus] = useState(false);
	const [isDueDateFocus, setIsDueDateFocus] = useState(false);
	const [isBillNoFocus, setIsBillNoFocus] = useState(false);
	const [additionDiscountAndTax, setAdditionDiscountAndTax] = useState({
		discount: 0,
		adj: 0,
		roundOff: 0,
		taxes: 0,
	});
	const [loading, setLoading] = useState({
		isLoading: false,
		loaderId: 'draft',
	});
	const [subTotal, setSubTotal] = useState(0);
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
		purhcaseOrderIdList: [],
	});

	const [purchaseBillFormData, setPurchaseBillFormData] = useState({
		vendor_id: null,
		purchase_order_number: '',
		purchase_order_id: null,
		purchase_receive_id: null,
		date: getCurrentDate(),
		bill_number: '',
		bill_date: getCurrentDate(),
		due_date: '',
		bill_amount: '',
		payment_method: 'Cash',
		other_adjustment: '',
		purchase_bill_products: [],
		e_way_bill: {
			e_way_bill_no: '',
			challan_no: '',
			date: getCurrentDate(),
			transport: '',
			cases: '',
			purchase_tax: '',
			remarks: '',
			e_way_bill_status: false,
		},
	});
	const [isEWayBillStatus, setIsEWayBillStatus] = useState(false);
	const [isBillModalOpen, setIsBillModalOpen] = useState(false);

	const [searchVendor, setSearchVendor] = useState("")
	const [params, setParams] = useState({
		vendor: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
			status: 1,
		},
		purchaseOrder: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
	});

	const [isOpenPastDate, setIsOpenPastDate] = useState(false);
	const [isUsedPastDate, setIsUsedPastDate] = useState(false);
	const [isOpenPBPastDate, setIsOpenPBPastDate] = useState(false);
	const [isUsedPBPastDate, setIsUsedPBPastDate] = useState(false);

	const confirmUsedPastDate = () => {
		const newError = { ...error };
		delete newError.date;
		setError(newError);
		setIsUsedPastDate(true);
		setIsOpenPastDate(false);
		setIsBillDateFocus(true);
	};
	const confirmUsedPBPastDate = () => {
		const newError = { ...error };
		delete newError.bill_date;
		setError(newError);
		setIsUsedPBPastDate(true);
		setIsOpenPBPastDate(false);
		setIsDueDateFocus(true);
	};

	const cancelUsedPastDate = () => {
		if (isOpenPastDate) {
			setIsOpenPastDate(false);
			setIsBillDateFocus(true);
		} else {
			setIsOpenPBPastDate(false);
			setIsDueDateFocus(true);
		}
	};

	const handleAddPaymentMethod = async () => {

		try {
			if (paymentMethod === "") {
				setErrorTransportType('Please enter payment method name!')
			}
			const data = await createPaymentMethod({ payment_method_name: paymentMethod })
			setPaymentMethodList([{ key: data.paymentMethod?.payment_method_name, value: data.paymentMethod?.payment_method_name }]);
			setPurchaseBillFormData((prev) => ({
				...prev,
				payment_method: data.paymentMethod?.payment_method_name
			}))
			setIsAddPaymentMethod(false);

		} catch (error) {
			toast.error("Something went wrong!")
		}
	};

	const handleTransportType = async () => {
		try {

			if (transportType === "") {
				setErrorTransportType('Please enter transport agency name!')
				return
			}

			const data = await createTransport({ transport_name: transportType })

			setTransportTypeList([{
				key: data?.transport?.transport_name,
				value: data?.transport?.transport_name
			}]);

			setPurchaseBillFormData((prev) => ({
				...prev,
				e_way_bill: {
					...prev.e_way_bill,
					transport: data?.transport?.transport_name
				}
			}))
			setTransportType('');
			setIsTransportType(false);

		} catch (error) {
			toast.error('Please enter transport agency name')
		}
	};

	function handleResetPurchaseBillFormData() {
		setPurchaseBillFormData({
			vendor_id: null,
			purchase_order_number: '',
			purchase_order_id: null,
			purchase_receive_id: null,
			date: getCurrentDate(),
			bill_number: '',
			bill_date: '',
			due_date: '',
			bill_amount: '',
			payment_method: 'Cash',
			other_adjustment: '',
			purchase_bill_products: [],
			e_way_bill: {
				e_way_bill_no: '',
				challan_no: '',
				due_date: '',
				transport: '',
				cases: '',
				purchase_tax: '',
				remarks: '',
				e_way_bill_status: false,
			},
		});
		setParams({
			vendor: {
				page: 1,
				pageSize: 20,
				orderBy: null,
				sortBy: null,
				filterBy: null,
				filterValue: null,
			},
			purchaseOrder: {
				page: 1,
				pageSize: 20,
				orderBy: null,
				sortBy: null,
				filterBy: null,
				filterValue: null,
			},
		});
	}

	const calculateTotalBill = () => {
		return (
			Number(subTotal) -
			(Number(subTotal) * Number(additionDiscountAndTax.discount)) / 100 +
			Number(purchaseBillFormData.other_adjustment ?? 0) +
			additionDiscountAndTax.taxes
		);
	};

	const renderTabContent = () => {
		switch (activeTab) {
			case 'Product Details':
				return <ProductDetails productDetails={productDetails} />;
			case 'Transactions':
				return <Transactions />;
			default:
				return null;
		}
	};
	//TODO: change form data
	function handleFormData(key, value) {
		setPurchaseBillFormData((prev) => ({
			...prev,
			[key]: value,
		}));
		checkFormValidation(key, value);
	}
	function handleKeyPress(event, name) {
		let { value } = event.target;
		if (event.key === 'Enter' || event.key === 'Tab') {
			handleFormData(name, value);
		}
	}

	const checkFormValidation = (type, value) => {
		let errorData = { ...error };
		let errorEWayBillData = { ...errorEwayBill };
		if (value) {
			if (type === 'vendor_id') {
				delete errorData.vendor_id;
			} else if (type === 'bill_number') {
				delete errorData.bill_number;
			} else if (type === 'bill_date') {
				delete errorData.bill_date;
			} else if (type === 'bill_amount') {
				delete errorData.bill_amount;
			} else if (type === 'payment_method') {
				delete errorData.payment_method;
			} else if (type === 'date') {
				delete errorData.date;
			} else if (type === 'E-way-bill-no') {
				delete errorEWayBillData.e_way_bill_no;
			} else if (type === 'challan-no') {
				delete errorEWayBillData.challan_no;
			} else if (type === 'due-date') {
				delete errorEWayBillData.due_date;
			} else if (type === 'transport') {
				delete errorEWayBillData.transport;
			} else if (type === 'cases') {
				delete errorEWayBillData.cases;
			} else if (type === 'purchase-tax') {
				delete errorEWayBillData.purchase_tax;
			}
		} else {
			if (type === 'vendor_id') {
				errorData.vendor_id = 'Please select a valid vendor from the list.';
			} else if (type === 'bill_number') {
				errorData.bill_number = 'Please enter bill number in the field';
			} else if (type === 'bill_date') {
				errorData.bill_date = 'Please enter bill date in the field';
			} else if (type === 'bill_amount') {
				errorData.bill_amount = 'Please enter bill amount in the field';
			} else if (type === 'payment_method') {
				errorData.payment_method = 'Please select a valid Payment method from the list.';
			} else if (type === 'date') {
				errorData.date = 'Please enter date in the field';
			} else if (type === 'E-way-bill-no') {
				errorEWayBillData.e_way_bill_no = 'Please enter e-way bill number in the field';
			} else if (type === 'challan-no') {
				errorEWayBillData.challan_no = 'Please enter challan number in the field';
			} else if (type === 'due-date') {
				errorEWayBillData.due_date = 'Please enter e-way due date in the field';
			} else if (type === 'transport') {
				errorEWayBillData.transport = 'Please enter transport in the field';
			} else if (type === 'cases') {
				errorEWayBillData.cases = 'Please enter cases in the field';
			} else if (type === 'purchase-tax') {
				errorEWayBillData.purchase_tax = 'Please enter purchase tax in the field';
			}
		}
		setError(errorData);
		setErrorEwayBill(errorEWayBillData);
	};

	function handleEwayBill() {
		let newError = { ...errorEwayBill };
		if (
			!purchaseBillFormData?.e_way_bill?.e_way_bill_no ||
			purchaseBillFormData?.e_way_bill?.e_way_bill_no === '' ||
			purchaseBillFormData?.e_way_bill?.e_way_bill_no === undefined
		) {
			newError.e_way_bill_no = 'Please enter bill number in the field';
		} else {
			delete newError.e_way_bill_no;
		}
		if (
			!purchaseBillFormData?.e_way_bill?.challan_no ||
			purchaseBillFormData?.e_way_bill?.challan_no === '' ||
			purchaseBillFormData?.e_way_bill?.challan_no === undefined
		) {
			newError.challan_no = 'Please enter challan number in the field';
		} else {
			delete newError.challan_no;
		}
		// if ( 	!purchaseBillFormData?.e_way_bill?.date ||
		// 	purchaseBillFormData?.e_way_bill?.date === '' ||
		// 	purchaseBillFormData?.e_way_bill?.date === undefined
		// ) {
		// 	newError.due_date = 'Please enter due date in the field';
		// } else {
		// 	delete newError.due_date;
		// }
		if (
			!purchaseBillFormData?.e_way_bill?.transport ||
			purchaseBillFormData?.e_way_bill?.transport === '' ||
			purchaseBillFormData?.e_way_bill?.transport === undefined
		) {
			newError.transport = 'Please enter transport in the field';
		} else {
			delete newError.transport;
		}
		if (
			!purchaseBillFormData?.e_way_bill?.cases ||
			purchaseBillFormData?.e_way_bill?.cases === '' ||
			purchaseBillFormData?.e_way_bill?.cases === undefined
		) {
			newError.cases = 'Please enter cases in the field';
		} else {
			delete newError.cases;
		}
		if (
			!purchaseBillFormData?.e_way_bill?.purchase_tax ||
			purchaseBillFormData?.e_way_bill?.purchase_tax === '' ||
			purchaseBillFormData?.e_way_bill?.purchase_tax === undefined
		) {
			newError.purchase_tax = 'Please enter purchase tax in the field';
		} else {
			delete newError.purchase_tax;
		}

		if (Object.keys(newError).length > 0) {
			setErrorEwayBill(newError);
			setPurchaseBillFormData((prev) => ({
				...prev,
				e_way_bill: {
					...prev.e_way_bill,
					e_way_bill_status: false,
				},
			}));
		} else {
			setIsBillModalOpen(false);
			setErrorEwayBill(newError);
			setPurchaseBillFormData((prev) => ({
				...prev,
				e_way_bill: {
					...prev.e_way_bill,
					e_way_bill_status: true,
				},
			}));
		}
	}

	//TODO: validation of form

	function checkValidation(status) {
		const newError = { ...error };
		if (
			!purchaseBillFormData.vendor_id ||
			purchaseBillFormData.vendor_id === '' ||
			purchaseBillFormData.vendor_id === undefined
		) {
			newError.vendor_id = 'Please select a valid vendor from the list.';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}

		// if (!purchaseBillFormData.purchase_order_id ||
		// 	purchaseBillFormData.purchase_order_id === '' ||
		// 	purchaseBillFormData.purchase_order_id === undefined
		// ) {
		// 	newError.purchase_order_id = 'Please enter purchase order number in the field';
		// } else {
		// 	delete newError.purchase_order_id; // Agar fill kiya hai to error ko remove karein
		// }
		if (
			!purchaseBillFormData.bill_amount ||
			purchaseBillFormData.bill_amount === '' ||
			purchaseBillFormData.bill_amount === undefined
		) {
			newError.bill_amount = 'Please enter bill amount in the field';
		} else {
			delete newError.bill_amount; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!purchaseBillFormData.bill_number ||
			purchaseBillFormData.bill_number === '' ||
			purchaseBillFormData.bill_number === undefined
		) {
			newError.bill_number = 'Please enter bill number in the field';
		} else {
			delete newError.bill_number; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!purchaseBillFormData.bill_date ||
			purchaseBillFormData.bill_date === '' ||
			purchaseBillFormData.bill_date === undefined ||
			(purchaseBillFormData.bill_date !== '' && newError.bill_date)
		) {
			newError.bill_date = newError.bill_date ?? 'Please enter bill date in the field';
		} else {
			delete newError.bill_date;
		}
		if (
			!purchaseBillFormData.date ||
			purchaseBillFormData.date === '' ||
			purchaseBillFormData.date === undefined ||
			(purchaseBillFormData.date !== '' && newError.date)
		) {
			newError.date = newError.date ?? 'Please enter date in the field';
		} else {
			delete newError.date;
		}
		// if (!purchaseBillFormData.due_date ||
		// 	purchaseBillFormData.due_date === '' ||
		// 	purchaseBillFormData.due_date === undefined
		// ) {
		// 	newError.due_date = 'Please enter due date in the field';
		// } else {
		// 	delete newError.due_date; // Agar fill kiya hai to error ko remove karein
		// }
		if (
			!purchaseBillFormData.payment_method ||
			purchaseBillFormData.payment_method === '' ||
			purchaseBillFormData.payment_method === undefined
		) {
			newError.payment_method = 'Please enter payment method in the field';
		} else {
			delete newError.payment_method; // Agar fill kiya hai to error ko remove karein
		}
		// if (
		// 	purchaseBillFormData.e_way_bill.length > 0 && 	!purchaseBillFormData.e_way_bill.e_way_bill_status
		// ) {
		// 	newError.e_way_bill = 'Please fill e-way bill details.';
		// } else {
		// 	delete newError.e_way_bill;
		// }

		if (
			Object.keys(newError).length > 0 ||
			(status === 1 && TableError) ||
			(status === 1 &&
				Number(purchaseBillFormData.bill_amount).toFixed(2) !== calculateTotalBill().toFixed(2))
		) {
			setError(newError);
			return true;
		} else {
			return false;
		}
	}

	async function handleSavePurchaseBill(status) {
		const isErrorShow = checkValidation(status);
		if (isErrorShow) {
			if (Number(purchaseBillFormData.bill_amount).toFixed(2) !== calculateTotalBill().toFixed(2)) {
				toast.error('Please mismatch bill amount and bill total');
			} else {
				toast.error('Unable to create purchase bill');
			}
		} else {
			try {
				setLoading((prev) => ({
					...prev,
					isLoading: true,
					loaderId: status,
				}));
				const body = {
					...purchaseBillFormData,
					status,
					purchase_bill_products: purchaseBillFormData?.purchase_bill_products
						.filter(
							(product) =>
								(product.product_id !== '' && product.product_id !== null) ||
								(product.quantity !== 0 && product.quantity !== null && product.quantity !== '') ||
								(product.mrp !== 0 && product.mrp !== null && product.mrp !== '') ||
								(product.purchase_rate !== 0 &&
									product.purchase_rate !== null &&
									product.purchase_rate !== '') ||
								(product.amount !== 0 && product.amount !== null && product.amount !== ''),
						)
						.map((prod) => {
							const { discount, free_quantity, quantity, mrp, purchase_rate, amount, ...rest } =
								prod;
							return {
								...rest,
								quantity: Number(quantity),
								free_quantity: Number(free_quantity),
								discount: Number(discount),
								mrp: Number(mrp),
								purchase_rate: Number(purchase_rate),
								amount: Number(amount),
							};
						}),
				};
				// console.log(body);
				await create_purchase_bill(body);
				toast.success('Purchase Bill Created Successfully');
				navigate(`/user/${suburl}/purchases-bills`);
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading((prev) => ({
					...prev,
					isLoading: false,
					loaderId: status,
				}));
			}
		}
	}

	const handleUpdatePurchaseBill = useCallback(async (status) => {
		try {
			await updatePurchaseBill({ ...purchaseBillFormData, status });
			toast.success('Purchase Bill Updated Successfully');
			navigate(`/user/${suburl}/purchases-bills`);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	})

	//TODO: fetching vendor list
	const handleFetchVendorList = useCallback(async () => {
		try {
			const { vendors } = await fetchVendors(params?.vendor);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			console.log(vendorOptionList);
			if (purchaseBillFormData.vendor_id) {
				const isVendorFound = vendorOptionList.find(
					(vendor) => vendor.key === purchaseBillFormData.vendor_id,
				);
				if (!isVendorFound) {
					setParams((prev) => ({
						...prev,
						vendor: {
							filterBy: 'vendor_id',
							filterValue: Number(purchaseBillFormData.vendor_id),
						},
					}));
				}
			} else {
				setInputSelectOption(() => ({
					vendorList: vendorOptionList,
				}));
			}
		} catch (error) {
			toast.error('Something went wrong!');
		}
	})


	//TODO: fetching purchase order number list according to the vendor id
	async function handleFetchPurchaseOrders() {
		try {
			const { purchaseOrders } = await fetchPurchaseOrders({
				...params?.purchaseOrder,
				filterBy: 'vendor_id',
				filterValue: purchaseBillFormData?.vendor_id,
			});

			const purhcaseOrderIdList =
				purchaseOrders?.length > 0
					? purchaseOrders.map((po) => {
						return {
							key: po.purchase_order_id,
							value: po.purchase_order_number,
						};
					})
					: [];

			setInputSelectOption((prev) => ({
				...prev,
				purhcaseOrderIdList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}


	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	function generatePurchaseBillProducts(products) {
		return products.reduce((acc, product) => {
			acc.push({
				product_id: product?.product_id,
				batch_no: '',
				quantity: product?.received_quantity || 0,
				mfg_date: '',
				exp_date: '',
				mrp: 0,
				purchase_rate: 0,
				discount: '',
				amount: 0,
				free_quantity: 0,
			});
			return acc;
		}, []);
	}

	// handing the conveort to bill modal directly from the purchase bill while selecting the purchase order
	async function handleConvertToBill(type, ids) {
		setIsBillNoFocus(true);
		if (type === 'yetToReceive') {
			handleFetchPurchaseOrdersForYTR(ids[0]);
		}

		if (type === 'received') {
			fetchPR(ids[0]);
		}
	}

	async function fetchPR(id) {
		const { purchaseReceive } = await fetchPurchaseReceive({
			filterBy: 'purchase_receive_id',
			filterValue: id,
		});
		console.log({ purchaseReceive });
		setPurchaseBillFormData((prev) => ({
			...prev,
			purchase_receive_id: purchaseReceive[0]?.purchase_receive_id,
			vendor_id: purchaseReceive[0]?.vendor_id,
			purchase_order_number: purchaseReceive[0]?.purchase_order?.purchase_order_number,
			purchase_order_id: purchaseReceive[0]?.purchase_order_id,
			purchase_bill_products: generatePurchaseBillProducts(
				purchaseReceive[0].purchase_receive_products,
			),
		}));
	}

	async function handleFetchPurchaseOrdersForYTR(id) {
		const { purchaseOrders } = await fetchPurchaseOrders({
			filterBy: 'purchase_order_id',
			filterValue: Number(id),
		});
		const purchaseOrderDetails = purchaseOrders[0];
		const purchase_bill_products = generatePurchaseBillProducts(
			purchaseOrderDetails.purchase_order_products.filter(
				(product) => product.order_quantity !== product.received_quantity,
			),
		);
		setPurchaseOrderDetails(purchaseOrderDetails);
		setPurchaseBillFormData((prev) => ({
			...prev,
			purchase_bill_products,
			vendor_id: purchaseOrderDetails?.vendor_id,
			purchase_order_number: purchaseOrderDetails?.purchase_order_number,
			purchase_order_id: purchaseOrderDetails?.purchase_order_id,
		}));
	}

	useEffect(() => {
		handleFetchVendorList();
	}, [params.vendor]);

	useEffect(() => {
		let timer = setTimeout(() => {
			setParams((prev) => ({
				...prev,
				vendor: {
					...prev.vendor,
					filterBy: "vendor_name",
					filterValue: searchVendor
				}
			}))
		}, 500);
		return () => clearTimeout(timer)
	}, [searchVendor])


	const handleFetchPaymentMethod = useCallback(async () => {
		try {
			const data = await fetchPaymentMethodList(paymentMethodParams)
			const paymentMethodOptionList = data?.paymentMethods?.map((pm) => {
				return {
					key: pm?.payment_method_name,
					value: pm?.payment_method_name
				}
			})
			setPaymentMethodList(paymentMethodOptionList)
		} catch (error) {
			toast.error("Something went wrong")
		}
	})


	const handleFetchTransportList = useCallback(async () => {
		try {
			const data = await fetchTransportList(transportParams)
			const transportOptionList = data?.transports?.map((t) => {
				return {
					key: t?.transport_name,
					value: t?.transport_name
				}
			})
			setTransportTypeList(transportOptionList)
		} catch (error) {
			toast.error("Something went wrong")
		}
	})

	// Fetch data when the search parameter changes
	useEffect(() => {
		handleFetchTransportList();
	}, [transportParams?.search]); // Add handleFetchPaymentMethod as a dependency

	// Debounce the search input to prevent excessive API calls
	useEffect(() => {
		const timer = setTimeout(() => {
			setTransportParams((prev) => ({
				...prev,
				search: searchTransport
			}));
		}, 500);
		return () => clearTimeout(timer);
	}, [searchTransport]);



	// Fetch data when the search parameter changes
	useEffect(() => {
		handleFetchPaymentMethod();
	}, [paymentMethodParams?.search]); // Add handleFetchPaymentMethod as a dependency

	// Debounce the search input to prevent excessive API calls
	useEffect(() => {
		const timer = setTimeout(() => {
			setPaymentMethodParams((prev) => ({
				...prev,
				search: searchPaymentMethod
			}));
		}, 500);
		return () => clearTimeout(timer);
	}, [searchPaymentMethod]);

	useEffect(() => {
		if (purchaseBillFormData.vendor_id) {
			handleFetchPurchaseOrders();
		}
	}, [purchaseBillFormData.vendor_id]);

	useEffect(() => {
		// creating bill from purchase receive
		if (id && id.includes('pr')) {
			fetchPR(id.split('-')[1]);
		}

		// creating bill from purchase order - received
		if (id && id.includes('por')) {
			fetchPR(id.split('-')[1]);
		}

		// creating bill from purchase order - yet to receive
		if (id && id.includes('ytr')) {
			handleFetchPurchaseOrdersForYTR(id.split('-')[1]);
		}

		if (id && !id.includes('ytr') && !id.includes('por') && !id.includes('pr')) {
			async function handleFetchPurchaseBillById(id) {
				const purchaseBillDetails = await fetchPurchaseBillDetails(id);
				const {
					purchase_bill_products,
					e_way_bill,
					vendor_id,
					purchase_order_number,
					purchase_order_id,
					purchase_receive_id,
					date,
					bill_number,
					bill_date,
					due_date,
					bill_amount,
					payment_method,
					discount,
					freight,
					other_adjustment,
					purchase_bill_id,
				} = purchaseBillDetails;

				setPurchaseBillFormData({
					purchase_bill_products:
						purchase_bill_products.length === 0
							? []
							: purchase_bill_products?.map((p) => {
								return {
									...p,
									mfg_date: getDate(p.mfg_date, true, true),
									exp_date: getDate(p.exp_date, true, true),
								};
							}),
					e_way_bill: {
						...e_way_bill,
						date: getDate(e_way_bill?.due_date, true)
					},
					vendor_id,
					purchase_receive_id,
					purchase_order_number,
					purchase_order_id,
					date: getDate(date, true),
					bill_number,
					bill_date: getDate(bill_date, true),
					due_date: getDate(due_date, true),
					bill_amount,
					payment_method,
					discount,
					freight,
					other_adjustment,
					purchase_bill_id,
				});

				setInputSelectOption((prev) => ({
					...prev,
					vendorOptionList: [
						{
							vendor_id: purchaseBillDetails?.vendor?.vendor_id || vendor_id,
							value: purchaseBillDetails?.vendor?.vendor_name,
						},
					],
					purhcaseOrderIdList: [
						{
							key: purchase_order_id,
							value:
								purchase_order_number || purchaseBillDetails?.purchase_order?.purchase_order_number,
						},
					],
				}));

				if (e_way_bill && e_way_bill?.e_way_bill_no) {
					setIsEWayBillStatus(true);
				}
			}
			handleFetchPurchaseBillById(id);
		}
	}, [id]);

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>
							{id && !id.includes('ytr') && !id.includes('por') && !id.includes('pr')
								? 'Update '
								: 'Create New '}
							Purchase Bill
						</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save"
								loader={loading?.status === 1 && loading.isLoading}
								onClick={() => {
									if (id && !id.includes('ytr') && !id.includes('por') && !id.includes('pr')) {
										handleUpdatePurchaseBill(1);
									} else {
										handleSavePurchaseBill(1);
									}
								}}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Draft"
								loader={loading?.status === 0 && loading.isLoading}
								onClick={() => {
									if (id && !id.includes('ytr') && !id.includes('por') && !id.includes('pr')) {
										handleUpdatePurchaseBill(0);
									} else {
										handleSavePurchaseBill(0);
									}
								}}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-bills`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={inputSelectOption.vendorList}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								returnInputValue={false}
								value={purchaseBillFormData?.vendor_id}
								onSelect={(option) => {
									checkFormValidation('vendor_id', option.key);
									setPurchaseBillFormData((prev) => ({
										...prev,
										vendor_id: option.key,
									}));
									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) => {

									if (value === '') {
										checkFormValidation('vendor_id', '');
										handleResetPurchaseBillFormData();
									}
									setSearchVendor(value)
								}}
								disabled={disabled}
								error={error?.vendor_id}
								autoFocus={true}
								leftIcons={
									purchaseBillFormData?.vendor_id
										? [
											{
												src: OpenPOAlert,
												alt: 'showVendorDetails',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Open POs');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
											{
												src: tooltipIcon, // Image 2 path
												alt: 'Icon 2',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Details');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
										]
										: []
								}
								onAddNew={() => setIsAddNewVendorModalOpen(true)}
							/>
						</div>
						<div className="col-3">
							<InputSelect
								options={inputSelectOption.purhcaseOrderIdList}
								label="PO#"
								placeholder="Purchase Order No"
								required={false}
								moreInfobtn={
									<a
										href="#"
										tabIndex={-1}
										style={{ textDecoration: 'none', fontSize: '11px' }}
										onClick={() => {
											setVendorDetailsDrawerActiveOpenTab('Open POs');
											setIsVendorDetailsDrawerOpen(true);
										}}
									>
										More Info
									</a>
								}
								returnInputValue={false}
								value={purchaseBillFormData?.purchase_order_id}
								onSelect={(option) => {
									setPurchaseBillFormData((prev) => ({
										...prev,
										purchase_order_id: option.key,
										purchase_order_number: option.value,
									}));
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_id',
											filterValue: option.key,
										},
									}));
									handleFetchPurchaseOrdersForYTR(option.key);
									setIsConvertToBillOpen(true);
								}}
								onChange={(value) => {
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_number',
											filterValue: value,
										},
									}));
								}}
								disabled={disabled}
							// required={true}
							// error={error?.purchase_order_id}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Bill No #"
								placeholder="Bill No #"
								type="text"
								required={true}
								autoFocus={isBillNoFocus}
								onKeyPress={(e) => handleKeyPress(e, 'bill_number')}
								onChange={(value) => handleFormData('bill_number', value)}
								value={purchaseBillFormData.bill_number}
								disabled={disabled}
								error={error?.bill_number}
							// onFocus={(e) => {
							// 	isVendorDisplayNameTouched.current = true;
							// }}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							<InputField
								label="Date"
								placeholder="Enter Date"
								onBlur={(e) => {
									let value = e.target.value;
									let newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										if (!isUsedPastDate) {
											setIsOpenPastDate(true);
											newError.date = 'Date cannot be in the past.';
											setError(newError);
										} else {
											delete newError.date;
											setError(newError);
										}
										setPurchaseBillFormData((prev) => ({
											...prev,
											date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										setIsOpenPastDate(false);
										newError.date = 'Date cannot be in the future';
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											date: value,
										}));
									} else {
										setIsOpenPastDate(false);
										delete newError.date;
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											date: value,
										}));
									}
								}}
								type="date"
								value={purchaseBillFormData.date}
								onChange={(value) => {
									// let newError = { ...error };
									// var date = new Date(value);
									// var currentDate = new Date();
									// currentDate.setDate(currentDate.getDate() + 1);
									// let year = date.getFullYear().toString();
									// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									// 	if (!isUsedPastDate) {
									// 		setIsOpenPastDate(true);
									// 		newError.date = 'Date cannot be in the past.';
									// 		setError(newError);
									// 	} else {
									// 		delete newError.date;
									// 		setError(newError);
									// 	}
									// 	setPurchaseBillFormData((prev) => ({
									// 		...prev,
									// 		date: value,
									// 	}));
									// } else if (
									// 	year.length === 4 &&
									// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
									// ) {
									// 	setIsOpenPastDate(false);
									// 	newError.date = 'Date cannot be in the future';
									// 	setError(newError);
									// 	setPurchaseBillFormData((prev) => ({
									// 		...prev,
									// 		date: value,
									// 	}));
									// } else {
									// 	setIsOpenPastDate(false);
									// 	delete newError.date;
									// 	setError(newError);
									setPurchaseBillFormData((prev) => ({
										...prev,
										date: value,
									}));
									// }
								}}
								disabled={disabled}
								required={true}
								error={error?.date}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Bill Date"
								placeholder="Enter Bill Date"
								onBlur={(e) => {
									let value = e.target.value;
									const newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										if (!isUsedPBPastDate) {
											setIsOpenPBPastDate(true);
											newError.bill_date = 'Bill date cannot be in the past.';
											setError(newError);
										} else {
											delete newError.bill_date;
											setError(newError);
										}
										setPurchaseBillFormData((prev) => ({
											...prev,
											bill_date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										setIsOpenPBPastDate(false);
										newError.bill_date = 'Bill date cannot be in the future';
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											bill_date: value,
										}));
									} else {
										setIsOpenPBPastDate(false);
										delete newError.bill_date;
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											bill_date: value,
										}));
									}
								}}
								type="date"
								value={purchaseBillFormData.bill_date}
								onChange={(value) => {
									// const newError = { ...error };
									// var date = new Date(value);
									// var currentDate = new Date();
									// currentDate.setDate(currentDate.getDate() + 1);
									// let year = date.getFullYear().toString();
									// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									// 	if (!isUsedPBPastDate) {
									// 		setIsOpenPBPastDate(true);
									// 		newError.bill_date = 'Bill date cannot be in the past.';
									// 		setError(newError);
									// 	} else {
									// 		delete newError.bill_date;
									// 		setError(newError);
									// 	}
									// 	setPurchaseBillFormData((prev) => ({
									// 		...prev,
									// 		bill_date: value,
									// 	}));
									// } else if (
									// 	year.length === 4 &&
									// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
									// ) {
									// 	setIsOpenPBPastDate(false);
									// 	newError.bill_date = 'Bill date cannot be in the future';
									// 	setError(newError);
									// 	setPurchaseBillFormData((prev) => ({
									// 		...prev,
									// 		bill_date: value,
									// 	}));
									// } else {
									// 	setIsOpenPBPastDate(false);
									// 	delete newError.bill_date;
									// 	setError(newError);
									setPurchaseBillFormData((prev) => ({
										...prev,
										bill_date: value,
									}));
									// }
								}}
								autoFocus={isBillDateFocus}
								required={true}
								disabled={disabled}
								error={error?.bill_date}
							/>
						</div>

						<div className="col-3">
							<InputField
								label="Due Date"
								placeholder="Enter Date"
								type="date"
								autoFocus={isDueDateFocus}
								value={purchaseBillFormData.due_date}
								onKeyPress={(e) => {
									let value = e.target.value;
									const newError = { ...error };
									var date = new Date(value);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										newError.due_date = 'Due date cannot be in the past.';
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											due_date: value,
										}));
										return;
									}
									if (year.length < 4 || year.length === 4) {
										delete newError.due_date;
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											due_date: value,
										}));
									}
								}}
								onChange={(value) => {
									const newError = { ...error };
									var date = new Date(value);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										newError.due_date = 'Due date cannot be in the past.';
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											due_date: value,
										}));
										return;
									}
									if (year.length < 4 || year.length === 4) {
										delete newError.due_date;
										setError(newError);
										setPurchaseBillFormData((prev) => ({
											...prev,
											due_date: value,
										}));
									}
								}}
								error={error?.due_date}
								disabled={disabled}
								required={false}
							/>
						</div>
						<div className="col-3">
							<InputField
								label="Bill Amount"
								placeholder="Bill Amount"
								onKeyPress={(e) => handleKeyPress(e, 'bill_amount')}
								type="number"
								value={purchaseBillFormData.bill_amount}
								onChange={(val) => handleFormData('bill_amount', val)}
								disabled={disabled}
								required={true}
								error={error?.bill_amount}
							/>
						</div>
					</div>
					<div
						className="row"
						style={{ justifyContent: 'space-between' }}
					>
						<div className="col-3">
							<InputSelect
								options={paymentMethodList}
								label="Payment Method"
								placeholder="Payment Method"
								required={true}
								value={purchaseBillFormData.payment_method}
								onChange={(value) => {
									setSearchPaymentMethod(value)
									// handleFormData('payment_method', value);
								}}
								onSelect={(options) => handleFormData('payment_method', options.value)}
								error={error?.payment_method}
								onAddNew={() => setIsAddPaymentMethod(true)}
								disabled={disabled}
							/>
						</div>
						{/* <div className="col-3">
							<InputField
								label="Discount"
								placeholder="Discount"
								type="text"
								value={purchaseBillFormData.discount}
								onChange={(value) => handleFormData('discount', value)}
								disabled={disabled}
							/>
						</div>

						<div className="col-3">
							<InputField
								label="Freight"
								placeholder="Freight"
								type="text"
								value={purchaseBillFormData.freight}
								onChange={(value) => handleFormData('freight', value)}
								disabled={disabled}
							/>
						</div> */}

						<div className="col-3">
							<InputField
								label="Other Adjustment"
								placeholder="Other Adjustment"
								type="number"
								value={purchaseBillFormData.other_adjustment}
								onChange={(value) => handleFormData('other_adjustment', value)}
								disabled={disabled}
							/>
						</div>
					</div>

					{purchaseBillFormData?.e_way_bill?.e_way_bill_no ? (
						<div
							className="col-3"
							style={{ margin: '10px 0px', height: '72px' }}
						>
							<div className="col">
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<p style={{ fontSize: '14px', fontWeight: 600 }}>E-Way Bill #</p>
									<p
										style={{ color: '#0d6efd', cursor: 'pointer', fontSize: '11px' }}
										onClick={() => setIsBillModalOpen(true)}
									>
										View Details
									</p>
								</div>
								<div
									style={{
										height: '34px',
										backgroundColor: '#fcfcfc',
										border: '1px solid rgba(85, 91, 103, 0.15)',
										borderRadius: '6px',
										padding: '8px',
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<p style={{ paddingTop: '9px' }}>
										{purchaseBillFormData?.e_way_bill?.e_way_bill_no}
									</p>
									<div
										// className="add-pro"
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											width: '20px',
											height: '20px',
											backgroundColor: 'red',
											borderRadius: '100%',
											cursor: 'pointer',
										}}
										onClick={() => {
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													e_way_bill_no: '',
													challan_no: '',
													due_date: '',
													transport: '',
													cases: '',
													purchase_tax: '',
													remarks: '',
													e_way_bill_status: false,
												},
											}));
										}}
									>
										<img
											className="add-pro"
											style={{
												width: '10px',
												height: '10px',
												objectFit: 'contain',
											}}
											alt="close"
											src={closei}
										/>
									</div>
								</div>
							</div>
						</div>
					) : (
						<button
							className="d-flex align-items-center justify-content-around"
							style={{
								height: '32px',
								width: '160px',
								backgroundColor: '#3B58FF',
								marginTop: '12px',
							}}
							onClick={() => setIsBillModalOpen(true)}
						>
							<img
								src={add}
								alt=""
							/>{' '}
							Add E-Way Bill
						</button>
					)}
					<br />
				</div>
				{/* Bill Table */}
				<BillTable
					tableData={purchaseBillFormData.purchase_bill_products}
					setTableData={(data) => {
						console.log(data);
						setPurchaseBillFormData((prev) => ({
							...prev,
							purchase_bill_products: data,
						}));
						const subTotalAmount = purchaseBillFormData?.purchase_bill_products?.reduce(
							(sum, item) => Number(sum) + Number(item.amount),
							0,
						);
						let totalTax1 = purchaseBillFormData?.purchase_bill_products?.reduce(
							(sum, item) =>
								Number(sum) + (Number(item.amount ?? 0) * Number(item.product_tax1 ?? 0)) / 100,
							0,
						);
						let totalTax2 = purchaseBillFormData?.purchase_bill_products?.reduce(
							(sum, item) =>
								Number(sum) + (Number(item.amount ?? 0) * Number(item.product_tax2 ?? 0)) / 100,
							0,
						);

						setAdditionDiscountAndTax((prev) => ({
							...prev,
							taxes: Number(totalTax1) + Number(totalTax2),
						}));

						setSubTotal(Number(subTotalAmount));
					}}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setIsTableError}
				/>
			</div>
			<div
				style={{
					height: '200px',
					border: '1px solid #C3CBDC',
					backgroundColor: '#DADDEC',
					alignItems: 'center',
				}}
			>
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="TotalBillDivCredit">
						<div style={{ textAlign: 'right' }}>
							<p style={{ marginBottom: '16px', marginTop: '10px' }}>Discount</p>
							<p>Sub Total</p>
							<p>Taxes</p>
							<p>Bill Total </p>
						</div>
						<div style={{ textAlign: 'left' }}>
							<p>
								<AppendInput
									label=""
									placeholder="0"
									appendLabel="%"
									type="text"
									className="custom-append-text"
									error={error?.payment_amount}
									value={additionDiscountAndTax?.discount}
									onChange={(value) => {
										setAdditionDiscountAndTax((prev) => ({
											...prev,
											discount: Number(value),
										}));
									}}
								/>
							</p>
							<p>₹{subTotal.toFixed(2)}/-</p>
							<p>₹{additionDiscountAndTax.taxes.toFixed(2)}/-</p>
							<p>₹{calculateTotalBill().toFixed(2)}/-</p>
						</div>
					</div>
				</div>

				{/* Sidebar ka layout */}
				{isSidebarOpen && (
					<div className="sidebar">
						<div
							className="d-flex align-self-center justify-content-sm-between "
							style={{ gap: '60px' }}
						>
							<div className="VenderDetails">Product Details</div>

							<div
								className="d-flex align-self-center "
								style={{ gap: '26px', alignItems: 'center' }}
							>
								<div>
									<Link to={`/user/${suburl}/purchases-order-details/${id}`}>
										View more details
									</Link>
								</div>

								<div
									className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
									onClick={toggleSidebar}
								>
									<img
										src={BlackClose}
										alt=""
										style={{ width: '14px', cursor: 'pointer' }}
									/>
								</div>
							</div>
						</div>

						<br />

						<div
							className="d-flex"
							style={{ gap: '20px' }}
						>
							<div className="profileImage d-flex align-self-center justify-content-sm-center">
								<img
									src={ProfilePlaceHolder}
									alt=""
									style={{ width: '55px', borderRadius: '10px' }}
								/>
							</div>

							<div
								className="d-flex flex-column"
								style={{ gap: '15px' }}
							>
								<div
									style={{
										fontSize: '18px',
										fontWeight: '600',
										color: '#333',
									}}
								>
									{productDetails?.product?.product_name}
								</div>

								<div
									style={{
										fontSize: '14px',
										color: 'var(--sub, #555B67)',
									}}
								>
									<b>{productDetails?.product_packaging}</b> contains{' '}
									<b>{productDetails?.product?.contains}</b>
								</div>
							</div>
						</div>
						<br />
						<hr />

						{/* tabs  */}
						<div>
							<ul className="nav mb-4 mt-2">
								{navTabs.map((item, index) => (
									<li
										key={index}
										className="nav-item"
									>
										<a
											className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
											href="#"
											onClick={(e) => {
												e.preventDefault(); // Prevent the default anchor click behavior
												setActiveTab(item);
											}}
										>
											{item}
										</a>
									</li>
								))}
							</ul>
							<hr
								style={{
									backgroundColor: '#E3E4E5',
									boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
									marginTop: '-13px',
								}}
							/>
							<div>{renderTabContent()}</div>
						</div>
					</div>
				)}

				{isBillModalOpen && (
					<Modal
						className=" billModal"
						isOpen={isBillModalOpen}
						onClose={() => {
							setIsBillModalOpen(false);
							if (!isEWayBillStatus) {
								setPurchaseBillFormData((prev) => ({
									...prev,
									e_way_bill: {
										e_way_bill_no: '',
										challan_no: '',
										date: '',
										transport: '',
										cases: '',
										purchase_tax: '',
										remarks: '',
									},
								}));
								setIsEWayBillStatus(false);
							}
						}}
						cancelButton={() => {
							setIsBillModalOpen(false);
							if (!isEWayBillStatus) {
								setPurchaseBillFormData((prev) => ({
									...prev,
									e_way_bill: {
										e_way_bill_no: '',
										challan_no: '',
										date: '',
										transport: '',
										cases: '',
										purchase_tax: '',
										remarks: '',
									},
								}));
								setIsEWayBillStatus(false);
							}
						}}
						title={
							<div
								style={{
									fontWeight: '600',
									color: '#000000',
									fontSize: '22px',
								}}
							>
								Add E-Way Bill Details
							</div>
						}
						headerClassName="discard-header"
						bodyClassName="discard-Body"
					>
						<div>
							<div className="row">
								<div className="col">
									<InputField
										label="E-Way Bill No."
										placeholder="Enter E-Way Bill No"
										required={true}
										value={purchaseBillFormData.e_way_bill.e_way_bill_no}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													e_way_bill_no: value,
												},
											}))
										}
										autoComplete={'E-way Bill No'}
										name="E-way Bill No"
										// disabled={disabled}
										error={errorEwayBill?.e_way_bill_no}
										autoFocus={true}
									/>
								</div>
								<div className="col">
									<InputField
										label="Challan No."
										placeholder="Enter Challan No"
										autoComplete={'Challan No'}
										name="Challan No"
										type="text"
										value={purchaseBillFormData.e_way_bill.challan_no}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													challan_no: value,
												},
											}))
										}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
										error={errorEwayBill?.challan_no}
									/>
								</div>
								<div className="col">
									<InputField
										label="E-Way Bill Date"
										placeholder="Enter E-Way Bill Date"
										autoComplete={'E-Way Bill Date'}
										name="E-Way Bill Date"
										type="date"
										value={purchaseBillFormData.e_way_bill.date}
										onKeyPress={(e) => {
											let value = e.target.value;
											const newError = { ...errorEwayBill };
											var date = new Date(value);
											var currentDate = new Date();
											currentDate.setDate(currentDate.getDate() + 1);
											let year = date.getFullYear().toString();
											if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
												// if (!isUsedPastDate) {
												// 	setIsOpenPastDate(true);
												// 	newError.receive_date = 'Date cannot be in the past.';
												// 	setError(newError);
												// } else {
												delete newError.date;
												setErrorEwayBill(newError);
												// }
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											} else if (
												year.length === 4 &&
												new Date(value) > currentDate.setHours(0, 0, 0, 0)
											) {
												newError.date = 'Date cannot be in the future';
												setErrorEwayBill(newError);
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											} else {
												delete newError.date;
												setErrorEwayBill(newError);
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											}
										}}
										onChange={(value) => {
											const newError = { ...errorEwayBill };
											var date = new Date(value);
											var currentDate = new Date();
											currentDate.setDate(currentDate.getDate() + 1);
											let year = date.getFullYear().toString();
											if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
												// if (!isUsedPastDate) {
												// 	setIsOpenPastDate(true);
												// 	newError.receive_date = 'Date cannot be in the past.';
												// 	setError(newError);
												// } else {
												delete newError.date;
												setErrorEwayBill(newError);
												// }
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											} else if (
												year.length === 4 &&
												new Date(value) > currentDate.setHours(0, 0, 0, 0)
											) {
												newError.date = 'Date cannot be in the future';
												setErrorEwayBill(newError);
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											} else {
												delete newError.date;
												setErrorEwayBill(newError);
												setPurchaseBillFormData((prev) => ({
													...prev,
													e_way_bill: {
														...prev.e_way_bill,
														date: value,
													},
												}));
											}
										}}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
										error={errorEwayBill?.date}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<InputSelect
										options={transportTypeList}
										label="Transport"
										placeholder="Enter Transport Agency Name"
										required={true}
										returnInputValue={false}
										value={purchaseBillFormData.e_way_bill.transport}
										onSelect={(option) => {
											checkFormValidation('vendor_id', option.key);
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													transport: option.value,
												},
											}));
										}}
										onChange={(value) => {
											setSearchTransport(value)
											// setPurchaseBillFormData((prev) => ({
											// 	...prev,
											// 	e_way_bill: {
											// 		...prev.e_way_bill,
											// 		transport: value,
											// 	},
											// }));
										}}
										disabled={disabled}
										error={errorEwayBill?.transport}
										autoFocus={true}
										onAddNew={() => setIsTransportType(true)}
									/>
									{/* <InputField
										label="Transport"
										placeholder="Enter Transport Agency Name"
										autoComplete={'Transport'}
										name="Transport"
										required={true}
										value={purchaseBillFormData.e_way_bill.transport}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													transport: value,
												},
											}))
										}
										// disabled={disabled}
										error={errorEwayBill?.transport}
									/> */}
								</div>
								<div className="col">
									<InputField
										label="Cases"
										placeholder="Enter Number of Cases"
										autoComplete={'Cases'}
										name="Cases"
										required={true}
										value={purchaseBillFormData.e_way_bill.cases}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													cases: value,
												},
											}))
										}
										// disabled={disabled}
										error={errorEwayBill?.cases}
									/>
								</div>
								<div className="col">
									<InputField
										label="Purchase Tax"
										placeholder="Enter Purchase Tax"
										autoComplete={'Purchase Tax'}
										name="Purchase Tax"
										type="number"
										value={purchaseBillFormData.e_way_bill.purchase_tax}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													purchase_tax: value,
												},
											}))
										}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={true}
										error={errorEwayBill?.purchase_tax}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<InputField
										label="Remarks"
										autoComplete={'Remarks'}
										name="Remarks"
										placeholder="Enter Remarks"
										value={purchaseBillFormData.e_way_bill.remarks}
										onChange={(value) =>
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													...prev.e_way_bill,
													remarks: value,
												},
											}))
										}
										// autoFocus={disabled ? false : true}
										// disabled={disabled}
										required={false}
									// error={error?.date}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'start',
									gap: 12,
									marginTop: '10px',
								}}
							>
								<Button
									type="button"
									className="mt-0 add-pro bill"
									button="Save E-Way Bill"
									onClick={() => handleEwayBill()}
								/>
								<Button
									type="button"
									className="mt-0 add-pro bill2"
									button="Cancel"
									onClick={() => {
										setIsBillModalOpen(false);
										if (!isEWayBillStatus) {
											setPurchaseBillFormData((prev) => ({
												...prev,
												e_way_bill: {
													e_way_bill_no: '',
													challan_no: '',
													due_date: '',
													transport: '',
													cases: '',
													purchase_tax: '',
													remarks: '',
												},
											}));
											setIsEWayBillStatus(false);
										}
									}}
								/>
							</div>
						</div>
					</Modal>
				)}
				{isAddNewVendorModalOpen && (
					<AddVendorModal
						handleFormData={handleFormData}
						vendorList={inputSelectOption.vendorList}
						setVendorList={(value) =>
							setInputSelectOption((prev) => ({
								...prev,
								vendorList: value,
							}))
						}
						onClose={() => setIsAddNewVendorModalOpen(false)}
					/>
				)}
				{isConvetToBillOpen && (
					<ConvertToBillModal
						isConvertToBillOpen={isConvetToBillOpen}
						setIsConvertToBillOpen={setIsConvertToBillOpen}
						purchaseOrderDetails={purchaseOrderDetails}
						handleConvertToBill={handleConvertToBill}
					/>
				)}
			</div>
			{(isOpenPastDate || isOpenPBPastDate) && (
				<Modal
					className="delivery-modal"
					title="Confirm Past Date"
					isOpen={isOpenPastDate === false ? isOpenPBPastDate : isOpenPastDate}
					onClose={() => cancelUsedPastDate()}
					cancelButton={true}
				>
					<p>You are creating a transaction in the past date. Do you want to continue?</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							marginTop: '20px',
						}}
					>
						<Button
							button="Proceed"
							type="button"
							className="btn btn-primary"
							onClick={isOpenPastDate ? confirmUsedPastDate : confirmUsedPBPastDate}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => cancelUsedPastDate()}
						/>
					</div>
				</Modal>
			)}
			{isAddPaymentMethod && (
				<Modal
					className="delivery-modal"
					title="Payment Method"
					isOpen={isAddPaymentMethod}
					onClose={() => setIsAddPaymentMethod(false)}
					cancelButton={true}
				>
					<p>
						<InputField
							label="Payment Method"
							placeholder="Enter Payment Method"
							autoComplete={'Payment Method'}
							name="Payment Method"
							value={paymentMethod}
							onChange={(value) => {
								if (value !== '' && value !== null) {
									setPaymentMethod(value);
									setErrorPaymentMethod('');
								} else {
									setPaymentMethod('');
									setErrorPaymentMethod('Please enter payment method');
								}
							}}
							required={true}
							error={errorPaymentMethod}
						/>
					</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginTop: '20px',
						}}
					>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => {
								setPaymentMethod("")
								setIsAddPaymentMethod(false)
							}}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="OK"
							type="button"
							className="btn btn-primary"
							onClick={handleAddPaymentMethod}
						/>
					</div>
				</Modal>
			)}
			{isTransportType && (
				<Modal
					className="delivery-modal"
					title="Transport"
					isOpen={isTransportType}
					onClose={() => setIsTransportType(false)}
					cancelButton={true}
				>
					<p>
						<InputField
							label="Transport Name"
							placeholder="Enter Transport Name"
							autoComplete={'Transport'}
							name="Transport"
							value={transportType}
							onChange={(value) => {
								if (value !== '' && value !== null) {
									setTransportType(value);
									setErrorTransportType('');
								} else {
									setTransportType('');
									setErrorTransportType('Please enter transport type');
								}
							}}
							required={true}
							error={errorTransportType}
						/>
					</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-end',
							marginTop: '20px',
						}}
					>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => {
								setIsTransportType(false)
							}}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="OK"
							type="button"
							className="btn btn-primary"
							onClick={handleTransportType}
						/>
					</div>
				</Modal>
			)}
			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={purchaseBillFormData.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>
		</div>
	);
};

export default CreatePurchaseBill;
