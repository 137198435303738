import React, { useState } from 'react';
import './AddEmailModal.scss';
import { toast } from 'react-toastify';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputField from '../../../../component/form/FormInput';
import Button from '../../../../component/ui/Button';
import { ContactSolutation } from '../../../../utils/dropsdown';
import { addVendorContactPerson } from '../../../../services/vendor';

function AddEmailModal({
	setIsAddContactModal,
	vendor_id,
	communicationEmail,
	setCommunicationEmail,
}) {
	const [disabled, setDisabled] = useState(false);
	const [error, setError] = useState({});
	const [formData, setFormData] = useState({
		salutation: '',
		first_name: '',
		last_name: '',
		email: '',
		work_phone: '',
		mobile: '',
		department: '',
	});

	const handleInputChange = (key, value) => {
		const updatedData = { ...formData, [key]: value };
		setFormData(updatedData);
		if (value) {
			delete error[key];
		}
	};

	const addContactPerson = async () => {
		try {
			const data = await addVendorContactPerson(formData, vendor_id);
			setCommunicationEmail([
				...communicationEmail,
				{ isSelected: false, email: data?.data?.email },
			]);
			setIsAddContactModal(false);
		} catch (error) {
			toast.error('Something went wrong');
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			addContactPerson();
		}
	};

	return (
		<div
			className="add-contact-person"
			onKeyDown={handleKeyDown}
		>
			<div className="row">
				<div className="col">
					<InputSelect
						options={ContactSolutation || []}
						label="Salutation"
						placeholder="Salutation"
						className="vendorContact-salutation"
						onChange={(value) => handleInputChange('salutation', value)}
						value={formData.salutation}
						disabled={disabled}
						error={error?.salutation}
						autoFocus={true}
					/>
				</div>
				<div className="col">
					<InputField
						label="First Name"
						placeholder="First Name"
						type="text"
						value={formData.first_name}
						onChange={(value) => handleInputChange('first_name', value)}
						disabled={disabled}
						error={error?.first_name}
						name={`new-first-name-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="new-first-name"
					/>
				</div>
				<div className="col">
					<InputField
						label="Last Name"
						placeholder="Last Name"
						type="text"
						name={`new-last-name-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="new-last-name"
						value={formData.last_name}
						onChange={(value) => handleInputChange('last_name', value)}
						disabled={disabled}
						error={error?.last_name}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col">
					<InputField
						label="Email"
						placeholder="Email"
						type="text"
						name={`email-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="new-email"
						value={formData.email}
						onChange={(value) => handleInputChange('email', value)}
						disabled={disabled}
						error={error?.email}
					/>
				</div>
				<div className="col">
					<InputField
						label="Phone(Work)"
						placeholder="Phone No."
						type="text"
						name={`phone-work-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="phone-work"
						value={formData.work_phone}
						onChange={(value) => handleInputChange('work_phone', value)}
						disabled={disabled}
						error={error?.work_phone}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<InputField
						label="Phone(Mobile)"
						placeholder="Phone no."
						type="text"
						name={`phone-no-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="phone-no"
						value={formData.mobile}
						onChange={(value) => handleInputChange('mobile', value)}
						disabled={disabled}
						error={error?.mobile}
					/>
				</div>
				<div className="col">
					<InputField
						label="Department"
						placeholder="Department"
						type="text"
						name={`deparment-${Math.random().toString(36).substr(2, 9)}`}
						autoComplete="deparment"
						value={formData.department}
						onChange={(value) => handleInputChange('department', value)}
						disabled={disabled}
						error={error?.department}
					/>
				</div>
			</div>
			<br />

			<span className="">
				<Button
					type="button"
					className="btn-primary px-5 w-auto"
					button="Add"
					onClick={addContactPerson}
				/>
			</span>
		</div>
	);
}

export default AddEmailModal;
