export const updateAdvanceFilter = (payload) => {
	return {
		type: 'SET_ADVANCE_FILTER',
		payload: payload,
	};
};

export const clearAdvanceFilter = () => {
	return {
		type: 'SET_ADVANCE_FILTER',
	};
};
