import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';
import axiosInstance from '../../../services/axios';

export const USER_INFO_GET_DONE = 'USER_INFO_GET_DONE';
export const USER_INFO_GET_FAIL = 'USER_INFO_GET_FAIL';

const USER_INFO_API_URL = `${APP_API_URL}/user/info`;

export const fetchUserInfo = () => {
	const token = getLocalStorageData();
	return (dispatch) => {
		return axiosInstance
			.get(USER_INFO_API_URL, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token?.token}`,
				},
			})
			.then((res) => {
				if (res?.data?.success) {
					dispatch({
						type: USER_INFO_GET_DONE,
						payload: res.data,
					});
					// toast.success("User info loaded successfully!");
					return res?.data;
				} else {
					dispatch({
						type: USER_INFO_GET_FAIL,
						payload: res,
					});
					// toast.error("Failed to load user info");
					return res;
				}
			})
			.catch((err) => {
				dispatch({
					type: USER_INFO_GET_FAIL,
					payload: err,
				});
				// toast.error("Failed to load user info");
				return err;
			});
	};
};
