import { APP_API_URL } from '../../../utils/xhr-instance';
import { getLocalStorageData } from '../../../utils/helper';
import { toast } from 'react-toastify';
import axiosInstance from '../../../services/axios';

export const EXPORT_TABLE_DONE = 'EXPORT_TABLE_DONE';
export const EXPORT_TABLE_FAIL = 'EXPORT_TABLE_FAIL';

export const EXPORT_TABLE_API_URL = `${APP_API_URL}/app/export-table`;

export const ExportTable = async (param) => {
	let API_URL = `${EXPORT_TABLE_API_URL}?type=${param}`;
	const token = getLocalStorageData();
	try {
		const response = await axiosInstance.get(API_URL, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token?.token}`,
			},
			responseType: 'blob',
		});
		const blob = response.data;
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `${param}_table.xlsx`;
		document.body.appendChild(a);
		a.click();
		a.remove();
		toast.success('Downloaded successfully');
	} catch (error) {
		toast.error('Error! unable to  downloading the file.');
	}
};
