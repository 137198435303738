import blueCircle from '../../assets/image/blue_circle.png';
import greyCircle from '../../assets/image/grey_circle.png';
import greyBlueCircle from '../../assets/image/partially_blue_grey_circle.png';

export const PurchaseReceivedColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PR#',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const prIdAndStatus = params.data?.PR || ''; // Fallback to an empty string if undefined
			const [prId, status] = prIdAndStatus.split('|');

			if (status) {
				return (
					<p>
						{prId}
						<span
							style={{
								backgroundColor: '#718096',
								color: 'white',
								borderRadius: '20px',
								padding: '2px 10px',
								paddingBottom: '3px',
								marginLeft: '10px',
								fontSize: '11px',
							}}
						>
							{status}
						</span>
					</p>
				);
			}
			return <p>{prId}</p>;
		},
	},
	{
		field: 'PO#',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const POID = params.data?.PO || ''; // Fallback to an empty string if undefined
			return <p>{POID}</p>;
		},
	},
	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'RECEIVED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.RECEIVED || ''; // Fallback to an empty string if undefined

			if (status) {
				if (status === '0') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '1') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={greyBlueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				} else if (status === '2') {
					return (
						<div style={{ textAlign: 'center' }}>
							<img
								src={blueCircle}
								alt="status"
								width="24"
								height="24"
							/>
						</div>
					);
				}
			} else {
				return <p>{status}</p>;
			}
		},
	},
	{
		field: 'BILLED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const { is_billed, is_partially_billed } = JSON.parse(params?.data.BILLED);

			if (is_billed) {
				return (
					<div style={{ textAlign: 'center' }}>
						<img
							src={blueCircle}
							alt="status"
							width="24"
							height="24"
						/>
					</div>
				);
			}

			if (is_partially_billed) {
				return (
					<div style={{ textAlign: 'center' }}>
						<img
							src={greyBlueCircle}
							alt="status"
							width="24"
							height="24"
						/>
					</div>
				);
			}

			return (
				<div style={{ textAlign: 'center' }}>
					<img
						src={greyCircle}
						alt="status"
						width="24"
						height="24"
					/>
				</div>
			);
		},
	},
];

export const PurchaseOrderColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 110,
		maxWidth: 110,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'PO#',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 140,
		maxWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const PO = JSON.parse(params.data['PO#']);
			return (
				<p>
					{PO?.po_number}
					{PO?.is_draft === 1 && (
						<span
							style={{
								backgroundColor: '#718096',
								color: 'white',
								borderRadius: '20px',
								padding: '2px 10px',
								paddingBottom: '3px',
								marginLeft: '10px',
								fontSize: '11px',
							}}
						>
							Draft
						</span>
					)}
				</p>
			);
		},
	},
	{
		field: 'VENDOR NAME',
		suppressSizeToFit: true,
		headerClass: 'HeaderBg',
		flex: 1,
		minWidth: 150,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		maxWidth: 200,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.STATUS || ''; // Get the status value
			let statusCode = '';

			// Set the image based on status value
			switch (status) {
				case 'Received':
					statusCode = '#6AA247';
					break;
				case 'Pending':
					statusCode = '#FFC008';
					break;
				case 'Pending Approval':
					statusCode = '#3B58FF';
					break;
				case 'Approved':
					statusCode = '#3B58FF';
					break;
				case 'Issued':
					statusCode = '#3B58FF';
					break;
				case 'Closed':
					statusCode = '#989C95';
					break;
				case 'Deleted':
					statusCode = '#D30000';
					break;
				case 'Partially Received':
					statusCode = '#FFA500';
					break;
				case 'In-Transit':
					statusCode = '#11caf0';
					break;
				default:
					statusCode = '#DC3545';
					break;
			}

			// Return the image inside a flex container for centering
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '24px',
						fontSize: '12px',
						borderRadius: '5px',
						fontWeight: status === 'Draft' ? '' : 'bold',
						margin: '8px 0px',
						color: status === 'Draft' ? 'black' : '#ffffff',
						backgroundColor: status === 'Draft' ? '' : statusCode,
					}}
				>
					{status === 'Draft' ? 'N/A' : status}
				</div>
			);
		},
	},
	{
		field: 'RECEIVED',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 110,
		maxWidth: 120,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const received = JSON.parse(params.data?.RECEIVED) || '';
			const { is_received, is_partially_received, status } = received;
			// Logic for rendering circles
			if (
				is_received === 1 &&
				status !== 'Pending Approval' &&
				status !== 'In-Transit' &&
				status !== 'Cancelled' &&
				status !== 'Issued'
			) {
				// Full Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={blueCircle}
						/>
					</div>
				);
			}

			if (
				is_partially_received === 1 &&
				status !== 'Pending Approval' &&
				status !== 'In-Transit' &&
				status !== 'Cancelled' &&
				status !== 'Issued'
			) {
				// Half Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={greyBlueCircle}
						/>
					</div>
				);
			}
			// if (
			// 	((status === 'Pending Approval' ||
			// 		status === 'In-Transit' ||
			// 		status === 'Cancelled' ||
			// 		status === 'Issued') &&
			// 		(is_partially_received === 1 ||
			// 			is_received === 1 ||
			// 			(is_partially_received === 1 && is_received === 1))) ||
			// 	status === 'Pending Approval' ||
			// 	status === 'In-Transit' ||
			// 	status === 'Cancelled' ||
			// 	status === 'Issued'
			// ) {
			// Empty Circle
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100%',
					}}
				>
					<img
						alt="is_receive?"
						src={greyCircle}
					/>
				</div>
			);
			// }
		},
	},
	{
		field: 'BILLED',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 110,
		maxWidth: 120,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const billed = JSON.parse(params.data?.BILLED) || '';
			const { is_billed, is_partially_billed } = billed;
			// Logic for rendering circles
			if (is_billed === 1) {
				// Full Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={blueCircle}
						/>
					</div>
				);
			}

			if (is_partially_billed === 1) {
				// Half Circle
				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
						}}
					>
						<img
							alt="is_receive?"
							src={greyBlueCircle}
						/>
					</div>
				);
			}

			// Empty Circle
			return (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: '100%',
					}}
				>
					<img
						alt="is_receive?"
						src={greyCircle}
					/>
				</div>
			);
		},
	},
	{
		field: 'Expected By',
		headerClass: 'HeaderBg left-align',
		suppressSizeToFit: true,
		minWidth: 140,
		maxWidth: 140,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const convertToBillColumn = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'PURCHASE ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'ORDER DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellClass: 'left-align',
	},
];

export const Billcolumn = [
	{
		field: 'BILL#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];

export const Ordercolumn = [
	{
		field: 'ORDER#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 50,
		flex: 1,
		cellStyle: { color: '#3B58FF' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
		cellStyle: { color: '#555B67' },
		headerStyle: { color: '#555B67' },
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 200,
		flex: 1,
	},
	{
		field: 'DUE DATE',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'AMOUNT',
		suppressSizeToFit: true,
		minWidth: 40,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
	{
		field: 'BALANCE DUE',
		suppressSizeToFit: true,
		minWidth: 70,
		flex: 1,
		cellClass: 'right-align',
		headerClass: 'HeaderBg right-align',
	},
];
