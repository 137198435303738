import React from 'react';
import NoteTable from './components/note_table';

const CreditNoteTable = ({
	tableData,
	setTableData,
	disabled,
	paramId,
	tableError,
	setIsTableErrorShow,
}) => {
	const handleTableChange = (newData) => {
		setTableData(newData);
	};

	const columns = [
		{ name: 'product_id', label: 'Product Name', type: 'product_name' },
		{ name: 'batch_no', label: 'Batch Number', type: 'batch_no' },
		{ name: 'exp_date', label: 'Exp', type: 'date' },
		{ name: 'mfg_date', label: 'Mfg', type: 'date' },
		{ name: 'mrp', label: 'MRP', type: 'text' },
		{ name: 'quantity', label: 'Quantity', type: 'text' },
		{ name: 'rate', label: 'Rate', type: 'text' },
		{ name: 'tax', label: 'Tax', type: 'text' },
		{ name: 'amount', label: 'Amount', type: 'text' },
	];

	const initialRows = [
		{
			product_id: null,
			batch_no: '',
			exp_date: '',
			mfg_date: '',
			mrp: 0,
			quantity: 0,
			rate: 0,
			tax: 0,
			amount: 0,
		},
	];

	return (
		<div
			className=" max-w-1297"
			style={{ padding: '0rem 2rem' }}
		>
			{tableData && (
				<NoteTable
					initialRows={initialRows}
					columns={columns}
					onChange={handleTableChange}
					value={tableData}
					disabled={disabled}
					newRowgenerate={'Quantity Adjusted'}
					returnInputvalue={false}
					paramId={paramId}
					tableError={tableError}
					setIsTableErrorShow={setIsTableErrorShow}
				/>
			)}
		</div>
	);
};

export default CreditNoteTable;
