import React, { useEffect, useRef, useState } from 'react';
import InputSelect from '../../../../component/inputSelect/InputSelect';
import InputField from '../../../../component/form/FormInput';
import { fetchVendors } from '../../../../services/vendor';
import OrderTable from '../../OrderTable';
import { toast } from 'react-toastify';
import tooltipIcon from '../../../../assets/image/tooltip_vendor name.png';
import OpenPOAlert from '../../../../assets/image/Open PO Alert.svg';
import settingIcon from '../../../../assets/image/settings2.svg';
import EmailCommunication from '../EmailCommunication/EmailCommunication';
import {
	addDeliveryMethod,
	fetchPurchaseOrderNumber,
	fetchPurchaseOrders,
	getDeliveryMethod,
} from '../../../../services/purchaseOrder';
import Modal from '../../../../component/modal/Modal';
import Button from '../../../../component/ui/Button';
import Configure from '../../../../component/Configure/Configure';
import AddVendorModal from '../../AddNewVendor';
import VendorDetailsDrawer from '../VendorDetailsDrawer';
import DatePicker from 'react-datepicker';

function PurchaseOrderForm({
	id,
	formData,
	handleFormData,
	handleSavePurchaseOrder,
	disabled,
	paramId,
	tableError,
	error,
	setErrorCells,
	errorCells,
	setError,
	setIsTableErrorShow,
}) {
	// const [isVendorFocus, setIsVendorFocus] = useState(true);
	const [vendorDetails, setVendorDetails] = useState(null);
	const [deliveryMethodList, setDeliveryMethodList] = useState([]);
	const [vendorList, setVendorList] = useState([]);
	const [vendorParams, setVendorParams] = useState({
		status: 1,
		filterBy: null,
		filterValue: null,
	});
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
	});
	const [searchDeliveryMethod, setSearchDeliveryMethod] = useState(formData?.delivery_method);
	const [isAddNewDeliveryMethodOpen, setIsAddNewDeliveryMethodOpen] = useState(false);
	const [addNewDeliveryMethod, setAddNewDeliveryMethod] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [isConfigurePurchaseOrderModalOpen, setIsConfigurePurchaseOrderModalOpen] = useState(false);
	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const [isAutoGeneratedPurchaseOrder, setIsAutoGeneratedPurchaseOrder] = useState(true);
	const [forceRender, setForceRender] = useState(false);
	const [isOpenPastDate, setIsOpenPastDate] = useState(false);
	const [isUsedPastDate, setIsUsedPastDate] = useState(false);
	const today = new Date();
	const formattedDate = today.toISOString().split('T')[0];
	const [currentDate, setCurrentDate] = useState(formattedDate);
	const [communicationEmail, setCommunicationEmail] = useState([]);
	const [isDeliveryAddressFocus, setIsDeliveryAddressFocus] = useState(false);

	function triggerForceRender() {
		setForceRender(!forceRender); // Toggle the state to force re-render
	}

	const confirmUsedPastDate = () => {
		const newError = { ...error };
		delete newError.date;
		setError(newError);
		setIsUsedPastDate(true);
		setIsOpenPastDate(false);
		setIsDeliveryAddressFocus(true);
	};

	const cancelUsedPastDate = () => {
		setIsOpenPastDate(false);
		setIsDeliveryAddressFocus(true);
	};

	const checkFormValidation = (type, value) => {
		let newError = { ...error };
		if (value) {
			if (type === 'vendor_id' && value !== null && value !== '') {
				delete newError.vendor_id;
			}
			if (type === 'purchase_order_number' && value !== '' && value !== null) {
				delete newError.purchase_order_number;
			}
			if (type === 'date') {
				delete newError.date;
			}
		} else {
			if (type === 'vendor_id' && (value === null || value === '')) {
				newError.vendor_id = 'Please select a valid vendor from the list.';
			}
			if (type === 'purchase_order_number' && (value === '' || value === null)) {
				newError.purchase_order_number = 'Please enter purchase order number in the field';
			}
			if (type === 'date' && (value === '' || value === null)) {
				newError.date = 'Please enter date in the field';
			}
		}
		setError(newError);
	};

	const handleKeyDown = (name, event) => {
		let { value } = event.target;
		console.log(value);
		if (event.key === 'Enter' && event.key === 'Tab') {
			handleFormData(name, value);
			checkFormValidation(name, value);
		}
	};

	//TODO: Add Delivery method
	//TODO: Create new delivery method set to form data and into deliveryMethod list and auto select to delivery method
	async function handleAddNewDeliveryMethodFrom() {
		const body = {
			method_name: addNewDeliveryMethod,
		};

		const deliveryMethod = await addDeliveryMethod(body);
		const updatedDeliveryMethodList = [
			{ key: deliveryMethod?.method_name, value: deliveryMethod?.method_name },
			...deliveryMethodList,
		];
		setDeliveryMethodList(updatedDeliveryMethodList);
		setIsAddNewDeliveryMethodOpen(false);
		setAddNewDeliveryMethod('');
		handleFormData('delivery_method', deliveryMethod?.method_name);
		triggerForceRender();
	}

	// TODO: Search Vendor with api call,
	async function handleFetchSearchVendor() {
		try {
			const data = await fetchVendors(vendorParams);

			const vendorOptionList = data.vendors.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			if (formData.vendor_id) {
				const isVendorFound = vendorOptionList.find((vendor) => vendor.key === formData.vendor_id);
				if (!isVendorFound) {
					setVendorParams((prev) => ({
						...prev,
						vendor: {
							filterBy: 'vendor_id',
							filterValue: Number(formData.vendor_id),
						},
					}));
				}
			} else {
				setInputSelectOption((prev) => ({
					vendorList: vendorOptionList,
				}));
			}
			setVendorDetails(data.vendors);
		} catch (error) {
			console.log(error);
		}
	}
	// TODO: Search Delivery method with api call,
	async function handleSearchDeliveryMethod() {
		try {
			const params = {
				filterBy: 'method_name',
				filterValue: searchDeliveryMethod,
			};

			const data = await getDeliveryMethod(params);

			const deliveryMethodOptionList = data.map((delivery) => {
				return {
					key: delivery?.method_name,
					value: delivery?.method_name,
				};
			});
			setDeliveryMethodList(deliveryMethodOptionList);
		} catch (error) {
			toast.error('Something went wrong while searching for delivery method!');
		}
	}

	async function handlePurchaseOrderNumber() {
		try {
			const purchaseOrderNumber = await fetchPurchaseOrderNumber();
			handleFormData('purchase_order_number', purchaseOrderNumber);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleFetchPurchaseOrdersByVendorId() {
		try {
			if (formData?.vendor_id === null || formData?.vendor_id === '') return;

			const { vendors } = await fetchVendors({
				filterBy: 'vendor_id',
				filterValue: formData.vendor_id,
			});

			const vendor = vendors[0];

			if (vendor?.contact_person) {
				// Extract emails from contact_person array
				const emails = vendor.contact_person.reduce((acc, contact) => {
					if (contact?.email) {
						acc.push(contact.email);
					}
					return acc;
				}, []);

				// Update form data with extracted emails
				handleFormData('email_communication', emails);

				if (emails.length > 0) {
					const mappedEmails = emails.map((email) => ({
						isSelected: false,
						email,
					}));

					setCommunicationEmail(mappedEmails);
				}
			}
			setVendorDetails(vendor);

			const isVendorPresent = vendorList.find((v) => v.vendor_id === vendor?.vendor_id);

			if (!isVendorPresent) {
				const updateVendorList = [
					{ key: vendor?.vendor_id, value: vendor?.vendor_name },
					...vendorList,
				];
				setVendorList(updateVendorList);
			}
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleFetchVendorList() {
		try {
			const { vendors } = await fetchVendors(vendorParams);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			setVendorList(vendorOptionList);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: First fetch vendor list
	useEffect(() => {
		handleFetchVendorList();
		handleSearchDeliveryMethod();
		if (!id) {
			handlePurchaseOrderNumber();
		}
	}, []);

	// TODO: Second fetch vendor details with Purchase Orders
	useEffect(() => {
		if (formData.vendor_id || id) {
			handleFetchPurchaseOrdersByVendorId();
		}
	}, [formData.vendor_id, id]);

	useEffect(() => {
		if (vendorParams) {
			handleFetchSearchVendor();
		}

		if (searchDeliveryMethod) {
			handleSearchDeliveryMethod();
		}
	}, [vendorParams, searchDeliveryMethod]);

	// useEffect(() => {
	// 	function handleSetIsVendorFocus() {
	// 		setIsVendorFocus(true);
	// 	}
	// 	handleSetIsVendorFocus();
	// }, []);

	return (
		<div>
			<div
				className="max-w-1297"
				style={{ padding: '1rem 2rem 0' }}
			>
				<div className="row">
					<div className="col-6">
						<InputSelect
							options={inputSelectOption.vendorList}
							label="Vendor Name"
							placeholder="Vendor Name"
							required={true}
							// onBlurCb={(value) => {
							// 	console.log('Blur ' + value);
							// 	if (value === '' || value === null) {
							// 		checkFormValidation('vendor_id', '');
							// 		handleFormData('vendor_id', '');
							// 	}
							// }}
							onFocusCb={() => {
								if (inputSelectOption.vendorList.length === 0) {
									setVendorParams((prev) => ({
										...prev,
										filterBy: null,
										value: null,
									}));
								}
								handleFetchVendorList();
							}}
							onKeyDown={(e) => handleKeyDown('vendor_id', e)}
							error={error?.vendor_id}
							value={formData?.vendor_id}
							returnInputValue={false}
							onChange={(value) => {
								console.log('Value ' + value);
								if (value === '') {
									checkFormValidation('vendor_id', '');
									handleFormData('vendor_id', '');
									handleFormData('email_communication', []);
									setCommunicationEmail([]);
									handleFetchVendorList();
								} else {
									setVendorParams((prev) => ({
										...prev,
										filterBy: 'vendor_name',
										filterValue: value,
									}));
								}
							}}
							onSelect={(option) => {
								handleFormData('vendor_id', option.key);
								checkFormValidation('vendor_id', option.key);
							}}
							autoFocus={true}
							onAddNew={() => setIsAddNewVendorModalOpen(true)}
							className="labelClass"
							leftIcons={
								formData?.vendor_id && [
									{
										src: OpenPOAlert,
										alt: 'showVendorDetails',
										onClick: () => {
											setVendorDetailsDrawerActiveOpenTab('Open POs');
											setIsVendorDetailsDrawerOpen(true);
										},
									},
									{
										src: tooltipIcon, // Image 2 path
										alt: 'Icon 2',
										onClick: () => {
											setVendorDetailsDrawerActiveOpenTab('Details');
											setIsVendorDetailsDrawerOpen(true);
										},
									},
								]
							}
						/>
					</div>
					<div className="col-3">
						<InputField
							label="Purchase Order #"
							placeholder="Purchase Order Number"
							type="text"
							required={true}
							error={error?.purchase_order_number}
							value={formData.purchase_order_number}
							onChange={(value) => {
								handleFormData('purchase_order_number', value);
								checkFormValidation('purchase_order_number', value);
							}}
							disabled={isAutoGeneratedPurchaseOrder}
							rightIcon={{
								src: settingIcon,
								alt: 'Purchase order configure icon',
								onClick: () => setIsConfigurePurchaseOrderModalOpen(true),
							}}
						/>
					</div>
					<div className="col-3">
						<InputField
							label="Date"
							placeholder="Enter Date"
							type="date"
							required={true}
							error={error?.date}
							value={formData.date}
							onBlur={(e) => {
								var value = e.target.value;
								const newError = { ...error };
								var date = new Date(value);
								var currentDate = new Date();
								currentDate.setDate(currentDate.getDate() + 1);
								let year = date.getFullYear().toString();
								if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									if (!isUsedPastDate) {
										setIsOpenPastDate(true);
										newError.date = 'Date cannot be in the past.';
										setError(newError);
									} else {
										delete newError.date;
										setError(newError);
									}
									handleFormData('date', value);
								} else if (
									year.length === 4 &&
									new Date(value) > currentDate.setHours(0, 0, 0, 0)
								) {
									setIsOpenPastDate(false);
									newError.date = 'Date cannot be in the future';
									setError(newError);
									handleFormData('date', value);
								} else {
									setIsOpenPastDate(false);
									delete newError.date;
									setError(newError);
									handleFormData('date', value);
								}
							}}
							onChange={(value) => {
								// const newError = { ...error };
								// var date = new Date(value);
								// var currentDate = new Date();
								// currentDate.setDate(currentDate.getDate() + 1);
								// let year = date.getFullYear().toString();
								// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
								// 	if (!isUsedPastDate) {
								// 		setIsOpenPastDate(true);
								// 		newError.date = 'Date cannot be in the past.';
								// 		setError(newError);
								// 	} else {
								// 		delete newError.date;
								// 		setError(newError);
								// 	}
								// 	handleFormData('date', value);
								// } else if (
								// 	year.length === 4 &&
								// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
								// ) {
								// 	newError.date = 'Date cannot be in the future';
								// 	setError(newError);
								// 	handleFormData('date', value);
								// } else {
								// 	setIsOpenPastDate(false);
								// 	delete newError.date;
								// 	setError(newError);
								// }
								console.log({ value });
								handleFormData('date', value);
							}}
							max={currentDate}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-7">
						<InputField
							autoFocus={isDeliveryAddressFocus}
							label="Delivery Address"
							placeholder="Delivery Address"
							onKeyPress={(e) => handleKeyDown('delivery_address', e)}
							type="text"
							error={error?.delivery_address}
							returnInputValue={true}
							value={formData.delivery_address}
							onChange={(value) => handleFormData('delivery_address', value)}
						/>
					</div>

					<div className="col-5">
						<InputField
							label="Expected delivery Date"
							placeholder="expected delivery Date"
							type="date"
							min={currentDate}
							error={error?.expected_delivery_date}
							value={formData.expected_delivery_date}
							onBlur={(e) => {
								const value = e.target.value;
								const newError = { ...error };
								const selectedDate = new Date(value);
								const today = new Date();
								today.setHours(0, 0, 0, 0); // Reset time to midnight for comparison

								// Check if the date is in the past
								if (value && selectedDate < today) {
									newError.expected_delivery_date = 'Expected Delivery Date cannot be in the past.';
									setError(newError);
								} else {
									delete newError.expected_delivery_date;
									setError(newError);
								}
							}}
							onChange={(value) => {
								handleFormData('expected_delivery_date', value);
							}}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<InputField
							label="Reference #"
							placeholder="Reference #"
							type="text"
							error={error?.remarks}
							value={formData.remarks}
							onChange={(value) => handleFormData('remarks', value)}
						/>
					</div>
					<div className="col-6">
						<InputSelect
							options={deliveryMethodList}
							onAddNew={() => setIsAddNewDeliveryMethodOpen(true)}
							label="Delivery Methods"
							placeholder="Delivery Methods"
							error={error?.delivery_method}
							returnInputValue={false}
							value={formData.delivery_method}
							onChange={(value) => setSearchDeliveryMethod(value)}
							onSelect={(value) => handleFormData('delivery_method', value.value)}
						/>
					</div>
				</div>
			</div>

			{/* Purchase order Table */}
			<OrderTable
				id={id}
				tableData={formData.purchase_order_products}
				setTableData={handleFormData}
				handleSavePurchaseOrder={handleSavePurchaseOrder}
				disabled={disabled}
				setErrorCells={setErrorCells}
				errorCells={errorCells}
				paramId={paramId}
				tableError={tableError}
				setIsTableErrorShow={setIsTableErrorShow}
			/>

			<EmailCommunication
				formData={formData}
				communicationEmail={communicationEmail}
				setCommunicationEmail={setCommunicationEmail}
				handleFormData={handleFormData}
			/>

			{isAddNewDeliveryMethodOpen && (
				<Modal
					className="delivery-modal"
					title="Add New Delivery Method"
					isOpen={isAddNewDeliveryMethodOpen}
					onClose={() => setIsAddNewDeliveryMethodOpen(false)}
					cancelButton={true}
				>
					<div>
						<InputField
							placeholder="Add New Delivery method"
							value={addNewDeliveryMethod}
							onChange={(value) => {
								setAddNewDeliveryMethod(value);
							}}
						/>
						<br />
						<Button
							button="Add"
							type="button"
							className="btn btn-primary"
							onClick={handleAddNewDeliveryMethodFrom}
						/>
					</div>
				</Modal>
			)}
			{isOpenPastDate && (
				<Modal
					className="delivery-modal"
					title="Confirm Past Date"
					isOpen={isOpenPastDate}
					onClose={() => cancelUsedPastDate()}
					cancelButton={true}
				>
					<p>You are creating a transaction in the past date. Do you want to continue?</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							marginTop: '20px',
						}}
					>
						<Button
							button="Proceed"
							type="button"
							className="btn btn-primary"
							onClick={confirmUsedPastDate}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => cancelUsedPastDate()}
						/>
					</div>
				</Modal>
			)}

			{isConfigurePurchaseOrderModalOpen && (
				<Configure
					formData={formData}
					handleFormData={handleFormData}
					isAutoGeneratedPurchaseOrder={isAutoGeneratedPurchaseOrder}
					setIsAutoGeneratedPurchaseOrder={setIsAutoGeneratedPurchaseOrder}
					isConfigurePurchaseOrderModalOpen={isConfigurePurchaseOrderModalOpen}
					setIsConfigurePurchaseOrderModalOpen={setIsConfigurePurchaseOrderModalOpen}
				/>
			)}

			{isAddNewVendorModalOpen && (
				<AddVendorModal
					handleFormData={handleFormData}
					vendorList={vendorList}
					setVendorList={(value) => setVendorList(value)}
					onClose={() => setIsAddNewVendorModalOpen(false)}
				/>
			)}

			{
				<VendorDetailsDrawer
					isOpen={isVendorDetailsDrawerOpen}
					onClose={() => setIsVendorDetailsDrawerOpen(false)}
					vendor_id={formData.vendor_id}
					vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
				/>
			}
		</div>
	);
}

export default PurchaseOrderForm;
