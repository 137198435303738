import React from 'react';
import './index.scss';
import { getDate } from '../../../utils/dateUtils';

function PdfView({ printPdfRef, purchaseOrderDetails }) {
	const {
		is_received,
		is_billed,
		vendor,
		purchase_order_number,
		date,
		organisation,
		delivery_address,
		purchase_order_products,
	} = purchaseOrderDetails || {};
	return (
		<div ref={printPdfRef}>
			<div className="invoice-status">
				<div className="received-s">
					Received Status:{' '}
					<span style={{ color: purchaseOrderDetails.status === "Draft" ? "black" : is_received ? '#6AA247' : 'red' }}>{purchaseOrderDetails.status ? "N/A" : is_received ? 'Receied' : 'Not Received'}</span>
				</div>
				<span>|</span>
				<div className="bill-s">
					Bill Status: <span style={{ color: purchaseOrderDetails.status === "Draft" ? "black" : is_billed ? '#6AA247' : 'red' }}>{purchaseOrderDetails.status ? "N/A" : is_billed ? 'Billed' : 'Pending'}</span>
				</div>
			</div>
			<div style={{ backgroundColor: 'white', padding: '20px' }}>
				<div
					style={{
						border: '0.1px solid gray',
						padding: '20px',
					}}
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<h1 style={{ fontWeight: 'bold' }}>{vendor?.vendor_name}</h1>
							<p style={{ color: '#555B67' }}>
								{vendor?.address}
								<div className="state">Rajasthan</div>
								<div className="country">India</div>
								<div className="email-id">
									{vendor?.phone_mobile} | {vendor?.email}
								</div>
							</p>
						</div>

						<div className="bill-info">
							<h2>Purchase order</h2>
							<p style={{ color: '#555B67', fontWeight: 'bold' }}># {purchase_order_number}</p>
							<p
								className="label"
								style={{ color: '#555B67', fontWeight: 'bold' }}
							>
								Order Date: <span className="date"> {getDate(date)}</span>
							</p>
						</div>
					</div>

					<div className="delivey-details">
						<div className="address-to">
							<p className="label">Vendor Address</p>
							<p className="address">{organisation?.organisation_name}</p>
							<p>DL. No.: {vendor?.drug_license_number || 'N/A'}</p>
							<p>GSTIN: {vendor?.vendor_other_details?.gst || 'N/A'}</p>
						</div>

						<div className="deliver-to">
							<p
								className="label"
								style={{ fontSize: '16px', fontWeight: '600' }}
							>
								Deliver To
							</p>
							<p
								className="address"
								style={{ color: '#555B67' }}
							>
								{delivery_address}
							</p>
						</div>
					</div>

					<table className="items">
						<thead>
							<tr>
								<th>#</th>
								<th>ITEMS AND DESCTIPTION</th>
								<th>PackaGING</th>
								<th>ORDERED</th>
							</tr>
						</thead>
						<tbody>
							{purchase_order_products?.map((prod, index) => (
								<tr key={prod.purchase_order_product_id}>
									<td>{index + 1}</td>
									<td style={{ color: 'blue' }}>{prod.product.product_name}</td>
									<td>{prod?.product_packaging}</td>
									<td>{prod?.order_quantity}</td>
								</tr>
							))}
						</tbody>
					</table>

					<div
						className="signature"
						style={{ textAlign: 'start', color: '#555B67' }}
					>
						<p>Authorized Signature--------------------</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PdfView;
