import React, { useEffect, useState } from 'react';
import arrow from '../../../assets/image/arrow.svg';
import Table from '../../../component/table/Table';
import FullCircle from '../../../assets/image/full_circle.svg';
import HalfCircle from '../../../assets/image/half_circle.svg';
import EmptyCircle from '../../../assets/image/empty_circle.svg';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import PageLoader from '../../../component/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../component/pagination';
import { toast } from 'react-toastify';
import { getDate } from '../../../utils/dateUtils';
import { fetchPurchaseOrders } from '../../../services/purchaseOrder';
import { fetchPurchaseReceive } from '../../../services/purchase_receive';
import { fetchPurchaseBill } from '../../../services/purchase_bill';
import { fetchPaymentMadeList } from '../../../services/payment_made';
import { PaymentMadeColoum } from '../../paymentMade/PaymentmadeColoum';
import { CreditNoteColoum } from '../../creditNote/creditNotecoloum';
import { fetchCreditNoteList } from '../../../services/credit_note';

// Arrow Icon as SVG

const ArrowIcon = ({ isOpen, onClick }) => (
	<button
		onClick={onClick}
		style={{
			background: 'none',
			border: 'none',
			cursor: 'pointer',
			paddingLeft: '20px',
			transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
			transition: 'transform 0.3s ease',
		}}
		aria-label="Toggle section"
	>
		<img
			src={arrow}
			alt="Arrow Icon"
		/>
	</button>
);

// const sortDataList = (param) => {
// 	const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
// 	setSortDirection({ [param.orderBy]: newDirection });
// 	setPurchaseOrderParams((prev) => ({
// 		...prev,
// 		orderBy: param.orderBy,
// 		sortBy: newDirection,
// 		filterValue: null,
// 	}));
// };

const sortIconMap = {
	asc: <IoIosArrowRoundUp />,
	desc: <IoIosArrowRoundDown />,
};

const FaqSection = () => {
	const navigate = useNavigate();
	const [openSection, setOpenSection] = useState(null);
	const [loader, setLoader] = useState(false);
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;

	const [tableParams, setTableParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'purchase_order_id',
		sortBy: 'asc',
		filterBy: 'vendor_id',
		filterValue: id,
	});
	const navigateRouter = (path) => {
		navigate(path);
	};

	const toggleSection = (index) => {
		setOpenSection(openSection === index ? null : index);
	};

	const [tableData, setTableData] = useState({
		currentPage: 1,
		totalPages: 0,
		data: [],
	});

	async function handleFetchTableData() {
		console.log(tableParams);
		if (tableParams.orderBy === 'purchase_order_id') {
			try {
				setLoader(true);
				const { purchaseOrders, totalPages } = await fetchPurchaseOrders(tableParams);
				const mappedPurchaseOrderTableData = purchaseOrders.map((purchase) => {
					return {
						id: purchase.purchase_order_id,
						DATE: getDate(purchase?.date),
						'PO#': JSON.stringify({
							po_number: purchase?.purchase_order_number,
							is_draft: purchase?.is_draft,
						}),
						'VENDOR NAME': purchase?.vendor?.vendor_name,
						Status: purchase?.status || 'N/A',
						Received: JSON.stringify({
							is_received: purchase.is_received,
							is_partially_received: purchase.is_partially_received,
							status: purchase?.status,
						}),
						Billed: JSON.stringify({
							is_billed: purchase?.is_billed,
							is_partially_billed: purchase?.is_partially_billed,
						}),
						'Expected By':
							purchase?.expected_delivery_date === null
								? 'N/A'
								: getDate(purchase?.expected_delivery_date),
					};
				});
				setTableData((prev) => ({
					...prev,
					data: mappedPurchaseOrderTableData,
					totalPages,
				}));
				console.log(tableData.data);
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoader(false);
			}
		} else if (tableParams.orderBy === 'purchase_receive_id') {
			try {
				setLoader(true);
				const { purchaseReceive, totalPages } = await fetchPurchaseReceive(tableParams);
				const tableData = purchaseReceive.map((pr) => {
					return {
						id: pr.purchase_receive_id,
						DATE: getDate(pr.receive_date),
						PR: JSON.stringify({ pr: pr?.purchase_receive_id, status: pr?.status }),
						'PO#': pr.purchase_order.purchase_order_number || pr.purchase_order_number,
						'VENDOR NAME': pr.vendor.vendor_name,
						RECEIVED: pr?.status,
						BILLED: JSON.stringify({
							is_billed: pr?.is_billed,
							is_partially_billed: pr?.is_partially_billed,
						}),
					};
				});
				setTableData((prev) => ({
					...prev,
					data: tableData,
					totalPages,
				}));
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoader(false);
			}
		} else if (tableParams.orderBy === 'purchase_bill_id') {
			try {
				setLoader(true);
				const { purchaseBills, totalPages } = await fetchPurchaseBill(tableParams);
				const data = purchaseBills
					? purchaseBills.map((pr) => {
						return {
							id: pr.purchase_bill_id,
							DATE: getDate(pr.bill_date),
							PB: JSON.stringify({
								pb_number: pr?.bill_number,
								status: pr?.status,
							}),
							'VENDOR NAME': pr.vendor.vendor_name,
							status: pr?.status,
							AMOUNT: pr?.bill_amount,
						};
					})
					: [];
				setTableData((prev) => ({
					...prev,
					data: data,
					totalPages,
				}));
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoader(false);
			}
		} else if (tableParams.orderBy === 'payment_made_id') {
			try {
				setLoader(true);
				const data = await fetchPaymentMadeList(tableParams);
				const mappedTableData = data?.map((pm) => {
					return {
						id: pm.payment_made_id,
						DATE: getDate(pm?.date),
						'VENDOR NAME': pm?.vendor?.vendor_name,
						MODE: pm?.payment_mode,
						AMOUNT: pm?.payment_amount,
					};
				});

				setTableData((prev) => ({
					...prev,
					data: mappedTableData,
				}));
			} catch (error) {
				toast.error('Somethings went wrong!');
			} finally {
				setLoader(false);
			}
		} else if (tableParams.orderBy === 'credit_note_id') {
			try {
				setLoader(true);
				const { data, totalPages } = await fetchCreditNoteList(tableParams);
				const creditNoteData = data?.map((ele) => {
					return {
						id: ele.credit_note_id,
						DATE: getDate(ele?.date),
						CN: JSON.stringify({ cn: ele?.credit_note_number, is_draft: ele?.status }),
						'VENDOR NAME': ele?.vendor?.vendor_name,
						status: ele?.status === 0 ? 'N/A' : ele?.status === 1 ? 'Active' : 'Closed',
						AMOUNT: Number(ele?.credit_note_amount) || 0,
					};
				});
				setTableData((prev) => ({
					...prev,
					data: creditNoteData,
					totalPages,
				}));
			} catch (error) {
				toast.error('somethings went wrong!');
			} finally {
				setLoader(false);
			}
		}
	}

	const handlePageChange = (page, type) => {
		setTableData((prev) => ({
			...prev,
			orderBy: type,
			page: page,
		}));
	};

	useEffect(() => {
		handleFetchTableData();
	}, [tableParams]);

	const faqData = [
		{
			title: 'Purchase Orders',
			columns: [
				{
					field: '',
					headerClass: 'HeaderBg',
					checkboxSelection: true,
					headerCheckboxSelection: true,
					minWidth: 50,
					maxWidth: 50,
					lockPosition: true,
				},
				{
					field: 'DATE',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 110,
					maxWidth: 110,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					DATE
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.date]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
				{
					field: 'PO#',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 140,
					maxWidth: 140,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'purchase_order_id', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					PO#
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.purchase_order_id]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
					cellRenderer: (params) => {
						const PO = JSON.parse(params.data['PO#']);
						return (
							<p>
								{PO?.po_number}
								{PO?.is_draft === 1 && (
									<span
										style={{
											backgroundColor: '#718096',
											color: 'white',
											borderRadius: '20px',
											padding: '2px 10px',
											paddingBottom: '3px',
											marginLeft: '10px',
											fontSize: '11px',
										}}
									>
										Draft
									</span>
								)}
							</p>
						);
					},
				},
				{
					field: 'VENDOR NAME',
					suppressSizeToFit: true,
					headerClass: 'HeaderBg',
					flex: 1,
					minWidth: 400,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					VENDOR NAME
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.vendor_name]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
				{
					field: 'Status',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 200,
					maxWidth: 200,
					flex: 1,
					cellClass: 'left-align',
					cellRenderer: (params) => {
						const status = params.data?.Status || ''; // Get the status value
						let statusCode = '';

						// Set the image based on status value
						switch (status) {
							case 'Received':
								statusCode = '#6AA247';
								break;
							case 'Pending':
								statusCode = '#FFC008';
								break;
							case 'Pending Approval':
								statusCode = '#3B58FF';
								break;
							case 'Approved':
								statusCode = '#3B58FF';
								break;
							case 'Issued':
								statusCode = '#3B58FF';
								break;
							case 'Closed':
								statusCode = '#989C95';
								break;
							case 'Deleted':
								statusCode = '#D30000';
								break;
							case 'Partially Received':
								statusCode = '#FFA500';
								break;
							case 'In-Transit':
								statusCode = '#11caf0';
								break;
							default:
								statusCode = '#DC3545';
								break;
						}

						// Return the image inside a flex container for centering
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '24px',
									fontSize: '12px',
									borderRadius: '5px',
									fontWeight: status === 'Draft' ? '' : 'bold',
									margin: '8px 0px',
									color: status === 'Draft' ? 'black' : '#ffffff',
									backgroundColor: status === 'Draft' ? '' : statusCode,
								}}
							>
								{status === 'Draft' ? 'N/A' : status}
							</div>
						);
					},
				},
				{
					field: 'Received',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 100,
					maxWidth: 100,
					flex: 1,
					cellClass: 'left-align',
					cellRenderer: (params) => {
						const received = JSON.parse(params.data?.Received) || '';
						const { is_received, is_partially_received, status } = received;
						// Logic for rendering circles
						if (
							is_received === 1 &&
							status !== 'Pending Approval' &&
							status !== 'In-Transit' &&
							status !== 'Cancelled' &&
							status !== 'Issued'
						) {
							// Full Circle
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<img
										alt="is_receive?"
										src={FullCircle}
									/>
								</div>
							);
						}

						if (
							is_partially_received === 1 &&
							status !== 'Pending Approval' &&
							status !== 'In-Transit' &&
							status !== 'Cancelled' &&
							status !== 'Issued'
						) {
							// Half Circle
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<img
										alt="is_receive?"
										src={HalfCircle}
									/>
								</div>
							);
						}
						// if (
						// 	((status === 'Pending Approval' ||
						// 		status === 'In-Transit' ||
						// 		status === 'Cancelled' ||
						// 		status === 'Issued') &&
						// 		(is_partially_received === 1 ||
						// 			is_received === 1 ||
						// 			(is_partially_received === 1 && is_received === 1))) ||
						// 	status === 'Pending Approval' ||
						// 	status === 'In-Transit' ||
						// 	status === 'Cancelled' ||
						// 	status === 'Issued'
						// ) {
						// Empty Circle
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
								}}
							>
								<img
									alt="is_receive?"
									src={EmptyCircle}
								/>
							</div>
						);
						// }
					},
				},
				{
					field: 'Billed',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 100,
					maxWidth: 100,
					flex: 1,
					cellClass: 'left-align',
					cellRenderer: (params) => {
						const billed = JSON.parse(params.data?.Billed) || '';
						const { is_billed, is_partially_billed } = billed;
						// Logic for rendering circles
						if (is_billed === 1) {
							// Full Circle
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<img
										alt="is_receive?"
										src={FullCircle}
									/>
								</div>
							);
						}

						if (is_partially_billed === 1) {
							// Half Circle
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<img
										alt="is_receive?"
										src={HalfCircle}
									/>
								</div>
							);
						}

						// Empty Circle
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
								}}
							>
								<img
									alt="is_receive?"
									src={EmptyCircle}
								/>
							</div>
						);
					},
				},
				{
					field: 'Expected By',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 120,
					maxWidth: 120,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'expected_delivery_date', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					Expected By
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.expected_delivery_date]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
			],
		},
		{
			title: 'Purchase Received',
			columns: [
				{
					field: '',
					headerClass: 'HeaderBg',
					checkboxSelection: true,
					headerCheckboxSelection: true,
					minWidth: 50,
					maxWidth: 50,
					lockPosition: true,
				},
				{
					field: 'DATE',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 150,
					maxWidth: 150,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'receive_date', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					DATE
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.receive_date]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
				{
					field: 'PR#',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 180,
					maxWidth: 180,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'purchase_receive_id', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					PR#
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.purchase_receive_id]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
					cellRenderer: (params) => {
						const PR = JSON.parse(params.data.PR);
						return (
							<p>
								PR-{PR?.pr}
								{PR?.status === 0 && (
									<span
										style={{
											backgroundColor: '#718096',
											color: 'white',
											borderRadius: '20px',
											padding: '2px 10px',
											paddingBottom: '3px',
											marginLeft: '10px',
											fontSize: '11px',
										}}
									>
										Draft
									</span>
								)}
							</p>
						);
					},
				},
				{
					field: 'PO#',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 150,
					maxWidth: 150,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'purchase_order_number', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					PO#
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.purchase_order_number]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
				{
					field: 'VENDOR NAME',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 150,
					flex: 1,
					cellClass: 'left-align',
					// headerComponent: () => {
					// 	return (
					// 		<div
					// 			style={{
					// 				width: '100%',
					// 				paddingTop: '10px',
					// 				cursor: 'pointer',
					// 			}}
					// 			onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
					// 		>
					// 			<div
					// 				style={{
					// 					width: '55px',
					// 				}}
					// 			>
					// 				<p
					// 					style={{
					// 						display: 'flex',
					// 						alignItems: 'center',
					// 						color: 'black',
					// 					}}
					// 				>
					// 					VENDOR NAME
					// 					<span
					// 						style={{
					// 							fontSize: '18px',
					// 							fontWeight: '800',
					// 						}}
					// 					>
					// 						{sortIconMap[sortDirection?.vendor_name]}
					// 					</span>
					// 				</p>
					// 			</div>
					// 		</div>
					// 	);
					// },
				},
				{
					field: 'RECEIVED',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 120,
					maxWidth: 120,
					flex: 1,
					cellClass: 'center-align',
					cellRenderer: (params) => {
						const status = params.data?.RECEIVED || 0;
						if (status === 1) {
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									{' '}
									<img src={FullCircle} />
								</div>
							);
						}
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
								}}
							>
								<img src={EmptyCircle} />
							</div>
						);
					},
				},
				{
					field: 'BILLED',
					headerClass: 'HeaderBg left-align',
					suppressSizeToFit: true,
					minWidth: 100,
					maxWidth: 100,
					flex: 1,
					cellClass: 'center-align',
					cellRenderer: (params) => {
						const status = params.data?.BILLED || ''; // Fallback to an empty string if undefined
						if (status === 3) {
							return (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: '100%',
									}}
								>
									<img
										src={FullCircle}
										style={{ alignItems: 'center' }}
									/>
								</div>
							);
						}
						return (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '100%',
								}}
							>
								<img
									src={EmptyCircle}
									style={{ alignItems: 'center' }}
								/>
							</div>
						);
					},
				},
			],
		},
		{
			title: 'Purchase Bills',
			columns: [
				{
					field: '',
					headerClass: 'HeaderBg',
					checkboxSelection: true,
					headerCheckboxSelection: true,
					minWidth: 50,
					maxWidth: 50,
					lockPosition: true,
				},
				{
					field: 'DATE',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 200,
					flex: 1,
					cellClass: 'left-align',
				},
				{
					field: 'PURCHASE BILL#',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 200,
					flex: 1,
					cellClass: 'left-align',
					cellRenderer: (params) => {
						const PB = JSON.parse(params.data['PB']);
						return (
							<p style={{ alignItems: 'center' }}>
								{PB?.pb_number}
								{PB?.status === 0 && (
									<span
										style={{
											backgroundColor: '#718096',
											color: 'white',
											borderRadius: '20px',
											padding: '2px 10px',
											paddingBottom: '3px',
											marginLeft: '10px',
											fontSize: '11px',
										}}
									>
										Draft
									</span>
								)}
							</p>
						);
					},
				},

				{
					field: 'VENDOR NAME',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 150,
					flex: 1,
					cellClass: 'left-align',
				},
				{
					field: 'STATUS',
					headerClass: 'HeaderBg',
					suppressSizeToFit: true,
					minWidth: 150,
					flex: 1,
					cellClass: 'left-align',
					cellRenderer: (params) => {
						const status = params.data?.status || 0; // Fallback to an empty string if undefined
						// console.log(status);
						if (status) {
							return (
								<p>
									<span
										style={{
											color:
												status === 0
													? '#000'
													: status === 1
														? '#FFC008'
														: status === 2
															? '#FFA500'
															: '#6AA247',
										}}
									>
										{status === 0
											? 'N/A'
											: status === 1
												? 'Open'
												: status === 2
													? 'Partially Paid'
													: 'Closed'}
									</span>
								</p>
							);
						} else {
							return (
								<p>
									<span
										style={{
											color: '#000',
										}}
									>
										N / A
									</span>
								</p>
							);
						}
					},
				},
				{
					field: 'AMOUNT',
					headerClass: 'HeaderBg right-align',
					suppressSizeToFit: true,
					minWidth: 150,
					flex: 1,
					cellClass: 'right-align',
				},
			],
		},
		{
			title: 'Payment Made',
			columns: PaymentMadeColoum,
		},
		{
			title: 'Credit Notes',
			columns: CreditNoteColoum,
		},
	];

	function handleCreateButton(section, index) {
		let baseUrl = `/user/${suburl}/`
		const urlMap = {
			0: baseUrl + `purchases-order-create`,
			1: baseUrl + `purchases-receive-create`,
			2: baseUrl + `purchases-bills-create`,
			3: baseUrl + `payment-made-create`,
			4: baseUrl + `credit-note-create`,
		}

		navigate(urlMap[Number(index)])
	}

	return (
		<div>
			{faqData.map((section, index) => (
				<div
					key={index}
					style={{ marginBottom: '8px' }}
				>
					{/* Section Header */}
					<div
						style={{
							cursor: 'pointer',
							background: '#F6F6F9',
							// padding: '10px',
							display: 'flex',
							height: '58px',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						{/* Left Arrow Icon */}
						<div
							onClick={() => {
								toggleSection(index);
								setTableParams({
									page: 1,
									pageSize: 20,
									orderBy:
										index === 0
											? 'purchase_order_id'
											: index === 1
												? 'purchase_receive_id'
												: index === 2
													? 'purchase_bill_id'
													: index === 3
														? 'payment_made_id'
														: 'credit_note_id',
									sortBy: 'asc',
									filterBy: 'vendor_id',
									filterValue: id,
								});
								setTableData({
									currentPage: 1,
									totalPages: 0,
									data: [],
								});
							}}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<ArrowIcon isOpen={openSection === index} />
							<span style={{ marginLeft: '0px' }}>{section.title}</span>
						</div>

						{/* Right "New +" Button */}
						<button
							// onClick={() => {
							// 	toggleSection(index);
							// 	setTableParams({
							// 		page: 1,
							// 		pageSize: 20,
							// 		orderBy:
							// 			index === 0
							// 				? 'purchase_order_id'
							// 				: index === 1
							// 					? 'purchase_receive_id'
							// 					: index === 2
							// 						? 'purchase_bill_id'
							// 						: index === 3
							// 							? 'payment_made_id'
							// 							: 'credit_note_id',
							// 		sortBy: 'asc',
							// 		filterBy: 'vendor_id',
							// 		filterValue: id,
							// 	});
							// 	setTableData({
							// 		currentPage: 1,
							// 		totalPages: 0,
							// 		data: [],
							// 	});
							// }}
							onClick={() => handleCreateButton(section, index)}
							style={{
								backgroundColor: '#3B58FF',
								fontSize: '16',
								fontWeight: '600',
								marginRight: '8px',
								width: '84px',
								height: '42px',
								color: 'white',
								border: 'none',
								// padding: '4px 8px',
								borderRadius: '6px',
								cursor: 'pointer',
							}}
						>
							New +
						</button>
					</div>

					{/* Section Content */}
					{openSection === index && (
						<div style={{ borderBottom: '1px solid #ccc', backgroundColor: '#F6F6F9' }}>
							{loader ? (
								<PageLoader />
							) : (
								<>
									{/* Table Content */}
									<div style={styles.tableContainer}>
										<Table
											coloum={section.columns}
											row={tableData?.data}
											onRowClick={(rowId) => {
												if (index === 0) {
													navigateRouter(`/user/${suburl}/purchases-order-details/${rowId}`);
												} else if (index === 1) {
													navigateRouter(`/user/${suburl}/purchases-receive-details/${rowId}`);
												} else if (index === 2) {
													navigateRouter(`/user/${suburl}/purchases-bills-details/${rowId}`);
												} else if (index === 3) {
													navigateRouter(`/user/${suburl}/payment-made-details/${rowId}`);
												} else {
													navigateRouter(`/user/${suburl}/credit-note-details/${rowId}`);
												}
											}} // Disable row click
										/>
									</div>
									<div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
										<Pagination
											currentPage={tableData.currentPage}
											totalPages={tableData.totalPages}
											PageFix={1}
											onChangePage={(page) =>
												handlePageChange(
													page,
													index === 0
														? 'purchase_order_id'
														: index === 1
															? 'purchase_receive_id'
															: index === 2
																? 'purchase_bill_id'
																: index === 3
																	? 'payment_made_id'
																	: 'credit_note_id',
												)
											}
										/>
									</div>
									{/* View All Button */}
									{/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
										<button
											style={{
												fontSize: '16',
												fontWeight: '600',
												marginRight: '8px',
												width: '84px',
												height: '42px',
												color: 'black',
												border: '1px solid #3B58FF',
												background: 'transparent',
												padding: '4px 8px',
												marginBottom: '7px',
												borderRadius: '6px',
												cursor: 'pointer',
											}}
										>
											View All
										</button>
									</div> */}
								</>
							)}
						</div>
					)}
				</div>
			))}
		</div>
	);
};

const styles = {
	tableContainer: {
		width: '100%',
		minHeight: '210px', // Set a maximum height
		overflowY: 'hidden',
	},
};

export default FaqSection;
