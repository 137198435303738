import { APP_API_URL } from '../../utils/xhr-instance';
import axiosInstance from '../axios';

export async function fetchTransportList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/transports/`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function createTransport(body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(`${APP_API_URL}/transports/`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		return data?.data;
	} catch (error) {
		throw error;
	}
}
