import { APP_API_URL } from "../../utils/xhr-instance";
import axiosInstance from "../axios";

export async function create_purchase_receive(body) {
  const token = localStorage.getItem('authToken');
  const { data } = await axiosInstance.post(`${APP_API_URL}/purchase-receive/create-purchase-receive`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}


export async function update_purchase_receive(body) {
  const token = localStorage.getItem('authToken');
  const { data } = await axiosInstance.put(`${APP_API_URL}/purchase-receive/update-purchase-receive`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}
export async function updatePurchaseReceiveStatus(body) {
  try {
    const token = localStorage.getItem('authToken');
    const { data } = await axiosInstance.post(`${APP_API_URL}/purchase-receive/update-status`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (data?.success === false) {
      throw new Error(data?.message)
    }
    return data;
  } catch (error) {
    throw error
  }
}

export const fetchPurchaseReceive = async (params) => {
  try {
    const token = localStorage.getItem('authToken');
    const { data } = await axiosInstance.get(`${APP_API_URL}/purchase-receive/get-purchase-receive`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data.data;
  } catch (error) {
    throw error;
  }
};


export async function sendEmail(body) {
  const token = localStorage.getItem('authToken');
  const { data } = await axiosInstance.post(`${APP_API_URL}/purchase-receive/send-email`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}