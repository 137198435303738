import React, { useEffect, useRef } from 'react';

const AppendInput = ({
	label,
	name,
	appendLabel,
	className,
	placeholder,
	value,
	onKeyPress,
	error,
	onChange,
	required,
	disabled,
	autoFocus,
}) => {
	const inputRef = useRef(null);

	const handleChange = (event) => {
		const { value } = event.target;
		onChange(value);
	};

	useEffect(() => {
		if (autoFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [autoFocus]);

	return (
		<div>
			{label && <label className="form-label">{label}</label>}
			{required && <span style={{ color: 'red' }}>*</span>}
			<div className="input-group mb-3">
				<input
					ref={inputRef} // Add the ref here
					style={{
						borderRadius: '8px',
						borderTopRightRadius: '0',
						borderBottomRightRadius: '0',
						border: '1px solid rgba(85, 91, 103, 0.15)',
						backgroundColor: '#FCFCFC',
						padding: '8px',
						borderRight: '0',
					}}
					onKeyDown={onKeyPress}
					type="text"
					className="form-control"
					id="inlineFormInputGroup"
					value={value}
					name={name}
					placeholder={placeholder}
					onChange={handleChange}
					disabled={disabled}
				/>
				<div className="input-group-append">
					<span
						className={`input-group-text font-medium ${className}`}
						style={{
							minHeight: '3.6rem',
							fontSize: '1.4rem',
							marginLeft: '.1rem',
							minWidth: '5rem',
							borderLeft: '0',
							borderTopLeftRadius: '0',
							borderBottomLeftRadius: '0',
							justifyContent: 'center',
							color: 'var(--font-color)',
						}}
					>
						{appendLabel}
					</span>
				</div>
			</div>
			{error && (
				<div
					className="error-text"
					style={{ color: 'red', fontSize: '12px', marginBottom: '10px' }}
				>
					{error}
				</div>
			)}
		</div>
	);
};

export default AppendInput;
