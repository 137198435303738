import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProductDetails from '../../purchaseOrder/Navigation/ProductDetails';
import Transactions from '../../purchaseOrder/Navigation/Transactions';
import ProfilePlaceHolder from '../../../assets/image/emailPlaceHolder.svg';
import BlackClose from '../../../assets/image/x-squareBlack.svg';
import { fetchProductDetails } from '../../../services/products';
import { toast } from 'react-toastify';
import PageLoader from '../../../component/PageLoader';

function ProductDetailsDrawer({ id, setIsSidebarOpen, vendor }) {
  const suburl = localStorage.getItem('suburl')
  const [activeTab, setActiveTab] = useState('Product Details');
  const navTabs = ['Product Details', 'Transactions'];
  const [isLoading, setIsLoading] = useState(false)
  const [productDetails, setProductDetails] = useState(null)
  const [params, setParams] = useState({
    from: "purchase_order",
    page: 1,
    pageSize: 20,
    orderBy: null,
    sortBy: null,
    filterBy: null,
    filterValue: null
  })

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Product Details':
        return <ProductDetails productDetails={productDetails} />;
      case 'Transactions':
        return <Transactions setParams={setParams} vendor={vendor} productDetails={productDetails} />;
      default:
        return null;
    }
  };

  async function handleFetchProductDetails() {
    try {
      setIsLoading(true)
      const data = await fetchProductDetails(id, params);
      setProductDetails(data);
    } catch (error) {
      toast.error('Someting went wrong!');
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleFetchProductDetails();
  }, [id, params]);


  return (
    <div className="sidebar">
      <div
        className="d-flex align-self-center justify-content-sm-between "
        style={{ gap: '60px' }}
      >
        <div className="VenderDetails">Product Details</div>

        <div
          className="d-flex align-self-center "
          style={{ gap: '26px', alignItems: 'center' }}
        >
          <div>
            <Link to={`/user/${suburl}/product-create/${id}`}>View more details</Link>
          </div>

          <div
            className="SidebaarCloseBTN d-flex align-self-center justify-content-sm-center"
            onClick={() => {
              setIsSidebarOpen(false);
            }}
          >
            <img
              src={BlackClose}
              alt=""
              style={{ width: '14px', cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
      {
        !productDetails ? <PageLoader /> : <>
          <br />

          <div
            className="d-flex"
            style={{ gap: '20px' }}
          >
            <div className="profileImage d-flex align-self-center justify-content-sm-center">
              <img
                src={ProfilePlaceHolder}
                alt=""
                style={{ width: '55px', borderRadius: '10px' }}
              />
            </div>

            <div
              className="d-flex flex-column"
              style={{ gap: '15px' }}
            >
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: '600',
                  color: '#333',
                }}
              >
                {productDetails?.product_details?.product_name}
              </div>

              <div
                style={{
                  fontSize: '14px',
                  color: 'var(--sub, #555B67)',
                }}
              >
                <b>{productDetails?.product_details?.packaging?.name}</b> {" | "}
                <b>{productDetails?.product_details?.contains} {productDetails?.product_details?.unit?.name}</b>
              </div>
            </div>
          </div>
          <br />
          <hr />

          {/* tabs  */}
          <div>
            <ul className="nav mb-4 mt-2">
              {navTabs.map((item, index) => (
                <li
                  key={index}
                  className="nav-item"
                >
                  <a
                    className={`nav-link ${activeTab === item ? 'active' : ''} cursur-pointer`}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default anchor click behavior
                      setActiveTab(item);
                    }}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <hr
              style={{
                backgroundColor: '#E3E4E5',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                marginTop: '-13px',
              }}
            />
            <div>{renderTabContent()}</div>
          </div></>
      }
    </div>
  );
}

export default ProductDetailsDrawer;
