import React, { useEffect, useState } from 'react';
import Button from '../../../../component/ui/Button';
import Table from '../../../../component/table/Table';
import Modal from '../../../../component/modal/Modal';
import { getDate } from '../../../../utils/dateUtils';
import { toast } from 'react-toastify';

function ConvertToBillModal({
	purchaseOrderDetails,
	isConvertToBillOpen,
	setIsConvertToBillOpen,
	handleConvertToBill,
}) {
	const [isReceived, setIsReceived] = useState('yetToReceive');
	const [bulkAction, setBulkAction] = useState([]);
	const [tableData, setTableData] = useState({
		yetToReceiveTableData: [],
		receivedTableData: [],
	});

	const yetToReceiveColumn = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'PURCHASE ORDER#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'ORDER DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
	];

	const receivedColumn = [
		{
			field: '',
			headerClass: 'HeaderBg',
			checkboxSelection: true,
			headerCheckboxSelection: true,
			minWidth: 50,
			maxWidth: 50,
			lockPosition: true,
		},
		{
			field: 'PURCHASE RECEIVE#',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'RECEIVED DATE',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
		{
			field: 'STATUS',
			headerClass: 'HeaderBg',
			suppressSizeToFit: true,
			minWidth: 200,
			flex: 1,
			cellClass: 'left-align',
		},
	];

	const handleChange = (e) => {
		setBulkAction([]);
		setIsReceived(e.target.value);
	};

	function convertToBill() {
		if (bulkAction.length === 0) {
			toast.error('Please select some value!');
			return;
		}
		setIsConvertToBillOpen(false);
		handleConvertToBill(isReceived, bulkAction);
		setBulkAction([]);
	}

	const handleSetTableData = () => {
		const yetToReceivePO = [];
		const receivedPR = [];

		if (purchaseOrderDetails) {
			if (purchaseOrderDetails.is_received !== 1) {
				yetToReceivePO.push({
					id: purchaseOrderDetails.purchase_order_id,
					'PURCHASE ORDER#': purchaseOrderDetails.purchase_order_number,
					'ORDER DATE': getDate(purchaseOrderDetails.date),
					STATUS: purchaseOrderDetails.status,
				});
			}

			purchaseOrderDetails.purchase_receive?.forEach((pr) => {
				if (pr.status === 1) {
					receivedPR.push({
						id: pr.purchase_receive_id,
						'PURCHASE RECEIVE#': 'PR-' + pr.purchase_receive_id,
						'RECEIVED DATE': getDate(pr.receive_date),
						STATUS: 'Received',
					});
				}
			});
		}

		setTableData({
			yetToReceiveTableData: yetToReceivePO,
			receivedTableData: receivedPR,
		});

		// Adjust default selection based on data availability
		if (receivedPR.length && !yetToReceivePO.length) {
			setIsReceived('received');
		} else if (!receivedPR.length && yetToReceivePO.length) {
			setIsReceived('yetToReceive');
		}
	};

	useEffect(() => {
		handleSetTableData();
	}, [purchaseOrderDetails]);

	const renderRadioButton = (label, description, value, isVisible) => {
		if (!isVisible) return null;

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<input
					type="radio"
					id={value}
					name="status"
					value={value}
					checked={isReceived === value}
					onChange={handleChange}
					style={{ width: '16px', height: '16px' }}
				/>
				<label
					htmlFor={value}
					style={{ marginLeft: 5 }}
				>
					<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>{label}</div>
					<div style={{ margin: 0, color: '#9FA6B5', fontSize: '14px' }}>{description}</div>
				</label>
			</div>
		);
	};

	return (
		<Modal
			className="convertToBillModal"
			isOpen={isConvertToBillOpen}
			onClose={() => setIsConvertToBillOpen(false)}
			title={
				<div
					style={{
						fontWeight: '600',
						color: '#000000',
						fontSize: '22px',
					}}
				>
					Purchase Order ({purchaseOrderDetails?.purchase_order_number || 'N/A'})
				</div>
			}
			headerClassName="custom-header"
			bodyClassName="convertToBillBody"
		>
			<div style={{ backgroundColor: '#E6F5FF', height: '36px', marginBottom: '36px' }}>
				<p
					style={{
						fontSize: '18px',
						color: '#555B67',
						paddingLeft: '10px',
						paddingTop: '3px',
						marginTop: '8px',
					}}
				>
					Note: You can create a bill for either Yet to Receive items or Received Items.
				</p>
			</div>

			<div>
				<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
					{renderRadioButton(
						'Yet to Receive',
						'Yet to receive product in this PO',
						'yetToReceive',
						tableData.yetToReceiveTableData.length > 0,
					)}
					{renderRadioButton(
						'Received',
						'Received product in this PO',
						'received',
						tableData.receivedTableData.length > 0,
					)}
				</div>

				{((isReceived === 'yetToReceive' && tableData.yetToReceiveTableData.length > 0) ||
					(isReceived === 'received' && tableData.receivedTableData.length > 0)) && (
						<div style={styles.tableContainer}>
							<Table
								coloum={isReceived === 'yetToReceive' ? yetToReceiveColumn : receivedColumn}
								row={
									isReceived === 'yetToReceive'
										? tableData.yetToReceiveTableData
										: tableData.receivedTableData
								}
								onRowClick={(id) => console.log(id)}
								action={setBulkAction}
								bulkAction={bulkAction}
							/>
						</div>
					)}

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'end',
						gap: 10,
						marginTop: '20px',
					}}
				>
					<Button
						type="button"
						className="mt-0 btn-gray add-pro"
						button="Cancel"
						onClick={() => setIsConvertToBillOpen(false)}
					/>
					<Button
						type="button"
						className="mt-0 btn-primary add-pro"
						button="Convert To Bill"
						onClick={convertToBill} // Add your conversion logic here
					/>
				</div>
			</div>
		</Modal>
	);
}

const styles = {
	tableContainer: {
		marginTop: '20px',
		maxHeight: '140px',
		overflowY: 'auto',
		width: '100%',
	},
};

export default ConvertToBillModal;
