import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Billcolumn, PurchaseOrderColoum } from './purchaseReceivedColoum';
import Header from '../components/Header';
import PageLoader from '../../component/PageLoader';
import BillReceiveTable from '../components/BillReceiveTable';
import { getDate } from '../../utils/dateUtils';
import { fetchPurchaseReceive, updatePurchaseReceiveStatus } from '../../services/purchase_receive';
import NonPdfView from '../components/NonPdfView';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import Modal from '../../component/modal/Modal';
import { convertToBillColumn } from './purchaseReceivedColoum';
import Table from '../../component/table/Table';
import Button from '../../component/ui/Button';
import { useReactToPrint } from 'react-to-print';
import pdfMake from 'pdfmake/build/pdfmake';
import 'pdfmake/build/vfs_fonts';
import ProductDetailsDrawer from '../components/ProductDetailsDrawer';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';

const PurchaseReceiveDetails = () => {
	const [isConvetToBillOpen, setIsConvertToBillOpen] = useState(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const suburl = localStorage.getItem('suburl');
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isRestrictDeletePR, setIsRestrictDeletePR] = useState(false);
	const [purchaseReceiveDetails, setPurchaseReceiveDetails] = useState(null);
	const printPdfRef = useRef(null);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const printPurchaseOrderPdf = useReactToPrint({ contentRef: printPdfRef });
	const [purchaseReceiveParams, setPurchaseReceiveParams] = useState({
		page: 1,
		pageNo: 20,
		orderBy: null,
		sortBy: null,
		filterBy: null,
		filterValue: null,
	});

	const ConvertToBill = [
		{
			'PURCHASE ORDER#': 'PO-0012',
			'ORDER DATE': '29/03/2024',
			STATUS: 'Issued',
		},
	];

	async function handleDeletePurchaseReceive() {
		if (purchaseReceiveDetails.is_billed === 1) {
			setIsRestrictDeletePR(true);
		} else {
			try {
				setIsDeleteModalOpen(true);
			} catch (error) {
				toast.error('Something went wrong!');
			}
		}
	}

	const handleConfirmDelete = async () => {
		try {
			const body = {
				purchase_receive_id: id,
				status: 'is_deleted',
				value: true,
			};
			await updatePurchaseReceiveStatus(body);
			toast.success('Purchase Receive deleted successfully.');
			setIsDeleteModalOpen(false);
			navigate(`/user/${suburl}/purchases-receive`);
		} catch (error) {
			console.log(error)
			toast.error('Failed to delete Purchase Receive!');
		}
	};

	const handleCancelDelete = () => {
		setIsDeleteModalOpen(false);
	};

	async function printPurchaseReceivePdf() {
		const { status, is_billed, purchase_receive_id, vendor, delivery_address, purchase_order, receive_date, purchase_receive_products } = purchaseReceiveDetails
		const { vendor_name } = vendor
		const { date: order_date } = purchase_order

		const docDefinitions = {
			content: [
				{
					columns: [
						{
							text: `PR-${purchase_receive_id}`,
							style: 'vendorName',
						},
						{
							stack: [
								{
									text: "Vendor Name",
									style: 'vendorName',
								},
								{
									text: vendor_name || 'Vendor Name Not Available',
									style: '',
								}
							]
						},
					],
					margin: [0, 0, 0, 10],
				},
				{
					columns: [
						{
							stack: [
								{
									text: `Received Status: ${status === 0 ? 'Draft' : status === 1 ? 'Received' : 'Not Received'
										}`,
									style: 'status',
								},
								{
									text: `Bill Status: ${is_billed ? 'Billed' : 'Pending'}`,
									style: 'status',
									color: is_billed ? '#6AA247' : 'red',
								},
							],
						},
						{
							stack: [
								{ text: 'Deliver Address', style: 'label' },
								{ text: delivery_address || 'N/A', style: 'detail' },
							],
						},
					],
					// columnGap: 10,
				},
				{
					columns: [
						{
							stack: [
								{ text: `Order Date: ${getDate(order_date) || 'N/A'}`, style: 'orderDate' },
								{ text: `Receive Date: ${getDate(receive_date) || 'N/A'}`, style: 'orderDate' },
							],
							margin: [0, 20, 0, 10],
						},
					],
				},
				{
					table: {
						widths: ['10%', '50%', '40%'],
						body: [
							[
								{ text: '#', bold: true },
								{ text: 'ITEMS AND DESCRIPTION', bold: true },
								{ text: 'RECEIVED STATUS', bold: true },
							],
							...purchase_receive_products.map((prod, index) => [
								{ text: index + 1 },
								{ text: `${prod.product?.product_name} Received` || 'N/A', color: 'blue' },
								{ text: prod.received_quantity || 0 },
							]),
						],
					},
					margin: [0, 20, 0, 10],
				},
				{
					text: 'Authorized Signature --------------------',
					margin: [0, 30, 0, 0],
					alignment: 'left',
				},
			],
			styles: {
				status: {
					fontSize: 10,
					margin: [0, 0, 0, 5],
				},
				vendorName: {
					fontSize: 16,
					bold: true,
				},
				vendorAddress: {
					fontSize: 12,
				},
				subheader: {
					fontSize: 14,
					bold: true,
				},
				orderNumber: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				orderDate: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				label: {
					fontSize: 10,
					bold: true,
					margin: [0, 5, 0, 5],
				},
				detail: {
					fontSize: 10,
					margin: [0, 2, 0, 2],
				},
			},
		};


		pdfMake.createPdf(docDefinitions).print();
	}

	async function handleFetchPurchaseReceiveDetails() {
		try {
			const data = await fetchPurchaseReceive({
				...purchaseReceiveParams,
				filterBy: 'purchase_receive_id',
				filterValue: Number(id),
			});
			const purchaseReceiveData = data.purchaseReceive[0];
			setPurchaseReceiveDetails(purchaseReceiveData);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	useEffect(() => {
		if (id) {
			handleFetchPurchaseReceiveDetails();
		}
	}, [id]);

	return (
		<div
			style={{
				backgroundColor: '#EDEDF7',
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Header
				headerTitle="Purchase Receive Details"
				closeButton={{
					showCloseButton: true,
					handler: () => navigate(`/user/${suburl}/purchases-receive`),
				}}
				buttons={[
					...(purchaseReceiveDetails && purchaseReceiveDetails?.status === 0 ?
						[{
							id: 1,
							className: 'btn-gray mt-0 px-4',
							button: 'Edit',
							handler: () => navigate(`/user/${suburl}/purchases-receive-create/pr-${id}`),
						}] : []
					),
					{
						id: 2,
						className: 'btn-gray mt-0',
						button: 'Send Email',
						handler: () => navigate(`/user/${suburl}/purchases-receive-send-emails/${id}`),
					},
					{
						id: 3,
						className: 'mt-0 btn-gray add-pro',
						button: 'PDF/Print',
						handler: async () => {
							// await setIsShowPdfView(true)
							printPurchaseReceivePdf();
						},
					},
					...(purchaseReceiveDetails && purchaseReceiveDetails?.status !== 0 ? [{
						id: 4,
						className: 'btn-gray add-pro',
						button: 'Convert To Bill',
						handler: () => navigate(`/user/${suburl}/purchases-bills-create/pr-${id}`),
					}] : []),
					// {
					// 	id: 6,
					// 	className: 'mt-0 btn-gray add-pro',
					// 	button: 'Mark as in Transit',
					// 	// handler: handleVoidPurchaseOrder,
					// },
					{
						id: 7,
						className: 'btn-gray add-pro',
						button: 'Delete',
						handler: handleDeletePurchaseReceive,
					},
				]}
			/>

			{!purchaseReceiveDetails ? (
				<PageLoader />
			) : (
				<div>
					<BillReceiveTable
						height={'83.5px'}
						tableConfig={{
							billTable: {
								title: 'Bills',
								column: Billcolumn,
								row:
									purchaseReceiveDetails?.purchase_bill?.length > 0
										? purchaseReceiveDetails?.purchase_bill?.map((pb) => {
											const formattedBillDate = new Date(pb?.bill_date).toLocaleDateString(
												'en-GB',
											);
											const formattedDueDate = new Date(pb?.due_date).toLocaleDateString('en-GB');

											const status =
												pb?.status === 1
													? 'Open'
													: pb?.status === 2
														? 'Partially Paid'
														: 'Closed';

											return {
												'BILL#': pb?.bill_number,
												DATE: formattedBillDate,
												STATUS: status,
												'DUE DATE': formattedDueDate,
												AMOUNT: pb?.bill_amount,
												'BALANCE DUE': pb?.due_amount,
											};
										})
										: [],
								count: purchaseReceiveDetails?.purchase_bill.length,
								onRowClick: () => console.log(),
							},
							receiveTable: {
								title: 'Purchase Order',
								column: PurchaseOrderColoum,
								row: [purchaseReceiveDetails.purchase_order].map((po) => {
									return {
										id: po.purchase_order_id,
										DATE: getDate(po?.date),
										'PO#': JSON.stringify({
											po_number: po?.purchase_order_number,
											is_draft: po?.is_draft,
										}),
										'VENDOR NAME': po?.vendor?.vendor_name,
										STATUS: po?.status || 'N/A',
										RECEIVED: JSON.stringify({
											is_received: po.is_received,
											is_partially_received: po.is_partially_received,
											status: po?.status,
										}),
										BILLED: JSON.stringify({
											is_billed: po?.is_billed,
											is_partially_billed: po?.is_partially_billed,
										}),
										'Expected By':
											po?.expected_delivery_date === null
												? 'N/A'
												: getDate(po?.expected_delivery_date),
									};
								}),
								count: 1,
								onRowClick: (id) => {
									if (id) {
										navigate(`/user/${suburl}/purchases-order-details/${id}`);
									}
								},
							},
						}}
					/>

					<NonPdfView
						isPurchaseReceive={true}
						orderStatus={false}
						isShowProductDiscardButton={false}
						handleProductDetails={(product) => {
							setProductDetails(product);
							setIsSidebarOpen(true);
						}}
						setVendorDetailsDrawerActiveOpenTab={setVendorDetailsDrawerActiveOpenTab}
						setIsVendorDetailsDrawerOpen={setIsVendorDetailsDrawerOpen}
						handleDiscardQuantity={() => console.log('product details')}
						purchaseOrderDetails={{
							...purchaseReceiveDetails?.purchase_order,
							purchase_order_number: 'PR-' + purchaseReceiveDetails.purchase_receive_id,
							purchase_order_products: purchaseReceiveDetails.purchase_receive_products,
							expected_delivery_date: purchaseReceiveDetails?.receive_date,
							is_billed: purchaseReceiveDetails?.is_billed,
							is_received: purchaseReceiveDetails?.status,
							is_partially_billed: purchaseReceiveDetails?.is_partially_billed,
							is_draft: purchaseReceiveDetails?.status === 0 ? 1 : 0,
							...(purchaseReceiveDetails?.status === 0 ? { status: "Draft" } : {})
						}}
					/>
				</div>
			)}

			{isConvetToBillOpen && (
				<Modal
					className="convertToBillModal"
					isOpen={isConvetToBillOpen}
					onClose={setIsConvertToBillOpen}
					cancelButton={setIsConvertToBillOpen}
					title={
						<div
							style={{
								fontWeight: '600',
								color: '#000000',
								fontSize: '22px',
								paddingTop: '10px',
							}}
						>
							Purchase Receive ({purchaseReceiveDetails?.purchase_receive_number})
						</div>
					}
					headerClassName="custom-header"
					bodyClassName="convertToBillBody"
				>
					<div style={{ backgroundColor: '#E6F5FF', height: '36px', marginBottom: '36px' }}>
						<p
							style={{
								fontSize: '18px',
								color: '#555B67',
								paddingLeft: '10px',
								paddingTop: '3px',
								marginTop: '8px',
							}}
						>
							Note: You can create a bill for either Yet to Receive items or Received Items.
						</p>
					</div>

					<div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<input
									type="radio"
									id="yetToReceive"
									name="status"
									value="yetToReceive"
									style={{ marginBottom: '21px', width: '16px', height: '23px' }}
								/>
								<label
									htmlFor="yetToReceive"
									style={{ marginLeft: 5 }}
								>
									<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>
										Yet to Receive
									</div>
									<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
										Yet to receive product in this PO
									</div>
								</label>
							</div>

							<div style={{ display: 'flex', alignItems: 'center', marginLeft: '104px' }}>
								<input
									type="radio"
									id="received"
									name="status"
									value="received"
									style={{ marginBottom: '19px', width: '16px', height: '23px' }}
								/>
								<label
									htmlFor="received"
									style={{ marginLeft: 5 }}
								>
									<div style={{ margin: 0, color: '#555B67', fontSize: '16px' }}>Received</div>
									<div style={{ margin: 0, color: '#9FA6B5', fontSize: '16px' }}>
										Received product in this PO
									</div>
								</label>
							</div>
						</div>

						<div
							style={{
								marginTop: '20px',
								maxHeight: '85px',
								overflowY: 'hidden',
								width: '626px',
							}}
						>
							<Table
								coloum={convertToBillColumn}
								row={ConvertToBill}
								onRowClick={() => { }}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'end',
								gap: 4,
								marginTop: '60px',
							}}
						>
							<Button
								type="button"
								className="mt-0 btn-gray add-pro "
								button="Cancel"
							// onClick={() => setIsProductDetailsOpsen(false)}
							/>
							<Button
								type="button"
								className="mt-0 btn-primary add-pro"
								button="Convert To Bill"
							// onClick={handleDeletePurchaseOrder}
							/>
						</div>
					</div>
				</Modal>
			)}

			{/* Confirmation Modal */}
			<ConfirmationModal
				isOpen={isDeleteModalOpen}
				onCancel={handleCancelDelete}
				onOk={handleConfirmDelete}
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete{' '}
						<span style={{ fontWeight: '600' }}>
							{purchaseReceiveDetails?.purchase_receive_number || 'this purchase receive'}
						</span>
						?
					</p>
				}
			/>
			{isRestrictDeletePR && (
				<Modal
					className="deleteVendorsModal"
					title="Change Status PO"
					isOpen={isRestrictDeletePR}
					onClose={() => {
						setIsRestrictDeletePR(false);
					}}
					cancelButton={() => {
						setIsRestrictDeletePR(false);
					}}
				>
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '600',
							color: 'black',
						}}
					>
						{'Delete the associated Purchase Bill PB-' +
							purchaseReceiveDetails?.purchase_bill[0]?.purchase_bill_id +
							' before deleting the Purchase Receive.'}
					</p>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
							justifyContent: 'end',
						}}
					>
						<Button
							type="button"
							className="mt-0 btn-gray add-pro"
							button="Cancel"
							onClick={() => {
								handleConfirmDelete();
							}}
						/>

						<Button
							type="button"
							button={'Go to PB-' + purchaseReceiveDetails?.purchase_bill[0]?.purchase_bill_id}
							className="mt-0 px-4 btn-danger relative"
							onClick={() =>
								navigate(
									`/user/${suburl}/purchases-bills-details/${purchaseReceiveDetails?.purchase_bill[0]?.purchase_bill_id}`,
								)
							}
						/>
					</div>
				</Modal>
			)}

			{/* Sidebar ka layout */}
			{isSidebarOpen && (
				<ProductDetailsDrawer
					id={productDetails?.product_id}
					vendor={{
						vendor_id: purchaseReceiveDetails?.vendor?.vendor_id,
						vendor_name: purchaseReceiveDetails?.vendor?.vendor_name,
					}}
					setIsSidebarOpen={setIsSidebarOpen}
				/>
			)}
			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={purchaseReceiveDetails?.vendor?.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>
		</div>
	);
};

export default PurchaseReceiveDetails;
