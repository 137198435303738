import React, { useCallback, useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InputSelect from '../../component/inputSelect/InputSelect';
import PurchaseReceiveTable from './PurchaseReceiveTable';
import { fetchVendors } from '../../services/vendor';
import { toast } from 'react-toastify';
import { fetchPurchaseOrders } from '../../services/purchaseOrder';
import {
	create_purchase_receive,
	fetchPurchaseReceive,
	update_purchase_receive,
} from '../../services/purchase_receive';
import tooltipIcon from '../../assets/image/tooltip_vendor name.png';
import OpenPOAlert from '../../assets/image/Open PO Alert.svg';
import { convertToISO, getCurrentDate, getDate } from '../../utils/dateUtils';
import Modal from '../../component/modal/Modal';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';
import AddVendorModal from '../purchaseOrder/AddNewVendor';

const CreatePurchaseReceive = () => {
	const today = new Date();
	const formattedDate = today.toISOString().split('T')[0];
	const [currentDate, setCurrentDate] = useState(formattedDate);
	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const suburl = localStorage.getItem('suburl');
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [loading, setLoading] = useState({
		isLoading: false,
		loaderId: 'draft',
	});
	const [error, setError] = useState({});
	const [tableError, setTableError] = useState(false);
	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [searchVendor, setSearchVendor] = useState("")
	const [params, setParams] = useState({
		vendor: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
			status: 1,
		},
		purchaseOrder: {
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
			dropdownList: 'purchase_receive',
		},
	});
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
		purchaseOrderIdList: [],
	});
	const [purchaseReceiveFormData, setPurchaseReceiveFormData] = useState({
		vendor_id: null,
		purchase_order_number: '',
		delivery_address: '',
		receive_date: getCurrentDate(),
		purchase_order_id: null,
		purchase_receive_products: [],
	});

	const [isOpenPastDate, setIsOpenPastDate] = useState(false);
	const [isUsedPastDate, setIsUsedPastDate] = useState(false);

	const confirmUsedPastDate = () => {
		const newError = { ...error };
		delete newError.receive_date;
		setError(newError);
		setIsUsedPastDate(true);
		setIsOpenPastDate(false);
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	function handleFormData(name, val) {
		setPurchaseReceiveFormData((prev) => ({
			...prev,
			[name]: val,
		}));
		checkFormValidation(val, name);
	}

	const handleKeyDown = (name, event, val) => {
		setPurchaseReceiveFormData((prev) => ({
			...prev,
			[name]: val,
		}));
		if (event.key === 'Enter' || event.key === 'Tab') {
			checkFormValidation(val, name);
		}
	};

	function checkValidation() {
		const newError = { ...error };
		if (
			!purchaseReceiveFormData.vendor_id ||
			purchaseReceiveFormData.vendor_id === '' ||
			purchaseReceiveFormData.vendor_id === undefined
		) {
			newError.vendor_id = 'Please select valid Vendor name in the list';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}

		if (
			!purchaseReceiveFormData.purchase_order_id ||
			purchaseReceiveFormData.purchase_order_id === '' ||
			purchaseReceiveFormData.purchase_order_id === undefined
		) {
			newError.purchase_order_id = 'Please enter Purchase order number in the field';
		} else {
			delete newError.purchase_order_id; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!purchaseReceiveFormData.receive_date ||
			purchaseReceiveFormData.receive_date === '' ||
			purchaseReceiveFormData.receive_date === undefined ||
			(purchaseReceiveFormData.receive_date !== '' && newError.receive_date)
		) {
			newError.receive_date = newError.receive_date ?? 'Please enter Receive date in the field';
		} else {
			delete newError.receive_date; // Agar fill kiya hai to error ko remove karein
		}
		if (Object.keys(newError).length > 0 || tableError) {
			setError(newError);
			return true;
		} else {
			return false;
		}
	}

	//TODO: Saving the purcahse receive products
	async function handleSavePurchaseReceive(status) {
		let isError = checkValidation();
		if (isError) {
			toast.error('Unable to create purchase received');
		} else {
			try {
				setLoading((prev) => ({
					...prev,
					isLoading: true,
					loaderId: status,
				}));
				const body = {
					...purchaseReceiveFormData,
					status,
					purchase_receive_products:
						status === 0 &&
							purchaseReceiveFormData?.qty === '' &&
							purchaseReceiveFormData?.qty === null
							? []
							: purchaseReceiveFormData?.purchase_receive_products.map((prod) => {
								const { qty, is_in_transit, ...rest } = prod;
								return {
									...rest,
									received_quantity: Number(qty),
								};
							}),
				};
				await create_purchase_receive(body);
				toast.success('Purchase Receive Created Successfully');
				navigate(`/user/${suburl}/purchases-receive`);
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading((prev) => ({
					...prev,
					isLoading: false,
					loaderId: status,
				}));
			}
		}
	}

	async function handleUpdatePurchaseReceive(status) {
		let isError = checkValidation();
		if (isError) {
			toast.error('Unable to update purchase received');
		} else {
			try {
				setLoading((prev) => ({
					...prev,
					status: 1,
					isLoading: false,
				}));

				const body = {
					status,
					purchase_receive_id: Number(id.split('-')[1]),
					delivery_address: purchaseReceiveFormData.delivery_address,
					receive_date: convertToISO(purchaseReceiveFormData.receive_date),
					purchase_order_id: Number(purchaseReceiveFormData.purchase_order_id),
					purchase_receive_products: purchaseReceiveFormData?.purchase_receive_products.map(
						(prod) => {
							const { qty, is_in_transit, ...rest } = prod;
							return {
								...rest,
								received_quantity: Number(qty),
							};
						},
					),
				};

				await update_purchase_receive(body);
				toast.success('Purchase Receive Updated Successfully');
				navigate(`/user/${suburl}/purchases-receive`);
			} catch (error) {
				toast.error('Someting went wrong!');
			} finally {
				setLoading((prev) => ({
					...prev,
					status: 2,
					isLoading: false,
				}));
			}
		}
	}

	//TODO: fetching vendor list
	const handleFetchVendorList = useCallback(async () => {
		try {
			const { vendors } = await fetchVendors(params?.vendor);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			console.log(vendorOptionList);
			if (purchaseReceiveFormData.vendor_id) {
				const isVendorFound = vendorOptionList.find(
					(vendor) => vendor.key === purchaseReceiveFormData.vendor_id,
				);
				if (!isVendorFound) {
					setParams((prev) => ({
						...prev,
						vendor: {
							filterBy: 'vendor_id',
							filterValue: Number(purchaseReceiveFormData.vendor_id),
						},
					}));
				}
			} else {
				setInputSelectOption(() => ({
					vendorList: vendorOptionList,
				}));
			}
		} catch (error) {
			toast.error('Something went wrong!');
		}
	})

	function checkFormValidation(value, name) {
		let errorData = { ...error };

		if (name === 'vendor_id' && value !== '') {
			delete errorData.vendor_id;
		} else if (name === 'vendor_id' && (value === '' || value === null)) {
			errorData.vendor_id = 'Please select a valid vendor from the list.';
		}
		if (name === 'purchase_order_id' && value !== '') {
			delete errorData.purchase_order_id;
		} else if (name === 'purchase_order_id' && (value === '' || value === null)) {
			errorData.purchase_order_id = 'Please enter purchase order number in the field';
		}
		if (name === 'receive_date' && value !== '') {
			delete errorData.receive_date;
		} else if (name === 'receive_date' && (value === '' || value === null)) {
			errorData.receive_date = 'Please enter receive date in the field.';
		}
		setError(errorData);
	}

	//TODO: fetching purchase order number list according to the vendor id
	async function handleFetchPurchaseOrders() {
		try {
			console.log('check ' + purchaseReceiveFormData.vendor_id);
			const { purchaseOrders } = await fetchPurchaseOrders({
				...params.purchaseOrder,
				filterBy: 'vendor_id',
				filterValue: purchaseReceiveFormData.vendor_id,
			});

			const purchaseOrderIdList =
				purchaseOrders?.length > 0
					? purchaseOrders.map((po) => {
						return {
							key: po.purchase_order_id,
							value: po.purchase_order_number,
						};
					})
					: [];
			console.log(purchaseOrderIdList);
			setInputSelectOption((prev) => ({
				...prev,
				purchaseOrderIdList,
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	//TODO: fetching purchase order details products according to the specific purchase order
	async function handleFetchPurchaseOrder() {
		try {
			if (id && id.includes('pr')) return;
			const { purchaseOrders } = await fetchPurchaseOrders(
				id && id
					? { ...params?.purchaseOrder, filterBy: 'purchase_order_id', filterValue: Number(id) }
					: params.purchaseOrder,
			);
			const purchaseOrderDetails = purchaseOrders[0];
			const purchase_receive_products =
				purchaseOrderDetails.purchase_order_products.length > 0
					? purchaseOrderDetails.purchase_order_products?.map((pop) => {
						return {
							...pop,
							product_name: pop.product.product_name,
							order_quantity: pop.order_quantity,
							received_quantity: pop.received_quantity || 0,
							is_in_transit: 0,
							qty: 0,
						};
					})
					: [];

			setPurchaseOrderDetails(purchaseOrderDetails);
			setPurchaseReceiveFormData((prev) => ({
				...prev,
				delivery_address: purchaseOrderDetails.delivery_address,
				purchase_receive_products,
				...(id
					? {
						vendor_id: purchaseOrderDetails?.vendor_id,
						purchase_order_number: purchaseOrderDetails?.purchase_order_number,
						purchase_order_id: purchaseOrderDetails?.purchase_order_id,
					}
					: {}),
			}));
			setInputSelectOption((prev) => ({
				vendorList: [
					{
						key: purchaseOrderDetails?.vendor_id,
						value: purchaseOrderDetails?.vendor?.vendor_name,
					},
				],
				purchaseOrderIdList: [
					{
						key: purchaseOrderDetails?.purchase_order_id,
						value: purchaseOrderDetails?.purchase_order_number,
					},
				],
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleFetchPurchaseReceiveDetails(id) {
		try {
			const data = await fetchPurchaseReceive({
				filterBy: 'purchase_receive_id',
				filterValue: Number(id),
			});
			const purchaseReceiveData = data.purchaseReceive[0];

			setPurchaseReceiveFormData((prev) => ({
				...prev,
				vendor_id: purchaseReceiveData?.vendor_id,
				purchase_order_number: purchaseReceiveData?.purchase_order?.purchase_order_number,
				delivery_address: purchaseReceiveData?.delivery_address,
				receive_date: getDate(purchaseReceiveData?.receive_date, true),
				purchase_order_id: purchaseReceiveData?.purchase_order_id,
				purchase_receive_products:
					purchaseReceiveData.purchase_receive_products.length > 0
						? purchaseReceiveData.purchase_receive_products?.map((pop) => {
							return {
								...pop,
								product_name: pop.product.product_name,
								order_quantity: pop.order_quantity,
								received_quantity: pop.received_quantity || 0,
								is_in_transit: 0,
								qty: 0,
							};
						})
						: [],
			}));
			setInputSelectOption(() => ({
				vendorList: [
					{ key: purchaseReceiveData?.vendor_id, value: purchaseReceiveData?.vendor?.vendor_name },
				],
				purchaseOrderIdList: [
					{
						key: purchaseReceiveData?.purchase_order?.purchase_order_id,
						value: purchaseReceiveData?.purchase_order?.purchase_order_number,
					},
				],
			}));
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}


	useEffect(() => {
		handleFetchVendorList();
	}, [params?.vendor]);

	useEffect(() => {
		let timer = setTimeout(() => {
			setParams((prev) => ({
				...prev,
				vendor: {
					...prev.vendor,
					filterBy: "vendor_name",
					filterValue: searchVendor
				}
			}))
		}, 500)

		return () => clearTimeout(timer)
	}, [searchVendor])


	useEffect(() => {
		if (purchaseReceiveFormData.vendor_id && !id) {
			handleFetchPurchaseOrders();
		} else {
			setInputSelectOption((prev) => ({
				...prev,
				purchaseOrderIdList: [],
			}));
		}
	}, [purchaseReceiveFormData.vendor_id]);

	useEffect(() => {
		if (purchaseReceiveFormData.purchase_order_id && !id) handleFetchPurchaseOrder();
	}, [purchaseReceiveFormData.purchase_order_id]);

	useEffect(() => {
		if (id?.includes('pr')) {
			handleFetchPurchaseReceiveDetails(id.split('-')[1]);
		} else if (id) {
			handleFetchPurchaseOrder();
		}
	}, [id]);

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>{id && id.includes('pr') ? 'Update' : 'Create New '} Purchase Receive</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Draft"
								loader={loading?.status === 0 && loading.isLoading}
								onClick={() => {
									if (id && id.includes('pr')) {
										handleUpdatePurchaseReceive(0);
									} else {
										handleSavePurchaseReceive(0);
									}
								}}
							/>
							<Button
								type="button"
								className="btn-primary mt-0 px-4"
								button="Save as Received"
								loader={loading?.status === 1 && loading.isLoading}
								onClick={() => {
									if (id && id.includes('pr')) {
										handleUpdatePurchaseReceive(1);
									} else {
										handleSavePurchaseReceive(1);
									}
								}}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/purchases-receive`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col-6">
							<InputSelect
								options={inputSelectOption.vendorList}
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								returnInputValue={false}
								value={purchaseReceiveFormData?.vendor_id}
								onFocusCb={() => {
									if (inputSelectOption.vendorList.length === 0) {
										setParams((prev) => ({
											...prev,
											vendor: {
												...prev.vendor,
												filterBy: null,
												filterValue: null,
											},
										}));
									}
									handleFetchVendorList();
								}}
								onSelect={(option) => {
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										vendor_id: option.key,
									}));
									checkFormValidation(option.key, 'vendor_id');
								}}
								onChange={(value) => {
									if (value === '' || value.isEmpty) {
										checkFormValidation('', 'vendor_id');
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											vendor_id: null,
										}));
										setParams((prev) => ({
											...prev,
											vendor: {
												...prev.vendor,
												filterBy: null,
												filterValue: null,
											},
										}));
										handleFetchVendorList();
									} else {
										setSearchVendor(value)
									}
								}}
								// disabled={disabled}
								error={error?.vendor_id}
								autoFocus={true}
								leftIcons={
									purchaseReceiveFormData?.vendor_id
										? [
											{
												src: OpenPOAlert,
												alt: 'showVendorDetails',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Open POs');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
											{
												src: tooltipIcon, // Image 2 path
												alt: 'Icon 2',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Details');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
										]
										: []
								}
								onAddNew={() => setIsAddNewVendorModalOpen(true)}
							/>
						</div>
						<div className="col">
							<InputSelect
								options={inputSelectOption.purchaseOrderIdList}
								label="Purchase Order#"
								placeholder="Purchase Order Number"
								required={true}
								returnInputValue={false}
								value={purchaseReceiveFormData?.purchase_order_id}
								onSelect={(option) => {
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										purchase_order_id: option.key,
										purchase_order_number: option.value,
									}));
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_id',
											filterValue: option.key,
										},
									}));
									checkFormValidation(option.key, 'purchase_order_id');
								}}
								onChange={(value) => {
									if (value === '') {
										checkFormValidation('', 'purchase_order_id');
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											purchase_order_id: null,
											purchase_order_number: '',
										}));
										handleFetchPurchaseOrders();
									}
									setParams((prev) => ({
										...prev,
										purchaseOrder: {
											...prev.purchaseOrder,
											filterBy: 'purchase_order_number',
											filterValue: value,
										},
									}));
								}}
								// disabled={disabled}
								// required={true}
								error={error?.purchase_order_id}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Delivery Address"
								placeholder="Delivery Address"
								type="text"
								value={purchaseReceiveFormData.delivery_address}
								onChange={(value) =>
									setPurchaseReceiveFormData((prev) => ({
										...prev,
										delivery_address: value,
									}))
								}
							/>
						</div>

						<div className="col">
							<InputField
								label="Receive Date"
								placeholder="Receive  Date"
								type="date"
								max={currentDate}
								onKeyPress={(e) => {
									let value = e.target.value;
									const newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										// if (!isUsedPastDate) {
										// 	setIsOpenPastDate(true);
										// 	newError.receive_date = 'Date cannot be in the past.';
										// 	setError(newError);
										// } else {
										delete newError.receive_date;
										setError(newError);
										// }
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										newError.receive_date = 'Date cannot be in the future';
										setError(newError);
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									} else {
										delete newError.receive_date;
										setError(newError);
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									}
								}}
								value={purchaseReceiveFormData.receive_date}
								onChange={(value) => {
									const newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										// if (!isUsedPastDate) {
										// 	setIsOpenPastDate(true);
										// 	newError.receive_date = 'Date cannot be in the past.';
										// 	setError(newError);
										// } else {
										delete newError.receive_date;
										setError(newError);
										// }
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										newError.receive_date = 'Date cannot be in the future';
										setError(newError);
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									} else {
										delete newError.receive_date;
										setError(newError);
										setPurchaseReceiveFormData((prev) => ({
											...prev,
											receive_date: value,
										}));
									}
								}}
								required={true}
								error={error?.receive_date}
							/>
						</div>
					</div>
				</div>
				{/* order Table */}
				<PurchaseReceiveTable
					tableData={
						purchaseReceiveFormData.purchase_receive_products.length > 0 &&
						purchaseReceiveFormData.purchase_receive_products.filter(
							(prod) => prod.order_quantity !== prod.received_quantity,
						)
					}
					setTableData={(data) => {
						setPurchaseReceiveFormData((prev) => ({
							...prev,
							purchase_receive_products: data,
						}));
					}}
					disabled={false}
					paramId={null}
					tableError={tableError}
					setIsTableErrorShow={setTableError}
				/>
			</div>
			{isAddNewVendorModalOpen && (
				<AddVendorModal
					handleFormData={handleFormData}
					vendorList={inputSelectOption.vendorList}
					setVendorList={(value) =>
						setInputSelectOption((prev) => ({
							...prev,
							vendorList: value,
						}))
					}
					onClose={() => setIsAddNewVendorModalOpen(false)}
				/>
			)}
			{isOpenPastDate && (
				<Modal
					className="delivery-modal"
					title="Confirm Past Date"
					isOpen={isOpenPastDate}
					onClose={() => setIsOpenPastDate(false)}
					cancelButton={true}
				>
					<p>You are creating a transaction in the past date. Do you want to continue?</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							marginTop: '20px',
						}}
					>
						<Button
							button="Proceed"
							type="button"
							className="btn btn-primary"
							onClick={confirmUsedPastDate}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => setIsOpenPastDate(false)}
						/>
					</div>
				</Modal>
			)}
			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={purchaseReceiveFormData.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>
		</div>
	);
};

export default CreatePurchaseReceive;
