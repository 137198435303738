import { APP_API_URL } from '../../utils/xhr-instance';
import {
	CREATE_PURCHASE_ORDER,
	UPDATE_PURCHASE_ORDER_API_URL,
} from '../../store/action/purchaseOrder';
import axiosInstance from '../axios';

export const fetchPurchaseOrders = async (params) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.get(`${APP_API_URL}/purchase-order/get-purchase-order`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const deletePurchaseOrder = async (id) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.delete(
			`${APP_API_URL}/purchase-order/delete-purchaseorder/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data.data;
	} catch (error) {
		throw error;
	}
};

export async function fetchPurchaseOrderNumber() {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.get(
		`${APP_API_URL}/purchase-order/get-purchase-order-number`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data?.purchase_order_number;
}

export async function getDeliveryMethod(params) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.get(`${APP_API_URL}/purchase-order/get-delivery-method`, {
		params,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return data?.delivery_methods;
}

export async function addDeliveryMethod(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.post(
		`${APP_API_URL}/purchase-order/create-delivery-method`,
		body,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data?.delivery_method;
}

export async function create_purchase_order(body) {
	try {
		let API_URL = CREATE_PURCHASE_ORDER;
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(API_URL, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export async function update_purchase_order(id, body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.put(
			`${APP_API_URL}/purchase-order/update-purchase-order/${id}`,
			body,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export async function update_purchase_order_status(id, body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.put(
		`${APP_API_URL}/purchase-order/update-purchaseorder-status/${id}`,
		body,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
}

export async function discard_purchase_order_product_quantity(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.post(
		`${APP_API_URL}/purchase-order//discard-purchase-order-product-quantity`,
		body,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
}

export async function update_bulk_purchase_order_status(body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.put(
			`${APP_API_URL}/purchase-order/bulkupdate-purchaseorder-status`,
			body,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}

export async function sendEmail(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.post(`${APP_API_URL}/purchase-order/send-email`, body, {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	return data;
}

export async function exportPurchaseOrderData(params) {
	const token = localStorage.getItem('authToken');

	// Fetch the file as a blob
	const response = await axiosInstance.get(`${APP_API_URL}/purchase-order/export-table`, {
		params,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob', // Correctly placed responseType
	});

	// Convert the blob data into a downloadable URL
	const blob = new Blob([response.data], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = window.URL.createObjectURL(blob);

	// Create a temporary anchor tag and simulate click
	const a = document.createElement('a');
	a.href = url;
	a.download = `purchase_table.xlsx`; // File name
	document.body.appendChild(a);
	a.click();

	// Clean up
	a.remove();
	window.URL.revokeObjectURL(url);
}
