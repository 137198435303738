import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';
import InputSelect from '../../component/inputSelect/InputSelect';
import './CreditNote.scss';
import CreditNoteTable from './CreditNoteTable';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';
import { fetchVendors } from '../../services/vendor';
import { toast } from 'react-toastify';
import {
	create_credit_note,
	fetchCreditNoteDetails,
	update_credit_note,
} from '../../services/credit_note';
import { getDate } from '../../utils/dateUtils';
import Modal from '../../component/modal/Modal';
import AppendInput from '../../component/form/AppendInput';
import AddVendorModal from '../purchaseOrder/AddNewVendor';

const CreateCreditNote = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);
	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const param = useParams();
	const id = param?.id;
	const navigate = useNavigate();
	const [disabled, setDisabled] = useState(false);
	const [TableError, setTableError] = useState(false);
	const [vendorDetails, setVendorDetails] = useState(null);
	const [error, setError] = useState({});
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
	});
	const [additionDiscountAndTax, setAdditionDiscountAndTax] = useState({
		discount: 0,
		adj: 0,
		roundOff: 0,
	});
	const [subTotal, setSubTotal] = useState(0);
	const [taxTotal, setTaxTotal] = useState(0);
	const [loading, setLoading] = useState({
		isLoading: false,
		loaderId: 'draft',
	});

	const [creditNoteFormData, setCreditNoteFormData] = useState({
		vendor_id: null,
		credit_note_number: '',
		date: today,
		credit_note_date: today,
		reference_number: '',
		notes: '',
		credit_note_products: [],
	});
	const [params, setParams] = useState({
		vendor: {
			status: 1,
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
	});

	const [isOpenPastDate, setIsOpenPastDate] = useState(false);
	const [isUsedPastDate, setIsUsedPastDate] = useState(false);
	const [isOpenCNPastDate, setIsOpenCNPastDate] = useState(false);
	const [isUsedCNPastDate, setIsUsedCNPastDate] = useState(false);
	const [isCreditNoteDateFocus, setIsCreditNoteFocus] = useState(false);
	const [isReferenceFocus, setIsReferenceFocus] = useState(false);

	const confirmUsedPastDate = () => {
		const newError = { ...error };
		delete newError.date;
		setError(newError);
		setIsUsedPastDate(true);
		setIsOpenPastDate(false);
		setIsCreditNoteFocus(true);
	};
	const confirmUsedCNPastDate = () => {
		const newError = { ...error };
		delete newError.credit_note_date;
		setError(newError);
		setIsUsedCNPastDate(true);
		setIsOpenCNPastDate(false);
		setIsReferenceFocus(true);
	};

	const cancelUsedPastDate = () => {
		if (isOpenPastDate) {
			setIsOpenPastDate(false);
			setIsCreditNoteFocus(true);
		} else {
			setIsOpenCNPastDate(false);
			setIsReferenceFocus(true);
		}
	};

	async function handleFetchVendorList() {
		try {
			const { vendors } = await fetchVendors(params?.vendor);
			const vendorOptionList = vendors?.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			if (creditNoteFormData.vendor_id) {
				const isVendorFound = vendorOptionList.find(
					(vendor) => vendor.key === creditNoteFormData.vendor_id,
				);
				if (!isVendorFound) {
					setParams((prev) => ({
						...prev,
						vendor: {
							filterBy: 'vendor_id',
							filterValue: Number(creditNoteFormData.vendor_id),
						},
					}));
				}
			} else {
				setInputSelectOption((prev) => ({
					vendorList: vendorOptionList,
				}));
			}
			setVendorDetails(vendors);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	function handleKeyPress(name, e) {
		let { value } = e.target;
		if (e.key === 'Enter' || e.key === 'Tab') {
			handleFormData(name, value);
		}
	}

	function handleFormData(key, value) {
		setCreditNoteFormData((prev) => ({
			...prev,
			[key]: value,
		}));

		let errorData = { ...error };

		if (key === 'vendor_id' && (value === '' || value === null)) {
			errorData.vendor_id = 'Please select a valid vendor in the list';
		} else if (key === 'vendor_id' && (value !== '' || value !== null)) {
			delete errorData.vendor_id;
		}

		if (key === 'credit_note_number' && (value === '' || value === null)) {
			errorData.credit_note_number = 'Please enter credit note number in the field';
		} else if (key === 'credit_note_number' && (value !== '' || value !== null)) {
			delete errorData.credit_note_number;
		}

		if (key === 'date' && (value === '' || value === null)) {
			errorData.date = 'Please enter date in the field';
		} else if (key === 'date' && (value !== '' || value !== null)) {
			delete errorData.date;
		}

		if (key === 'credit_note_date' && (value === '' || value === null)) {
			errorData.credit_note_date = 'Please enter credit note date in the field';
		} else if (key === 'credit_note_date' && (value !== '' || value !== null)) {
			delete errorData.credit_note_date;
		}
		setError(errorData);
	}

	function checkValidation() {
		const newError = { ...error };
		if (
			!creditNoteFormData.vendor_id ||
			creditNoteFormData.vendor_id === '' ||
			creditNoteFormData.vendor_id === undefined
		) {
			newError.vendor_id = 'Please select a valid vendor name in the list';
		} else {
			delete newError.vendor_id;
		}
		if (
			!creditNoteFormData.credit_note_number ||
			creditNoteFormData.credit_note_number === '' ||
			creditNoteFormData.credit_note_number === undefined
		) {
			newError.credit_note_number = 'Please enter credit note number in the field';
		} else {
			delete newError.credit_note_number;
		}
		if (
			!creditNoteFormData.date ||
			creditNoteFormData.date === '' ||
			creditNoteFormData.date === undefined ||
			(creditNoteFormData.date !== '' && newError.date)
		) {
			newError.date = newError.date ?? 'Please enter date in the field';
		} else {
			delete newError.date;
		}

		if (
			!creditNoteFormData.credit_note_date ||
			creditNoteFormData.credit_note_date === '' ||
			creditNoteFormData.credit_note_date === undefined ||
			(creditNoteFormData.credit_note_date !== '' && newError.credit_note_date)
		) {
			newError.credit_note_date =
				newError.credit_note_date ?? 'Please enter  credit note date in the field';
		} else {
			delete newError.credit_note_date;
		}

		if (Object.keys(newError).length > 0 || TableError) {
			setError(newError);
			return false;
		} else {
			return false;
		}
	}

	async function handleSaveCreditNote(status) {
		const isErrorShow = checkValidation();
		if (isErrorShow) {
			toast.error('Unable to create or update credit note');
		} else {
			try {
				setLoading((prev) => ({
					...prev,
					isLoading: true,
					loaderId: status,
				}));
				const body = {
					...creditNoteFormData,
					status,
					credit_note_products:
						status === 0 &&
						creditNoteFormData?.credit_note_products.length < 2 &&
						creditNoteFormData?.credit_note_products[0].product_id === '' &&
						creditNoteFormData?.credit_note_products[0].batch_no === '' &&
						creditNoteFormData?.credit_note_products[0].quantity === 0
							? []
							: creditNoteFormData?.credit_note_products,
				};
				if (id) {
					await update_credit_note(id, body);
					toast.success('Credit Note updated Successfully');
				} else {
					await create_credit_note(body);
					toast.success('Credit Note Created Successfully');
				}
				navigate(`/user/${suburl}/credit-note`);
			} catch (error) {
				toast.error('Something went wrong!');
			} finally {
				setLoading((prev) => ({
					...prev,
					isLoading: false,
					loaderId: status,
				}));
			}
		}
	}

	async function handleFetchCreditNoteDetails() {
		try {
			const creditNoteDetails = await fetchCreditNoteDetails(id);
			const {
				credit_note_id,
				vendor_id,
				credit_note_number,
				date,
				credit_note_date,
				reference_number,
				notes,
				credit_note_products,
			} = creditNoteDetails;
			setCreditNoteFormData({
				credit_note_id,
				vendor_id,
				credit_note_number,
				date: getDate(date, true),
				credit_note_date: getDate(credit_note_date, true),
				reference_number,
				notes,
				credit_note_products:
					credit_note_products?.length === 0
						? []
						: credit_note_products?.map((cnp) => {
								return {
									credit_note_products_id: cnp?.credit_note_products_id,
									credit_note_id: cnp?.credit_note_id,
									product_id: cnp?.product_id,
									batch_no: cnp?.batch_no,
									quantity: cnp?.quantity,
									exp_date: getDate(cnp?.exp_date, true, true),
									mfg_date: getDate(cnp?.mfg_date, true, true),
									mrp: cnp?.mrp,
									rate: cnp?.rate,
									tax: cnp?.tax,
									amount: cnp?.amount,
								};
							}),
			});
		} catch (e) {
			toast.error('Something went wrong!');
		}
	}

	const navigateRouter = (path) => {
		navigate(path);
	};

	function subTotalAmount() {
		const totalAmount = Number(subTotal) + Number(taxTotal);

		return (
			Number(totalAmount) - (Number(totalAmount) * Number(additionDiscountAndTax.discount)) / 100
		);
		// return subTotalAmount;
	}

	useEffect(() => {
		if (id) {
			handleFetchCreditNoteDetails();
		}
	}, [id]);

	useEffect(() => {
		handleFetchVendorList();
	}, [params.vendor, id]);

	return (
		<div>
			<div className="">
				<div className="">
					<div className="product-pg-header add-product">
						<p>Create New Credit Note</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="mt-0 px-4 btn-primary"
								button="Save as Draft"
								loader={loading?.status === 0 && loading.isLoading}
								onClick={() => {
									handleSaveCreditNote(0);
								}}
							/>
							<Button
								type="button"
								className="mt-0 px-4 btn-primary"
								button="Save as Open"
								loader={loading?.status === 1 && loading.isLoading}
								onClick={() => {
									handleSaveCreditNote(1);
								}}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigateRouter(`/user/${suburl}/credit-note`)}
							/>
						</div>
					</div>
				</div>
				{/*  Vendor Form */}
				<div
					className="max-w-1297"
					style={{ padding: '1rem 2rem 0' }}
				>
					<div className="row">
						<div className="col">
							<InputSelect
								label="Vendor Name"
								placeholder="Vendor Name"
								required={true}
								options={inputSelectOption.vendorList}
								returnInputValue={false}
								value={creditNoteFormData?.vendor_id}
								onAddNew={() => setIsAddNewVendorModalOpen(true)}
								onSelect={(option) => {
									handleFormData('vendor_id', option.key);
									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_id',
											filterValue: option.key,
										},
									}));
								}}
								onChange={(value) => {
									if (value === '') {
										handleFormData('vendor_id', value);
									}
									setParams((prev) => ({
										...prev,
										vendor: {
											...prev.vendor,
											filterBy: 'vendor_name',
											filterValue: value,
										},
									}));
								}}
								error={error?.vendor_id}
								autoFocus={true}
								disabled={disabled}
							/>
							<a
								href="#"
								tabIndex={-1}
								style={{ fontSize: '12px', paddingTop: '0', marginTop: '0' }}
								onClick={() => {
									setVendorDetailsDrawerActiveOpenTab('Details');
									setIsVendorDetailsDrawerOpen(true);
								}}
							>
								View Vendor Details
							</a>
						</div>

						<div className="col">
							<InputField
								label="Credit Note #"
								placeholder="INV-008"
								type="text"
								required={true}
								onKeyPress={(e) => handleKeyPress('credit_note_number', e)}
								onChange={(value) => handleFormData('credit_note_number', value)}
								value={creditNoteFormData.credit_note_number}
								error={error?.credit_note_number}
								disabled={disabled}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Date"
								placeholder="dd/mm/yyyy"
								type="date"
								value={creditNoteFormData.date}
								onBlur={(e) => {
									let value = e.target.value;
									const newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										if (!isUsedPastDate) {
											setIsOpenPastDate(true);
											newError.date = 'Date cannot be in the past.';
											setError(newError);
										} else {
											delete newError.date;
											setError(newError);
										}
										setCreditNoteFormData((prev) => ({
											...prev,
											date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										setIsOpenPastDate(false);
										newError.date = 'Date cannot be in the future';
										setError(newError);
										setCreditNoteFormData((prev) => ({
											...prev,
											date: value,
										}));
									} else {
										setIsOpenPastDate(false);
										delete newError.date;
										setError(newError);
										setCreditNoteFormData((prev) => ({
											...prev,
											date: value,
										}));
									}
								}}
								onChange={(value) => {
									// const newError = { ...error };
									// var date = new Date(value);
									// var currentDate = new Date();
									// currentDate.setDate(currentDate.getDate() + 1);
									// let year = date.getFullYear().toString();
									// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									// 	if (!isUsedPastDate) {
									// 		setIsOpenPastDate(true);
									// 		newError.date = 'Date cannot be in the past.';
									// 		setError(newError);
									// 	} else {
									// 		delete newError.date;
									// 		setError(newError);
									// 	}
									// 	setCreditNoteFormData((prev) => ({
									// 		...prev,
									// 		date: value,
									// 	}));
									// } else if (
									// 	year.length === 4 &&
									// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
									// ) {
									// 	setIsOpenPastDate(false);
									// 	newError.date = 'Date cannot be in the future';
									// 	setError(newError);
									// 	setCreditNoteFormData((prev) => ({
									// 		...prev,
									// 		date: value,
									// 	}));
									// } else {
									// 	setIsOpenPastDate(false);
									// 	delete newError.date;
									// 	setError(newError);
									setCreditNoteFormData((prev) => ({
										...prev,
										date: value,
									}));
									// }
								}}
								required={true}
								error={error?.date}
								disabled={disabled}
							/>
						</div>
						<div className="col">
							<InputField
								label="Credit Note Date"
								placeholder="dd/mm/yyyy"
								type="date"
								autoFocus={isCreditNoteDateFocus}
								value={creditNoteFormData.credit_note_date}
								onBlur={(e) => {
									let value = e.target.value;
									const newError = { ...error };
									var date = new Date(value);
									var currentDate = new Date();
									currentDate.setDate(currentDate.getDate() + 1);
									let year = date.getFullYear().toString();
									if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
										if (!isUsedCNPastDate) {
											setIsOpenCNPastDate(true);
											newError.credit_note_date = 'Credit note date cannot be in the past.';
											setError(newError);
										} else {
											delete newError.credit_note_date;
											setError(newError);
										}
										setCreditNoteFormData((prev) => ({
											...prev,
											credit_note_date: value,
										}));
									} else if (
										year.length === 4 &&
										new Date(value) > currentDate.setHours(0, 0, 0, 0)
									) {
										setIsOpenCNPastDate(false);
										newError.credit_note_date = 'Credit note date cannot be in the future';
										setError(newError);
										setCreditNoteFormData((prev) => ({
											...prev,
											credit_note_date: value,
										}));
									} else {
										setIsOpenCNPastDate(false);
										delete newError.credit_note_date;
										setError(newError);
										setCreditNoteFormData((prev) => ({
											...prev,
											credit_note_date: value,
										}));
									}
								}}
								onChange={(value) => {
									// const newError = { ...error };
									// var date = new Date(value);
									// var currentDate = new Date();
									// currentDate.setDate(currentDate.getDate() + 1);
									// let year = date.getFullYear().toString();
									// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									// 	if (!isUsedCNPastDate) {
									// 		setIsOpenCNPastDate(true);
									// 		newError.credit_note_date = 'Credit note date cannot be in the past.';
									// 		setError(newError);
									// 	} else {
									// 		delete newError.credit_note_date;
									// 		setError(newError);
									// 	}
									// 	setCreditNoteFormData((prev) => ({
									// 		...prev,
									// 		credit_note_date: value,
									// 	}));
									// } else if (
									// 	year.length === 4 &&
									// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
									// ) {
									// 	setIsOpenCNPastDate(false);
									// 	newError.credit_note_date = 'Credit note date cannot be in the future';
									// 	setError(newError);
									// 	setCreditNoteFormData((prev) => ({
									// 		...prev,
									// 		credit_note_date: value,
									// 	}));
									// } else {
									// 	setIsOpenCNPastDate(false);
									// 	delete newError.credit_note_date;
									// 	setError(newError);
									setCreditNoteFormData((prev) => ({
										...prev,
										credit_note_date: value,
									}));
									// }
								}}
								required={true}
								error={error?.credit_note_date}
							/>
						</div>
						<div className="col">
							<InputField
								label="References # "
								placeholder="Ref-00005"
								type="text"
								value={creditNoteFormData.reference_number}
								onChange={(value) =>
									setCreditNoteFormData((prev) => ({
										...prev,
										reference_number: value,
									}))
								}
								disabled={disabled}
								autoFocus={isReferenceFocus}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<InputField
								label="Notes "
								placeholder="Notes"
								type="text"
								value={creditNoteFormData.notes}
								onChange={(value) =>
									setCreditNoteFormData((prev) => ({
										...prev,
										notes: value,
									}))
								}
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
				<br />

				{/* Credit Note Table */}
				<CreditNoteTable
					tableData={creditNoteFormData.credit_note_products}
					setTableData={(data) => {
						setCreditNoteFormData((prev) => ({
							...prev,
							credit_note_products: data,
						}));

						const totalAmount = creditNoteFormData?.credit_note_products?.reduce(
							(sum, item) => Number(sum) + Number(item.amount),
							0,
						);
						const totalAmountWithTax = creditNoteFormData?.credit_note_products?.reduce(
							(sum, item) =>
								Number(sum) +
								(Number(item.amount) + (Number(item.tax) * Number(item.amount)) / 100),
							0,
						);

						setTaxTotal(totalAmountWithTax - totalAmount);
						setSubTotal(totalAmount);
					}}
					disabled={disabled}
					paramId={id}
					tableError={TableError}
					setIsTableErrorShow={setTableError}
				/>
			</div>
			<br />
			<div
				style={{
					height: '112px',
					border: '1px solid #C3CBDC',
					backgroundColor: '#DADDEC',
					display: 'flex',
					marginTop: '20px',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div className="TotalBillFormDiv">
					<AppendInput
						label="Discont"
						type="text"
						appendLabel="%"
						onChange={(value) => {
							setAdditionDiscountAndTax((prev) => ({
								...prev,
								discount: value,
							}));
						}}
						value={additionDiscountAndTax.discount}
						className="custom-append-text"
						placeholder="0"
					/>

					<InputField
						label="Adj"
						style={{
							width: '106px',
						}}
						value={additionDiscountAndTax.adj}
						onChange={(value) => {
							setAdditionDiscountAndTax((prev) => ({
								...prev,
								adj: value,
							}));
						}}
						placeholder="0.00"
					/>
					<InputField
						label="Round Off"
						style={{
							width: '106px',
						}}
						value={additionDiscountAndTax.roundOff}
						onChange={(value) => {
							setAdditionDiscountAndTax((prev) => ({
								...prev,
								roundOff: value,
							}));
						}}
						placeholder="0.00"
					/>
				</div>
				<div className="TotalBillDivCredit">
					<div style={{ textAlign: 'right' }}>
						<p>Sub Total</p>
						<p>Taxes</p>
						<p>Bill Total </p>
					</div>
					<div style={{ textAlign: 'left' }}>
						<p>₹{subTotal.toFixed(2)}/-</p>
						<p>₹{taxTotal.toFixed(2)}/-</p>
						<p>
							₹{subTotalAmount().toFixed(2)}
							/-
						</p>
					</div>
				</div>
			</div>

			{isAddNewVendorModalOpen && (
				<AddVendorModal
					handleFormData={handleFormData}
					vendorList={inputSelectOption.vendorList}
					setVendorList={(value) =>
						setInputSelectOption((prev) => ({
							...prev,
							vendorList: value,
						}))
					}
					onClose={() => setIsAddNewVendorModalOpen(false)}
				/>
			)}

			{
				<VendorDetailsDrawer
					isOpen={isVendorDetailsDrawerOpen}
					onClose={() => setIsVendorDetailsDrawerOpen(false)}
					vendorDetails={vendorDetails}
				/>
			}
			{(isOpenPastDate || isOpenCNPastDate) && (
				<Modal
					className="delivery-modal"
					title="Confirm Past Date"
					isOpen={isOpenPastDate === false ? isOpenCNPastDate : isOpenPastDate}
					onClose={() => cancelUsedPastDate()}
					cancelButton={true}
				>
					<p>You are creating a transaction in the past date. Do you want to continue?</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							marginTop: '20px',
						}}
					>
						<Button
							button="Proceed"
							type="button"
							className="btn btn-primary"
							onClick={isOpenPastDate ? confirmUsedPastDate : confirmUsedCNPastDate}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => cancelUsedPastDate()}
						/>
					</div>
				</Modal>
			)}
			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={creditNoteFormData.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>
		</div>
	);
};

export default CreateCreditNote;
