import React, { useEffect, useState } from 'react';
import InputField from '../../component/form/FormInput';
import Button from '../../component/ui/Button';
import InputSelect from '../../component/inputSelect/InputSelect';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchVendors } from '../../services/vendor';
import { fetchPurchaseBill, fetchPurchaseBillByVendorId } from '../../services/purchase_bill';
import { getCurrentDate, getDate } from '../../utils/dateUtils';
import tooltipIcon from '../../assets/image/tooltip_vendor name.png';
import OpenPOAlert from '../../assets/image/Open PO Alert.svg';
import {
	create_payment_made,
	fetchPaymentMadeDetails,
	fetchPaymentMadeNumber,
	update_payment_made,
} from '../../services/payment_made';
import AppendInput from '../../component/form/AppendInput';
import { toast } from 'react-toastify';
import Modal from '../../component/modal/Modal';
import VendorDetailsDrawer from '../purchaseOrder/components/VendorDetailsDrawer';
import AddVendorModal from '../purchaseOrder/AddNewVendor';

const CreatePaymentMade = () => {
	const today = new Date().toISOString().split('T')[0];
	const suburl = localStorage.getItem('suburl');
	const navigate = useNavigate();
	const param = useParams();
	const id = param?.id;
	const [paymentAmount, setPaymentAmount] = useState('');
	const [inputSelectOption, setInputSelectOption] = useState({
		vendorList: [],
	});
	const [error, setError] = useState({});
	const [paymentMethodList, setPaymentMethodList] = useState([
		{ key: 'Cash', value: 'Cash' },
		{ key: 'IMPS', value: 'IMPS' },
		{ key: 'NEFT', value: 'NEFT' },
		{ key: 'RTGS', value: 'RTGS' },
	]);
	const [params, setParams] = useState({
		vendor: {
			status: 1,
			page: 1,
			pageSize: 20,
			orderBy: null,
			sortBy: null,
			filterBy: null,
			filterValue: null,
		},
	});
	const [isFullPayment, setIsFullPayment] = useState(false);
	const [isPaymentMethodFocus, setIsPaymentMethodFocus] = useState(false);
	const [paymentMadeFormData, setPaymentMadeFormData] = useState({
		vendor_id: null,
		payment_number: '',
		payment_amount: '',
		date: getCurrentDate(),
		payment_mode: '',
		reference_number: '',
		purchase_bills: [],
	});

	const [isAddNewVendorModalOpen, setIsAddNewVendorModalOpen] = useState(false);
	const [isOpenPastDate, setIsOpenPastDate] = useState(false);
	const [isUsedPastDate, setIsUsedPastDate] = useState(false);
	const [vendorDetailsDrawerActiveOpenTab, setVendorDetailsDrawerActiveOpenTab] = useState('');
	const [isVendorDetailsDrawerOpen, setIsVendorDetailsDrawerOpen] = useState(false);

	const confirmUsedPastDate = () => {
		const newError = { ...error };
		delete newError.date;
		setError(newError);
		setIsUsedPastDate(true);
		setIsOpenPastDate(false);
		setIsPaymentMethodFocus(true);
	};

	const cancelUsedPastDate = () => {
		setIsOpenPastDate(false);
		setIsPaymentMethodFocus(true);
	};

	const handlePaymentChange = (index, value) => {
		const paymentBillsData = [...paymentMadeFormData.purchase_bills];
		if (Number(paymentBillsData[index].due_amount) >= Number(value)) {
			paymentBillsData[index].paid_amount = value;
			setPaymentMadeFormData((prev) => ({
				...prev,
				purchase_bills: paymentBillsData,
			}));
		}
	};

	const handleFullPaymentToggle = (index) => {
		setIsFullPayment((prev) => !prev);
		if (!isFullPayment) {
			const paymentBillsData = [...paymentMadeFormData.purchase_bills];
			paymentBillsData[index].paid_amount = paymentBillsData[index].due_amount;
			setPaymentMadeFormData((prev) => ({
				...prev,
				purchase_bills: paymentBillsData,
			}));
		} else {
			const paymentBillsData = [...paymentMadeFormData.purchase_bills];
			paymentBillsData[index].paid_amount = 0;
			setPaymentMadeFormData((prev) => ({
				...prev,
				purchase_bills: paymentBillsData,
			}));
		}
	};

	const calculateTotalPayment = () => {
		return paymentMadeFormData?.purchase_bills?.reduce(
			(total, bill) => Number(total) + Number(bill.paid_amount),
			0,
		);
	};

	const navigateRouter = (path) => {
		navigate(path);
	};

	function handleKeyPress(name, e) {
		let { value } = e.target;
		if (e.key === 'Enter' || e.key === 'Tab') {
			handleFormData(name, value);
		}
	}

	function handleFormData(key, value) {
		setPaymentMadeFormData((prev) => ({
			...prev,
			[key]: value,
		}));
		if (key === 'payment_amount') {
			setPaymentAmount(value);
		}
		let errorData = { ...error };
		if (key === 'payment_amount' && (value === '' || value === null || value === undefined)) {
			errorData.payment_amount = 'Please enter payment amount in the field';
		} else if (key === 'payment_amount' && value !== '' && value !== null) {
			delete errorData.payment_amount;
		}

		if (key === 'vendor_id' && (value === '' || value === null)) {
			errorData.vendor_id = 'Please select a valid vendor in the list';
		} else if (key === 'vendor_id' && (value !== '' || value !== null)) {
			delete errorData.vendor_id;
		}

		if (key === 'payment_number' && (value === '' || value === null)) {
			errorData.payment_number = 'Please enter payment number in the field';
		} else if (key === 'payment_number' && (value !== '' || value !== null)) {
			delete errorData.payment_number;
		}

		if (key === 'date' && (value === '' || value === null)) {
			errorData.date = 'Please enter date in the field';
		} else if (key === 'date' && (value !== '' || value !== null)) {
			delete errorData.date;
		}

		if (key === 'payment_mode' && (value === '' || value === null)) {
			errorData.payment_mode = 'Please select a valid payment made in the list';
		} else if (key === 'payment_mode' && (value !== '' || value !== null)) {
			delete errorData.payment_mode;
		}
		setError(errorData);
	}

	function handleClearAppliedAmount() {
		const paymentBillsData = paymentMadeFormData.purchase_bills.map((item) => ({
			...item,
			paid_amount: '',
		}));
		setPaymentMadeFormData((prev) => ({
			...prev,
			purchase_bills: paymentBillsData,
		}));
	}

	// TODO: Search Vendor with api call,
	async function handleFetchSearchVendor() {
		try {
			const data = await fetchVendors(params?.vendor);

			const vendorOptionList = data.vendors.map((vendor) => {
				return {
					key: vendor?.vendor_id,
					value: vendor?.vendor_name,
				};
			});
			if (paymentMadeFormData.vendor_id) {
				const isVendorFound = vendorOptionList.find(
					(vendor) => vendor.key === paymentMadeFormData.vendor_id,
				);
				if (!isVendorFound) {
					setParams((prev) => ({
						...prev,
						vendor: {
							filterBy: 'vendor_id',
							filterValue: Number(paymentMadeFormData.vendor_id),
						},
					}));
				}
			} else {
				setInputSelectOption((prev) => ({
					vendorList: vendorOptionList,
				}));
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		handleFetchSearchVendor();
	}, [param.vendor]);

	useEffect(() => {
		handleFetchSearchVendor();
	}, []);

	async function handleFetchPurchaseBillByVendorId(vendor_id) {
		//TODO: add api to fetch purchase bill that is open / partially paid by vendor_id
		const purchaseBills = await fetchPurchaseBillByVendorId(vendor_id);
		setPaymentMadeFormData((prev) => ({
			...prev,
			purchase_bills: purchaseBills?.map((pb) => ({
				purchase_bill_id: pb?.purchase_bill_id,
				date: pb?.bill_date,
				due_date: pb?.due_date,
				bill_number: pb.bill_number,
				purchase_order_number:
					pb.purchase_order_number || pb?.purchase_order?.purchase_order_number || 'N/A',
				bill_amount: pb.bill_amount,
				due_amount: pb.due_amount,
				paid_amount: 0,
			})),
		}));
	}

	function checkValidation() {
		const newError = { ...error };
		if (
			!paymentMadeFormData.vendor_id ||
			paymentMadeFormData.vendor_id === '' ||
			paymentMadeFormData.vendor_id === undefined
		) {
			newError.vendor_id = 'Please select a valid vendor from the list.';
		} else {
			delete newError.vendor_id; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!paymentMadeFormData.payment_number ||
			paymentMadeFormData.payment_number === '' ||
			paymentMadeFormData.payment_number === undefined
		) {
			newError.payment_number = 'Please enter payment number in the field';
		} else {
			delete newError.payment_number; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!paymentMadeFormData.date ||
			paymentMadeFormData.date === '' ||
			paymentMadeFormData.date === undefined ||
			(paymentMadeFormData.date !== '' && newError.date)
		) {
			newError.date = newError.date ?? 'Please enter date in the field';
		} else {
			delete newError.date; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!paymentMadeFormData.payment_amount ||
			paymentMadeFormData.payment_amount === '' ||
			paymentMadeFormData.payment_amount === undefined
		) {
			newError.payment_amount = 'Please enter payment amount in the field';
		} else {
			delete newError.payment_amount; // Agar fill kiya hai to error ko remove karein
		}
		if (
			!paymentMadeFormData.payment_mode ||
			paymentMadeFormData.payment_mode === '' ||
			paymentMadeFormData.payment_mode === undefined
		) {
			newError.payment_mode = 'Please select a payment mode from the list.';
		} else {
			delete newError.payment_mode; // Agar fill kiya hai to error ko remove karein
		}

		// if (!paymentMadeFormData.reference_number ||
		// 	paymentMadeFormData.reference_number === '' ||
		// 	paymentMadeFormData.reference_number === undefined
		// ) {
		// 	newError.reference_number = 'Please enter reference number in the field';
		// } else {
		// 	delete newError.reference_number; // Agar fill kiya hai to error ko remove karein
		// }

		if (Object.keys(newError).length > 0 || Number(paymentAmount) !== calculateTotalPayment()) {
			setError(newError);
			return true;
		} else {
			return false;
		}
	}

	async function handleSavePaymentMade() {
		const checkError = checkValidation();
		if (checkError) {
			if (Number(paymentAmount) !== calculateTotalPayment()) {
				toast.error('Please enter payment less than or equal to payment amount');
			} else {
				toast.error('Unable to create or update payment made');
			}
		} else {
			try {
				if (id) {
					await update_payment_made(id, paymentMadeFormData);
					toast.success('Payment Made updated successfully.');
				} else {
					await create_payment_made(paymentMadeFormData);
					toast.success('Payment Made created successfully.');
				}
				navigateRouter(`/user/${suburl}/payment-made`);
			} catch (error) {
				toast.error('Something went wrong!');
			}
		}
	}

	async function handleFetchPaymentMadeDetails() {
		const data = await fetchPaymentMadeDetails(id);
		setPaymentMadeFormData({
			payment_made_id: data?.payment_made_id,
			vendor_id: data?.vendor_id,
			payment_number: data?.payment_number,
			payment_amount: data?.payment_amount,
			date: getDate(data?.date, true),
			payment_mode: data?.payment_mode,
			reference: data?.reference_number,
			purchase_bills: data?.purchase_bill_payment_made?.map((pb) => {
				return {
					purchase_bill_id: pb?.purchase_bill?.purchase_bill_id,
					date: pb?.purchase_bill?.bill_date,
					due_date: pb?.purchase_bill?.due_date,
					bill_number: pb?.purchase_bill?.bill_number,
					purchase_order_number:
						pb?.purchase_bill?.purchase_order_number ||
						pb?.purchase_bill?.purchase_order?.purchase_order_number,
					bill_amount: pb?.purchase_bill?.bill_amount,
					due_amount: pb?.purchase_bill?.due_amount,
					paid_amount: pb?.payment_amount,
				};
			}),
		});
		setPaymentAmount(data?.payment_amount);
	}

	useEffect(() => {
		if (id) {
			handleFetchPaymentMadeDetails();
		}
		if (!id) {
			async function handleFetchPaymentMadeNumber() {
				const payment_made_number = await fetchPaymentMadeNumber();
				handleFormData('payment_number', payment_made_number);
			}
			handleFetchPaymentMadeNumber();
		}
	}, [id]);

	return (
		<div>
			{/* Header Section */}
			<div className="product-pg-header add-product">
				<p>Create New Payment Made</p>
				<div className="add-product-button">
					<Button
						type="button"
						className="btn-primary mt-0 px-4"
						button="Save"
						onClick={handleSavePaymentMade}
					/>
					<Button
						type="button"
						className="mt-0 btn-danger add-pro"
						button=""
						close
						onClick={() => navigateRouter(`/user/${suburl}/payment-made`)}
					/>
				</div>
			</div>

			{/* Vendor Form Section */}
			<div
				className="max-w-1297"
				style={{ padding: '1rem 2rem 0' }}
			>
				<div className="row">
					<div className="col-6">
						<InputSelect
							options={inputSelectOption?.vendorList}
							label="Vendor Name"
							placeholder="Vendor Name"
							required={true}
							error={error?.vendor_id}
							value={paymentMadeFormData?.vendor_id}
							returnInputValue={false}
							onChange={(value) => {
								if (value === '') {
									handleFormData('vendor_id', value);
								}
								setParams((prev) => ({
									...prev,
									vendor: {
										filterBy: 'vendor_name',
										filterValue: value,
									},
								}));
							}}
							onSelect={(option) => {
								handleFormData('vendor_id', option.key);
								handleFetchPurchaseBillByVendorId(option.key);
							}}
							autoFocus={true}
							leftIcons={
								paymentMadeFormData?.vendor_id
									? [
											{
												src: OpenPOAlert,
												alt: 'showVendorDetails',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Open POs');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
											{
												src: tooltipIcon, // Image 2 path
												alt: 'Icon 2',
												onClick: () => {
													setVendorDetailsDrawerActiveOpenTab('Details');
													setIsVendorDetailsDrawerOpen(true);
												},
											},
										]
									: []
							}
							onAddNew={() => setIsAddNewVendorModalOpen(true)}
							className="labelClass"
						/>
					</div>
					<div className="col">
						<InputField
							label="Payment#"
							placeholder="INV-008"
							value={paymentMadeFormData.payment_number}
							required={true}
							onKeyPress={(e) => handleKeyPress('payment_number', e)}
							onChange={(value) => handleFormData('payment_number', value)}
							error={error?.payment_number}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<AppendInput
							label="Payment Amount"
							placeholder="Amount"
							appendLabel="INR"
							type="text"
							onKeyPress={(e) => handleKeyPress('payment_amount', e)}
							className="custom-append-text"
							error={error?.payment_amount}
							value={paymentMadeFormData?.payment_amount}
							onChange={(value) => {
								handleFormData('payment_amount', value);
							}}
						/>
						{/* <div style={{ display: 'flex', alignItems: 'center' }}>
							<input
								type="checkbox"
								id="payFullAmount"
								onChange={handleFullPaymentToggle}
								style={{ width: '18px', height: '20px', marginRight: '10px', marginBottom: '10px' }}
							/>
							<label
								htmlFor="payFullAmount"
								style={{ fontSize: '14px', display: 'flex' }}
							>
								<p style={{ color: '#555B67', fontWeight: 'bold' }}> Pay Full Amount</p>
								<b style={{ marginLeft: '8px' }}>(Rs. 6000.00)</b>
							</label>
						</div> */}
					</div>
					<div className="col-6">
						<InputField
							label="Date"
							placeholder="Date"
							onKeyPress={(e) => handleKeyPress('date', e)}
							type="date"
							error={error?.date}
							value={paymentMadeFormData.date}
							onBlur={(e) => {
								let value = e.target.value;
								const newError = { ...error };
								var date = new Date(value);
								var currentDate = new Date();
								currentDate.setDate(currentDate.getDate() + 1);
								let year = date.getFullYear().toString();
								if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
									if (!isUsedPastDate) {
										setIsOpenPastDate(true);
										newError.date = 'Date cannot be in the past.';
										setError(newError);
									} else {
										delete newError.date;
										setError(newError);
									}
									setPaymentMadeFormData((prev) => ({
										...prev,
										date: value,
									}));
								} else if (
									year.length === 4 &&
									new Date(value) > currentDate.setHours(0, 0, 0, 0)
								) {
									newError.date = 'Date cannot be in the future';
									setError(newError);
									setPaymentMadeFormData((prev) => ({
										...prev,
										date: value,
									}));
								} else {
									delete newError.date;
									setError(newError);
									setPaymentMadeFormData((prev) => ({
										...prev,
										date: value,
									}));
								}
							}}
							onChange={(value) => {
								// const newError = { ...error };
								// var date = new Date(value);
								// var currentDate = new Date();
								// currentDate.setDate(currentDate.getDate() + 1);
								// let year = date.getFullYear().toString();
								// if (year.length === 4 && new Date(value) < new Date().setHours(0, 0, 0, 0)) {
								// 	if (!isUsedPastDate) {
								// 		setIsOpenPastDate(true);
								// 		newError.date = 'Date cannot be in the past.';
								// 		setError(newError);
								// 	} else {
								// 		delete newError.date;
								// 		setError(newError);
								// 	}
								// 	setPaymentMadeFormData((prev) => ({
								// 		...prev,
								// 		date: value,
								// 	}));
								// } else if (
								// 	year.length === 4 &&
								// 	new Date(value) > currentDate.setHours(0, 0, 0, 0)
								// ) {
								// 	newError.date = 'Date cannot be in the future';
								// 	setError(newError);
								// 	setPaymentMadeFormData((prev) => ({
								// 		...prev,
								// 		date: value,
								// 	}));
								// } else {
								// 	delete newError.date;
								// 	setError(newError);
								setPaymentMadeFormData((prev) => ({
									...prev,
									date: value,
								}));
								// }
							}}
						/>
					</div>
				</div>
				<div
					className="row"
					style={{ marginTop: '10px' }}
				>
					<div className="col-6">
						<InputSelect
							label="Payment Method"
							placeholder="Payment Method"
							autoFocus={isPaymentMethodFocus}
							type="text"
							options={paymentMethodList}
							error={error.payment_mode}
							value={paymentMadeFormData.payment_mode}
							onChange={(value) => handleFormData('payment_mode', value)}
							onSelect={(option) => {
								handleFormData('payment_mode', option.value);
							}}
							onAddNew={() => setIsAddNewVendorModalOpen(true)}
						/>
					</div>
					<div className="col">
						<InputField
							label="Reference#"
							placeholder="REF-0008"
							type="text"
							value={paymentMadeFormData.reference_number}
							onChange={(value) => handleFormData('reference_number', value)}
						/>
					</div>
				</div>
			</div>

			{/* Order Table Section */}
			<p
				style={{
					float: 'right',
					display: 'block',
					marginRight: '20px',
					borderBottom: '1px solid #3B58FF',
					color: '#3B58FF',
					fontWeight: 'bold',
					cursor: 'pointer',
				}}
				onClick={handleClearAppliedAmount}
			>
				Clear Applied Amount
			</p>
			<div style={{ margin: '20px 16px', Width: '97%' }}>
				<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
					<thead>
						<tr style={{ backgroundColor: '#f8f9fa', color: '#555B67' }}>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
								Date
							</th>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
								Bill#
							</th>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
								PO#
							</th>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'right' }}>
								Bill Amount
							</th>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'right' }}>
								Amount Due
							</th>
							<th style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'right' }}>
								Payment
							</th>
						</tr>
					</thead>
					<tbody style={{ color: '#555B67', fontWeight: 'bold' }}>
						{paymentMadeFormData?.purchase_bills?.map((pb, index) => (
							<tr
								key={index}
								style={{
									backgroundColor: index === 1 ? '#f0f8ff' : 'transparent',
								}}
							>
								<td style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
									{getDate(pb?.date)}
									<br />
									<span style={{ fontSize: '12px' }}>
										Due Date:{' '}
										<span style={{ color: '#000000', fontWeight: 'bold' }}>
											{getDate(pb?.due_date)}
										</span>
									</span>
								</td>
								<td style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
									{pb?.bill_number}
								</td>
								<td style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'left' }}>
									{pb?.purchase_order_number}
								</td>
								<td style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'right' }}>
									{pb?.bill_amount}
								</td>
								<td style={{ border: '1px solid #dee2e6', padding: '8px', textAlign: 'right' }}>
									{pb?.due_amount || 0}
								</td>
								<td style={{ border: '1px solid #dee2e6', padding: '8px' }}>
									<InputField
										type="text"
										value={pb?.paid_amount}
										onChange={(value) => handlePaymentChange(index, value)}
										style={{ width: '165px', textAlign: 'right', float: 'right' }}
									/>
									<div
										style={{
											fontSize: '12px',
											color: '#3B58FF',
											fontWeight: 'bold',
											float: 'right',
											cursor: 'pointer',
											marginBottom: '4px',
											marginLeft: '32px',
											borderBottom: '1px solid #3B58FF',
										}}
										onClick={() => handleFullPaymentToggle(index)}
									>
										Pay in Full
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{/* Total Section */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						padding: '10px',
						backgroundColor: '#f8f9fa',
						border: '1px solid #dee2e6',
					}}
				>
					<div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
						<span style={{ fontWeight: 'bold', color: '#555B67' }}>Total :</span>
						<span style={{ fontWeight: 'bold' }}>
							₹ {calculateTotalPayment()?.toFixed(2) || 'N/A'}
						</span>
					</div>
				</div>
			</div>

			{/* Summary Section */}
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-start',
					padding: '10px',
					backgroundColor: '#f8f9fa',
					width: '355px',
					float: 'right',
					marginRight: '18px',
				}}
			>
				<div>
					<div className="d-flex">
						<div style={{ color: '#000000', fontWeight: '600', textAlign: 'right' }}>
							<p>Amount Paid</p>
							<p>Amount Used For Payment</p>
							<p>Amount Refunded</p>
							<p style={{ display: 'flex', paddingLeft: '40px' }}>
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.71758 2.89393L1.36508 13.4989C1.2341 13.7258 1.1648 13.9829 1.16407 14.2448C1.16334 14.5067 1.23119 14.7643 1.3609 14.9918C1.4906 15.2194 1.67762 15.409 1.90336 15.5418C2.12909 15.6746 2.38568 15.7461 2.64758 15.7489H15.3526C15.6145 15.7461 15.8711 15.6746 16.0968 15.5418C16.3225 15.409 16.5096 15.2194 16.6393 14.9918C16.769 14.7643 16.8368 14.5067 16.8361 14.2448C16.8354 13.9829 16.7661 13.7258 16.6351 13.4989L10.2826 2.89393C10.1489 2.67351 9.96062 2.49127 9.73597 2.3648C9.51133 2.23832 9.25788 2.17188 9.00008 2.17188C8.74228 2.17188 8.48882 2.23832 8.26418 2.3648C8.03954 2.49127 7.85128 2.67351 7.71758 2.89393Z"
										stroke="#BF9A18"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M9 6.75V9.75"
										stroke="#BF9A18"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M9 12.75H9.0075"
										stroke="#BF9A18"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								Amount Excess
							</p>
						</div>
						<div style={{ marginLeft: '35px', fontWeight: '600' }}>
							<p>₹ {calculateTotalPayment()?.toFixed(2) || 'N/A'}/-</p>
							<p>₹ {calculateTotalPayment()?.toFixed(2) || 'N/A'}/-</p>
							<p>00.00/-</p>
							<p>00.00/-</p>
						</div>
					</div>
				</div>
			</div>
			{isAddNewVendorModalOpen && (
				<AddVendorModal
					handleFormData={handleFormData}
					vendorList={inputSelectOption.vendorList}
					setVendorList={(value) =>
						setInputSelectOption((prev) => ({
							...prev,
							vendorList: value,
						}))
					}
					onClose={() => setIsAddNewVendorModalOpen(false)}
				/>
			)}
			{isOpenPastDate && (
				<Modal
					className="delivery-modal"
					title="Confirm Past Date"
					isOpen={isOpenPastDate}
					onClose={() => cancelUsedPastDate()}
					cancelButton={true}
				>
					<p>You are creating a transaction in the past date. Do you want to continue?</p>

					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							marginTop: '20px',
						}}
					>
						<Button
							button="Proceed"
							type="button"
							className="btn btn-primary"
							onClick={confirmUsedPastDate}
						/>
						<span style={{ width: '20px' }}></span>
						<Button
							button="Cancel"
							type="button"
							className="btn btn-danger"
							onClick={() => cancelUsedPastDate()}
						/>
					</div>
				</Modal>
			)}
			<VendorDetailsDrawer
				isOpen={isVendorDetailsDrawerOpen}
				onClose={() => setIsVendorDetailsDrawerOpen(false)}
				vendor_id={paymentMadeFormData.vendor_id}
				vendorDetailsDrawerActiveOpenTab={vendorDetailsDrawerActiveOpenTab}
			/>
		</div>
	);
};

export default CreatePaymentMade;
