import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import { CreditNoteColoum } from './creditNotecoloum';
import DeleteCrediteNoteModel from './components/DeleteCrediteNoteModel';
import { delete_credit_note, fetchCreditNoteList } from '../../services/credit_note';
import { getDate } from '../../utils/dateUtils';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import addFilter from '../../assets/image/AddFilter.svg';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';
import { clearAdvanceFilter, updateAdvanceFilter } from '../../store/action/advanceFilter';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../../services/axios';
import { APP_API_URL } from '../../utils/xhr-instance';

const CreditNote = () => {
	const { advanceFilterReducer } = useSelector((state) => state);
	const suburl = localStorage.getItem('suburl');
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [headerDropDown, setHeaderDropDown] = useState('All Credit Notes');
	const [sortDirection, setSortDirection] = useState({ credit_note_id: 'asc' });
	const [creditNote, setCreditNote] = useState({
		data: [],
		totalPages: 0,
		currentPage: 1,
	});
	const [selectedVendorsListForDelete, setSelectedVendorsListForDelete] = useState([]);
	const [deselectRows, setDeselectRows] = useState(null);
	const [stateCondition, setStateCondition] = useState('');
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [creditNoteParams, setCreditNoteParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'credit_note_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [isAppliedAdvanceFilter, setIsAppliedAdvanceFilter] = useState(false);
	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		totalPages: 0,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);

	const navigate = useNavigate();

	const navigateRouter = (path) => {
		navigate(path);
	};
	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/credit-note-details/${id}`);
		}
	};
	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};
	async function getCreditNoteList() {
		try {
			setLoader(true);
			const { data, totalPages } = await fetchCreditNoteList(creditNoteParams);
			const creditNoteData = data?.map((ele) => {
				return {
					id: ele.credit_note_id,
					DATE: getDate(ele?.date),
					CN: JSON.stringify({ cn: ele?.credit_note_number, is_draft: ele?.status }),
					'VENDOR NAME': ele?.vendor?.vendor_name,
					status: ele?.status === 0 ? 'N/A' : ele?.status === 1 ? 'Active' : 'Closed',
					AMOUNT: Number(ele?.credit_note_amount) || 0,
				};
			});
			setCreditNote((prev) => ({
				...prev,
				data: creditNoteData,
				totalPages,
			}));
		} catch (error) {
			toast.error('somethings went wrong!');
		} finally {
			setLoader(false);
		}
	}

	async function handleAdvanceFilterDropdown(option) {
		if (!option) return;

		const { key, value } = option;
		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === key);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: key,
				label: value,
				data: [],
			},
		]);
	}

	// removing the selected inputs from both modal and tables
	function handleRemoveSelectedFilterInput(key) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
	}

	async function handleOnFocusSelectedFilterInput(key) {
		let vendorList = [];
		const data = await fetchCreditNoteList({ filterBy: key, filterValue: '' });
		const selectInputData = data?.data?.reduce((acc, cn) => {
			if (key === 'vendor_name') {
				acc.push({
					key: cn.vendor?.vendor_name,
					value: cn.vendor?.vendor_name,
				});
				return acc;
			} else if (key === 'credit_note_number') {
				acc.push({
					key: cn.credit_note_number,
					value: cn.credit_note_number,
				});
				return acc;
			} else {
				acc.push({
					key: cn.credit_note_amount,
					value: cn.credit_note_amount,
				});
				return acc;
			}
		}, []);

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === key) {
				return {
					...item,
					data: [...vendorList, ...selectInputData],
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
	}

	// searching in the option list for selected input
	async function handleOnKeyDownCb(key, value) {
		try {
			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchCreditNoteList(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});
			setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] =
					item.key === 'purchase_receive_id' ? item?.value.split('-')[1] : item?.value;
				return acc;
			}
			return acc;
		}, {});

		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axiosInstance.post(
				`${APP_API_URL}/credit-note/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);
			const filterData = data?.data?.map((ele) => {
				return {
					id: ele.credit_note_id,
					DATE: getDate(ele?.date),
					CN: JSON.stringify({ cn: ele?.credit_note_number, is_draft: ele?.status }),
					'VENDOR NAME': ele?.vendor?.vendor_name,
					status: ele?.status === 0 ? 'N/A' : ele?.status === 1 ? 'Active' : 'Closed',
					AMOUNT: Number(ele?.credit_note_amount) || 0,
				};
			});
			setCreditNote((prev) => ({
				...prev,
				data: filterData,
				totalPages: data?.totalPages,
			}));
			// setTableData(purchaseOrderFilterData);
			// setTotalPages(data?.data?.totalPages);
			setAdvanceFilterParams((prev) => ({
				...prev,
				totalPages: data?.data?.totalPages,
				pageSize: data?.data?.pageSize,
				filters: {
					...prev.filters,
					...params,
				},
			}));

			dispatch(
				updateAdvanceFilter({
					filters: { ...advanceFilterParams.filters, ...params },
					selectedInputs: advanceFilterSelectInputList,
				}),
			);
			setIsAdvanceFilterOpen(false);
			setIsAppliedAdvanceFilter(true);
		} catch (error) {
			toast.error('Something went wrong! Try again.' + error);
		}
	}

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setCreditNoteParams((prev) => ({
			...prev,
			...param,
			page: 1,
			pageSize: 20,
		}));
	};

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setCreditNoteParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
		}));
	};

	const updateURLWithFilters = (filters) => {
		const urlParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				urlParams.append(key, value);
			}
		});
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		window.history.replaceState(null, '', newUrl);
	};
	const handleClearAdvanceFilter = useCallback(() => {
		setIsAppliedAdvanceFilter(false);
		setadvanceFilterSelectInputList([]);
		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {},
		}));
		setCreditNoteParams((prev) => ({
			...prev,
			filterBy: null,
			filterValue: null,
		}));
		dispatch(clearAdvanceFilter());
	}, []);

	useEffect(() => {
		if (advanceFilterSelectInputList.length === 0 && isAppliedAdvanceFilter) {
			handleClearAdvanceFilter();
		}

		if (advanceFilterSelectInputList.length > 0 && isAppliedAdvanceFilter) {
			handleApplyFilters();
		}
	}, [advanceFilterSelectInputList, isAppliedAdvanceFilter]);

	// Update the URL whenever advanced filter parameters change
	useEffect(() => {
		const activeFilters = advanceFilterSelectInputList.reduce((acc, filter) => {
			if (filter?.key && filter?.value) {
				acc[filter.key] = filter.value;
			}
			return acc;
		}, {});

		if (Object.keys(activeFilters).length > 0) {
			updateURLWithFilters(activeFilters);
		}
	}, [advanceFilterSelectInputList]);

	useEffect(() => {
		const filtersFromRedux = advanceFilterReducer?.filters || {};

		if (Object.keys(filtersFromRedux).length === 0) {
			getCreditNoteList();
		}
		if (Object.keys(filtersFromRedux).length > 0) {
			setAdvanceFilterParams((prev) => ({
				...prev,
				filters: {
					...prev.filters,
					...filtersFromRedux,
				},
			}));
			setadvanceFilterSelectInputList(advanceFilterReducer?.selectedInputs);
			setIsAppliedAdvanceFilter(true);
		}
	}, [creditNoteParams, advanceFilterReducer?.filters]);

	// useEffect(() => {
	// 	let param = `${filterparam}${filterparam && '&'}${sortParam}${
	// 		sortParam && '&'
	// 	}page=${currentPage}&pageSize=20`;
	// 	getCreditNoteList(param);
	// }, [sortParam, filterparam, currentPage]);

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleBulkDelete() {
		try {
			await delete_credit_note(bulkAction);
			toast.success('Credit Note Deleted Successfully.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction([]);
			getCreditNoteList();
			deselectRows && deselectRows();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	function handleOpenBulkDeleteCrediteNoteModal() {
		const selectedVendorsList = creditNote?.data?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(JSON.parse(item.CN)?.cn);
				return acc;
			}
			return acc;
		}, []);
		setSelectedVendorsListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							onClick={() => window.print()}
						/>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray relative"
							onClick={handleOpenBulkDeleteCrediteNoteModal}
						></Button>

						<Button
							type="button"
							button="Bulk Update"
							className="mt-0 px-4 btn-gray relative"
						></Button>

						<Button
							type="button"
							className="mt-0 btn-danger add-pro"
							button=""
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						className="dropdown text-end d-flex"
						style={{ gap: '12px' }}
					>
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{
												orderBy: 'credit_note_id',
												sortBy: 'asc',
												filterBy: null,
												filterValue: null,
											},
											'',
											'Active Credit Notes',
										)
									}
								>
									All Credit Notes
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 0 }, 'active', 'Draft')
									}
								>
									Draft
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 1 }, 'active', 'Open')
									}
								>
									Active
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist({ filterBy: 'status', filterValue: 2 }, 'active', 'Close')
									}
								>
									Close
								</a>
							</li>
						</ul>
						<div>
							{isAppliedAdvanceFilter ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={handleClearAdvanceFilter}
									/>
									<a
										onClick={handleClearAdvanceFilter}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Credit Note"
							add
							onClick={() => navigateRouter(`/user/${suburl}/credit-note-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>

								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'updated_at', sortBy: 'asc' })}
									>
										Modified Time
										<span>{sortIconMap[sortDirection?.updated_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
									>
										Date
										<span>{sortIconMap[sortDirection?.date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'credit_note_date', sortBy: 'asc' })}
									>
										Credit Note Date
										<span>{sortIconMap[sortDirection?.credit_note_date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'credit_note_number', sortBy: 'asc' })}
									>
										Credit Note#
										<span>{sortIconMap[sortDirection?.credit_note_number]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'amount', sortBy: 'asc' })}
									>
										Amount
										<span>{sortIconMap[sortDirection?.amount]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`credit_note`)}
									>
										Export Credit Note
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'credit_note_id', sortBy: 'asc' })}
									>
										Refresh
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{isAppliedAdvanceFilter && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{advanceFilterSelectInputList.map((item) => (
							<AdvanceFilterSelectInput
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key);
								}}
								onKeyDownCb={(value) => {
									handleOnKeyDownCb(item?.key, value);
								}}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value: value.value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
								className="selectInput"
							/>
						))}
						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			{isDeleteBulkConfirmationModalOpen && (
				<DeleteCrediteNoteModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedVendorsListForDelete={selectedVendorsListForDelete}
					handleBulkDelete={handleBulkDelete}
					purchaseOrderLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={creditNote?.data}
						coloum={CreditNoteColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={creditNote?.currentPage}
				totalPages={creditNote?.totalPages}
				onChangePage={(page) =>
					setCreditNoteParams((prev) => ({
						...prev,
						page,
					}))
				}
			/>

			<AdvanceFilter
				modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
				isOpen={isAdvanceFilterOpen}
				onKeyDownCb={handleOnKeyDownCb}
				advanceFilterOptions={advanceFilterOptions.credit_note}
				handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
				advanceFilterSelectInputList={advanceFilterSelectInputList}
				handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
				onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
				handleOnChangeSelectedFilterInput={(key, value) => {
					const updatedData = advanceFilterSelectInputList.map((item) => {
						if (item?.key === key) {
							return {
								...item,
								value,
							};
						}

						return item;
					});
					setadvanceFilterSelectInputList(updatedData);
				}}
				onClose={() => {
					setIsAdvanceFilterOpen(false);
				}}
				onApply={handleApplyFilters}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
		</div>
	);
};

export default CreditNote;
