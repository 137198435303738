import React from 'react';
import Delete from '../../../assets/image/delete.svg';
import './index.scss';
import { getDate } from '../../../utils/dateUtils';

function NonPdfView({
	handleProductDetails,
	handleDiscardQuantity,
	purchaseOrderDetails,
	isPurchaseReceive = false,
	isShowProductDiscardButton = true,
	setVendorDetailsDrawerActiveOpenTab,
	setIsVendorDetailsDrawerOpen,
	orderStatus = true,
}) {
	function getColorCode(color) {
		let statusCode = '';
		switch (color) {
			case 'Received':
				statusCode = '#6AA247';
				break;
			case 'Pending':
				statusCode = '#FFC008';
				break;
			case 'Pending Approval':
				statusCode = '#3B58FF';
				break;
			case 'Approved':
				statusCode = '#3B58FF';
				break;
			case 'Issued':
				statusCode = '#3B58FF';
				break;
			case 'Closed':
				statusCode = '#989C95';
				break;
			case 'Deleted':
				statusCode = '#D30000';
				break;
			case 'Partially Received':
				statusCode = '#FFA500';
				break;
			case 'In-Transit':
				statusCode = '#11caf0';
				break;
			case 'Draft':
				statusCode = '';
				break;
			default:
				statusCode = '#DC3545';
				break;
		}

		return statusCode;
	}

	return (
		<div>
			<div className={'invoice'}>
				<div className="header">
					<div>
						<h1 style={{ fontSize: '22px' }}>{purchaseOrderDetails?.purchase_order_number}</h1>
						<br />
						<div>
							<div
								style={{
									color: '#555B67',
									fontWeight: '600',
									fontSize: '14px',
									marginBottom: '5px',
								}}
							>
								STATUS
							</div>
							<div
								className="d-flex"
								style={{
									gap: '30px',
									borderLeft: '2px solid #3B58FF',
									paddingLeft: '14px',
									marginBottom: '16px',
								}}
							>
								<div style={{ marginTop: '6px' }}>
									{!isPurchaseReceive && <p style={{ marginBottom: '17px' }}>Order</p>}
									<p>Receive</p>
									<p>Bill</p>
								</div>
								<div style={{ marginTop: '6px', marginLeft: '30px' }}>
									{!isPurchaseReceive && (
										<p
											style={{
												padding: purchaseOrderDetails?.status === 'Draft' ? '' : '0px 10px',
												marginBottom: '17px',
												// backgroundColor: purchaseOrderDetails?.is_closed
												// 	? '#6AA247'
												// 	: purchaseOrderDetails?.is_open
												// 		? 'red'
												// 		: '',
												backgroundColor: getColorCode(purchaseOrderDetails?.status),
												color: purchaseOrderDetails?.status === 'Draft' ? 'black' : '#fff',
												borderRadius: '5px',
												textAlign: purchaseOrderDetails?.status === 'Draft' ? 'left' : 'center',
											}}
										>
											{purchaseOrderDetails?.status}
										</p>
									)}
									<p
										style={{
											color:
												purchaseOrderDetails.status === 'Draft'
													? 'black'
													: purchaseOrderDetails?.is_received === 1
														? '#6AA247'
														: purchaseOrderDetails?.is_partially_received === 1
															? '#FFA500'
															: 'red',
										}}
									>
										{purchaseOrderDetails?.is_received === 1
											? 'Received'
											: purchaseOrderDetails?.is_partially_received === 1
												? 'Partially Received'
												: 'N/A'}
										{}
									</p>
									<p
										style={{
											color:
												purchaseOrderDetails.status === 'Draft'
													? 'black'
													: purchaseOrderDetails?.is_billed === 1
														? '#6AA247'
														: 'red',
										}}
									>
										{purchaseOrderDetails.status === 'Draft'
											? 'N/A'
											: purchaseOrderDetails?.is_billed === 1
												? 'Billed'
												: 'Pending'}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="bill-info">
						<h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>VENDOR NAME</h2>
						<p style={{ textAlign: 'start' }}>
							<a
								href="#"
								style={{ textDecoration: 'none' }}
								onClick={() => {
									setVendorDetailsDrawerActiveOpenTab('Details');
									setIsVendorDetailsDrawerOpen(true);
								}}
							>
								{purchaseOrderDetails?.vendor?.vendor_name}
							</a>
						</p>
						<div style={{ marginTop: '33px' }}>
							<h2 style={{ color: '#555B67', fontSize: '16px', textAlign: 'start' }}>
								DELIVERY ADDRESS
							</h2>
							<p style={{ textAlign: 'start', marginTop: '12px' }}>
								{purchaseOrderDetails?.delivery_address || 'N/A'}
							</p>
						</div>
					</div>
				</div>

				<div className="delivey-details">
					<div className="address-to">
						<div
							className="d-flex"
							style={{ gap: '25px' }}
						>
							<div style={{ color: '#555B67', fontWeight: '600' }}>
								<p>ORDER DATE</p>
								<p>
									{purchaseOrderDetails?.purchase_order_number.includes('PR-')
										? 'RECEIVE DATE'
										: 'EXPECTED DELIVERY DATE'}
								</p>
							</div>
							<div style={{ color: '#000', fontWeight: 'bold' }}>
								<p>{getDate(purchaseOrderDetails?.date)}</p>
								<p>
									{!purchaseOrderDetails?.expected_delivery_date
										? 'N/A'
										: getDate(purchaseOrderDetails?.expected_delivery_date)}
								</p>
							</div>
						</div>
					</div>
				</div>

				<table className="items">
					<thead>
						<tr>
							<th>#</th>
							<th>ITEMS AND DESCTIPTION</th>
							{orderStatus && <th>ORDERED</th>}
							<th>RECEIVED Status</th>
							{isShowProductDiscardButton && <th></th>}
						</tr>
					</thead>
					<tbody style={{ backgroundColor: 'white' }}>
						{purchaseOrderDetails?.purchase_order_products?.map((prod, index) => (
							<tr
								style={{ backgroundColor: 'white' }}
								key={prod.purchase_order_product_id}
							>
								<td>{index + 1}</td>
								<td
									style={{ color: 'blue', cursor: 'pointer' }}
									onClick={() => handleProductDetails(prod)}
								>
									{prod?.product?.product_name}
								</td>
								{orderStatus && (
									<td>
										{prod.order_quantity} {prod.product_packaging}
									</td>
								)}
								<td>
									{prod.received_quantity || 0} {' Received'}
								</td>
								{isShowProductDiscardButton && (
									<td
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<img
											src={Delete}
											alt=""
											style={{
												cursor: 'pointer',
											}}
											onClick={() => handleDiscardQuantity(prod)}
										/>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default NonPdfView;
