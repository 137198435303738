import { APP_API_URL } from '../../utils/xhr-instance';
import axiosInstance from '../axios';

export async function fetchPaymentMadeList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/payment-made/payments`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function fetchPaymentMadeNumber() {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(
			`${APP_API_URL}/payment-made/get-payment-made-number`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data.payment_made_number;
	} catch (error) {
		throw error;
	}
}

export async function fetchPaymentMadeDetails(id) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/payment-made/payments/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function create_payment_made(body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(`${APP_API_URL}/payment-made/payments`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export async function deletePaymentMade(payment_made_ids) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(
			`${APP_API_URL}/payment-made/delete-payments`,
			{ payment_made_ids },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export async function update_payment_made(id, body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.put(`${APP_API_URL}/payment-made/payments/${id}`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export const delete_payment_made = async (id) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.delete(`${APP_API_URL}/payment-made/payments/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};
