export const CreditNoteColoum = [
	{
		field: '',
		headerClass: 'HeaderBg',
		checkboxSelection: true,
		headerCheckboxSelection: true,
		minWidth: 50,
		maxWidth: 50,
		lockPosition: true,
	},
	{
		field: 'DATE',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		maxWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'CREDIT NOTE#',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 220,
		maxWidth: 220,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const PN = JSON.parse(params.data['CN']);
			return (
				<p>
					{PN?.cn}
					{PN?.is_draft === 0 && (
						<span
							style={{
								backgroundColor: '#718096',
								color: 'white',
								borderRadius: '20px',
								padding: '2px 10px',
								paddingBottom: '3px',
								marginLeft: '10px',
								fontSize: '11px',
							}}
						>
							Draft
						</span>
					)}
				</p>
			);
		},
	},

	{
		field: 'VENDOR NAME',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		flex: 1,
		cellClass: 'left-align',
	},
	{
		field: 'STATUS',
		headerClass: 'HeaderBg',
		suppressSizeToFit: true,
		minWidth: 150,
		maxWidth: 150,
		flex: 1,
		cellClass: 'left-align',
		cellRenderer: (params) => {
			const status = params.data?.status;
			return (
				<p>
					{status}
					{/* {PN?.is_draft === 0 && (
						<span
							style={{
								backgroundColor: '#718096',
								color: 'white',
								borderRadius: '20px',
								padding: '2px 10px',
								paddingBottom: '3px',
								marginLeft: '10px',
								fontSize: '11px',
							}}
						>
							Draft
						</span>
					)} */}
				</p>
			);
		},
	},
	{
		field: 'AMOUNT',
		headerClass: 'HeaderBg right-align',
		suppressSizeToFit: true,
		minWidth: 150,
		maxWidth: 150,
		flex: 1,
		cellClass: 'right-align',
	},
];
