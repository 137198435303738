import { APP_API_URL } from '../../utils/xhr-instance';
import axiosInstance from '../axios';

export async function create_purchase_bill(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.post(
		`${APP_API_URL}/purchase-bill/create-purchase-bill`,
		body,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
}

export async function updatePurchaseBill(body) {
	const token = localStorage.getItem('authToken');
	const { data } = await axiosInstance.post(
		`${APP_API_URL}/purchase-bill/update-purchase-bill`,
		body,
		{
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		},
	);
	return data;
}

export const fetchPurchaseBill = async (params) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.get(`${APP_API_URL}/purchase-bill/get-purchase-bill`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const fetchPurchaseBillDetails = async (id) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.get(
			`${APP_API_URL}/purchase-bill/get-purchase-bill/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const fetchPurchaseBillByVendorId = async (id) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.get(
			`${APP_API_URL}/purchase-bill/get-purchase-bill-by-vendor/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const deletePurchaseBill = async (purchase_bill_ids) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(
			`${APP_API_URL}/purchase-bill/delete-purchase-bill`,
			{ purchase_bill_ids },
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);
		return data.data;
	} catch (error) {
		throw error;
	}
};
