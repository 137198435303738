import React, { useState } from 'react';
import './ContactPersons.scss';
import email from '../../../assets/image/email.svg';
import phone from '../../../assets/image/phone-call.svg';

const DetailsTab = ({ vendorDetails }) => {
	// Accept vendor as a prop
	const [isOpen, setIsOpen] = useState(false);
	const [isAddressOpen, setIsAddressOpen] = useState(false);

	const contactToggleDropdown = () => {
		setIsOpen(!isOpen);
	};
	const addressToggleDropdown = () => {
		setIsAddressOpen(!isAddressOpen);
	};

	return (
		<div>
			<div
				className="d-flex"
				style={{ gap: '50px' }}
			>
				<div style={{ marginLeft: '18px' }}>
					<div style={{ color: '#000', fontSize: '17px' }}>Total Outstanding</div>
					<div
						style={{
							color: '#000',
							fontSize: '18px',
							fontWeight: '600',
							lineHeight: '30px',
							textAlign: 'center',
						}}
					>
						Not Available
					</div>
				</div>
				<div style={{ borderRight: '1px solid #C3CBDC' }}></div>
				<div>
					<div style={{ color: '#000', fontSize: '17px' }}>Total Credits</div>
					<div
						style={{
							color: '#000',
							fontSize: '18px',
							fontWeight: '600',
							lineHeight: '30px',
							textAlign: 'center',
						}}
					>
						Not Available
					</div>
				</div>
			</div>
			<br />

			<div
				style={{
					border: '1px solid #D0D0D0',
					borderRadius: '8px',
					padding: '16px',
					maxWidth: '100%',
				}}
			>
				<div style={{ display: 'flex' }}>
					<div>
						<h3
							style={{
								borderBottom: '1px solid #D0D0D0',
								paddingBottom: '8px',
								fontWeight: '600',
								width: '405px',
							}}
						>
							Other Details
						</h3>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">Customer Type</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">DL Number</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">PAN</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">GSTIN</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">Currency</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">Payment Terms</div>
						</div>
						<div style={{ marginBottom: '12px' }}>
							<div className="ListQ">Website URL</div>
						</div>
					</div>

					<div style={{ marginTop: '36px', marginLeft: '-256px' }}>
						<div style={{ marginBottom: '12px' }}>Not Available</div>
						<div style={{ marginBottom: '12px' }}>
							{vendorDetails?.drug_license_number || 'Not Available'}
						</div>
						<div style={{ marginBottom: '12px' }}>{vendorDetails?.pan || 'Not Available'}</div>
						<div style={{ marginBottom: '12px' }}>{vendorDetails?.gst || 'Not Available'}</div>
						<div style={{ marginBottom: '12px' }}>INR</div>
						<div style={{ marginBottom: '12px' }}>
							{vendorDetails?.payment_terms || 'Not Available'}
						</div>
						<div style={{ marginBottom: '12px' }}>
							{vendorDetails?.website_url || 'Not Available'}
						</div>
					</div>
				</div>
			</div>
			<br />

			<div className="contact-card">
				{/* Dropdown Header */}
				<div
					className="dropdown-header"
					onClick={contactToggleDropdown}
				>
					<h3
						style={{
							fontWeight: '600',
							fontSize: '16px',
							color: '#000',
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						Contact Persons{' '}
						<span
							style={{
								backgroundColor: '#555b67',
								padding: '4px 8px',
								fontSize: '12px',
								fontWeight: 'bold',
								color: 'white',
								borderRadius: '5px',
							}}
						>
							{vendorDetails?.contact_person && vendorDetails?.contact_person?.length}
						</span>
					</h3>
					<span>{isOpen ? '▲' : '▼'}</span>
				</div>

				{/* Dropdown Content */}
				{isOpen && (
					<div className="contact-details">
						{vendorDetails?.contact_person &&
							vendorDetails?.contact_person?.length > 0 &&
							vendorDetails?.contact_person?.map((contact) => (
								<div className="contact-item">
									<div className="contact-avatar">{contact?.first_name[0]}</div>
									<div>
										<div className="contact-name">
											{contact?.salutation} {contact?.first_name} {contact?.last_name}
										</div>
										<div className="contact-info">
											<span
												role="img"
												aria-label="phone"
											>
												{' '}
												<img
													src={phone}
													alt=""
												/>
											</span>{' '}
											{contact?.mobile ? '+' + contact?.mobile : 'Not Available'}
										</div>
										<div className="contact-info">
											<span
												role="img"
												aria-label="email"
											>
												<img
													src={email}
													alt=""
												/>
											</span>{' '}
											{contact?.email ? contact?.email : 'Not Available'}
										</div>
									</div>
								</div>
							))}
					</div>
				)}
			</div>

			<br />
			<div className="contact-card">
				<div
					className="dropdown-header"
					onClick={addressToggleDropdown}
				>
					<h3 style={{ fontWeight: '600', fontSize: '16px', color: '#000' }}>Address</h3>
					<span>{isAddressOpen ? '▲' : '▼'}</span>
				</div>

				{isAddressOpen && (
					<div className="contact-details">
						<div style={{ color: '#555B67', fontSize: '16px', fontWeight: '600' }}>
							Billing Address
						</div>
						<div style={{ borderLeft: '1px solid black', padding: '5px', color: '#555b67' }}>
							<div>{vendorDetails?.vendor_address?.attention || 'Not Available'}</div>
							<div>{vendorDetails?.vendor_address?.city || 'Not Available'}</div>
							<div>{vendorDetails?.vendor_address?.street_1 || 'Not Available'}</div>
							<div>{vendorDetails?.vendor_address?.street_1 || 'Not Available'}</div>
							<div>{vendorDetails?.vendor_address?.street_2 || 'Not Available'}</div>
							<div>{vendorDetails?.vendor_address?.zip_code || 'Not Available'}</div>
						</div>
					</div>
				)}
			</div>

			<br />
			<br />
		</div>
	);
};

export default DetailsTab;
