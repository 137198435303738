import React, { useState } from 'react';
import NonPdfView from '../NonPdfView';
import PdfView from '../PdfView';
import './index.scss';

function PurchaseOrderInvoice({
	isShowPdfView,
	setIsShowPdfView,
	printPdfRef,
	handleProductDetails,
	handleDiscardQuantity,
	purchaseOrderDetails,
	setVendorDetailsDrawerActiveOpenTab,
	setIsVendorDetailsDrawerOpen,
}) {
	return (
		<div
			style={{
				width: '60%',
				margin: '0px auto',
				padding: '30px 0px',
			}}
		>
			<div className="status-toggle">
				<div
					style={{
						display: 'flex',
						// alignItems: "center",
						gap: 10,
					}}
				>
					<div>
						<p>Show PDF View</p>
					</div>
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							role="switch"
							id="flexSwitchCheckChecked"
							checked={isShowPdfView}
							onChange={() => setIsShowPdfView(!isShowPdfView)}
						/>
					</div>
				</div>
			</div>

			<div>
				{isShowPdfView ? (
					<PdfView
						printPdfRef={printPdfRef}
						purchaseOrderDetails={purchaseOrderDetails}
						setVendorDetailsDrawerActiveOpenTab={setVendorDetailsDrawerActiveOpenTab}
						setIsVendorDetailsDrawerOpen={setIsVendorDetailsDrawerOpen}
					/>
				) : (
					<NonPdfView
						purchaseOrderDetails={purchaseOrderDetails}
						setVendorDetailsDrawerActiveOpenTab={setVendorDetailsDrawerActiveOpenTab}
						setIsVendorDetailsDrawerOpen={setIsVendorDetailsDrawerOpen}
						handleProductDetails={handleProductDetails}
						handleDiscardQuantity={handleDiscardQuantity}
					/>
				)}
			</div>
		</div>
	);
}

export default PurchaseOrderInvoice;
