import React from 'react';
import PurchaseOrderTable from '../../component/InputTable/PurchaseOrderTable';

const OrderTable = ({
	tableData,
	setTableData,
	disabled,
	handleSavePurchaseOrder,
	paramId,
	tableError,
	setErrorCells,
	errorCells,
	id,
	setIsTableErrorShow,
}) => {
	const handleTableChange = (newData) => {
		setTableData('purchase_order_products', newData);
	};

	const columns = [
		{ name: 'product_id', label: 'Name', type: 'product_id' },
		{ name: 'product_packaging', label: 'Product Packaging', type: 'product_packaging' },
		{ name: 'order_quantity', label: 'Quantity ', type: 'number' },
	];

	let initialRows = [
		{
			product_id: '',
			product_packaging: '',
			order_quantity: null,
		},
	];

	return (
		<div
			className="add-adjustment-inputTable max-w-1297"
			style={{ padding: '0rem 2rem' }}
		>
			<PurchaseOrderTable
				id={id}
				initialRows={initialRows}
				columns={columns}
				onChange={handleTableChange}
				handleSavePurchaseOrder={handleSavePurchaseOrder}
				value={tableData}
				disabled={disabled}
				newRowgenerate={'Quantity'}
				returnInputvalue={false}
				setErrorCells={setErrorCells}
				errorCells={errorCells}
				paramId={paramId}
				tableError={tableError}
				setIsTableErrorShow={setIsTableErrorShow}
			/>
		</div>
	);
};

export default OrderTable;
