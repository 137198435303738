import { APP_API_URL } from '../../utils/xhr-instance';
import axiosInstance from '../axios';

export async function fetchCreditNoteList(params) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/credit-note/credit-notes`, {
			params,
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data;
	} catch (error) {
		throw error;
	}
}

export async function fetchCreditNoteDetails(id) {
	const token = localStorage.getItem('authToken');
	try {
		const { data } = await axiosInstance.get(`${APP_API_URL}/credit-note/credit-notes/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
}

export async function create_credit_note(body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(`${APP_API_URL}/credit-note/credit-notes`, body, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export async function update_credit_note(id, body) {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.put(
			`${APP_API_URL}/credit-note/credit-notes/${id}`,
			body,
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			},
		);

		if (data.code === 422) {
			throw new Error(data.message[0].message);
		}
		return data;
	} catch (error) {
		throw error;
	}
}

export const delete_credit_note = async (credit_note_ids) => {
	try {
		const token = localStorage.getItem('authToken');
		const { data } = await axiosInstance.post(`${APP_API_URL}/credit-note/delete-credit-notes`, { credit_note_ids }, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return data.data;
	} catch (error) {
		throw error;
	}
};
