import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sortbydot from '../../assets/image/sort_by_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../component/ui/Button';
import Table from '../../component/table/Table';
import Pagination from '../../component/pagination';
import { toast } from 'react-toastify';
import { ExportTable } from '../../store/action/export/Export';
import { PaymentMadeColoum } from './PaymentmadeColoum';
import DeletePaymentMadeModel from './components/DeletePaymentMadeModel';
import {
	deletePaymentMade,
	fetchPaymentMadeDetails,
	fetchPaymentMadeList,
} from '../../services/payment_made';
import { getDate } from '../../utils/dateUtils';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import pdfMake, { fonts } from 'pdfmake/build/pdfmake';
import { numberToWords } from '../../utils/helper';
import { clearAdvanceFilter, updateAdvanceFilter } from '../../store/action/advanceFilter';
import axiosInstance from '../../services/axios';
import { APP_API_URL } from '../../utils/xhr-instance';
import advanceFilterOptions from '../../component/Filter/advanceFilterOptions';
import AdvanceFilter from '../../component/Filter/AdvanceFilter';
import addFilter from '../../assets/image/AddFilter.svg';
import clearAllFilter from '../../assets/image/clearAllFilter.svg';
import advanceFilterIcon from '../../assets/image/advanceFilter.svg';
import AdvanceFilterSelectInput from '../../component/Filter/AdvanceFilterSelectInput';

const PaymentMade = () => {
	const { advanceFilterReducer } = useSelector((state) => state);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const suburl = localStorage.getItem('suburl');
	const [loader, setLoader] = useState(false);
	const [bulkAction, setBulkAction] = useState([]);
	const [filterparam, setFilterparam] = useState('');
	const [sortParam, setSortParam] = useState('');
	const [headerDropDown, setHeaderDropDown] = useState('All Payment Made');
	const [stateCondition, setStateCondition] = useState('');
	const [sortDirection, setSortDirection] = useState({ payment_made_id: 'asc' });
	const [isDeleteBulkConfirmationModalOpen, setIsDeleteBulkConfirmationModalOpen] = useState(false);
	const [selectedPaymentMadeListForDelete, setSelectedPaymentMadeListForDelete] = useState([]);
	const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState(false);
	const [paymentMadeParams, setPaymentMadeParams] = useState({
		page: 1,
		pageSize: 20,
		orderBy: 'payment_made_id',
		sortBy: 'asc',
		filterBy: null,
		filterValue: null,
	});
	const [deselectRows, setDeselectRows] = useState(null);
	const handleDeselectAll = (deselectAllRowsFn) => {
		setDeselectRows(() => deselectAllRowsFn);
	};

	const [paymentMade, setPaymentMade] = useState({
		data: [],
		totalPages: 0,
		currentPage: 1,
	});
	const [loading, setLoading] = useState({
		pdf: {
			button: 'pdf',
			isLoading: false,
		},
		download: {
			button: 'download',
			isLoading: false,
		},
	});

	const [isAppliedAdvanceFilter, setIsAppliedAdvanceFilter] = useState(false);
	const [advanceFilterParams, setAdvanceFilterParams] = useState({
		page: 1,
		totalPages: 0,
		pageSize: 20,
		filters: {},
	});
	const [advanceFilterSelectInputList, setadvanceFilterSelectInputList] = useState([]);

	const navigateRouter = (path) => {
		navigate(path);
	};
	const onRowClick = (id) => {
		if (id) {
			navigate(`/user/${suburl}/payment-made-details/${id}`);
		}
	};

	async function handleAdvanceFilterDropdown(option) {
		if (!option) return;

		const { key, value } = option;
		const isAlreadyPresent = advanceFilterSelectInputList.find((item) => item.key === key);

		if (isAlreadyPresent) {
			return;
		}

		setadvanceFilterSelectInputList([
			...advanceFilterSelectInputList,
			{
				key: key,
				label: value,
				data: [],
			},
		]);
	}

	// removing the selected inputs from both modal and tables
	function handleRemoveSelectedFilterInput(key) {
		const updatedFilterInputs = advanceFilterSelectInputList.filter((item) => item?.key !== key);
		setadvanceFilterSelectInputList(updatedFilterInputs);
	}

	async function handleOnFocusSelectedFilterInput(key) {
		let vendorList = [];
		const data = await fetchPaymentMadeList({ filterBy: key, filterValue: '' });
		const selectInputData = data?.reduce((acc, pm) => {
			if (key === 'vendor_name') {
				acc.push({
					key: pm.vendor?.vendor_name,
					value: pm.vendor?.vendor_name,
				});
				return acc;
			} else if (key === 'payment_number') {
				acc.push({
					key: pm.payment_number,
					value: pm.payment_number,
				});
				return acc;
			} else {
				acc.push({
					key: pm.payment_amount,
					value: pm.payment_amount,
				});
				return acc;
			}
		}, []);

		const updatedSelectInputData = advanceFilterSelectInputList.map((item) => {
			if (item.key === key) {
				return {
					...item,
					data: [...vendorList, ...selectInputData],
				};
			}
			return item;
		});

		setadvanceFilterSelectInputList(updatedSelectInputData);
	}

	// searching in the option list for selected input
	async function handleOnKeyDownCb(key, value) {
		try {
			const params = {
				filterBy: key,
				filterValue: value,
			};
			const { vendors } = await fetchPaymentMadeList(params);

			const optionsList = vendors?.map((vendor) => {
				return {
					key: vendor[key],
					value: vendor[key],
				};
			});

			const updatedAdvanceFilterSelectInputList = advanceFilterSelectInputList?.map((item) => {
				if (item[key]) {
					return {
						...item,
						data: optionsList,
					};
				}
				return item;
			});
			setadvanceFilterSelectInputList(updatedAdvanceFilterSelectInputList);
		} catch (error) {
			toast.error('Something went wrong!');
		}
	}

	async function handleApplyFilters() {
		const params = advanceFilterSelectInputList.reduce((acc, item) => {
			if (item?.value) {
				acc[item.key] =
					item.key === 'purchase_receive_id' ? item?.value.split('-')[1] : item?.value;
				return acc;
			}
			return acc;
		}, {});

		try {
			const token = localStorage.getItem('authToken');
			const { data } = await axiosInstance.post(
				`${APP_API_URL}/payment-made/search-filter`,
				{ ...advanceFilterParams, filters: { ...params } },
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
				},
			);
			console.log(data);
			const paymentMadeFilterData = data?.data?.payments.map((pm) => {
				return {
					id: pm.payment_made_id,
					DATE: getDate(pm?.date),
					'VENDOR NAME': pm?.vendor?.vendor_name,
					MODE: pm?.payment_mode,
					AMOUNT: pm?.payment_amount,
				};
			});
			setPaymentMade((prev) => ({
				...prev,
				data: paymentMadeFilterData,
				totalPages: data?.totalPages,
			}));
			// setTableData(purchaseOrderFilterData);
			// setTotalPages(data?.data?.totalPages);
			setAdvanceFilterParams((prev) => ({
				...prev,
				totalPages: data?.data?.totalPages,
				pageSize: data?.data?.pageSize,
				filters: {
					...prev.filters,
					...params,
				},
			}));

			dispatch(
				updateAdvanceFilter({
					filters: { ...advanceFilterParams.filters, ...params },
					selectedInputs: advanceFilterSelectInputList,
				}),
			);
			setIsAdvanceFilterOpen(false);
			setIsAppliedAdvanceFilter(true);
		} catch (error) {
			toast.error('Something went wrong! Try again.' + error);
		}
	}

	const sortIconMap = {
		asc: <IoIosArrowRoundUp />,
		desc: <IoIosArrowRoundDown />,
	};

	const FilterDatalist = (param, value, dropDownData) => {
		setStateCondition(value);
		setHeaderDropDown(dropDownData);
		setPaymentMadeParams((prev) => ({
			...prev,
			...param,
			page: 1,
		}));
	};

	async function generatePdfToPrintAndDownload() {
		const filterValue = JSON.stringify(bulkAction);
		const { purchaseBills } = await fetchPaymentMadeDetails({
			filterBy: 'purchase_made_id',
			filterValue,
		});
		const docDefinitions = [];
		purchaseBills.forEach((pm) => {
			const {
				vendor,
				organisation,
				payment_number,
				date,
				reference_number,
				payment_mode,
				payment_amount,
				purchase_bill_payment_made,
			} = pm;

			docDefinitions.push({
				content: [
					{
						text: vendor?.vendor_name,
						style: 'vendorName',
						margin: [0, 20, 0, 0],
					},
					{
						columns: [
							{
								stack: [
									{
										text: [
											'\nRajasthan\nIndia\n',
											{ text: `${vendor?.phone_mobile} \n ${vendor?.email}`, bold: true },
										],
										style: 'vendorAddress',
									},
								],
							},
							{
								stack: [
									{ text: 'BILL', style: 'header', bold: true },
									{ text: 'N/A', style: 'billNumber' },
								],
								margin: [0, 20, 0, 10],
							},
						],
						columnGap: 100,
					},
					{
						columns: [
							{
								stack: [
									{ text: `Payment# : ${payment_number || 'N/A'}`, style: 'detail' },
									{
										text: `Payment Date : ${date != null ? getDate(date) : 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Reference Number : ${reference_number || 'N/A'}`,
										style: 'detail',
									},
								],
							},
							{
								stack: [
									{ text: 'Amount Paid', style: 'label' },
									{ text: `Rs ${payment_amount ?? 0.0}`, bold: true },
								],
								fillColor: '#e3e3e3', // Light gray background color
								margin: [10, 5, 10, 5], // Optional: Adds padding around the container
								border: [true, true, true, true], // Optional: Adds borders around the container
								borderColor: '#e3e3e3',
							},
						],
						columnGap: 100,
					},
					{
						columns: [
							{
								stack: [
									{
										text: `Payment Mode : ${payment_mode || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Paid Through : ${vendor.vendor_display_name || 'N/A'}`,
										style: 'detail',
									},
									{
										text: `Amount Paid In Words : ${numberToWords(Number(payment_amount)) || 'N/A'}`,
										style: 'detail',
									},
								],
							},
						],
					},
					{
						stack: [
							{ text: 'Paid To', style: 'label' },
							{ text: `${organisation?.organisation_name}`, style: 'linkStyle' },
						],
						margin: [0, 30, 10, 10],
					},
					{
						table: {
							widths: ['30%', '20%', '20%', '30%'],
							body: [
								[
									{ text: 'Bill Number', bold: true },
									{ text: 'Bill Date', bold: true },
									{ text: 'Bill Amount', bold: true },
									{ text: 'Payment Amount', bold: true },
								],
								...purchase_bill_payment_made.map((pm, index) => [
									{ text: pm.purchase_bill?.bill_number, color: 'blue' },
									{ text: getDate(pm.purchase_bill?.bill_date) },
									{ text: pm.purchase_bill?.bill_amount },
									{ text: pm.payment_amount },
								]),
							],
						},
						margin: [0, 20, 0, 10],
					},
					{
						text: 'Authorized Signature --------------------',
						margin: [0, 30, 0, 0],
						alignment: 'left',
					},
				],
				styles: {
					status: {
						fontSize: 10,
						margin: [0, 0, 0, 5],
					},
					linkStyle: {
						fontSize: 12,
						color: '#3B58FF',
					},
					header: {
						fontSize: 15,
						margin: [0, 10, 10, 0],
					},
					vendorName: {
						fontSize: 16,
						bold: true,
					},
					vendorAddress: {
						fontSize: 12,
					},
					subheader: {
						fontSize: 14,
						bold: true,
					},
					orderNumber: {
						fontSize: 12,
						margin: [0, 5, 0, 5],
					},
					orderDate: {
						fontSize: 12,
					},
					label: {
						fontSize: 15,
						bold: true,
						margin: [0, 5, 0, 5],
					},
					detail: {
						fontSize: 10,
						margin: [0, 2, 0, 2],
					},
				},
			});
		});

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};

		return mergedDocDefinition;
	}

	async function handlePrintAndDownloadPdf(isPrint) {
		setLoading((prev) => {
			const obj = {};
			if (isPrint) {
				obj.pdf = {
					button: 'pdf',
					isLoading: true,
				};
			} else {
				obj.download = {
					button: 'download',
					isLoading: true,
				};
			}

			return {
				...prev,
				...obj,
			};
		});

		const mergedDocDefinition = await generatePdfToPrintAndDownload();
		if (isPrint) {
			pdfMake.createPdf(mergedDocDefinition).print();
		} else {
			pdfMake.createPdf(mergedDocDefinition).download();
		}

		setLoading((prev) => ({
			...prev,
			pdf: {
				...prev.pdf,
				isLoading: false,
			},
			download: {
				...prev.download,
				isLoading: false,
			},
		}));
	}

	const sortDataList = (param) => {
		const newDirection = sortDirection[param.orderBy] === 'desc' ? 'asc' : 'desc';
		setSortDirection({ [param.orderBy]: newDirection });
		setPaymentMadeParams((prev) => ({
			...prev,
			orderBy: param.orderBy,
			sortBy: newDirection,
			filterValue: null,
			filterBy: null,
		}));
	};

	async function handleFetchPaymentMade() {
		try {
			setLoader(true);
			const data = await fetchPaymentMadeList(paymentMadeParams);
			const mappedTableData = data?.map((pm) => {
				return {
					id: pm.payment_made_id,
					DATE: getDate(pm?.date),
					'VENDOR NAME': pm?.vendor?.vendor_name,
					MODE: pm?.payment_mode,
					AMOUNT: pm?.payment_amount,
				};
			});

			setPaymentMade((prev) => ({
				...prev,
				data: mappedTableData,
			}));
		} catch (error) {
			toast.error('Somethings went wrong!');
		} finally {
			setLoader(false);
		}
	}

	// useEffect(() => {
	// 	handleFetchPaymentMade();
	// }, [paymentMadeParams]);

	const exportData = (param) => {
		ExportTable(param);
	};

	async function handleBulkDelete() {
		try {
			await deletePaymentMade(bulkAction);
			toast.success('Payment Made Deleted Successfully.');
			setIsDeleteBulkConfirmationModalOpen(false);
			setBulkAction([]);
			handleFetchPaymentMade();
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	function handleOpenBulkDeletePaymentMadeModal() {
		const selectedVendorsList = paymentMade?.data?.reduce((acc, item) => {
			if (bulkAction.includes(item.id)) {
				acc.push(item.id);
				return acc;
			}
			return acc;
		}, []);
		setSelectedPaymentMadeListForDelete(selectedVendorsList);
		setIsDeleteBulkConfirmationModalOpen(true);
	}

	const updateURLWithFilters = (filters) => {
		const urlParams = new URLSearchParams();
		Object.entries(filters).forEach(([key, value]) => {
			if (value) {
				urlParams.append(key, value);
			}
		});
		const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
		window.history.replaceState(null, '', newUrl);
	};
	const handleClearAdvanceFilter = useCallback(() => {
		setIsAppliedAdvanceFilter(false);
		setadvanceFilterSelectInputList([]);
		setAdvanceFilterParams((prev) => ({
			...prev,
			filters: {},
		}));
		setPaymentMadeParams((prev) => ({
			...prev,
			filterBy: null,
			filterValue: null,
		}));
		dispatch(clearAdvanceFilter());
	}, []);

	useEffect(() => {
		if (advanceFilterSelectInputList.length === 0 && isAppliedAdvanceFilter) {
			handleClearAdvanceFilter();
		}

		if (advanceFilterSelectInputList.length > 0 && isAppliedAdvanceFilter) {
			handleApplyFilters();
		}
	}, [advanceFilterSelectInputList, isAppliedAdvanceFilter]);

	// Update the URL whenever advanced filter parameters change
	useEffect(() => {
		const activeFilters = advanceFilterSelectInputList.reduce((acc, filter) => {
			if (filter?.key && filter?.value) {
				acc[filter.key] = filter.value;
			}
			return acc;
		}, {});

		if (Object.keys(activeFilters).length > 0) {
			updateURLWithFilters(activeFilters);
		}
	}, [advanceFilterSelectInputList]);

	useEffect(() => {
		const filtersFromRedux = advanceFilterReducer?.filters || {};

		if (Object.keys(filtersFromRedux).length === 0) {
			handleFetchPaymentMade();
		}
		if (Object.keys(filtersFromRedux).length > 0) {
			setAdvanceFilterParams((prev) => ({
				...prev,
				filters: {
					...prev.filters,
					...filtersFromRedux,
				},
			}));
			setadvanceFilterSelectInputList(advanceFilterReducer?.selectedInputs);
			setIsAppliedAdvanceFilter(true);
		}
	}, [paymentMadeParams, advanceFilterReducer?.filters]);

	return (
		<div>
			{bulkAction && bulkAction.length > 0 ? (
				<div className="product-pg-header">
					<div className="dropdown text-end">
						<p className="d-block link-dark text-decoration-none">Bulk Actions</p>
					</div>
					<div className="add-product-button">
						<Button
							type="button"
							button="Print"
							className="mt-0 px-4 btn-gray"
							loader={loading.pdf.isLoading}
							onClick={() => handlePrintAndDownloadPdf(true)}
						/>

						<Button
							type="button"
							button="Bulk Update"
							className="mt-0 px-4 btn-gray relative"
						></Button>

						<Button
							type="button"
							button="Delete"
							className="mt-0 px-4 btn-gray"
							// onClick={() => handleUpdateBulkPurchaseOrder("is_deleted")}
							onClick={handleOpenBulkDeletePaymentMadeModal}
						/>

						<Button
							type="button"
							button=""
							className="mt-0 px-4 btn-danger"
							close
							onClick={() => {
								setBulkAction(false);
								setBulkAction([]);
								deselectRows && deselectRows();
							}}
						/>
						{/* {stateCondition !== 'active' && (
							<Button
								type="button"
								button="Mark as Active"
								className="mt-0 px-4 btn-gray"
								onClick={markAsActive}
							/>
						)}
						{stateCondition !== 'inactive' && (
							<Button
								type="button"
								button="Mark as Inactive"
								className="mt-0 px-4 btn-gray"
								onClick={markAsinactive}
							/>
						)} */}
					</div>
				</div>
			) : (
				<div className="product-pg-header add-product">
					<div
						className="dropdown text-end d-flex"
						style={{ gap: '12px' }}
					>
						<a
							className="d-block link-dark text-decoration-none dropdown-toggle"
							id="dropdownUser1"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<p>{headerDropDown}</p>
						</a>
						<ul
							className="dropdown-menu font-14"
							aria-labelledby="dropdownUser1"
							style={{ minWidth: '240px' }}
						>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: null, filterValue: null },
											'active',
											'All Payment Made',
										)
									}
								>
									All
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'payment_mode', filterValue: 'Cash' },
											'active',
											'Cash',
										)
									}
								>
									Cash
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'payment_mode', filterValue: 'Bank Transfer' },
											'active',
											'Bank Transfer',
										)
									}
								>
									Bank Transfer
								</a>
							</li>
							<li>
								<a
									className="dropdown-item"
									onClick={() =>
										FilterDatalist(
											{ filterBy: 'payment_mode', filterValue: 'Online' },
											'active',
											'Online',
										)
									}
								>
									Online
								</a>
							</li>
						</ul>
						<div>
							{isAppliedAdvanceFilter ? (
								<div>
									<img
										src={clearAllFilter}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
										onClick={handleClearAdvanceFilter}
									/>
									<a
										onClick={handleClearAdvanceFilter}
										style={{
											color: '#FF0000',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Clear All Filters
									</a>
								</div>
							) : (
								<div>
									<img
										src={advanceFilterIcon}
										alt=""
										style={{ width: '18px', height: '18px', marginRight: '8px' }}
									/>
									<a
										onClick={() => setIsAdvanceFilterOpen(true)}
										style={{
											color: '#2E63F6',
											fontSize: '14px',
											cursor: 'pointer',
											fontWeight: '600',
											lineHeight: '24px',
										}}
									>
										Advanced Filters
									</a>
								</div>
							)}
						</div>
					</div>

					<div className="add-product-button column-gap-0">
						<Button
							type="button"
							className="btn-primary mt-0 px-5"
							button="Create New Payment Made"
							add
							onClick={() => navigateRouter(`/user/${suburl}/payment-made-create`)}
						/>
						<div className="dropdown text-end ms-3">
							<a
								href="#"
								className="d-block link-dark text-decoration-none"
								id="dropdownUser1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<img src={sortbydot} />
							</a>
							{/* date , reason, created_at , description,status,reference_number, */}
							<ul
								className="dropdown-menu font-14"
								aria-labelledby="dropdownUser1"
								style={{ minWidth: '240px' }}
							>
								<li>
									<a className="dropdown-item sortBy">Sort By</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'created_at', sortBy: 'asc' })}
									>
										Created Time
										<span>{sortIconMap[sortDirection?.created_at]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'date', sortBy: 'asc' })}
									>
										Date
										<span>{sortIconMap[sortDirection?.date]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'payment_made_id', sortBy: 'asc' })}
									>
										Payment#
										<span>{sortIconMap[sortDirection?.payment_made_id]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'vendor_name', sortBy: 'asc' })}
									>
										Vendor Name
										<span>{sortIconMap[sortDirection?.vendor_name]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'payment_mode', sortBy: 'asc' })}
									>
										Mode
										<span>{sortIconMap[sortDirection?.payment_mode]}</span>
									</a>
								</li>
								<li>
									<a
										style={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
										className="dropdown-item"
										onClick={() => sortDataList({ orderBy: 'payment_amount', sortBy: 'asc' })}
									>
										Amount
										<span>{sortIconMap[sortDirection?.payment_amount]}</span>
									</a>
								</li>
								<li>
									<a
										className="dropdown-item"
										onClick={() => exportData(`payment_made`)}
									>
										Export Payment Made
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: 10,
					padding: '0px 10px',
				}}
			>
				{isAppliedAdvanceFilter && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: 10,
						}}
					>
						{advanceFilterSelectInputList.map((item) => (
							<AdvanceFilterSelectInput
								options={item?.data}
								placeholder={`Select ${item?.label}`}
								value={item?.value}
								onFocusCb={() => {
									handleOnFocusSelectedFilterInput(item?.key);
								}}
								onKeyDownCb={(value) => {
									handleOnKeyDownCb(item?.key, value);
								}}
								onChange={(value) => {
									const updatedData = advanceFilterSelectInputList.map((_item) => {
										if (_item?.key === item?.key) {
											return {
												..._item,
												value: value.value,
											};
										}
										return _item;
									});
									setadvanceFilterSelectInputList(updatedData);
								}}
								removeInput={() => handleRemoveSelectedFilterInput(item?.key)}
								className="selectInput"
							/>
						))}
						<div style={{ marginBottom: '8px' }}>
							<img
								src={addFilter}
								alt=""
								onClick={() => setIsAdvanceFilterOpen(true)}
							/>
						</div>
					</div>
				)}
			</div>

			{isDeleteBulkConfirmationModalOpen && (
				<DeletePaymentMadeModel
					isDeleteBulkConfirmationModalOpen={isDeleteBulkConfirmationModalOpen}
					setIsDeleteBulkConfirmationModalOpen={setIsDeleteBulkConfirmationModalOpen}
					selectedPaymentMadeListForDelete={selectedPaymentMadeListForDelete}
					handleBulkDelete={handleBulkDelete}
					PaymentMadeLength={bulkAction.length}
				/>
			)}

			{loader ? (
				<div
					className="d-flex justify-content-center align-items-center"
					style={{ height: '100%', marginTop: '20%' }}
				>
					<span
						className="Page_loader"
						role="status"
						aria-hidden="true"
					></span>
				</div>
			) : (
				<div className="productTable-body">
					<Table
						row={paymentMade.data}
						coloum={PaymentMadeColoum}
						className="productTable"
						onRowClick={onRowClick}
						action={setBulkAction}
						onDeselectAll={handleDeselectAll}
					/>
				</div>
			)}
			<Pagination
				currentPage={paymentMade.currentPage}
				totalPages={paymentMade.totalPages}
				onChangePage={(page) => {
					setPaymentMadeParams((prev) => ({
						...prev,
						currentPage: page,
					}));
				}}
			/>

			<AdvanceFilter
				modalLabel="Note: You can create a bill for either Yet to Receive Items or Received Items."
				isOpen={isAdvanceFilterOpen}
				onKeyDownCb={handleOnKeyDownCb}
				advanceFilterOptions={advanceFilterOptions.payment_made}
				handleAdvanceFilterDropdown={handleAdvanceFilterDropdown}
				advanceFilterSelectInputList={advanceFilterSelectInputList}
				handleRemoveSelectedFilterInput={handleRemoveSelectedFilterInput}
				onFocusSelectedFilterInput={handleOnFocusSelectedFilterInput}
				handleOnChangeSelectedFilterInput={(key, value) => {
					const updatedData = advanceFilterSelectInputList.map((item) => {
						if (item?.key === key) {
							return {
								...item,
								value,
							};
						}

						return item;
					});
					setadvanceFilterSelectInputList(updatedData);
				}}
				onClose={() => {
					setIsAdvanceFilterOpen(false);
				}}
				onApply={handleApplyFilters}
			/>

			{/* {openPaymentTermsModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openPaymentTermsModal}
						onClose={() => setOpenPaymentTermsModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<AddPaymentTerms
							isOpen={openPaymentTermsModal}
							onCLose={() => setOpenPaymentTermsModal(false)}
							handleSaveClick={handleBulkPaymentTermsUpdate}
						/>
					</Modal>
				</div>
			)} */}
			{/* {openCurrencyModal && (
				<div>
					<Modal
						className="add-payment-terms-modal"
						isOpen={openCurrencyModal}
						onClose={() => setCurrencyModal(false)}
						title="Payment Terms"
						cancelButton={true}
					>
						<UpdateCurrency
							isOpen={openCurrencyModal}
							onCLose={() => setCurrencyModal(false)}
							handleSaveClick={handleBulkCurrencyUpdate}
						/>
					</Modal>
				</div>
			)} */}
		</div>
	);
};

export default PaymentMade;
