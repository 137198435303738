import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmationModal from '../../component/modal/ConfirmationModal';
import { delete_credit_note, fetchCreditNoteDetails } from '../../services/credit_note';
import Button from '../../component/ui/Button';
import { numberToWords } from '../../utils/helper';
import { toast } from 'react-toastify';
import pdfMake from 'pdfmake/build/pdfmake';
import { getDate } from '../../utils/dateUtils';
import ProductDetailsDrawer from '../components/ProductDetailsDrawer';

const CreditNoteDetails = () => {
	const suburl = localStorage.getItem('suburl');
	const params = useParams();
	const navigate = useNavigate();
	const { id } = params;
	const [creditNoteDetails, setCreditNoteDetails] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [productDetails, setProductDetails] = useState(null);
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	async function handleConfirmDelete() {
		try {
			await delete_credit_note([id]);
			toast.success('Credit Note Deleted Successfully.');
			navigate(`/user/${suburl}/credit-note`);
		} catch (error) {
			toast.error(error?.message || 'Something went wrong');
		}
	}

	async function handleFetchCreditNoteDetails() {
		try {
			const creditNoteDetails = await fetchCreditNoteDetails(id);
			setCreditNoteDetails(creditNoteDetails);
		} catch (error) {
			toast.error('Someting went wrong!');
		}
	}

	async function generatePdfToPrintAndDownload() {
		const docDefinitions = [];
		const {
			vendor,
			date,
			credit_note_date,
			terms,
			credit_note_number,
			payment_method,
			reference_number,
			credit_note_amount,
			credit_note_products,
		} = creditNoteDetails;
		docDefinitions.push({
			content: [
				{
					text: vendor?.vendor_name,
					style: 'vendorName',
					margin: [0, 20, 0, 0],
				},
				{
					columns: [
						{
							stack: [
								{
									text: [
										'\nRajasthan\nIndia\n',
										{ text: `${vendor?.phone_mobile} \n ${vendor?.email}`, bold: true },
									],
									style: 'vendorAddress',
								},
							],
						},
						{
							stack: [{ text: 'Credit Note', style: 'header', bold: true }],
							margin: [0, 20, 0, 10],
						},
					],
					columnGap: 100,
				},
				{
					columns: [
						{
							stack: [
								{ text: `Credit Note# : ${credit_note_number || 'N/A'}`, style: 'detail' },
								{
									text: `Date : ${date != null ? getDate(date) : 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Credit Note Date : ${credit_note_date != null ? getDate(credit_note_date) : 'N/A'}`,
									style: 'detail',
								},
								{
									text: `Reference Number : ${reference_number || 'N/A'}`,
									style: 'detail',
								},
							],
						},
						{
							stack: [
								{ text: 'Credit Amount', style: 'label' },
								{ text: `Rs ${credit_note_amount ?? 0.0}`, bold: true },
							],
							fillColor: '#e3e3e3', // Light gray background color
							margin: [10, 5, 10, 5], // Optional: Adds padding around the container
							border: [true, true, true, true], // Optional: Adds borders around the container
							borderColor: '#e3e3e3',
						},
					],
					columnGap: 100,
				},
				{
					columns: [
						{
							stack: [
								{
									text: `Amount Paid In Words : ${numberToWords(Number(credit_note_amount)) || 'N/A'}`,
									style: 'detail',
								},
							],
						},
					],
				},
				{
					stack: [
						{ text: 'Bill To', style: 'label' },
						{ text: `${vendor?.vendor_display_name}`, style: 'linkStyle' },
					],
					margin: [0, 30, 10, 10],
				},
				{
					table: {
						widths: ['5%', '40%', '15%', '15%', '25%'],
						body: [
							[
								{ text: '#', bold: true },
								{ text: 'ITEMS AND DESCRIPTION', bold: true },
								{ text: 'QNTY', bold: true },
								{ text: 'RATE', bold: true },
								{ text: 'AMOUNT', bold: true },
							],
							...credit_note_products.map((prod, index) => [
								{ text: index + 1 },
								{ text: prod.product?.product_name, color: 'blue' },
								{ text: prod.quantity },
								{ text: prod.mrp },
								{ text: prod.amount },
							]),
						],
					},
					margin: [0, 20, 0, 10],
				},
				{
					text: 'Authorized Signature --------------------',
					margin: [0, 30, 0, 0],
					alignment: 'left',
				},
			],
			styles: {
				status: {
					fontSize: 10,
					margin: [0, 0, 0, 5],
				},
				linkStyle: {
					fontSize: 12,
					color: '#3B58FF',
				},
				header: {
					fontSize: 15,
					margin: [0, 10, 10, 0],
				},
				vendorName: {
					fontSize: 16,
					bold: true,
				},
				vendorAddress: {
					fontSize: 12,
				},
				subheader: {
					fontSize: 14,
					bold: true,
				},
				orderNumber: {
					fontSize: 12,
					margin: [0, 5, 0, 5],
				},
				orderDate: {
					fontSize: 12,
				},
				label: {
					fontSize: 15,
					bold: true,
					margin: [0, 5, 0, 5],
				},
				detail: {
					fontSize: 10,
					margin: [0, 2, 0, 2],
				},
			},
		});
		// });

		let mergedContent = [];
		docDefinitions.forEach((doc) => {
			mergedContent = mergedContent.concat(doc.content); // Concatenate all content
			// Optionally, you can add a page break between the documents
			mergedContent.push({ text: '', pageBreak: 'after' });
		});

		// Create the final merged document definition
		const mergedDocDefinition = {
			content: mergedContent,
			styles: {
				header: { fontSize: 16, bold: true },
				subheader: { fontSize: 12 },
			},
		};
		pdfMake.createPdf(mergedDocDefinition).print();

		// return mergedDocDefinition;
	}

	useEffect(() => {
		if (id) {
			handleFetchCreditNoteDetails();
		}
	}, [id]);

	return (
		<div style={{ backgroundColor: '#EDEDF7', height: '100%', overflow: 'auto' }}>
			<div className="">
				<div
					className=""
					style={{
						position: 'sticky',
						top: 0,
						zIndex: 100,
					}}
				>
					<div className="product-pg-header add-product">
						<p>Credit Note Detail</p>
						<div className="add-product-button">
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Edit"
								onClick={() => navigate(`/user/${suburl}/credit-note-create/${id}`)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Pdf/Print"
								onClick={() => generatePdfToPrintAndDownload()}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Send Email"
								// onClick={() => handleSavePurchaseReceive(1)}
							/>
							<Button
								type="button"
								className="btn-gray mt-0 px-4"
								button="Delete"
								onClick={() => setIsDeleteModalOpen(true)}
							/>
							<Button
								type="button"
								className="mt-0 btn-danger add-pro"
								button=""
								close
								onClick={() => navigate(`/user/${suburl}/credit-note`)}
							/>
						</div>
					</div>
				</div>

				{/* Credit Note Details */}
				<div>
					<div
						style={{
							maxWidth: '750px',
							margin: '19px auto',
							minHeight: '600px',
							backgroundColor: '#fff',
							padding: '20px',
							boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
						}}
					>
						{/* Store Details */}
						<div style={{ border: '1px solid #555B67', padding: '12px' }}>
							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div style={{ marginBottom: '20px' }}>
									<h4 style={{ marginBottom: '13px', fontWeight: 'bold', fontSize: '22px' }}>
										Medical Store
									</h4>
									<p style={{ color: '#555B67', fontSize: '14px' }}>N/A</p>
									<p style={{ color: '#555B67', fontSize: '14px' }}>N/A</p>
									<p style={{ color: '#555B67', fontSize: '14px' }}>
										{creditNoteDetails?.vendor?.email}
									</p>
								</div>

								<div style={{ marginTop: '16px' }}>
									<h2
										style={{
											fontSize: '30px',
											color: '#000000',
											fontWeight: '600',
											textAlign: 'end',
											marginTop: '8px',
											marginBottom: '12px',
										}}
									>
										Credit Note
									</h2>
								</div>
							</div>

							{/* Payment Info */}
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									marginBottom: '20px',
								}}
							>
								<div>
									<div className="d-flex">
										<div style={{ marginTop: '5px', color: '#555B67' }}>
											<p style={{ marginBottom: '16px' }}>Credit Note#</p>
											<p style={{ marginBottom: '16px' }}>Date</p>
											<p style={{ marginBottom: '16px' }}>Credit Note Date</p>
											<p style={{ marginBottom: '16px' }}>Reference Number</p>
											<p style={{ marginBottom: '16px' }}>Amount Paid In Words</p>
										</div>
										<div style={{ width: '270px', marginLeft: '35px', fontWeight: 'bold' }}>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '20px' }}>
												{creditNoteDetails?.credit_note_number}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>
												{getDate(creditNoteDetails?.date)}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>
												{getDate(creditNoteDetails?.credit_note_date)}
											</p>
											<p style={{ borderBottom: '1px solid #E5E6E8', marginBottom: '15px' }}>N/A</p>
											<p style={{ borderBottom: '1px solid #E5E6E8' }}>
												{numberToWords(Number(creditNoteDetails?.credit_note_amount))}
											</p>
										</div>
									</div>
								</div>

								<div
									style={{
										backgroundColor: '#84AD5F',
										width: '170px',
										height: '110px',
										marginTop: '18px',
										color: 'white',
										textAlign: 'center',
									}}
								>
									<div style={{ marginTop: '32px' }}>
										<p style={{ marginRight: '24px' }}> Credit Amount</p>
										<p style={{ fontSize: '20px', fontWeight: '600' }}>
											{'Rs. '}
											{creditNoteDetails?.credit_note_amount}
										</p>
									</div>
								</div>
							</div>

							{/* Paid To */}
							<div style={{ marginBottom: '20px' }}>
								<p style={{ fontWeight: 'bold', margin: 0 }}>Paid To</p>
								<a
									href="#"
									style={{ color: '#3B58FF', textDecoration: 'none', fontWeight: 'bold' }}
								>
									ABC Company Private Limited
								</a>
							</div>

							{/* Table */}
							<table
								style={{
									width: '100%',
									border: '1px solid #dee2e6',
									borderCollapse: 'collapse',
									textAlign: 'left',
								}}
							>
								<thead>
									<tr style={{ backgroundColor: '#f1f3f5', fontWeight: 'bold', color: '#555B67' }}>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'left' }}>
											ITEM & DESCRIPTION
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'left' }}>
											QTY
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'right' }}>
											RATE
										</th>
										<th style={{ padding: '8px', border: '1px solid #dee2e6', textAlign: 'right' }}>
											AMOUNT
										</th>
									</tr>
								</thead>
								<tbody>
									{creditNoteDetails?.credit_note_products?.map((pb) => (
										<tr>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'left',
													color: '#3B58FF',
													cursor: 'pointer',
												}}
												onClick={() => {
													setProductDetails(pb);
													setIsSidebarOpen(true);
												}}
											>
												{pb?.product?.product_name}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'left',
													color: '#555B67',
												}}
											>
												{pb?.quantity}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'right',
													color: '#555B67',
												}}
											>
												{pb?.mrp}
											</td>
											<td
												style={{
													padding: '8px',
													border: '1px solid #dee2e6',
													textAlign: 'right',
													color: '#555B67',
												}}
											>
												{pb?.amount}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{isSidebarOpen && (
				<ProductDetailsDrawer
					id={productDetails?.product_id}
					vendor={{
						vendor_id: creditNoteDetails?.vendor?.vendor_id,
						vendor_name: creditNoteDetails?.vendor?.vendor_name,
					}}
					setIsSidebarOpen={setIsSidebarOpen}
				/>
			)}
			<ConfirmationModal
				isOpen={isDeleteModalOpen}
				onCancel={() => setIsDeleteModalOpen(false)}
				onOk={handleConfirmDelete}
				title="Confirm Deletion"
				content={
					<p
						style={{
							textAlign: 'center',
							backgroundColor: '#E6F5FF',
							padding: '10px',
							borderRadius: '5px',
							fontWeight: '500',
							color: 'black',
						}}
					>
						Are you sure you want to delete{' '}
						<span style={{ fontWeight: '600' }}>{creditNoteDetails?.credit_note_number}</span>?
					</p>
				}
			/>
		</div>
	);
};

export default CreditNoteDetails;
