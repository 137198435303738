import React, { useEffect, useState } from 'react';
import AddEmailModal from './AddEmailModal';
import Modal from '../../../../component/modal/Modal';
import closeIcon from '../../../../assets/image/SingeCloseRed.svg';
import EmailPlaceHolder from '../../../../assets/image/emailPlaceHolder.svg';
import './index.scss';
import '../../purchaseOrder.scss';

function EmailCommunication({
	formData,
	handleFormData,
	communicationEmail,
	setCommunicationEmail,
}) {
	// const [isSelectedEmails, setIsSelectedEmails] = useState([]);
	const [isAddContactModalOpen, setIsAddContactModal] = useState(false);

	// Initialize email list with isSelected flag
	// useEffect(() => {
	// 	function handleSetInitialEmails() {
	// 		if (formData?.email_communication.length > 0) {
	// 			const mappedEmails = formData?.email_communication.map((email) => ({
	// 				isSelected: false,
	// 				email,
	// 			}));
	// 			setIsSelectedEmails(mappedEmails);
	// 		}
	// 	}
	// 	handleSetInitialEmails();
	// }, [formData?.email_communication]);

	// Pass selected emails to parent component
	const updateEmailCommunication = (emails) => {
		const selectedEmails = emails.filter((email) => email.isSelected).map((e) => e.email);
		handleFormData('email_communication', selectedEmails);
	};

	// Toggle individual email selection
	const handleCheckboxChange = (index) => {
		const updatedEmails = communicationEmail.map((email, i) =>
			i === index ? { ...email, isSelected: !email.isSelected } : email,
		);
		setCommunicationEmail(updatedEmails);
		updateEmailCommunication(updatedEmails);
	};

	// Toggle selection of all emails
	const handleSelectAll = () => {
		const allSelected = communicationEmail.every((email) => email.isSelected);
		const updatedEmails = communicationEmail.map((email) => ({
			...email,
			isSelected: !allSelected,
		}));
		setCommunicationEmail(updatedEmails);
		updateEmailCommunication(updatedEmails);
	};

	const handleRemoveEmail = (removeThisEmail) => {
		const updatedEmail = communicationEmail.filter((email) => email !== removeThisEmail);
		setCommunicationEmail(updatedEmail);
		updateEmailCommunication(updatedEmail);
	};

	return (
		<div style={{ padding: '10px 20px' }}>
			<div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
				<p style={{ fontWeight: '600' }}>Email Communications</p>
				<p
					style={{ color: 'blue', cursor: 'pointer', textDecorationLine: 'underline' }}
					onClick={handleSelectAll}
				>
					Select All
				</p>
				<br />
				<br />
			</div>

			<div
				className="d-flex align-items-center flex-wrap "
				style={{ gap: 15 }}
			>
				{communicationEmail &&
					communicationEmail.map((email, index) => (
						<div
							key={email.email}
							style={{
								display: 'flex',
								alignContent: 'center',
								border: '1px solid #C3CBDC',
								backgroundColor: '#F6F6F9',
								borderRadius: '5px',
								fontSize: '14px',
								fontWeight: '600',
								height: '36px',
							}}
							onChange={() => handleCheckboxChange(index)}
						>
							<div
								className="d-flex align-items-center "
								style={{
									gap: '5px',
									display: 'flex',
									justifyContent: 'space-evenly',
									alignItems: 'center',
									padding: '0px 5px',
								}}
							>
								<div style={{ marginLeft: '5px', width: '24px' }}>
									<label className="custom-checkbox">
										<input
											type="checkbox"
											checked={email.isSelected}
											onChange={() => handleCheckboxChange(index)}
											style={{ display: 'none' }} // Hide the default checkbox
										/>
										<span className="checkmark"></span>
									</label>
								</div>

								<div
									className="d-flex align-items-center justify-content-center"
									style={{
										width: '26px',
										height: '26px',
										borderRadius: '5px',
										backgroundColor: '#EDEDF7',
										width: '27px',
									}}
								>
									<img
										src={EmailPlaceHolder}
										alt=""
									/>
								</div>

								<div
									className="d-flex "
									style={{
										color: '#000000',
										overflow: 'scroll auto ',
										marginTop: '15px',
										fontSize: '13px',
										fontWeight: 500,
									}}
								>
									{email.email}
								</div>

								<div
									style={{
										paddingLeft: '5px',
										cursor: 'pointer',
									}}
								>
									<img
										src={closeIcon}
										alt="Closed"
										width={12}
										height={30}
										onClick={() => handleRemoveEmail(email)}
									/>
								</div>
							</div>
						</div>
					))}
				{formData?.vendor_id && (
					<p
						style={{ color: 'blue', cursor: 'pointer', textDecorationLine: 'underline' }}
						onClick={() => {
							setIsAddContactModal(true);
						}}
					>
						+ Add New
					</p>
				)}
			</div>

			{isAddContactModalOpen && (
				<div>
					<Modal
						className="add-contact-modal"
						isOpen={isAddContactModalOpen}
						onClose={() => setIsAddContactModal(false)}
						title="Add Contact Person"
						cancelButton={true}
					>
						<AddEmailModal
							setIsAddContactModal={setIsAddContactModal}
							vendor_id={formData?.vendor_id}
							communicationEmail={communicationEmail}
							setCommunicationEmail={setCommunicationEmail}
						/>
					</Modal>
				</div>
			)}
		</div>
	);
}

export default EmailCommunication;
